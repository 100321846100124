import { REDUCERS } from '../../constants/reducers';

const initialState = {
  roles: []
};

const admin = (state = initialState, action) => {
  switch (action.type) {
    case REDUCERS.ADMIN.SET_ADMIN_STATE: {
      return Object.assign({}, state, {
        ...state,
        ...action.value
      });
    }
    case REDUCERS.RESET:
      return initialState;
    default:
      return state;
  }
};

export { admin };
