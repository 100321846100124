import React from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  FormGroup,
  Label,
  Input,
  Table
} from 'reactstrap';
import Select from 'react-select';
import classnames from 'classnames';
import ReactTable from 'react-table';
import moment from 'moment';
import Slider from 'rc-slider/lib/Slider';

import axios from '../../axios';
import default_animal from '../../assets/images/default-animal.jpg';
import zoom from '../../assets/images/zoom.png';
import Map from '../../components/map-tracking.jsx';
import LoadingBar from '../../components/LoadingBar';
import SelectionPopover from '../../components/Popovers/SelectionPopover';
import { errorToastHandler } from '../../components/action_notifier';
import PrevNextButtons from '../../components/PrevNextButtons';
import { capitalize } from '../../helpers/common';
import StatusIndicator from '../../components/statusIndicator';
import messages from '../../constants/messages';

import 'rc-slider/assets/index.css';

const minTimeFrameDotsNumber = {
  1: 5,
  2: 10,
  6: 30,
  12: 60,
  24: 120,
  36: 180,
  48: 240
};
class Tracking extends React.Component {
  time_frame = 66;
  time_interval = 0;

  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      isOpenSelectionPopover: false,
      tableData: [],
      animal: null,
      animal_tracking: {},
      animal_tracking_filtered: {},
      animals: [],
      farms: [],
      dropdownOpen: false,
      animalId: this.props?.match?.params?.id,
      farm_opts: [],
      activeTab: '1',
      all_tracks: {},
      all_tracksCopy: {},
      filter_open: false,
      isFilterApplied: false,
      isFullScreen: false,
      isTracksLoading: false,
      filters: {
        time: null,
        labels: [],
        notifications: false,
        animal_ids: [],
        farms: []
      },
      time_options: {
        '1day': moment.utc().subtract(1, 'day').format(),
        '1week': moment.utc().subtract(1, 'week').format(),
        '2week': moment.utc().subtract(2, 'week').format(),
        '4week': moment.utc().subtract(4, 'week').format()
      },
      col_1: {
        eartag_management_id: {
          is_editable: true,
          lable: 'Mgmt Tag ID'
        },
        kraal_tag_id: {
          is_editable: true,
          lable: 'Kraal Tag ID'
        },
        eartag_official_id: {
          is_editable: true,
          lable: 'Official Tag ID'
        },
        brand: {
          is_editable: true,
          lable: 'Brand'
        },
        name: {
          is_editable: true,
          lable: 'Description'
        },
        colour: {
          is_editable: true,
          lable: 'Colour'
        },
        breed: {
          is_editable: true,
          lable: 'Breed'
        },
        sex: {
          is_editable: true,
          lable: 'Sex'
        }
      },
      col_2: {
        geofences: {
          is_editable: true,
          lable: 'Assigned Geofence'
        },
        dob_at: {
          is_editable: true,
          lable: 'DOB'
        },
        age: {
          is_editable: false,
          lable: 'Age'
        },
        last_notification: {
          is_editable: false,
          lable: 'Last Notification'
        },
        last_reading: {
          is_editable: false,
          lable: 'Last Reading'
        }
      },
      detail_slider: {
        timeFrameSlots: [
          {
            index: 0,
            value: 1,
            label: '1hr'
          },
          {
            index: 16,
            value: 2,
            label: '2hrs'
          },
          {
            index: 32,
            value: 6,
            label: '6hrs'
          },
          {
            index: 49,
            value: 12,
            label: '12hrs'
          },
          {
            index: 66,
            value: 24,
            label: '24hrs'
          },
          {
            index: 83,
            value: 36,
            label: '36hrs'
          },
          {
            index: 100,
            value: 48,
            label: '48hrs'
          }
          // {
          //   index: 86,
          //   value: 96,
          //   label: '4days'
          // },
          // {
          //   index: 100,
          //   value: 168,
          //   label: '7days'
          // }
        ],
        timeIntervalSlots: [
          {
            index: 0,
            value: 1,
            label: '10mins'
          },
          {
            index: 15,
            value: 2,
            label: '20mins'
          },
          {
            index: 30,
            value: 3,
            label: '30mins'
          },
          {
            index: 45,
            value: 6,
            label: '60mins'
          },
          {
            index: 58,
            value: 12,
            label: '2hrs'
          },
          {
            index: 72,
            value: 36,
            label: '6hrs'
          },
          {
            index: 86,
            value: 72,
            label: '12hrs'
          },
          {
            index: 100,
            value: 144,
            label: '24hrs'
          }
        ]
      },
      endDateTime: moment.utc().format(),
      selectAll: false,
      selected_animals: []
    };
    this.onChange = this.onChange.bind(this);
    this.toggleButton = this.toggleButton.bind(this);
    this.trProps = this.trProps.bind(this);
    this.toggle = this.toggle.bind(this);
    this.setAnimal = this.setAnimal.bind(this);
    this.toggleFilters = this.toggleFilters.bind(this);
    this.closeFilters = this.closeFilters.bind(this);
    this.changeFilter = this.changeFilter.bind(this);
    this.getLabels = this.getLabels.bind(this);
    this.changeNotificationsFilter = this.changeNotificationsFilter.bind(this);
    this.showSelectedAnimals = this.showSelectedAnimals.bind(this);
    this.singleSelect = this.singleSelect.bind(this);
    this.closeDetailView = this.closeDetailView.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.onClickAllRecords = this.onClickAllRecords.bind(this);
    this.onClickOnThisPage = this.onClickOnThisPage.bind(this);
  }
  toggleFilters() {
    this.setState({
      filter_open: !this.state.filter_open
    });
  }
  onToggleSelectionPopover(isOpen) {
    const open =
      isOpen !== undefined
        ? isOpen
        : !this.state.isOpenSelectionPopover && !this.state.selectAll;

    this.setState({
      isOpenSelectionPopover: open
    });
  }
  closeFilters(bool) {
    this.setState({
      filter_open: false,
      isFilterApplied: bool
    });
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  onChange = (value, field) => {
    this.setState((state) => ({
      ...state,
      [field]: value
    }));
  };
  componentDidMount() {
    this.resetFilters();
    this.getAnimals();
    this.getFarms();
    this.getLabels();

    if (this.props?.match?.params?.id) {
      this.setState(
        {
          animalId: this.props.match.params.id
        },
        () => this.getAnimal()
      );
    }
  }
  async getAllTracks() {
    try {
      let response = await axios.get('animals/tag-data', {
        params: {
          label_ids: this.state.filters.labels.map((x) => x.value),
          only_notifications: this.state.filters.notifications,
          farm_ids: this.state.filters.farms.map((x) => x.value)
        }
      });
      if (response.status === 200) {
        if (response.data.features) {
          response.data.features = response.data.features.map((x) => {
            if (x.properties) {
              x.properties['isVisible'] = true;
            }
            return x;
          });
        }
        if (this.state.animals.length === 0) {
          this.setState({
            tracksLoaded: true,
            all_tracks: {},
            all_tracksCopy: {}
          });
          return;
        }
        response.data.features.map((m) => {
          this.state.animals.find((x) => {
            if (x.id === m.properties.animal_id) {
              m.geometry.coordinates[0] = x?.tag?.lat;
              m.geometry.coordinates[1] = x?.tag?.lng;
            }
          });
        });
        const res = this.state.animals.filter(
          (item1) =>
            !response.data.features.some(
              (item2) => item2.properties.animal_id === item1.id
            )
        );
        let arr = [];
        for (let i in res) {
          arr.push({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [res[i].tag?.lat, res[i].tag?.lng]
            },
            properties: {
              farm_id: res[i].farm_id,
              animal_id: res[i].id,
              customIcon: 'start',
              diagri_id: res[i].tag.diagri_id,
              eartag_management_id: res[i].eartag_management_id,
              eartag_official_id: res[i].eartag_official_id,
              hasAlerts: res[i].hasAlerts,
              identifier: res[i].identifier,
              isVisible: true,
              notifications: [],
              sex: res[i].sex,
              specie: res[i].specie.name,
              time: ''
            }
          });
        }
        response.data.features = response.data.features.concat(arr);
        this.setState({
          tracksLoaded: true,
          all_tracks: response.data,
          all_tracksCopy: response.data
        });
        this.showSelectedAnimals();
      }
    } catch (e) {
      this.setState({
        tracksLoaded: true
      });
    }
  }
  async getAnimal() {
    let animalId = this.state.animalId;
    if (animalId === null) {
      this.setState({
        animal: null
      });
    } else {
      try {
        const animal = await axios.get('animals/' + animalId, {
          params: {
            only_linked: true
          }
        });

        const animalTagData = await axios.get(
          'animals/' + animalId + '/tag-data',
          {
            params: {
              from_date: moment.utc().subtract(48, 'hours').format(), // LAST 2 DAYS DATA (24*2)
              only_notifications: this.state.filters.notifications
            }
          }
        );

        if (animalTagData.data.features) {
          animalTagData.data.features = animalTagData.data.features.map(
            (item) => {
              return {
                ...item,
                properties: {
                  ...item.properties,
                  isVisible: true,
                  eartag_management_id: animal.data.eartag_management_id,
                  eartag_official_id: animal.data.eartag_official_id,
                  hasAlerts: animal.data.hasAlerts,
                  identifier: animal.data.identifier
                }
              };
            }
          );
        }

        this.setState({
          tracksLoaded: true,
          animal: animal.data,
          animal_tracking: animalTagData.data,
          farm: this.state.farms.find((x) => x.id === animal.data.farm_id)
        });
        // FILTER TRACKING DATA ACCORDING TO SLIDERS //
        this.filterAnimalTracking();
      } catch (e) {
        this.setState({
          tracksLoaded: true
        });
      }
    }
  }
  async getAnimals() {
    try {
      let response = await axios.get('animals', {
        params: {
          only_linked: true,
          label_ids: this.state.filters.labels.map((x) => x.value),
          only_notifications: this.state.filters.notifications,
          farm_ids: this.state.filters.farms.map((x) => x.value)
        }
      });
      if (response.status === 200) {
        this.setState(
          {
            animalsLoaded: true,
            animals: response.data
          },
          () => {
            this.getAllTracks();
          }
        );
      }
    } catch (e) {
      this.setState({
        animalsLoaded: true
      });
    }
  }
  toggleModal(modal) {
    this.setState((state) => ({
      ...state,
      [modal]: !state[modal]
    }));
  }
  async getFarms() {
    let response = await axios.get('farms?with_details=true');
    if (response.status === 200) {
      let mapped_farms = [];
      for (let farm of response.data) {
        mapped_farms.push({
          value: farm.id,
          label: farm.name
        });
      }
      this.setState({
        farmsLoaded: true,
        farms: response.data,
        farm_opts: mapped_farms
      });
    }
  }
  toggleButton() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  setAnimal(id) {
    this.props.history.push('/tracking/' + id);
  }
  async getLabels() {
    let response = await axios.get('/labels');
    if (response.status === 200) {
      this.setState({
        labelsLoaded: true,
        labels: response.data.map((x) => {
          return { value: x.id, label: x.name };
        })
      });
    }
  }
  trProps(state, rowInfo) {
    if (rowInfo && rowInfo.row) {
      return {
        onClick: () => {
          let id = rowInfo.original.id;
          if (this.state.animalId === id) {
            id = null;
          }
          if (id != null) {
            this.props.history.push('/tracking/' + id);
          } else {
            this.props.history.push('/tracking');
          }
          this.setState(
            {
              animalId: id
            },
            () => {
              this.getAnimal();
            }
          );
        },
        className:
          this.state.animal && this.state.animal.id === rowInfo.original.id
            ? 'selected'
            : ''
      };
    } else {
      return {};
    }
  }
  resetFilters(isClose) {
    this.setState(
      (state) => ({
        ...state,
        filters: {
          ...state.filters,
          labels: [],
          notifications: false,
          animal_ids: [],
          farms: []
        }
      }),
      () => this.updateFilters(isClose)
    );
  }
  updateFilters(bool) {
    this.setState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        animal_ids: []
      }
    }));
    this.getAnimals();
    this.showSelectedAnimals();
    if (bool) {
      this.closeFilters(true);
      return;
    }
    this.closeFilters(bool);
  }
  changeFilter(value, field) {
    this.setState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        [field]: value
      }
    }));
  }
  changeNotificationsFilter() {
    this.setState({
      filters: {
        ...this.state.filters,
        notifications: !this.state.filters.notifications
      }
    });
  }
  capitalize(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
  }
  singleSelect(id, isChecked) {
    id = parseInt(id);
    const animal_ids = this.state.filters.animal_ids;

    // REMOVE ELEMENT FROM ARRAY //
    if (!isChecked) {
      const index = animal_ids.indexOf(id);
      if (index > -1) {
        animal_ids.splice(index, 1);
      }
    }
    // ADD ELEMENT INTO ARRAY //
    else {
      animal_ids.push(id);
    }
    this.setState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        animal_ids: animal_ids
      }
    }));
    this.showSelectedAnimals();
  }
  showSelectedAnimals() {
    if (this.state.all_tracksCopy && this.state.all_tracksCopy.features) {
      let filteredFeatures = [...this.state.all_tracksCopy.features];
      const filterFarms = (this.state.filters.farms || []).map(({ value }) => {
        return value;
      });
      const filterAnimals = [...(this.state.filters.animal_ids || [])];
      if (filterFarms.length) {
        filteredFeatures = filteredFeatures.filter((item) => {
          return filterFarms.includes(item.properties.farm_id);
        });
      }

      if (filterAnimals.length) {
        filteredFeatures = filteredFeatures.filter((item) => {
          return filterAnimals.includes(item.properties.animal_id);
        });
      }

      this.setState((state) => ({
        ...state,
        all_tracks: {
          ...state.all_tracks,
          features: filteredFeatures
        }
      }));
    }
  }
  closeDetailView() {
    this.setState({
      animal: null,
      tracking: null,
      animalId: null,
      animal_tracking: {},
      animal_tracking_filtered: {}
    });
    this.props.history.push('/tracking');
  }
  sliderHandler(value, field) {
    this[field] = value;

    // FILTER TRACKING DATA ACCORDING TO SLIDERS //
    this.filterAnimalTracking();
  }
  filterAnimalTracking() {
    let time_frame = this.state.detail_slider.timeFrameSlots.filter(
      (x) => x.index === this.time_frame
    )[0];
    let time_interval = this.state.detail_slider.timeIntervalSlots.filter(
      (x) => x.index === this.time_interval
    )[0];

    let animal_tracking = this.state.animal_tracking;
    let line_coordinates = [],
      animal_tracking_filtered = [];

    const last_reading = animal_tracking.features
      .filter((x) => x.geometry.type === 'Point')
      .filter((x) => x.properties.last_reading);

    if (animal_tracking.type) {
      animal_tracking_filtered = animal_tracking.features
        .filter((x) => x.geometry.type === 'Point')
        .filter((x) => !x.properties.last_reading)
        .map((x) => {
          delete x.properties.customIcon;
          return x;
        });

      // APPLY FILTERS 'TIME FRAME & TIME INTERVAL' //
      if (time_frame.index !== 100 || time_interval.index !== 0) {
        // ADDED 12 MINS MORE, SO CURRENT/START POINT CAN ALSO CONSIDER //
        let startDateTime = moment(this.state.endDateTime)
          .utc()
          .subtract({ hours: time_frame.value, minutes: 12 })
          .format();

        // FILTER WITH 'TIME FRAME' //
        if (time_frame.index !== 100) {
          animal_tracking_filtered = animal_tracking_filtered.filter((item) => {
            return moment(item.properties.time).isBetween(
              startDateTime,
              this.state.endDateTime
            );
          });
        }

        // FILTER WITH 'TIME INTERVAL'; GET EVERY NTH ELEMENT; //
        if (time_interval.index !== 0) {
          let temp_ary = [];
          for (
            let i = 0;
            i < animal_tracking_filtered.length;
            i = i + time_interval.value
          ) {
            temp_ary.push(animal_tracking_filtered[i]);
          }
          animal_tracking_filtered = temp_ary;
        }
      }

      line_coordinates = animal_tracking_filtered.map((x) => {
        return x.geometry.coordinates;
      });
    }

    const isLastReading = !animal_tracking_filtered.length;

    const isPartialReading =
      animal_tracking_filtered.length &&
      animal_tracking_filtered.length <=
        minTimeFrameDotsNumber[time_frame.value];

    if (animal_tracking_filtered.length > 1) {
      // MINIMUM 2 COORDINATES REQUIRED FOR LINE //
      animal_tracking_filtered.unshift({
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: line_coordinates
        }
      });
    }

    if (isLastReading) {
      animal_tracking_filtered = last_reading;
      errorToastHandler(messages.NO_LOCATION_DATA_AVAILABLE_FOR_TIME_FRAME);
    }

    if (isPartialReading) {
      errorToastHandler(messages.PARTIAL_LOCATION_DATA_AVAILABLE_FOR_PART_TIME);
    }

    this.setState({
      animal_tracking_filtered: {
        type: this.state.animal_tracking.type,
        features: animal_tracking_filtered
      }
    });
  }
  toggleFullscreen() {
    this.setState((state) => ({
      ...state,
      isFullScreen: !this.state.isFullScreen
    }));
  }

  onClickOnThisPage() {
    const newCheckboxValue = !this.state.selectAll;

    let checkedCopy = [];
    if (newCheckboxValue) {
      this.state.tableData.map((val) => {
        checkedCopy.push(val._original.id);
      });
    }
    this.setState(
      (state) => ({
        ...state,
        filters: {
          ...state.filters,
          animal_ids: checkedCopy
        }
      }),
      () => this.showSelectedAnimals()
    );
    this.setState({
      selectAll: newCheckboxValue
    });
    this.onToggleSelectionPopover();
  }

  onClickAllRecords() {
    const newCheckboxValue = !this.state.selectAll;
    let checkedCopy = [];
    if (newCheckboxValue) {
      this.state.animals.map((val) => {
        checkedCopy.push(val.id);
      });
    }
    this.setState(
      (state) => ({
        ...state,
        filters: {
          ...state.filters,
          animal_ids: checkedCopy
        }
      }),
      () => this.showSelectedAnimals()
    );
    this.setState({
      selectAll: newCheckboxValue
    });
    this.onToggleSelectionPopover();
  }

  render() {
    if (this.props?.match?.params?.id) {
      if (this.state.animal === null) {
        return <LoadingBar></LoadingBar>;
      }
    }
    if (
      (!this.state.tracksLoaded ||
        !this.state.animalsLoaded ||
        !this.state.farmsLoaded) &&
      !this.state.isFilterApplied
    ) {
      return <LoadingBar></LoadingBar>;
    }
    const checkboxColumn = {
      id: 'select',
      accessor: (d) => {
        return { id: d.id, tag: d.tag };
      },
      headerClassName: 'wordwrap',
      sortable: false,
      Header: () => (
        <div className="position-relative form-check">
          <SelectionPopover
            isOpen={this.state.isOpenSelectionPopover}
            onClickOnThisPage={this.onClickOnThisPage}
            onClickAllRecords={this.onClickAllRecords}
            setOpen={(isOpen) =>
              this.setState({
                isOpenSelectionPopover:
                  isOpen !== undefined
                    ? isOpen
                    : !this.state.isOpenSelectionPopover
              })
            }
            content={
              <input
                type="checkbox"
                defaultChecked={this.state.selectAll}
                onChange={() => {
                  this.onToggleSelectionPopover();
                  if (this.state.selectAll) {
                    this.setState({
                      selectAll: !this.state.selectAll
                    });
                    this.setState((state) => ({
                      ...state,
                      filters: {
                        ...state.filters,
                        animal_ids: []
                      }
                    }));
                  }
                }}
              />
            }
          />
        </div>
      ),
      Cell: (props) => {
        return (
          <FormGroup check>
            <Input
              type="checkbox"
              checked={this.state.filters.animal_ids.includes(
                parseInt(props.value.id)
              )}
              value={props.value.id}
              onChange={(e) =>
                this.singleSelect(e.target.value, e.target.checked)
              }
            />{' '}
          </FormGroup>
        );
      },
      filterable: false,
      maxWidth: 85
    };
    const columns = [
      {
        id: 'identifier',
        minWidth: 140,
        Header: 'Livestock ID',
        accessor: (d) => {
          return { id: d.id, identifier: d.identifier };
        },
        headerClassName: 'wordwrap',
        sortMethod: (a, b) => {
          return a.identifier.localeCompare(b.identifier);
        },
        Cell: (props) => (
          <a href={'/tracking/' + props.value.id}>{props.value.identifier}</a>
        )
      },
      {
        Header: 'Mgmt Tag ID',
        accessor: 'eartag_management_id',
        headerClassName: 'wordwrap',
        sortMethod: (a, b) => {
          return a.localeCompare(b);
        },
        Cell: (props) => {
          return props.value.indexOf('mgmt~tmp~') > -1 ? '' : props.value;
        },
        minWidth: 130
      },
      {
        Header: 'Sex',
        accessor: 'sex',
        headerClassName: 'wordwrap',
        Cell: (props) => {
          return this.capitalize(props.value);
        }
      },
      {
        Header: 'Colour',
        accessor: 'colour',
        headerClassName: 'wordwrap'
      },

      {
        Header: 'Breed',
        accessor: 'breed.display_name',
        headerClassName: 'wordwrap',
        minWidth: 120
      },
      {
        Header: 'Kraal Tag ID',
        id: 'KraalTag',
        accessor: (d) => {
          return {
            tag_id: d.tag ? d.tag.id : '',
            identifier: d.tag ? d.tag.diagri_id : 'Unlinked'
          };
        },
        headerClassName: 'wordwrap',
        sortMethod: (a, b) => {
          return a.identifier.localeCompare(b.identifier);
        },
        Cell: (props) => {
          return props.value.tag_id !== '' ? (
            <a
              href="#"
              onClick={() =>
                this.props.history.push('/tag/' + props.value.tag_id)
              }
            >
              {props.value.identifier}
            </a>
          ) : (
            'Unlinked'
          );
        },
        minWidth: 150
      },
      {
        Header: 'Brand',
        accessor: 'brand',
        headerClassName: 'wordwrap'
      },
      {
        Header: 'Farm',
        accessor: 'farm.name',
        headerClassName: 'wordwrap',
        minWidth: 180
      },
      {
        Header: 'Assigned Geofence',
        id: 'Assignedg',
        accessor: (d) => {
          return {
            identifier:
              d.geofences.length > 0
                ? d.geofences.filter((a) => a.is_master != true).length > 0
                  ? d.geofences
                      .filter((a) => a.is_master != true)
                      .map((x) => {
                        return x.name;
                      })
                      .join(',')
                  : ''
                : '',
            name:
              d.geofences.length > 0
                ? d.geofences.filter((a) => a.is_master != true).length > 0
                  ? d.geofences
                      .filter((a) => a.is_master != true)
                      .map((x, index) => {
                        return (
                          <a
                            key={index}
                            href="#"
                            onClick={() =>
                              this.props.history.push('/geofence/' + x.id)
                            }
                          >
                            {x.name}
                          </a>
                        );
                      })
                      .reduce((prev, curr) => [prev, ', ', curr])
                  : ''
                : ''
          };
        },
        headerClassName: 'wordwrap',
        Cell: (props) => {
          return props.value.name;
        },
        sortMethod: (a, b) => {
          return a.identifier.localeCompare(b.identifier);
        },
        minWidth: 150
      },
      {
        Header: 'Last Reading',
        id: 'timestamp_at',
        accessor: (item) => {
          const animalId = item.id;

          const tagData = this.state.all_tracks?.features?.length
            ? this.state.all_tracks?.features.find(
                (item) => item.properties.animal_id === animalId
              )
            : null;
          const lastReadingTime = tagData?.properties?.time;
          return lastReadingTime;
        },
        headerClassName: 'wordwrap',
        sortable: true,
        sortMethod: (a, b) => {
          return moment(b).format('x') - moment(a).format('x');
        },
        Cell: (v) => {
          const animalId = v.original.id;

          const tagData = this.state.all_tracks?.features?.length
            ? this.state.all_tracks?.features.find(
                (item) => item.properties.animal_id === animalId
              )
            : null;
          const lastReadingTime = tagData?.properties?.time;
          return lastReadingTime
            ? moment(lastReadingTime).format('DD/MM/YYYY HH:mm:ss')
            : null;
        },
        minWidth: 170
      },
      {
        Header: 'Connection Status',
        id: 'connection_status',
        headerClassName: 'wordwrap',
        accessor: (d) => {
          return d?.tag?.status;
        },
        sortMethod: (a, b) => {
          return a?.localeCompare(b);
        },
        Cell: (props) => {
          const value = props.original?.tag?.status;
          const id = props.original?.id;
          if (value) {
            return (
              <div>
                <StatusIndicator key={id} status={value} />
                {capitalize(value)}
              </div>
            );
          }
          return <></>;
        },
        minWidth: 150
      }
    ];

    if (!this.props.match.params.id) {
      columns.unshift(checkboxColumn);
    }

    function filterCaseInsensitive(filter, row) {
      const id = filter.pivotId || filter.id;
      if (typeof row[id] === 'object') {
        return row[id] !== undefined
          ? String(row[id]['identifier'].toLowerCase()).includes(
              filter.value.toLowerCase()
            )
          : true;
      } else if (typeof row[id] === 'number') {
        return row[id] !== undefined
          ? String(row[id]).includes(filter.value)
          : true;
      } else {
        return row[id] !== undefined
          ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
          : true;
      }
    }

    let COL1_ROWS = [],
      COL2_ROWS = [];
    if (this.state.animal) {
      // COLOUMN 1 - DATA
      for (let key of Object.keys(this.state.col_1)) {
        let label = this.state.col_1[key]['lable'] || '';
        let value = this.state.animal[key];

        if (key === 'eartag_management_id' && value.indexOf('mgmt~tmp~') > -1) {
          value = '';
        } else if (key === 'sex') {
          if (value === 'male') value = 'Male';
          else if (value === 'female') value = 'Female';
        } else if (key === 'kraal_tag_id') {
          if (this.state.animal[key]) {
            value = this.state.animal[key];
          } else {
            value = this.state.animal['tag'];
            value =
              value && value.diagri_id ? (
                <a href={'/tag/' + value.id}>{value.diagri_id}</a>
              ) : (
                ''
              );
          }
        } else if (key === 'stock_type') {
          value = this.capitalize(value);
        } else if (key === 'breed' && value?.display_name) {
          value = value?.display_name;
        } else if (value === null) {
          value = '';
        }

        COL1_ROWS.push(
          <tr key={key}>
            <td>{label}</td>
            <td>{value}</td>
          </tr>
        );
      }

      // COLOUMN 2 - DATA
      for (let key of Object.keys(this.state.col_2)) {
        let label = this.state.col_2[key]['lable'] || '';
        let value = this.state.animal[key];
        if (
          key === 'dob_at' &&
          value &&
          value != '' &&
          value != '0000-00-00 00:00:00'
        ) {
          value = new Date(value);
          value = moment(value).format('DD.MM.YYYY');
        }
        if (key === 'geofences') {
          value = value.filter((s) => s.name.indexOf('Master Geofence') === -1);
          value =
            value && value.length > 0
              ? value
                  .map((x) => (
                    <a
                      key={x.id}
                      href="javascript:;"
                      onClick={() =>
                        this.props.history.push('/geofence/' + x.id)
                      }
                    >
                      {x.name}
                    </a>
                  ))
                  .reduce((prev, curr) => [prev, ', ', curr]) || ''
              : '';
        } else if (key === 'age') {
          value = this.state.animal['dob_at'];
          if (value && value != '' && value != '0000-00-00 00:00:00') {
            let b = moment(value);
            let a = moment();
            let years = a.diff(b, 'year');
            b.add(years, 'years');
            let months = a.diff(b, 'months');
            b.add(months, 'months');
            let days = a.diff(b, 'days');
            value = years + 'y ' + months + 'm ' + days + 'd';
          } else {
            value = '';
          }
        } else if (key === 'last_reading') {
          value = moment(this.state.animal?.tag?.timestamp_at).format(
            'DD/MM/YYYY HH:mm:ss'
          );
        } else if (key === 'last_notification') {
          value = moment(
            this.state.animal?.last_notification?.created_at
          ).format('DD/MM/YYYY HH:mm:ss');
        } else if (value === null) {
          value = '';
        }

        COL2_ROWS.push(
          <tr key={key}>
            <td>{label}</td>
            <td>{value}</td>
          </tr>
        );
      }
    }

    let timeFrameSlots = {},
      timeIntervalSlots = {};
    for (let indx in this.state.detail_slider.timeFrameSlots) {
      let index = this.state.detail_slider.timeFrameSlots[indx].index;
      timeFrameSlots[index] =
        this.state.detail_slider.timeFrameSlots[indx].label;
    }
    for (let indx in this.state.detail_slider.timeIntervalSlots) {
      let index = this.state.detail_slider.timeIntervalSlots[indx].index;
      timeIntervalSlots[index] =
        this.state.detail_slider.timeIntervalSlots[indx].label;
    }
    const zoomImg = {
      backgroundImage: 'url(' + zoom + ')'
    };

    const filteredFarms = this.state.farms.filter((farm) => {
      return (
        !this.state.filters.farms.length ||
        this.state.filters.farms.find((item) => {
          return item.value === farm.id;
        })
      );
    });

    return (
      <div key={this.props.match.params.id}>
        <aside
          className={this.state.filter_open ? 'active' : ''}
          id="track-filters"
        >
          <div className="filters-header">
            <div className="close" onClick={() => this.closeFilters()}>
              <i className="mdi mdi-close"></i>
            </div>
            <h5>All filters</h5>
          </div>
          <div className="filters-body">
            <div className="filter-block time">
              <p>Farms filter</p>
              <FormGroup row>
                <Col sm="12" lg="12">
                  <Select
                    name="type"
                    onChange={(value) => this.changeFilter(value, 'farms')}
                    options={this.state.farm_opts}
                    isMulti={true}
                    isSearchable={true}
                  ></Select>
                </Col>
              </FormGroup>
            </div>
            <div className="filter-block labels">
              <p>Groups filter</p>
              <FormGroup row>
                <Col sm="12" lg="12">
                  <Select
                    name="type"
                    onChange={(value) => this.changeFilter(value, 'labels')}
                    options={this.state.labels}
                    isMulti={true}
                  ></Select>
                </Col>
              </FormGroup>
            </div>
            <div className="filter-block notifications">
              <p>Notifications filter</p>
              <FormGroup row>
                <Col sm="2">
                  <Input
                    style={{ marginLeft: 0 }}
                    checked={this.state.filters.notifications}
                    value={!this.state.filters.notifications}
                    onChange={() => this.changeNotificationsFilter()}
                    name="notifications"
                    type="checkbox"
                  ></Input>
                </Col>
                <Label sm="10">Only livestock with notifications</Label>
              </FormGroup>
            </div>
          </div>
          <div className="filters-footer">
            <Button
              color="primary"
              className=""
              onClick={() => this.updateFilters(true)}
            >
              Apply filters
            </Button>
            <Button
              className="outline float-right"
              onClick={() => this.resetFilters(true)}
            >
              Reset
            </Button>
          </div>
        </aside>
        <Row className="inner-header-top">
          <Col xs="12" md="12" lg="6">
            <h4 className="">
              Track Livestock{' '}
              {this.state.animal &&
                (this.state.animal.identifier ||
                  this.state.animal.eartag_official_id)}
            </h4>
            <Breadcrumb>
              <BreadcrumbItem>
                <a
                  href="#"
                  onClick={() => this.props.history.push('/tracking')}
                >
                  Track Livestock
                </a>
              </BreadcrumbItem>
              {this.state.animal && (
                <BreadcrumbItem>
                  <a
                    href="#"
                    onClick={() =>
                      this.props.history.push('/animal/' + this.state.animal.id)
                    }
                  >
                    Livestock{' '}
                    {this.state.animal.identifier ||
                      this.state.animal.eartag_official_id}
                  </a>
                </BreadcrumbItem>
              )}
            </Breadcrumb>
          </Col>
          <Col xs="12" md="12" lg="6">
            {this.state.animal && (
              <PrevNextButtons
                history={this.props.history}
                nextId={this.state.animal?.next_id}
                prevId={this.state.animal?.prev_id}
                path="tracking"
              />
            )}
            {!this.state.animal && (
              <Button
                color="primary"
                className="float-right"
                onClick={() => this.toggleFilters()}
              >
                <i className="fa fa-filter"></i>Filters
              </Button>
            )}
          </Col>
        </Row>
        <div className={this.state.isFullScreen ? 'rg-full-screen' : ''}>
          <Row>
            <Col lg="12">
              <div id="track_map">
                <Map
                  isFullScreen={this.state.isFullScreen}
                  key={JSON.stringify(this.state.animal)}
                  className="small-map"
                  onAnimalClick={this.setAnimal}
                  animal={this.state.animal}
                  farm={this.state.animal ? this.state.farm : null}
                  farms={!this.state.animal ? filteredFarms : null}
                  tracking={
                    this.state.animal
                      ? this.state.animal_tracking_filtered
                      : this.state.all_tracks
                  }
                />
              </div>
              {this.state.animal && (
                <div>
                  <div
                    className="full-screen-icon text-center"
                    style={zoomImg}
                    onClick={this.toggleFullscreen}
                  ></div>

                  {this.state.isFullScreen && (
                    <div className="full-screen-animal-map-popup">
                      <ul>
                        <li>
                          <b>Livestock ID: </b>
                          <span className="text-muted">
                            <a
                              href="#"
                              onClick={() =>
                                this.props.history.push(
                                  '/animal/' + this.state.animal.id
                                )
                              }
                            >
                              {this.state.animal.identifier}
                            </a>
                          </span>{' '}
                        </li>
                        <li>
                          <b>Mgmt Tag ID: </b>{' '}
                          <span className="text-muted">
                            {this.state.animal.eartag_management_id.indexOf(
                              'mgmt~tmp~'
                            ) > -1
                              ? ''
                              : this.state.animal.eartag_management_id}
                          </span>
                        </li>
                        <li>
                          <img
                            src={this.state.animal.photo || default_animal}
                            className="card-img-top ieFix"
                          />
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              )}
            </Col>
          </Row>
          {this.state.animal && (
            <Card className="animal-single-detail-page mb-1 range-sliders">
              <CardBody>
                <Row>
                  <Col lg="6" className="r-slider-left">
                    <Col className="text-left">
                      <b>Time Frame</b>
                    </Col>
                    <Slider
                      step={null}
                      marks={timeFrameSlots}
                      onAfterChange={(e) => this.sliderHandler(e, 'time_frame')}
                      defaultValue={66}
                    />
                  </Col>

                  <Col lg="6" className="r-slider-right">
                    <Col className="text-left">
                      <b>Time Interval</b>
                    </Col>
                    <Slider
                      step={null}
                      marks={timeIntervalSlots}
                      onAfterChange={(e) =>
                        this.sliderHandler(e, 'time_interval')
                      }
                      defaultValue={0}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}
        </div>
        {this.state.animal && (
          <Row>
            <Col lg="12">
              <Card className="animal-single-detail-page mb-1">
                <CardBody className="pb-0">
                  <Row>
                    <Col sm="9" style={{ padding: '0px 15px' }}>
                      <h4>Livestock {this.state.animal.identifier} Details</h4>
                    </Col>
                    <Col sm="3">
                      <Button
                        color="primary"
                        className="outline blue float-right btn-sm more-btn"
                        onClick={() =>
                          this.props.history.push(
                            '/animal/' + this.state.animal.id
                          )
                        }
                      >
                        More Details
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="4">
                      <Table className="no-border less-pad dual-text animal-card-table">
                        <tbody>{COL1_ROWS}</tbody>
                      </Table>
                    </Col>
                    <Col sm="5">
                      <Table className="no-border less-pad dual-text animal-card-table">
                        <tbody>{COL2_ROWS}</tbody>
                      </Table>
                    </Col>
                    <Col
                      sm="3"
                      className="animal-photo text-right tracking-detail"
                    >
                      <Card className="float-right">
                        <img
                          src={this.state.animal.photo || default_animal}
                          className="card-img-top ieFix"
                        />
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        <Card>
          <Row>
            <Col sm="6" style={{ padding: '15px 30px 0px 30px' }}>
              <h4>Track Livestock</h4>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Nav tabs className="fancy-tabs">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === '1'
                    })}
                    onClick={() => {
                      this.toggle('1');
                    }}
                  >
                    Livestock Location
                  </NavLink>
                </NavItem>
              </Nav>
              <Card>
                <TabContent className="no-bg" activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <ReactTable
                      showPagination={this.state.animals.length > 0}
                      minRows={0}
                      columns={columns}
                      data={this.state.animals}
                      resizable={false}
                      filterable={true}
                      defaultFilterMethod={filterCaseInsensitive}
                      defaultPageSize={25}
                      onFetchData={(props) => {
                        const data = props.data.length
                          ? props.sortedData.slice(0, props.pageSize)
                          : this.state.animals;
                        this.setState({ tableData: data });
                      }}
                    />
                  </TabPane>
                </TabContent>
              </Card>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

export default Tracking;
