import React, { memo } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';

const WidgetTemplate = ({ icon, title, value, onClick }) => {
  return (
    <Card className="rg-pointer" onClick={onClick}>
      {icon}
      <CardBody>
        <CardTitle>{title}</CardTitle>
        <h4 className="font-bold">{value}</h4>
      </CardBody>
    </Card>
  );
};

export default memo(WidgetTemplate);
