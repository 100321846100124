import React from 'react';

const Priority = ({ priority, isClear }) => {
  const backgroundColors = {
    priority: !isClear ? 'red' : '',
    high: !isClear ? 'red' : '',
    normal: !isClear ? '#FFBF00' : '',
    medium: !isClear ? '#FFBF00' : '',
    low: !isClear ? '#CCCCCC' : '',
    record: !isClear ? '#CCCCCC' : ''
  };
  const textColors = {
    priority: !isClear ? 'white' : '#565663',
    high: !isClear ? 'white' : '#565663',
    normal: !isClear ? 'black' : '#565663',
    medium: !isClear ? 'black' : '#565663',
    low: !isClear ? 'black' : '#565663',
    record: !isClear ? 'black' : '#565663'
  };
  return (
    <div
      className="priority-tag"
      style={{
        background: backgroundColors[priority],
        color: textColors[priority]
      }}
    >
      {priority.charAt(0).toUpperCase() + priority.slice(1)}
    </div>
  );
};

export default Priority;
