import React from 'react';
import {
  Nav,
  NavItem,
  NavLink,
  Button,
  Navbar,
  NavbarBrand,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { connect } from 'react-redux';
import { setNotificationList } from '../../redux/actions/notification';
import { getUserReq } from '../../redux/actions/user';
/*--------------------------------------------------------------------------------*/
/* Import images which are need for the HEADER                                    */
/*--------------------------------------------------------------------------------*/
import small_logo from '../../assets/images/logo-small.png';
import kraalWhitelogo from '../../assets/images/kraalWhitelogo.png';
import axios from '../../axios';
import moment from 'moment';
import FeatureProvider from '../FeatureProvider';
import { FEATURE_LIST } from '../../constants/subscription';
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.showMobilemenu = this.showMobilemenu.bind(this);
    this.sidebarHandler = this.sidebarHandler.bind(this);
    this.getUser = this.getUser.bind(this);
    this.getUserData = props.getUserData.bind(this);
    this.getNotifications = this.getNotifications.bind(this);
    this.state = {
      isOpen: false,
      user: {},
      notifications: [],
      notification: { list: [] }
    };
  }
  componentDidMount() {
    this.getUser();
    this.getNotifications();
  }
  async getNotifications() {
    const response = await axios.get('notifications', {
      params: {
        query: 'notification_badge'
      }
    });
    this.setState({
      notifications: response.data,
      new: response.data.length
    });
    this.props.setNotificationList(response.data);
  }
  /*--------------------------------------------------------------------------------*/
  /*To open NAVBAR in MOBILE VIEW                                                   */
  /*--------------------------------------------------------------------------------*/
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  logout() {
    if (this.props.socket) {
      this.props.socket.disconnect();
    }
    localStorage.removeItem('token');
    window.location.href = '/auth/login';
  }
  async getUser() {
    const res = await this.getUserData();

    if (res) {
      this.setState({
        user: res
      });
    }
  }
  /*--------------------------------------------------------------------------------*/
  /*To open SIDEBAR-MENU in MOBILE VIEW                                             */
  /*--------------------------------------------------------------------------------*/
  showMobilemenu() {
    document.getElementById('main-wrapper').classList.toggle('show-sidebar');
  }
  sidebarHandler = () => {
    let element = document.getElementById('main-wrapper');
    switch (this.props.data.settings[0].sidebartype) {
      case 'full':
      case 'iconbar':
        element.classList.toggle('mini-sidebar');
        if (element.classList.contains('mini-sidebar')) {
          element.setAttribute('data-sidebartype', 'mini-sidebar');
        } else {
          element.setAttribute(
            'data-sidebartype',
            this.props.data.settings[0].sidebartype
          );
        }
        break;

      case 'overlay':
      case 'mini-sidebar':
        element.classList.toggle('full');
        if (element.classList.contains('full')) {
          element.setAttribute('data-sidebartype', 'full');
        } else {
          element.setAttribute(
            'data-sidebartype',
            this.props.data.settings[0].sidebartype
          );
        }
        break;

      default:
    }
  };

  getProfilePicture() {
    return (
      this.props.user.profile_picture_url ??
      '/images/default_profile_picture.png'
    );
  }

  render() {
    let NOTIFICATIONS = [];

    const notificationList = this.props?.notification?.list;

    const shortNotificationList = notificationList?.length
      ? notificationList?.slice(0, 4)
      : [];
    const notificationLength = notificationList?.length;

    let indx = 1;
    const position = {
      height: '18px'
    };
    function truncateText(string) {
      let maxSize = 52;
      if (string.length > maxSize) {
        string = string.substring(0, maxSize) + '...';
      }
      return string;
    }
    for (let not of shortNotificationList) {
      NOTIFICATIONS.push(
        <li key={indx}>
          <div className="notification-item">
            <div className={'priority ' + not.priority} style={position}>
              <div className="circle"></div>
            </div>
            <div className="text">
              <span className="title">{truncateText(not.message)}</span>
              <br />
              <span className="text-small text-muted date">
                {moment(not.trigger_start_at).format('HH:mmA DD/MM/YYYY')}
              </span>
            </div>
          </div>
        </li>
      );
      ++indx;
    }
    let isDesktopView = true;
    if (
      document.getElementById('main-wrapper') &&
      document.getElementById('main-wrapper').offsetWidth < 768
    ) {
      isDesktopView = false;
    }
    return (
      <header
        className="topbar navbarbg"
        data-navbarbg={this.props.data.settings[0].navbarbg}
      >
        <Navbar
          className={
            'top-navbar ' +
            (this.props.data.settings[0].navbarbg === 'skin6'
              ? 'navbar-light'
              : 'navbar-dark')
          }
          expand="md"
        >
          <div
            className="navbar-header"
            id="logobg"
            data-logobg={this.props.data.settings[0].logobg}
          >
            {/*--------------------------------------------------------------------------------*/}
            {/* Mobile View Toggler  [visible only after 768px screen]                         */}
            {/*--------------------------------------------------------------------------------*/}
            <a
              className="nav-toggler d-block d-md-none"
              onClick={this.showMobilemenu}
            >
              <i className="ti-menu ti-close" />
            </a>
            {/*--------------------------------------------------------------------------------*/}
            {/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
            {/*--------------------------------------------------------------------------------*/}
            {!isDesktopView && (
              <NavbarBrand href="/">
                <b className="logo-icon">
                  <img
                    src={small_logo}
                    alt="homepage"
                    className="light-logo icon-logo"
                  />
                </b>
                <span className="logo-text">
                  <img
                    src={kraalWhitelogo}
                    className="light-logo expendlogo"
                    alt="homepage"
                  />
                </span>
              </NavbarBrand>
            )}
            {/*--------------------------------------------------------------------------------*/}
            {/* Mobile View Toggler  [visible only after 768px screen]                         */}
            {/*--------------------------------------------------------------------------------*/}
            <a
              className="topbartoggler d-block d-md-none"
              onClick={this.toggle}
            >
              <i className="ti-more" />
            </a>
          </div>
          <Collapse
            className="navbarbg"
            isOpen={this.state.isOpen}
            navbar
            data-navbarbg={this.props.data.settings[0].navbarbg}
          >
            <Nav className="float-left" navbar>
              <NavItem>
                <NavLink
                  href="#"
                  className="d-none d-md-block"
                  onClick={this.sidebarHandler}
                >
                  <i className="ti-menu" />
                </NavLink>
              </NavItem>
              {/*--------------------------------------------------------------------------------*/}
              {/* Start Mega Menu Dropdown                                                       */}
              {/*--------------------------------------------------------------------------------*/}
              {/* <UncontrolledDropdown nav inNavbar className="mega-dropdown">
                <DropdownToggle nav> Farms <i className="fa fa-angle-down" /></DropdownToggle>
                <DropdownMenu>
                  <Row>
                    Work in Progress
                  </Row>
                </DropdownMenu>
              </UncontrolledDropdown> */}
              {/*--------------------------------------------------------------------------------*/}
              {/* End Mega Menu Dropdown                                                         */}
              {/*--------------------------------------------------------------------------------*/}
            </Nav>
            <Nav className="ml-auto float-right" navbar>
              <FeatureProvider name={FEATURE_LIST.NOTIFICATION_ICON}>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret className="pro-pic notify-div">
                    <i className="fa fa-bell"></i>
                    {notificationLength > 0 && (
                      <React.Fragment>
                        <span className="badge badge-danger badge-pill noti-icon-badge">
                          {notificationLength}
                        </span>
                      </React.Fragment>
                    )}
                  </DropdownToggle>
                  <DropdownMenu right className="not-dd">
                    <span className="with-arrow">
                      <span className="bg-primary" />
                    </span>

                    <div className="d-flex no-block align-items-center p-3 bg-primary text-white mb-2">
                      <span>{this.notificationLength || ''} Notifications</span>
                    </div>
                    <ul>{NOTIFICATIONS}</ul>
                    <div
                      className="all-nots"
                      onClick={() => (window.location.href = '/notifications')}
                    >
                      See all notifications
                    </div>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </FeatureProvider>

              {/*--------------------------------------------------------------------------------*/}
              {/* Start Profile Dropdown                                                         */}
              {/*--------------------------------------------------------------------------------*/}
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret className="pro-pic">
                  <img
                    src={this.getProfilePicture()}
                    alt="user"
                    className="rounded-circle"
                    width="31"
                  />
                  <span className="admin-class">
                    {this.state.user.first_name} {this.state.user.last_name}{' '}
                    <i className="fa fa-caret-down" aria-hidden="true"></i>
                  </span>
                </DropdownToggle>
                <DropdownMenu right className="user-dd">
                  <span className="with-arrow">
                    <span className="bg-primary" />
                  </span>
                  <div className="d-flex no-block align-items-center p-3 bg-primary text-white mb-2">
                    <div className="">
                      <img
                        src={this.getProfilePicture()}
                        alt="user"
                        className="rounded-circle"
                        width="60"
                      />
                    </div>
                    <div className="ml-2">
                      <h4 className="mb-0">
                        {this.state.user.first_name} {this.state.user.last_name}
                      </h4>
                      <p className=" mb-0">{this.state.user.email}</p>
                    </div>
                  </div>
                  <DropdownItem
                    onClick={() => {
                      window.location.href = '/profile';
                    }}
                  >
                    <i className="ti-user mr-1 ml-1" /> My Profile
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      window.location.href = '/contact-us';
                    }}
                  >
                    <i className="ti-pencil-alt mr-1 ml-1" /> Contact us
                  </DropdownItem>
                  <DropdownItem divider />
                  <Button
                    color="success"
                    className="btn-rounded ml-3 mb-2 mt-2"
                    onClick={() => {
                      this.logout();
                    }}
                  >
                    Logout
                  </Button>
                </DropdownMenu>
              </UncontrolledDropdown>
              {/*--------------------------------------------------------------------------------*/}
              {/* End Profile Dropdown                                                           */}
              {/*--------------------------------------------------------------------------------*/}
            </Nav>
          </Collapse>
        </Navbar>
      </header>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user.user,
    notification: state.notification
  }),
  (dispatch) => ({
    setNotificationList: (id) => {
      dispatch(setNotificationList(id));
    },
    getUserData: () => {
      return dispatch(getUserReq());
    }
  })
)(Header);
