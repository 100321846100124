import React from "react";
import { Button } from "reactstrap";
import { Popover } from "react-tiny-popover";

import "./styles.scss";

const SelectionPopover = ({
  isOpen,
  setOpen,
  onClickOnThisPage,
  onClickAllRecords,
  content,
  position = ["top"],
}) => {
  return (
    <div className="selection-popover">
      <Popover
        className="selection-popover-component"
        isOpen={isOpen}
        position={position}
        onClickOutside={() => setOpen(false)}
        content={
          <>
            <Button
              color="primary"
              className="on-this-page-btn"
              onClick={onClickOnThisPage}
            >
              On this page
            </Button>
            <Button
              color="primary"
              className="all-records-btn"
              onClick={onClickAllRecords}
            >
              All records
            </Button>
          </>
        }
      >
        {content}
      </Popover>
    </div>
  );
};

export default SelectionPopover;
