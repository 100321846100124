export const GATEWAY_CSV_TEMPLATE = [
  {
    id: null,
    farm_id: null,
    appeui: '70-0d-be-ef-60-0d-c0-01',
    gweui: '00-80-00-00-a0-00-12-01',
    identifier: 'GW-000000001',
    serial_number: null,
    name: 'Gateway 1',
    arn: 'arn:aws:iot:eu-west-1:792027131409:thing/dev-gateway-00-80-00-00-a0-00-12-01',
    version: 'V1.0.0',
    carrier_type: 'S-528591301',
    connection_speed: null,
    lora_signal_strength: null,
    lat: null,
    lng: null,
    created_at: null,
    updated_at: null,
    deleted_at: null
  },
  {
    id: null,
    farm_id: null,
    appeui: '70-0d-be-ef-60-0d-c0-02',
    gweui: '00-80-00-00-a0-00-12-02',
    identifier: 'GW-000000002',
    serial_number: null,
    name: 'Gateway 2',
    arn: 'arn:aws:iot:eu-west-1:792027131409:thing/dev-gateway-00-80-00-00-a0-00-12-02',
    version: 'V1.0.0',
    carrier_type: 'S-528591302',
    connection_speed: null,
    lora_signal_strength: null,
    lat: null,
    lng: null,
    created_at: null,
    updated_at: null,
    deleted_at: null
  },
  {
    id: null,
    farm_id: null,
    appeui: '70-0d-be-ef-60-0d-c0-03',
    gweui: '00-80-00-00-a0-00-12-03',
    identifier: 'GW-000000003',
    serial_number: null,
    name: 'Gateway 3',
    arn: 'arn:aws:iot:eu-west-1:792027131409:thing/dev-gateway-00-80-00-00-a0-00-12-03',
    version: 'V1.0.0',
    carrier_type: 'S-528591303',
    connection_speed: null,
    lora_signal_strength: null,
    lat: null,
    lng: null,
    created_at: null,
    updated_at: null,
    deleted_at: null
  },
  {
    id: null,
    farm_id: null,
    appeui: '70-0d-be-ef-60-0d-c0-04',
    gweui: '00-80-00-00-a0-00-12-04',
    identifier: 'GW-000000004',
    serial_number: null,
    name: 'Gateway 4',
    arn: 'arn:aws:iot:eu-west-1:792027131409:thing/dev-gateway-00-80-00-00-a0-00-12-04',
    version: 'V1.0.0',
    carrier_type: 'S-528591304',
    connection_speed: null,
    lora_signal_strength: null,
    lat: null,
    lng: null,
    created_at: null,
    updated_at: null,
    deleted_at: null
  },
  {
    id: null,
    farm_id: null,
    appeui: '70-0d-be-ef-60-0d-c0-05',
    gweui: '00-80-00-00-a0-00-12-05',
    identifier: 'GW-000000005',
    serial_number: null,
    name: 'Gateway 5',
    arn: 'arn:aws:iot:eu-west-1:792027131409:thing/dev-gateway-00-80-00-00-a0-00-12-05',
    version: 'V1.0.0',
    carrier_type: 'S-528591305',
    connection_speed: null,
    lora_signal_strength: null,
    lat: null,
    lng: null,
    created_at: null,
    updated_at: null,
    deleted_at: null
  },
  {
    id: null,
    farm_id: null,
    appeui: '70-0d-be-ef-60-0d-c0-06',
    gweui: '00-80-00-00-a0-00-12-06',
    identifier: 'GW-000000006',
    serial_number: null,
    name: 'Gateway 6',
    arn: 'arn:aws:iot:eu-west-1:792027131409:thing/dev-gateway-00-80-00-00-a0-00-12-06',
    version: 'V1.0.0',
    carrier_type: 'S-528591306',
    connection_speed: null,
    lora_signal_strength: null,
    lat: null,
    lng: null,
    created_at: null,
    updated_at: null,
    deleted_at: null
  }
];

export const TAG_CSV_TEMPLATE = [
  {
    id: null,
    farm_id: null,
    identifier: null,
    gateway_id: null,
    type: 'sodaq',
    deveui: '00-04-a3-0b-00-1f-21-5f',
    diagri_id: 'SDQ-1.0-00-0004',
    name: null,
    arn: null,
    version: 'V1.0.0',
    firmware_version: 'V1.0.0',
    timestamp_at: null,
    battery_voltage: null,
    battery_adc_reading: null,
    board_temperature: null,
    gps_speed: null,
    gps_course: null,
    satellites_in_view: null,
    satellites_in_use: null,
    horizontal_dilution_of_precision: null,
    estimated_position_error: null,
    lat: null,
    lng: null,
    created_at: null,
    updated_at: null,
    deleted_at: null
  },
  {
    id: null,
    farm_id: null,
    identifier: null,
    gateway_id: null,
    type: 'sodaq',
    deveui: '00-04-a3-0b-00-1f-02-5c',
    diagri_id: 'SDQ-1.0-00-0003',
    name: null,
    arn: null,
    version: 'V1.0.0',
    firmware_version: 'V1.0.0',
    timestamp_at: null,
    battery_voltage: null,
    battery_adc_reading: null,
    board_temperature: null,
    gps_speed: null,
    gps_course: null,
    satellites_in_view: null,
    satellites_in_use: null,
    horizontal_dilution_of_precision: null,
    estimated_position_error: null,
    lat: null,
    lng: null,
    created_at: null,
    updated_at: null,
    deleted_at: null
  },
  {
    id: null,
    farm_id: null,
    identifier: null,
    gateway_id: null,
    type: 'sodaq',
    deveui: '00-04-a3-0b-00-1f-40-03',
    diagri_id: 'SDQ-1.0-00-0009',
    name: null,
    arn: null,
    version: 'V1.0.0',
    firmware_version: 'V1.0.0',
    timestamp_at: null,
    battery_voltage: null,
    battery_adc_reading: null,
    board_temperature: null,
    gps_speed: null,
    gps_course: null,
    satellites_in_view: null,
    satellites_in_use: null,
    horizontal_dilution_of_precision: null,
    estimated_position_error: null,
    lat: null,
    lng: null,
    created_at: null,
    updated_at: null,
    deleted_at: null
  },
  {
    id: null,
    farm_id: null,
    identifier: null,
    gateway_id: null,
    type: 'sodaq',
    deveui: '00-04-a3-0b-00-1e-fc-50',
    diagri_id: 'SDQ-1.0-00-0007',
    name: null,
    arn: null,
    version: 'V1.0.0',
    firmware_version: 'V1.0.0',
    timestamp_at: null,
    battery_voltage: null,
    battery_adc_reading: null,
    board_temperature: null,
    gps_speed: null,
    gps_course: null,
    satellites_in_view: null,
    satellites_in_use: null,
    horizontal_dilution_of_precision: null,
    estimated_position_error: null,
    lat: null,
    lng: null,
    created_at: null,
    updated_at: null,
    deleted_at: null
  },
  {
    id: null,
    farm_id: null,
    identifier: null,
    gateway_id: null,
    type: 'sodaq',
    deveui: '00-04-a3-0b-00-1f-1a-23',
    diagri_id: 'SDQ-1.0-00-0002',
    name: null,
    arn: null,
    version: 'V1.0.0',
    firmware_version: 'V1.0.0',
    timestamp_at: null,
    battery_voltage: null,
    battery_adc_reading: null,
    board_temperature: null,
    gps_speed: null,
    gps_course: null,
    satellites_in_view: null,
    satellites_in_use: null,
    horizontal_dilution_of_precision: null,
    estimated_position_error: null,
    lat: null,
    lng: null,
    created_at: null,
    updated_at: null,
    deleted_at: null
  },
  {
    id: null,
    farm_id: null,
    identifier: null,
    gateway_id: null,
    type: 'sodaq',
    deveui: '00-04-a3-0b-00-1e-c8-ac',
    diagri_id: 'SDQ-1.0-00-0006',
    name: null,
    arn: null,
    version: 'V1.0.0',
    firmware_version: 'V1.0.0',
    timestamp_at: null,
    battery_voltage: null,
    battery_adc_reading: null,
    board_temperature: null,
    gps_speed: null,
    gps_course: null,
    satellites_in_view: null,
    satellites_in_use: null,
    horizontal_dilution_of_precision: null,
    estimated_position_error: null,
    lat: null,
    lng: null,
    created_at: null,
    updated_at: null,
    deleted_at: null
  },
  {
    id: null,
    farm_id: null,
    identifier: null,
    gateway_id: null,
    type: 'sodaq',
    deveui: '00-04-a3-0b-00-1f-3f-a0',
    diagri_id: 'SDQ-1.0-00-0005',
    name: null,
    arn: null,
    version: 'V1.0.0',
    firmware_version: 'V1.0.0',
    timestamp_at: null,
    battery_voltage: null,
    battery_adc_reading: null,
    board_temperature: null,
    gps_speed: null,
    gps_course: null,
    satellites_in_view: null,
    satellites_in_use: null,
    horizontal_dilution_of_precision: null,
    estimated_position_error: null,
    lat: null,
    lng: null,
    created_at: null,
    updated_at: null,
    deleted_at: null
  },
  {
    id: null,
    farm_id: null,
    identifier: null,
    gateway_id: null,
    type: 'sodaq',
    deveui: '00-04-a3-0b-00-1e-dd-19',
    diagri_id: 'SDQ-1.0-00-0001',
    name: null,
    arn: null,
    version: 'V1.0.0',
    firmware_version: 'V1.0.0',
    timestamp_at: null,
    battery_voltage: null,
    battery_adc_reading: null,
    board_temperature: null,
    gps_speed: null,
    gps_course: null,
    satellites_in_view: null,
    satellites_in_use: null,
    horizontal_dilution_of_precision: null,
    estimated_position_error: null,
    lat: null,
    lng: null,
    created_at: null,
    updated_at: null,
    deleted_at: null
  },
  {
    id: null,
    farm_id: null,
    identifier: null,
    gateway_id: null,
    type: 'sodaq',
    deveui: '00-04-a3-0b-00-1f-75-9e',
    diagri_id: 'SDQ-1.0-00-0010',
    name: null,
    arn: null,
    version: 'V1.0.0',
    firmware_version: 'V1.0.0',
    timestamp_at: null,
    battery_voltage: null,
    battery_adc_reading: null,
    board_temperature: null,
    gps_speed: null,
    gps_course: null,
    satellites_in_view: null,
    satellites_in_use: null,
    horizontal_dilution_of_precision: null,
    estimated_position_error: null,
    lat: null,
    lng: null,
    created_at: null,
    updated_at: null,
    deleted_at: null
  }
];

export const MEASURES_CSV_TEMPLATE = [
  {
    measure_id: 2000,
    livestock_id: 300,
    sex: 'Male',
    breed: 'Bonsmara',
    major_measure_type: 'Weight',
    minor_measure_type: 'Management',
    uom: 'Kg',
    measurement_date: '2018-05-02',
    measurement: 35,
    comments: null
  },
  {
    measure_id: 2001,
    livestock_id: 301,
    sex: 'Male',
    breed: 'Bonsmara',
    major_measure_type: 'Weight',
    minor_measure_type: 'Management',
    uom: 'Kg',
    measurement_date: '2018-05-02',
    measurement: 35,
    comments: null
  },
  {
    measure_id: 2002,
    livestock_id: 302,
    sex: 'Male',
    breed: 'Bonsmara',
    major_measure_type: 'Weight',
    minor_measure_type: 'Management',
    uom: 'Kg',
    measurement_date: '2018-05-02',
    measurement: 35,
    comments: null
  }
];
export const BREEDING_CSV_TEMPLATE = [
  {
    event_id: 2000,
    livestock_id: 300,
    identifier: 'BR-770690',
    event_type_id: 5,
    event_start_date: '2016-01-07',
    event_end_date: '2016-01-07',
    expected_calving_date: null,
    event_description: null,
    is_pregnant: '0',
    sire_id: null,
    calf_id: 159,
    mating_type_id: null,
    straws: null,
    straw_ref: null,
    cost: '0',
    breeding_status_id: 6,
    calving_performance: null,
    maternity_performance: null,
    comments: null,
    breeding_plan: null,
    attribute1: null,
    attribute2: null,
    attribute3: null,
    attribute4: null,
    attribute5: null,
    is_completed: 1,
    is_active: 1,
    created_at: null,
    updated_at: null,
    deleted_at: null,
    created_by: null,
    updated_by: null
  },
  {
    event_id: 2001,
    livestock_id: 300,
    identifier: 'BR-770691',
    event_type_id: 5,
    event_start_date: '2016-01-07',
    event_end_date: '2016-01-07',
    expected_calving_date: null,
    event_description: null,
    is_pregnant: '0',
    sire_id: null,
    calf_id: 159,
    mating_type_id: null,
    straws: null,
    straw_ref: null,
    cost: '0',
    breeding_status_id: 6,
    calving_performance: null,
    maternity_performance: null,
    comments: null,
    breeding_plan: null,
    attribute1: null,
    attribute2: null,
    attribute3: null,
    attribute4: null,
    attribute5: null,
    is_completed: 1,
    is_active: 1,
    created_at: null,
    updated_at: null,
    deleted_at: null,
    created_by: null,
    updated_by: null
  },
  {
    event_id: 2002,
    livestock_id: 300,
    identifier: 'BR-770692',
    event_type_id: 5,
    event_start_date: '2016-01-07',
    event_end_date: '2016-01-07',
    expected_calving_date: null,
    event_description: null,
    is_pregnant: '0',
    sire_id: null,
    calf_id: 159,
    mating_type_id: null,
    straws: null,
    straw_ref: null,
    cost: '0',
    breeding_status_id: 6,
    calving_performance: null,
    maternity_performance: null,
    comments: null,
    breeding_plan: null,
    attribute1: null,
    attribute2: null,
    attribute3: null,
    attribute4: null,
    attribute5: null,
    is_completed: 1,
    is_active: 1,
    created_at: null,
    updated_at: null,
    deleted_at: null,
    created_by: null,
    updated_by: null
  }
];
export const TREATMENT_CSV_TEMPLATE = [
  {
    teatment_id: 2000,
    livestock_id: 300,
    identifier: 'TM-061690',
    treatment_type_id: 7,
    treatment_date: '2016-01-07',
    symptoms: 'none',
    is_positive: null,
    treatment: 'Brand',
    medication: 'CYS',
    dosage_uom: 'UT',
    dosage_amount: 1,
    vet: null,
    comments: null,
    cost: 2.35,
    repeat_date: null,
    review_date: null,
    result: 'Complete',
    attribute1: null,
    attribute2: null,
    attribute3: null,
    attribute4: null,
    attribute5: null,
    is_completed: 1,
    is_active: 1,
    created_at: null,
    updated_at: null,
    deleted_at: null,
    created_by: null,
    updated_by: null
  },
  {
    teatment_id: 2001,
    livestock_id: 300,
    identifier: 'TM-061691',
    treatment_type_id: 7,
    treatment_date: '2016-01-07',
    symptoms: 'none',
    is_positive: null,
    treatment: 'Brand',
    medication: 'CYS',
    dosage_uom: 'UT',
    dosage_amount: 1,
    vet: null,
    comments: null,
    cost: 2.35,
    repeat_date: null,
    review_date: null,
    result: 'Complete',
    attribute1: null,
    attribute2: null,
    attribute3: null,
    attribute4: null,
    attribute5: null,
    is_completed: 1,
    is_active: 1,
    created_at: null,
    updated_at: null,
    deleted_at: null,
    created_by: null,
    updated_by: null
  },
  {
    teatment_id: 2002,
    livestock_id: 300,
    identifier: 'TM-061692',
    treatment_type_id: 7,
    treatment_date: '2016-01-07',
    symptoms: 'none',
    is_positive: null,
    treatment: 'Brand',
    medication: 'CYS',
    dosage_uom: 'UT',
    dosage_amount: 1,
    vet: null,
    comments: null,
    cost: 2.35,
    repeat_date: null,
    review_date: null,
    result: 'Complete',
    attribute1: null,
    attribute2: null,
    attribute3: null,
    attribute4: null,
    attribute5: null,
    is_completed: 1,
    is_active: 1,
    created_at: null,
    updated_at: null,
    deleted_at: null,
    created_by: null,
    updated_by: null
  }
];
