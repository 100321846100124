import React, { memo } from 'react';
import Select from 'react-select';
import { Button, Modal, ModalBody, ModalFooter, FormGroup } from 'reactstrap';

const ChooseGeofencesModal = ({
  isOpen,
  onCancel,
  handleGeofenceChange,
  onSave,
  value = {},
  options = []
}) => {
  return (
    <Modal size="lg" isOpen={isOpen}>
      <ModalBody>
        <br />
        <h5 className="text-center">
          <strong>Geofences</strong>
        </h5>
        <br />
        <br />
        <FormGroup>
          <Select
            value={value}
            onChange={handleGeofenceChange}
            options={options}
            isSearchable={true}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>{' '}
        <Button color="primary" onClick={onSave}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default memo(ChooseGeofencesModal);
