import React, { memo } from 'react';

import WidgetTemplate from '../WidgetTemplate';

const NotificationsWidget = ({ props }) => {
  const onClick = () => {
    props.history.push({
      pathname: 'notifications',
      filter: { farms: props.farm ? [props.farm] : [] },
    });
  };

  const icon = (
    <div className="hc-icon">
      <i className="fa fa-bell" aria-hidden="true"></i>
    </div>
  );

  return (
    <WidgetTemplate
      icon={icon}
      title={'Active Notifications'}
      onClick={onClick}
      value={props.totalNotication}
    />
  );
};

export default memo(NotificationsWidget);
