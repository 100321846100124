import { REDUCERS } from '../../constants/reducers';
import { getQueryNameByRelationType } from '../../helpers/relation';
import { formatDate } from '../../helpers/date';

import { EventService } from '../../services';
import axios from '../../axios';

export const setCurrentEvent = (value) => ({
  type: REDUCERS.EVENT.SET_CURRENT_EVENT,
  value
});

export const setEventQueryParams = (value) => ({
  type: REDUCERS.EVENT.SET_EVENT_QUERY_PARAMS,
  value
});

export const setEventList = (value) => ({
  type: REDUCERS.EVENT.SET_EVENT_LIST,
  value
});

export const setEventState = (value) => ({
  type: REDUCERS.EVENT.SET_EVENT_STATE,
  value
});

export const getAllEvents = (params) => {
  return async (dispatch) => {
    try {
      const { data } = await EventService.getAllEvents(params);
      dispatch(setEventList(data));
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const getEventById = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await EventService.getEventById(id);
      const event = {
        ...data,
        remindDate: formatDate(data.remind_at),
        startDate: formatDate(data.start_at),
        endDate: formatDate(data.end_at),
        userIds: data.users?.map((user) => user.id),
        userEmails: data.users?.map((user) => user.email)
      };
      dispatch(setCurrentEvent(event));
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const createNewEvent = (payload) => {
  return async () => {
    try {
      const { data } = await EventService.createNewEvent(payload);
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const updateEvent = (id, payload) => {
  return async () => {
    try {
      const { data } = await EventService.updateEvent(id, payload);
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const deleteEvent = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await EventService.deleteEvent(id);
      dispatch(setCurrentEvent({}));
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const sendRelationQuery = (relationType) => {
  return async () => {
    try {
      const queryName = getQueryNameByRelationType(relationType);
      let response = await axios.get(queryName);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const acceptEvent = (id) => {
  return async () => {
    try {
      const { data } = await EventService.acceptEvent(id);
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const declineEvent = (id) => {
  return async () => {
    try {
      const { data } = await EventService.declineEvent(id);
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};
