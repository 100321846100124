import React, { useEffect, useState } from 'react';
import { Col, FormGroup } from 'reactstrap';
import axios from '../../../axios';
import Select from 'react-select';

const SiteFilters = (props) => {
  const [animals, setAnimals] = useState([]);
  const fetchData = async () => {
    let { data } = await axios.get('animals', {
      params: {
        only_linked: true
      }
    });
    setAnimals(
      data.map((x) => {
        let n = x.eartag_official_id;
        if (x.name != null) {
          n = `${x.name} (${n})`;
        }
        return {
          value: x.id,
          label: n
        };
      })
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="filter-block labels">
      <p>Livestock ID filter</p>
      <FormGroup row>
        <Col sm="12" lg="12">
          <Select
            name="type"
            value={props.selected}
            onChange={(value) => props.onChange(value, 'animals')}
            options={animals}
            isMulti={true}
            isSearchable={true}
          ></Select>
        </Col>
      </FormGroup>
    </div>
  );
};

export default SiteFilters;
