import React, { memo } from 'react';
import Select from 'react-select';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Label,
  Col,
  Input
} from 'reactstrap';
import DatePicker from 'react-datepicker';

const BreedingBulkModal = ({
  isOpen,
  onCancel,
  createBulkRows,
  onEditChange,
  commonData
}) => {
  return (
    <Modal isOpen={isOpen}>
      <ModalBody>
        <br />
        <h5 className="text-center">
          <b>Create New Breeding Event</b>
        </h5>
        <br />
        <Form>
          <FormGroup row>
            <Label sm="4">Event Type</Label>
            <Col sm="8">
              <Select
                value={
                  commonData.event_type_id.filter(
                    (x) => x.isSelected === true
                  )[0]
                }
                onChange={(selection) => {
                  const data = commonData.event_type_id.map((x) => {
                    x.isSelected = x.value === selection.value;
                    return x;
                  });

                  onEditChange('event_type_id', data);
                }}
                options={commonData.event_type_id}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm="4">Start Date</Label>
            <Col sm="8">
              <DatePicker
                selected={commonData.event_start_date}
                className="form-control"
                dateFormat="dd/MM/yyyy"
                onChange={(e) => onEditChange(e, 'event_start_date')}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm="4">End Date</Label>
            <Col sm="8">
              <DatePicker
                selected={commonData.event_end_date}
                className="form-control"
                dateFormat="dd/MM/yyyy"
                onChange={(e) => onEditChange(e, 'event_end_date')}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm="4">Expected Calving Date</Label>
            <Col sm="8">
              <DatePicker
                selected={commonData.expected_calving_date}
                className="form-control"
                dateFormat="dd/MM/yyyy"
                onChange={(e) => onEditChange(e, 'expected_calving_date')}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm="4">Event Description</Label>
            <Col sm="8">
              <Input
                type="text"
                defaultValue={commonData.event_description}
                onChange={(e) =>
                  onEditChange(e.target.value, 'event_description')
                }
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm="4">Is Pregnant</Label>
            <Col sm="8">
              <Input
                type="checkbox"
                onChange={(e) => onEditChange(e.target.checked, 'is_pregnant')}
                className="form-control"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm="4">Sire ID</Label>
            <Col sm="8">
              <Select
                value={
                  commonData.sire_id.filter((x) => x.isSelected === true)[0]
                }
                onChange={(selection) => {
                  const data = commonData.sire_id.map((x) => {
                    x.isSelected = x.value === selection.value;
                    return x;
                  });
                  onEditChange('sire_id', data);
                }}
                options={commonData.sire_id}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm="4">Calf ID</Label>
            <Col sm="8">
              <Select
                value={
                  commonData.calf_id.filter((x) => x.isSelected === true)[0]
                }
                onChange={(selection) => {
                  const data = commonData.calf_id.map((x) => {
                    x.isSelected = x.value === selection.value;
                    return x;
                  });
                  onEditChange('calf_id', data);
                }}
                options={commonData.calf_id}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm="4">Mating Type</Label>
            <Col sm="8">
              <Select
                value={
                  commonData.mating_type_id.filter(
                    (x) => x.isSelected === true
                  )[0]
                }
                onChange={(selection) => {
                  const data = commonData.mating_type_id.map((x) => {
                    x.isSelected = x.value === selection.value;
                    return x;
                  });
                  onEditChange('mating_type_id', data);
                }}
                options={commonData.mating_type_id}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm="4">Straws</Label>
            <Col sm="8">
              <Input
                type="text"
                defaultValue={commonData.straws}
                onChange={(e) => onEditChange(e.target.value, 'straws')}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm="4">Straw Ref</Label>
            <Col sm="8">
              <Input
                type="text"
                defaultValue={commonData.straw_ref}
                onChange={(e) => onEditChange(e.target.value, 'straw_ref')}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm="4">Cost</Label>
            <Col sm="8">
              <Input
                type="text"
                defaultValue={commonData.cost}
                onChange={(e) => onEditChange(e.target.value, 'cost')}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm="4">Breeding Status</Label>
            <Col sm="8">
              <Select
                value={
                  commonData.breeding_status_id.filter(
                    (x) => x.isSelected === true
                  )[0]
                }
                onChange={(selection) => {
                  const data = commonData.breeding_status_id.map((x) => {
                    x.isSelected = x.value === selection.value;
                    return x;
                  });
                  onEditChange('breeding_status_id', data);
                }}
                options={commonData.breeding_status_id}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm="4">Calving Performance</Label>
            <Col sm="8">
              <Input
                type="text"
                defaultValue={commonData.calving_performance}
                onChange={(e) =>
                  onEditChange(e.target.value, 'calving_performance')
                }
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm="4">Maternity Performance</Label>
            <Col sm="8">
              <Input
                type="text"
                defaultValue={commonData.maternity_performance}
                onChange={(e) =>
                  onEditChange(e.target.value, 'maternity_performance')
                }
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm="4">Comments</Label>
            <Col sm="8">
              <Input
                type="text"
                defaultValue={commonData.comments}
                onChange={(e) => onEditChange(e.target.value, 'comments')}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm="4">Breeding Plan</Label>
            <Col sm="8">
              <Input
                type="text"
                defaultValue={commonData.breeding_plan}
                onChange={(e) => onEditChange(e.target.value, 'breeding_plan')}
              />
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => onCancel('bulk_modal')}>
          Cancel
        </Button>{' '}
        <Button color="danger" onClick={() => createBulkRows()}>
          Create Breeding Events
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default memo(BreedingBulkModal);
