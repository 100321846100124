import React from 'react';
import {
  Row,
  Col,
  Card,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Input,
  Table,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  Modal,
  ModalBody,
  ModalFooter,
  DropdownItem
} from 'reactstrap';
import moment from 'moment';

import axios from '../../../axios';

class EditTag extends React.Component {
  //Tabs
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      selected_geofences: [],
      delete_modal: false,
      tag: {},
      editData: {},
      dropdownOpen: false,
      activeTab: '1'
    };
    this.toggle = this.toggle.bind(this);
    this.onChange = this.onChange.bind(this);
    this.enableEditing = this.enableEditing.bind(this);
    this.toggleButton = this.toggleButton.bind(this);
    this.onCancelEdit = this.onCancelEdit.bind(this);
    this.saveTag = this.saveTag.bind(this);
  }
  async componentDidMount() {
    this.getTag();
  }
  toggleModal(modal) {
    this.setState((state) => ({
      ...state,
      [modal]: !state[modal]
    }));
  }
  async deleteTag() {
    let response = await axios.delete('tags/archive', {
      data: {
        ids: [this.state.tag.id]
      }
    });
    if (response.status == 200) {
      this.getTag();
      this.toggleModal('delete_modal');
    }
  }
  async getTag() {
    let id = this.props.match.params.id;
    let response = await axios.get('tags/admin/' + id);
    if (response.status === 200) {
      let tag = response.data;
      this.setState((state) => ({
        ...state,
        tag: tag
      }));
    }
  }
  onCancelEdit = (value, field) => {
    this.setState((state) => ({
      ...state,
      editData: this.state.tag,
      [field]: value
    }));
  };
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  enableEditing() {
    this.setState((state) => ({
      ...state,
      editing: true,
      editData: { ...this.state.tag }
    }));
  }
  toggleButton() {
    this.setState((state) => ({
      ...state,
      dropdownOpen: !this.state.dropdownOpen
    }));
  }
  onChange = (value, field) => {
    this.setState((state) => ({
      ...state,
      editData: {
        ...state.editData,
        [field]: value
      }
    }));
  };
  setDeleteTags() {
    let selected;
    selected = this.state.selected_tags;

    this.setState({
      ...this.state,
      delete_tags: selected
    });
    this.toggleModal('delete_modal');
  }

  async saveTag() {
    let id = this.props.match.params.id;
    let editData = this.state.editData;

    try {
      let response = await axios.put('tags/' + id, {
        ...editData
      });
      if (response.status === 200) {
        this.getTag();
        this.setState({
          ...this.state,
          editing: false
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    if (!this.state.tag.id) {
      return null;
    }

    return (
      <div>
        <Row>
          <Col xs="6" md="6" lg="6">
            <h4 className="">
              {this.state.editing && 'Edit '}Tag{' '}
              {this.state.tag.name || this.state.tag.appeui}
            </h4>
            <Breadcrumb>
              <BreadcrumbItem>
                <a
                  href="#"
                  onClick={() =>
                    this.props.history.push('/administration/tags')
                  }
                >
                  List of Kraal Tags
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {this.state.editing && 'Edit '}Tag{' '}
                {this.state.tag.name || this.state.tag.appeui}
              </BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col xs="6" md="6" lg="6">
            {this.state.editing && (
              <React.Fragment>
                <Button
                  color="primary"
                  className="float-right"
                  onClick={() => this.saveTag()}
                >
                  Save
                </Button>
                <Button
                  className="outline float-right"
                  onClick={() => this.onCancelEdit(false, 'editing')}
                >
                  Cancel
                </Button>
              </React.Fragment>
            )}
            {!this.state.editing && !this.state.tag.deleted_at && (
              <ButtonDropdown
                className="float-right"
                isOpen={this.state.dropdownOpen}
                toggle={this.toggleButton}
              >
                <DropdownToggle caret className="remove-mr">
                  Actions
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => this.enableEditing()}>
                    Edit Kraal Tag
                  </DropdownItem>
                  <DropdownItem onClick={() => this.setDeleteTags()}>
                    Archive Kraal tag
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="12" lg="12" className="global-table">
            <Card>
              <Row>
                <Col xs="12" md="12" lg="12">
                  <div className="p-30  global-heading-tag">
                    <h5 className="details-title">
                      Tag {this.state.tag.name || this.state.tag.appeui}
                    </h5>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="12" lg="12">
                  <Table>
                    <tbody>
                      <tr>
                        <td className="v-align-middle">
                          <b>Tag Id</b>
                        </td>
                        <td>{this.state.tag.id || '/'}</td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Identifier</b>
                        </td>
                        <td>
                          <span>{this.state.tag.identifier || '/'}</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Farm Id</b>
                        </td>
                        <td>
                          <span>{this.state.tag.farm_id || '/'}</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Farm Name</b>
                        </td>
                        <td>
                          <span>{this.state.tag.farm_name || '/'}</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Gateway ID</b>
                        </td>
                        <td>
                          <span>{this.state.tag.gateway_id || '/'}</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Gateway Name</b>
                        </td>
                        <td>
                          <span>{this.state.tag.gateway_name || '/'}</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Type</b>
                        </td>
                        <td>
                          {this.state.editing ? (
                            <Input
                              name={'type'}
                              defaultValue={this.state.editData.type}
                              onChange={(e) => {
                                this.onChange(e.target.value, e.target.name);
                              }}
                            />
                          ) : (
                            <span>{this.state.tag.type || '/'}</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Deveui</b>
                        </td>
                        <td>
                          {this.state.editing ? (
                            <Input
                              name={'deveui'}
                              defaultValue={this.state.editData.deveui}
                              onChange={(e) => {
                                this.onChange(e.target.value, e.target.name);
                              }}
                            />
                          ) : (
                            <span>{this.state.tag.deveui || '/'}</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Diagri ID</b>
                        </td>
                        <td>
                          {this.state.editing ? (
                            <Input
                              name={'diagri_id'}
                              defaultValue={this.state.editData.diagri_id}
                              onChange={(e) => {
                                this.onChange(e.target.value, e.target.name);
                              }}
                            />
                          ) : (
                            <span>{this.state.tag.diagri_id || '/'}</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Version</b>
                        </td>
                        <td>
                          {this.state.editing ? (
                            <Input
                              name={'diagri_id'}
                              defaultValue={this.state.editData.version}
                              onChange={(e) => {
                                this.onChange(e.target.value, e.target.name);
                              }}
                            />
                          ) : (
                            <span>{this.state.tag.version || '/'}</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Firmware Version</b>
                        </td>
                        <td>
                          {this.state.editing ? (
                            <Input
                              name={'firmware_version'}
                              defaultValue={
                                this.state.editData.firmware_version
                              }
                              onChange={(e) => {
                                this.onChange(e.target.value, e.target.name);
                              }}
                            />
                          ) : (
                            <span>
                              {this.state.tag.firmware_version || '/'}
                            </span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Created at</b>
                        </td>
                        <td>
                          {(this.state.tag.created_at &&
                            moment(this.state.tag.created_at).format(
                              'DD/MM/YYYY HH:mm:ss'
                            )) ||
                            '/'}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Updated at</b>
                        </td>
                        <td>
                          {(this.state.tag.updated_at &&
                            moment(this.state.tag.updated_at).format(
                              'DD/MM/YYYY HH:mm:ss'
                            )) ||
                            '/'}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Deleted at</b>
                        </td>
                        <td>
                          {(this.state.tag.deleted_at &&
                            moment(this.state.tag.deleted_at).format(
                              'DD/MM/YYYY HH:mm:ss'
                            )) ||
                            '/'}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.delete_modal}
          className={this.props.className}
        >
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>Archive tags</b>
            </h5>
            <br />
            <br />
            <br />
            Are you sure you want to archive selected tags? This action cannot
            be undone.
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => this.toggleModal('delete_modal')}
            >
              Cancel
            </Button>{' '}
            <Button color="danger" onClick={() => this.deleteTag()}>
              Archive
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default EditTag;
