import axios from '../../axios';

const service = {
  async verifyToken(payload) {
    return await axios({
      method: 'post',
      url: '/password/reset/verify/token',
      data: payload
    });
  },

  async sendSetPassword(payload) {
    return await axios({
      method: 'post',
      url: '/password/sendSetPassword',
      data: payload
    });
  },

  async setPassword(payload) {
    return await axios({
      method: 'post',
      url: '/password/setPassword',
      data: payload
    });
  }
};

export default service;
