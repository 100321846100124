import React, { memo, useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactTable from 'react-table';
import {
  Row,
  Col,
  Card,
  Breadcrumb,
  BreadcrumbItem,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
  DropdownItem,
  FormGroup
} from 'reactstrap';
import moment from 'moment';
import Select from 'react-select';

import SelectionPopover from '../../../components/Popovers/SelectionPopover';
import ConfirmationModal from '../../../components/Modals/ConfirmationModal';
import { PLANS_STATUS } from '../../../constants/subscription';
import { STATUS_COLOR } from '../../../constants/subscription';
import { filterCaseInsensitive } from '../../../helpers/filter';
import { useSetState } from '../../../hooks/useSetState';
import { updateUsersProfile } from '../../../redux/actions/admin';
import { updateUserSubscriptionStatus } from '../../../redux/actions/subscription';
import {
  archiveUsers,
  deleteUsers,
  getAllUsers,
  getUsers,
  sendSetPassword
} from '../../../redux/actions/user';
import countries from '../../../countries.json';
import SubmitSubscriptionModal from '../../Modals/SubmitSubscriptionModal';
import axios from '../../../axios';

const UserTable = ({ history }) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.user.users);
  const userState = useSelector((state) => state.user);
  const roles = useSelector((state) => state.admin.roles);
  const plans = useSelector((state) => state.subscription.plans);

  const [countriesState, setCountries] = useState([]);
  const [selection, setSelection] = useState({
    selectAll: false,
    selectedUsers: []
  });
  const [editableRowIndex, setEditableRowIndex] = useState([]);
  const [search, setSearch] = useState();
  const [state, setState] = useSetState({
    archive_modal: false,
    delete_modal: false,
    isOpenSelectionPopover: false,
    tableData: [],
    archivedUsers: [],
    deletedUsers: []
  });
  const [isActionsOpen, setActionsOpen] = useState(false);
  const [isOpenSubmitSubscriptionModal, setOpenSubmitSubscriptionModal] =
    useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});

  const getTableData = (data) => {
    const copiedUsers = data.map((u) => {
      return { ...u };
    });
    setState({
      tableData: [...copiedUsers.slice(0, 25)]
    });
  };

  useEffect(() => {
    getTableData(users);
    let cntrs = [];
    for (let cnt of countries) {
      cntrs.push({ value: cnt, label: cnt });
    }
    setCountries(cntrs);
  }, [users]);

  function onClickOnThisPage() {
    const newCheckboxValue = !selection.selectAll;

    let checkedCopy = [];
    if (newCheckboxValue) {
      state.tableData.map((val) => {
        const id = val?._original?.id ?? val.id;
        checkedCopy.push(id);
      });
    }

    setSelection({
      selectedUsers: checkedCopy,
      selectAll: newCheckboxValue
    });
    onToggleSelectionPopover();
  }

  function onClickAllRecords() {
    const newCheckboxValue = !selection.selectAll;
    let checkedCopy = [];
    if (newCheckboxValue) {
      users.map((val) => {
        checkedCopy.push(val.id);
      });
    }
    setSelection((state) => ({
      ...state,
      selectedUsers: checkedCopy,
      selectAll: newCheckboxValue
    }));
    onToggleSelectionPopover();
  }
  function onToggleSelectionPopover(isOpen) {
    const open =
      isOpen !== undefined
        ? isOpen
        : !state.isOpenSelectionPopover && !selection.selectAll;

    setState({
      isOpenSelectionPopover: open
    });
  }
  const toggleModal = (modal) => {
    setState((state) => ({
      ...state,
      [modal]: !state[modal]
    }));
  };

  const onUserSelect = (user) => {
    let selectedUsers = selection.selectedUsers;

    if (selectedUsers.includes(user)) {
      var index = selectedUsers.indexOf(user);
      if (index > -1) {
        selectedUsers.splice(index, 1);
      }
    } else {
      selectedUsers.push(user);
    }

    if (users.length == selectedUsers.length) {
      setSelection((state) => {
        return { ...state, selectAll: true };
      });
    } else {
      setSelection((state) => {
        return { ...state, selectAll: false };
      });
    }
    setSelection((state) => {
      return { ...state, selectedUsers: selectedUsers };
    });
  };

  const onChangeSelectAllCheckbox = () => {
    onToggleSelectionPopover();
    if (selection.selectAll) {
      setSelection({
        selectAll: !selection.selectAll,
        selectedUsers: []
      });
    }
  };

  const handleInputChange = (cellInfo, event, isAddress) => {
    let data = [...state.tableData];
    data.forEach((item) => {
      item.address = { ...item.address };
    });
    if (isAddress) {
      data[cellInfo.index].address[cellInfo.column.id] = event.target.value;
    } else {
      data[cellInfo.index][cellInfo.column.id] = event.target.value;
    }
    setState({
      tableData: [...data]
    });
  };

  const handleSelectChange = (cellInfo, data, isAddress) => {
    let cellData = [...state.tableData];
    cellData.forEach((item) => {
      item.address = { ...item.address };
    });
    if (isAddress) {
      cellData[cellInfo.index].address[cellInfo.column.id] = data;
    } else {
      cellData[cellInfo.index][cellInfo.column.id] = data;
    }
    setState({
      tableData: [...cellData]
    });
  };

  const handleKeyPress = (charCode) => {
    if (charCode === 13) {
      updateUserList(search);
    }
  };

  const updateUserList = useCallback((query) => {
    const payload = { query: query };
    dispatch(getUsers(payload));
  }, []);

  const onActionsClick = () => {
    setActionsOpen((state) => !state);
  };

  const onClickSendSetPassword = () => {
    let emails = [];
    selection.selectedUsers.forEach((id) => {
      const user = users.find((user) => user.id === id);
      emails.push({ email: user.email });
    });
    dispatch(sendSetPassword(emails));
  };

  const setArchivedUsers = () => {
    let selected;
    selected = selection.selectedUsers;

    setState({
      archivedUsers: selected
    });
    toggleModal('archive_modal');
  };

  const setDeletedUsers = () => {
    let selected;
    selected = selection.selectedUsers;

    setState({
      deletedUsers: selected
    });
    toggleModal('delete_modal');
  };

  const onArchiveUsers = async () => {
    const payload = { ids: state.archivedUsers };
    try {
      await dispatch(archiveUsers(payload));
      setSelection({
        selectAll: false,
        selectedUsers: []
      });
      dispatch(getAllUsers());
      toggleModal('archive_modal');
    } catch (error) {
      console.error(error);
    }
  };

  const onDeleteUsers = async () => {
    const payload = { ids: state.deletedUsers };
    try {
      await dispatch(deleteUsers(payload));
      setSelection({
        selectAll: false,
        selectedUsers: []
      });
      dispatch(getAllUsers());
      toggleModal('delete_modal');
    } catch (error) {
      console.error(error);
    }
  };

  const onClickUpdateUserStatus = (status) => {
    const payload = { status, userIds: selection.selectedUsers };
    dispatch(updateUserSubscriptionStatus(payload)).then(() => {
      dispatch(getAllUsers());
    });
  };

  const onClickEditUser = () => {
    setEditableRowIndex([...selection.selectedUsers]);
  };

  const onClickCancelEdit = () => {
    setEditableRowIndex([]);
    setSelection({ selectAll: false, selectedUsers: [] });
    getTableData(users);
  };

  const onSaveEdit = () => {
    const usersId = [...editableRowIndex];

    const tableData = JSON.parse(JSON.stringify(state.tableData));
    const users = tableData.filter((user) => usersId.includes(user.id));
    if (users?.length) {
      dispatch(updateUsersProfile({ users: users })).then((data) => {
        if (data) {
          setEditableRowIndex([]);
          setSelection({
            selectAll: false,
            selectedUsers: []
          });
          dispatch(getAllUsers());
        }
      });
    }
  };

  const columns = useMemo(() => {
    return [
      {
        id: 'select',
        accessor: (item) => {
          return { id: item.id };
        },
        headerClassName: 'wordwrap',
        sortable: false,
        Header: () => (
          <div className="position-relative form-check">
            <SelectionPopover
              isOpen={state.isOpenSelectionPopover}
              onClickOnThisPage={onClickOnThisPage}
              onClickAllRecords={onClickAllRecords}
              setOpen={(isOpen) =>
                setState({
                  isOpenSelectionPopover:
                    isOpen !== undefined
                      ? isOpen
                      : !state.isOpenSelectionPopover
                })
              }
              content={
                <input
                  type="checkbox"
                  defaultChecked={selection.selectAll}
                  onChange={() => {
                    onChangeSelectAllCheckbox();
                  }}
                />
              }
            />
          </div>
        ),
        Cell: (values) => {
          return (
            <FormGroup check>
              <Input
                type="checkbox"
                defaultChecked={selection.selectedUsers.includes(
                  values.value.id
                )}
                value={values.value.id}
                onChange={(e) => onUserSelect(parseInt(e.target.value))}
                key={values.value.id}
              />
            </FormGroup>
          );
        },
        filterable: false,
        maxWidth: 85
      },

      {
        Header: 'User id',
        accessor: 'id',
        headerClassName: 'wordwrap',
        maxWidth: 70
      },
      {
        Header: 'Email',
        accessor: 'email',
        headerClassName: 'wordwrap',
        minWidth: 300
      },
      {
        Header: 'Email Verification Date',
        id: 'emailVerificationDate',
        accessor: (item) => {
          return item?.email_verified_at
            ? moment(item?.email_verified_at).format('DD/MM/YYYY HH:mm:ss')
            : null;
        },
        headerClassName: 'wordwrap',
        sortable: true,
        sortMethod: (a, b) => {
          return moment(b).format('x') - moment(a).format('x');
        },
        minWidth: 165
      },
      {
        Header: 'First Name',
        accessor: 'first_name',
        headerClassName: 'wordwrap',
        minWidth: 150,
        Cell: (cellInfo) => {
          const id = cellInfo.original.id;
          const cellValue =
            state.tableData?.[cellInfo.index]?.[cellInfo.column.id] ?? '';
          if (editableRowIndex.includes(id)) {
            return (
              <input
                placeholder=""
                key={'firstName'}
                type="text"
                className="form-control"
                value={cellValue}
                onChange={(e) => handleInputChange(cellInfo, e)}
              />
            );
          }
          return <span>{cellInfo.value}</span>;
        }
      },
      {
        Header: 'Last Name',
        accessor: 'last_name',
        headerClassName: 'wordwrap',
        minWidth: 150,
        Cell: (cellInfo) => {
          const id = cellInfo.original.id;
          const cellValue =
            state.tableData?.[cellInfo.index]?.[cellInfo.column.id] ?? '';
          if (editableRowIndex.includes(id)) {
            return (
              <input
                placeholder=""
                key={'lastName'}
                type="text"
                className="form-control"
                value={cellValue}
                onChange={(e) => handleInputChange(cellInfo, e)}
              />
            );
          }
          return <span>{cellInfo.value}</span>;
        }
      },

      {
        Header: 'Plan',
        id: 'user_plans',
        headerClassName: 'wordwrap',
        sortable: true,
        minWidth: 130,
        Cell: (cellInfo) => {
          const id = cellInfo.original.id;
          const [planData] =
            state.tableData?.[cellInfo.index]?.[cellInfo.column.id] ?? [];
          const plan = planData?.plan;
          const planInfo = { value: plan?.id, label: plan?.title, ...plan };
          const plansFormatted = [
            ...(plans || []).map((pl) => {
              return { value: pl.id, label: pl.title, ...pl };
            })
          ];

          if (editableRowIndex?.includes(id)) {
            return (
              <Select
                menuPortalTarget={document.body}
                value={planInfo}
                onChange={(e) => {
                  let payload = e;
                  delete payload.value;
                  delete payload.feature_options;
                  delete payload.total_price;
                  delete payload.label;
                  handleSelectChange(cellInfo, [
                    { ...planData, plan: { ...payload } }
                  ]);
                }}
                options={plansFormatted}
              />
            );
          }

          return plan ? (
            <Button
              color="primary"
              onClick={async () => {
                const resp = await axios.get(
                  `subscription/user/${planData.id}`
                );
                setOpenSubmitSubscriptionModal(true);
                setSelectedPlan({
                  ...resp?.data?.plan,
                  period: resp?.data?.period
                });
              }}
            >
              {planInfo.label}
            </Button>
          ) : (
            <></>
          );
        }
      },
      {
        Header: 'Plan Status',
        id: 'planStatus',
        accessor: (item) => {
          const status = item?.user_plans?.[0]?.status;

          return status ? (
            <span style={{ color: STATUS_COLOR[status] }}>{status}</span>
          ) : (
            <></>
          );
        },

        headerClassName: 'wordwrap',
        sortable: true,
        minWidth: 100
      },
      {
        Header: 'Plan End Date',
        id: 'user_plans',
        Cell: (cellInfo) => {
          const id = cellInfo?.original?.id;
          const [planData] =
            state.tableData?.[cellInfo.index]?.[cellInfo.column.id] ?? [];

          const endDate = planData?.end_date;
          const period = planData?.period ?? 1;
          const formattedPeriod = {
            value: period,
            label: `in ${period} month(s)`
          };
          if (planData && editableRowIndex?.includes(id)) {
            return (
              <Select
                menuPortalTarget={document.body}
                value={formattedPeriod}
                onChange={(e) => {
                  handleSelectChange(cellInfo, [
                    { ...planData, period: e.value }
                  ]);
                }}
                options={[
                  { value: 1, label: `in 1 month` },
                  { value: 3, label: `in 3 months` },
                  { value: 6, label: `in 6 months` },
                  { value: 12, label: `in 12 months` }
                ]}
              />
            );
          }
          return endDate ? moment(endDate).format('DD/MM/YYYY HH:mm:ss') : null;
        },
        headerClassName: 'wordwrap',
        sortable: true,
        minWidth: 165
      },
      {
        Header: 'Work Phone',
        accessor: 'work_phone',
        headerClassName: 'wordwrap',
        minWidth: 160,
        Cell: (cellInfo) => {
          const id = cellInfo.original.id;
          const cellValue =
            state.tableData?.[cellInfo.index]?.[cellInfo.column.id] ?? '';
          if (editableRowIndex.includes(id)) {
            return (
              <input
                placeholder=""
                type="text"
                key={'workPhone'}
                className="form-control"
                value={cellValue}
                onChange={(e) => handleInputChange(cellInfo, e)}
              />
            );
          }
          return <span>{cellInfo.value}</span>;
        }
      },
      {
        Header: 'Mobile Phone',
        accessor: 'private_phone',
        headerClassName: 'wordwrap',
        minWidth: 160,
        Cell: (cellInfo) => {
          const id = cellInfo.original.id;
          const cellValue =
            state.tableData?.[cellInfo.index]?.[cellInfo.column.id] ?? '';
          if (editableRowIndex.includes(id)) {
            return (
              <input
                placeholder=""
                type="text"
                key={'privatePhone'}
                className="form-control"
                value={cellValue}
                onChange={(e) => handleInputChange(cellInfo, e)}
              />
            );
          }
          return <span>{cellInfo.value}</span>;
        }
      },
      {
        Header: 'Backup Email',
        accessor: 'backup_email',
        headerClassName: 'wordwrap',
        minWidth: 300,
        Cell: (cellInfo) => {
          const id = cellInfo.original.id;
          const cellValue =
            state.tableData?.[cellInfo.index]?.[cellInfo.column.id] ?? '';
          if (editableRowIndex.includes(id)) {
            return (
              <input
                placeholder=""
                key={'backupEmail'}
                className="form-control"
                value={cellValue}
                onChange={(e) => handleInputChange(cellInfo, e)}
              />
            );
          }
          return <span>{cellInfo.value}</span>;
        }
      },
      {
        Header: 'SMS enabled',
        id: 'is_sms_notify_enabled',
        accessor: (item) => {
          return item.is_sms_notify_enabled ? 'Yes' : 'No';
        },
        headerClassName: 'wordwrap',
        sortable: true,
        Cell: (cellInfo) => {
          const id = cellInfo.original.id;
          const cellValue =
            state.tableData?.[cellInfo.index]?.[cellInfo.column.id] ?? '';
          const formattedValue = cellValue
            ? { label: 'Yes', value: 1 }
            : { label: 'No', value: 0 };
          if (editableRowIndex.includes(id)) {
            return (
              <Select
                name="isSMSNotifyEnabled"
                value={[formattedValue]}
                onChange={(e) => {
                  const value = !!e.value;
                  handleSelectChange(cellInfo, value);
                }}
                options={[
                  { label: 'Yes', value: 1 },
                  { label: 'No', value: 0 }
                ]}
              ></Select>
            );
          }
          return <span>{formattedValue.label}</span>;
        },
        minWidth: 120
      },
      {
        Header: 'Email Notification Enabled',
        id: 'is_email_notify_enabled',
        accessor: (item) => {
          return item.is_email_notify_enabled ? 'Yes' : 'No';
        },
        Cell: (cellInfo) => {
          const id = cellInfo.original.id;
          const cellValue =
            state.tableData?.[cellInfo.index]?.[cellInfo.column.id] ?? '';
          const formattedValue = cellValue
            ? { label: 'Yes', value: 1 }
            : { label: 'No', value: 0 };
          if (editableRowIndex.includes(id)) {
            return (
              <Select
                name="isEmailNotifyEnabled"
                value={[formattedValue]}
                onChange={(e) => {
                  const value = !!e.value;
                  handleSelectChange(cellInfo, value);
                }}
                options={[
                  { label: 'Yes', value: 1 },
                  { label: 'No', value: 0 }
                ]}
              ></Select>
            );
          }
          return <span>{formattedValue.label}</span>;
        },
        headerClassName: 'wordwrap',
        sortable: true,
        minWidth: 120
      },
      {
        Header: 'Street Name',
        id: 'street',
        accessor: (item) => {
          return item?.address?.street || '';
        },
        headerClassName: 'wordwrap',
        minWidth: 160,
        Cell: (cellInfo) => {
          const id = cellInfo.original.id;
          const cellValue =
            state.tableData?.[cellInfo.index]?.address?.street ?? '';

          if (editableRowIndex.includes(id)) {
            return (
              <input
                placeholder=""
                key={'street'}
                className="form-control"
                value={cellValue}
                onChange={(e) => handleInputChange(cellInfo, e, true)}
              />
            );
          }
          return <span>{cellValue}</span>;
        }
      },
      {
        Header: 'House Number / Name / Farm Name',
        id: 'house_number',
        accessor: (item) => {
          return item?.address?.house_number || '';
        },
        headerClassName: 'wordwrap',
        minWidth: 160,
        Cell: (cellInfo) => {
          const id = cellInfo.original.id;
          const cellValue =
            state.tableData?.[cellInfo.index]?.address?.house_number ?? '';

          if (editableRowIndex.includes(id)) {
            return (
              <input
                placeholder=""
                key={'house_number'}
                className="form-control"
                value={cellValue}
                onChange={(e) => handleInputChange(cellInfo, e, true)}
              />
            );
          }
          return <span>{cellValue}</span>;
        }
      },
      {
        Header: 'City / Town',
        id: 'city',
        accessor: (item) => {
          return item?.address?.city || '';
        },
        headerClassName: 'wordwrap',
        minWidth: 160,
        Cell: (cellInfo) => {
          const id = cellInfo.original.id;
          const cellValue =
            state.tableData?.[cellInfo.index]?.address?.city ?? '';

          if (editableRowIndex.includes(id)) {
            return (
              <input
                placeholder=""
                key={'city'}
                className="form-control"
                value={cellValue}
                onChange={(e) => handleInputChange(cellInfo, e, true)}
              />
            );
          }
          return <span>{cellValue}</span>;
        }
      },
      {
        Header: 'Postal Code',
        id: 'postal_code',
        accessor: (item) => {
          return item?.address?.postal_code || '';
        },
        headerClassName: 'wordwrap',
        minWidth: 160,
        Cell: (cellInfo) => {
          const id = cellInfo.original.id;
          const cellValue =
            state.tableData?.[cellInfo.index]?.address?.postal_code ?? '';

          if (editableRowIndex.includes(id)) {
            return (
              <input
                placeholder=""
                key={'postal_code'}
                className="form-control"
                value={cellValue}
                onChange={(e) => handleInputChange(cellInfo, e, true)}
              />
            );
          }
          return <span>{cellValue}</span>;
        }
      },
      {
        Header: 'Country',
        id: 'country',
        accessor: (item) => {
          return item?.address?.country || '';
        },
        headerClassName: 'wordwrap',
        minWidth: 160,
        Cell: (cellInfo) => {
          const id = cellInfo.original.id;
          const cellValue =
            state.tableData?.[cellInfo.index]?.address?.country ?? '';
          if (editableRowIndex.includes(id)) {
            return (
              <Select
                name="country"
                value={[cellValue].map((item) => {
                  return { value: item, label: item };
                })}
                onChange={(e) => {
                  const value = e.value;
                  handleSelectChange(cellInfo, value, true);
                }}
                options={countriesState}
              ></Select>
            );
          }
          return <span>{cellValue}</span>;
        }
      },
      {
        Header: 'Creation Date',
        id: 'created_at',
        accessor: (item) => {
          return moment(item.created_at).format('DD/MM/YYYY HH:mm:ss');
        },
        headerClassName: 'wordwrap',
        sortable: true,
        sortMethod: (a, b) => {
          return moment(b).format('x') - moment(a).format('x');
        },
        minWidth: 165
      },
      {
        Header: 'User Roles',
        id: 'roles',
        accessor: (item) => {
          return item.roles?.map((r, index) => {
            if (index !== 0) {
              return ', ' + r?.display_name;
            }
            return r?.display_name;
          });
        },
        headerClassName: 'wordwrap',
        sortable: true,
        minWidth: 220,
        Cell: (cellInfo) => {
          const id = cellInfo?.original?.id;
          const rolesData =
            state.tableData?.[cellInfo.index]?.[cellInfo.column.id] ?? [];

          const formattedCellValue = rolesData
            ?.map((value) => value?.display_name)
            .join(', ');
          const getFormattedValue = (value) => {
            return {
              value: value?.name,
              label: value?.display_name,
              display_name: value?.display_name,
              name: value?.name,
              description: value?.description,
              id: value?.id
            };
          };
          const formattedSelectValue = rolesData?.map((value) => {
            return getFormattedValue(value);
          });
          const formattedSelectOptions = roles?.map((value) => {
            return getFormattedValue(value);
          });
          if (editableRowIndex.includes(id)) {
            return (
              <Select
                name="type"
                menuPortalTarget={document.body}
                value={formattedSelectValue}
                onChange={(e) => {
                  const data = e.map((item) => {
                    return {
                      display_name: item.display_name,
                      name: item.name,
                      description: item.description,
                      id: item.id
                    };
                  });
                  handleSelectChange(cellInfo, data);
                }}
                options={formattedSelectOptions}
                isMulti={true}
              ></Select>
            );
          }
          return <span>{formattedCellValue}</span>;
        }
      },
      {
        Header: 'Sms Count',
        id: 'sms_count',
        accessor: (item) => {
          return item.sms_count ?? 0;
        },
        headerClassName: 'wordwrap',
        Cell: (pr) => <span className="number">{pr.value}</span>,
        sortable: true
      },
      {
        Header: 'Delete Date',
        id: 'deleted_at',
        accessor: (item) => {
          return item.deleted_at
            ? moment(item.deleted_at).format('DD/MM/YYYY HH:mm:ss')
            : null;
        },
        headerClassName: 'wordwrap',
        Cell: (pr) => <span className="number">{pr.value}</span>,
        sortable: true,
        sortMethod: (a, b) => {
          return moment(b).format('x') - moment(a).format('x');
        }
      }
    ];
  }, [editableRowIndex, selection, state, userState.loading]);
  return (
    <div className="user-table">
      <SubmitSubscriptionModal
        title="User Plan"
        isEditDisabled={true}
        cancelText="Close"
        isOpen={isOpenSubmitSubscriptionModal}
        onCancel={() => {
          setSelectedPlan({});
          setOpenSubmitSubscriptionModal(false);
        }}
        plan={selectedPlan}
      />
      <Row>
        <Col xs="12" md="12" lg="12">
          <Card>
            <Row className="pad-10">
              <Col xs="12" md="12" lg="6">
                <Row>
                  <Col xs="12" md="12" lg="6" className="listing-heading">
                    <h4 className="mb-0">Users</h4>
                    <Breadcrumb>
                      <BreadcrumbItem>List of user</BreadcrumbItem>
                    </Breadcrumb>
                  </Col>
                  <Col xs="12" md="12" lg="6"></Col>
                </Row>
              </Col>
              <Col
                xs="12"
                md="12"
                lg="6"
                className="d-flex justify-content-end align-items-center h-100"
              >
                <InputGroup className="head-search mr-2" size="sm">
                  <Input
                    type="search"
                    id="search"
                    name="query"
                    defaultValue={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Search User"
                    onKeyPress={(e) => handleKeyPress(e.charCode)}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText
                      onClick={() => handleKeyPress(13)}
                      className="rg-pointer"
                    >
                      <i className="fas fa-search"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                {editableRowIndex.length ? (
                  <div>
                    <Button
                      color="primary"
                      className="float-right"
                      onClick={() => onSaveEdit()}
                    >
                      Save
                    </Button>
                    <Button
                      className="outline float-right"
                      onClick={() => onClickCancelEdit()}
                    >
                      Cancel
                    </Button>
                  </div>
                ) : (
                  <ButtonDropdown
                    className="float-right"
                    isOpen={isActionsOpen}
                    toggle={onActionsClick}
                  >
                    <DropdownToggle caret>Actions</DropdownToggle>
                    <DropdownMenu>
                      {selection.selectAll ||
                      selection.selectedUsers?.length ? null : (
                        <DropdownItem
                          color="primary"
                          className="float-right"
                          onClick={() => history.push('/new-user')}
                        >
                          {' '}
                          Add new user
                        </DropdownItem>
                      )}
                      {selection.selectedUsers?.some(
                        (id) => !users.find((u) => u.id === id).deleted_at
                      ) ? (
                        <DropdownItem onClick={() => setArchivedUsers()}>
                          Archive user
                        </DropdownItem>
                      ) : null}
                      {selection.selectedUsers.length ? (
                        <DropdownItem onClick={() => setDeletedUsers()}>
                          Delete user
                        </DropdownItem>
                      ) : null}
                      {selection.selectedUsers.length ? (
                        <DropdownItem
                          color="primary"
                          onClick={onClickSendSetPassword}
                        >
                          {' '}
                          Send &#34;set password&#34;
                        </DropdownItem>
                      ) : null}
                      {selection.selectedUsers.length ? (
                        <DropdownItem color="primary" onClick={onClickEditUser}>
                          {' '}
                          Edit users
                        </DropdownItem>
                      ) : null}
                      {selection.selectedUsers.length ? (
                        <DropdownItem
                          color="primary"
                          onClick={() =>
                            onClickUpdateUserStatus(PLANS_STATUS.ACTIVE)
                          }
                        >
                          {' '}
                          Activate subscription
                        </DropdownItem>
                      ) : null}
                      {selection.selectedUsers.length ? (
                        <DropdownItem
                          color="primary"
                          onClick={() =>
                            onClickUpdateUserStatus(PLANS_STATUS.INACTIVE)
                          }
                        >
                          {' '}
                          Deactivate subscription
                        </DropdownItem>
                      ) : null}
                    </DropdownMenu>
                  </ButtonDropdown>
                )}
              </Col>
            </Row>

            <Col xs="12" md="12" lg="12" className="allow-overflow">
              <ReactTable
                showPagination={users.length > 0}
                minRows={0}
                data={[...users]}
                columns={[...columns]}
                resizable={true}
                defaultPageSize={25}
                filterable={true}
                defaultFilterMethod={filterCaseInsensitive}
                onFetchData={(pr) => {
                  const data = pr.data.length
                    ? pr.sortedData.slice(0, pr.pageSize)
                    : users;
                  setState({ tableData: [...data] });
                }}
              />
            </Col>
          </Card>
        </Col>
      </Row>
      <ConfirmationModal
        isOpen={state.archive_modal}
        title="Archive users"
        description="Archiving this user account will set it to inactive and will prevent
          the user from accessing the application. Do you want to archive this
          user account?"
        onCancel={() => toggleModal('archive_modal')}
        submitText="Archive"
        onSubmit={() => onArchiveUsers()}
      />

      <ConfirmationModal
        isOpen={state.delete_modal}
        title="Delete users"
        description="Deleting user account(s) will permanently delete all data
          for these accounts.  Are you sure you want to continue?"
        onCancel={() => toggleModal('delete_modal')}
        submitText="Delete"
        onSubmit={() => onDeleteUsers()}
      />
    </div>
  );
};

export default memo(UserTable);
