import React, { memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, Input } from 'reactstrap';
import moment from 'moment';
import ReactTable from 'react-table';

import { capitalize } from '../../helpers/common';
import SelectionPopover from '../Popovers/SelectionPopover';
import {
  getAllTasks,
  setCurrentTask,
  setTaskState,
} from '../../redux/actions/task';
import TaskModal from '../Modals/TaskModal';
import { TASK_MODAL_MODE } from '../../constants/task';
import { filterCaseInsensitive } from '../../helpers/filter';

import './styles.scss';

const Tasks = ({ queryParameters = {}, showPagination }) => {
  const dispatch = useDispatch();
  const taskState = useSelector((state) => state.task);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const queryParams = { ...queryParameters };
    dispatch(getAllTasks(queryParams));
  }, []);

  const [selectedRecords, setSelectedRecords] = useState([]);
  const [isSelectedAll, setSelectedAll] = useState(false);
  const [isOpenSelectionPopover, setOpenSelectionPopover] = useState(false);
  const [isOpenTaskModal, setOpenTaskModal] = useState(false);

  const onCheckboxClick = (value) => {
    const record = selectedRecords.includes(value);
    if (record) {
      setSelectedRecords(selectedRecords.filter((r) => r.id !== value));
    } else {
      let selected = [...selectedRecords];
      selected.push(value);
      setSelectedRecords(selected);
    }
  };
  const onToggleSelectionPopover = (isOpen) => {
    const open =
      isOpen !== undefined ? isOpen : !isOpenSelectionPopover && !isSelectedAll;
    setOpenSelectionPopover(open);
  };
  const onClickOnThisPage = () => {
    const newCheckboxValue = !isSelectedAll;

    let checkedCopy = [];
    if (newCheckboxValue) {
      tableData.map((val) => {
        const id = val?._original?.id ?? val.id;
        checkedCopy.push(id);
      });
    }
    const payload = {
      selectedTasks: [...checkedCopy],
    };
    dispatch(setTaskState(payload));
    setSelectedAll(newCheckboxValue);
    setSelectedRecords(checkedCopy);
    onToggleSelectionPopover();
  };

  const onClickAllRecords = () => {
    const newCheckboxValue = !isSelectedAll;
    let checkedCopy = [];
    if (newCheckboxValue) {
      taskState.tasks.map((val) => {
        checkedCopy.push(val.id);
      });
    }
    const payload = {
      selectedTasks: [...checkedCopy],
    };
    dispatch(setTaskState(payload));
    setSelectedAll(newCheckboxValue);
    setSelectedRecords(checkedCopy);
    onToggleSelectionPopover();
  };

  const onSubmit = () => {
    setOpenTaskModal(false);
  };

  const onTaskClick = (task) => {
    dispatch(setCurrentTask(task));
    setOpenTaskModal(true);
  };

  const columns = [
    {
      Header: (cellinfo) => (
        <div className="position-relative form-check">
          <SelectionPopover
            isOpen={isOpenSelectionPopover}
            onClickOnThisPage={onClickOnThisPage}
            onClickAllRecords={onClickAllRecords}
            setOpen={(isOpen) => {
              const state =
                isOpen !== undefined ? isOpen : !isOpenSelectionPopover;
              setOpenSelectionPopover(state);
            }}
            content={
              <input
                type="checkbox"
                defaultChecked={isSelectedAll}
                onChange={(e) => {
                  onToggleSelectionPopover();
                  if (isSelectedAll) {
                    setSelectedAll(!isSelectedAll);
                    setSelectedRecords([]);
                  }
                }}
              />
            }
          />
        </div>
      ),
      id: 'select',
      accessor: (d) => {
        return { id: d.id };
      },
      Cell: (props) => (
        <FormGroup check>
          <Input
            type="checkbox"
            value={props.value.id}
            defaultChecked={selectedRecords.includes(props.value.id)}
            onChange={(e) => onCheckboxClick(parseInt(e.target.value))}
            key={props.value.id}
          />
        </FormGroup>
      ),
      headerClassName: 'wordwrap',
      sortable: false,
      filterable: false,
      maxWidth: 85,
    },
    {
      Header: 'Title',
      accessor: 'title',
      headerClassName: 'wordwrap',
      Cell: (props) => {
        return (
          <span
            className="title-column"
            onClick={() => onTaskClick(props.original)}
          >
            {props.original.title}
          </span>
        );
      },
      minWidth: 240,
    },
    {
      Header: 'Due date',
      id: 'dueDate',
      accessor: (d) => {
        return moment(d.due_date).format('DD/MM/YYYY HH:mm');
      },
      headerClassName: 'wordwrap',
      sortable: true,
      minWidth: 200,
    },
    {
      Header: 'Priority',
      accessor: 'priority',
      headerClassName: 'wordwrap',
      minWidth: 120,
      Cell: (r) => {
        return capitalize(r.original.priority);
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      headerClassName: 'wordwrap',
      minWidth: 120,
      Cell: (r) => {
        return capitalize(r.original.status);
      },
    },
  ];
  return (
    <div className="tasks-component">
      <ReactTable
        showPagination={showPagination !== undefined ? showPagination : taskState.tasks.length > 0 }
        minRows={0}
        data={taskState.tasks}
        columns={columns}
        resizable={false}
        defaultPageSize={25}
        defaultFilterMethod={filterCaseInsensitive}
        filterable={true}
        onFetchData={(props) => {
          const data = props.data.length
            ? props.sortedData.slice(0, props.pageSize)
            : taskState.tasks;
          setTableData(data);
        }}
      />
      <TaskModal
        isOpen={isOpenTaskModal}
        onCancel={() => setOpenTaskModal(false)}
        onSubmit={onSubmit}
        mode={TASK_MODAL_MODE.VIEW}
      />
    </div>
  );
};

export default memo(Tasks);
