import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory
} from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Header from '../components/header/header.jsx';
import Sidebar from '../components/sidebar/sidebar.jsx';
import Footer from '../components/footer/footer.jsx';
import Customizer from '../components/customizer/customizer';
import ThemeRoutes from '../routes/routing.jsx';
import { getUserRoles, includesRole } from '../helpers/user';
import { setNotificationList } from '../redux/actions/notification';
import 'react-table/react-table.css';
import { SocketContext } from '../context/socket';
import axios from '../axios';
import ComponentProvider from '../components/ComponentProvider/index.jsx';

const priorityMapping = {
  low: 'info',
  medium: 'warning',
  high: 'error'
};
const FullLayout = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [width, setWidth] = useState(window.innerWidth);
  const { socket } = useContext(SocketContext);
  const [settings, setSettings] = useState([
    {
      theme: 'light',
      layout: 'vertical',
      dir: 'ltr',
      sidebartype: 'full',
      sidebarpos: 'fixed',
      headerpos: 'fixed',
      boxed: 'full',
      navbarbg: 'skin6',
      sidebarbg: 'skin1',
      logobg: 'skin1'
    }
  ]);
  const roles = getUserRoles(props.user?.roles);

  useEffect(() => {
    if (
      window.innerWidth < 767 &&
      document
        .getElementById('main-wrapper')
        .className.indexOf('show-sidebar') !== -1
    ) {
      document.getElementById('main-wrapper').classList.toggle('show-sidebar');
    }
  }, [history]);

  useEffect(() => {
    if (socket) {
      socket.on('notification', ({ message, priority }) => {
        toast(message, {
          type: priorityMapping[priority],
          className: `toast-notification--${priority}`
        });

        getNotifications();
      });
    }
  }, [socket]);

  const getNotifications = async () => {
    const response = await axios.get('notifications', {
      params: {
        query: 'notification_badge'
      }
    });
    props.setNotificationList(response.data);
  };

  useLayoutEffect(() => {
    window.addEventListener('load', updateDimensions);
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  /*--------------------------------------------------------------------------------*/
  /*Function that handles sidebar, changes when resizing App                        */
  /*--------------------------------------------------------------------------------*/
  const updateDimensions = () => {
    let element = document.getElementById('main-wrapper');
    setWidth(window.innerWidth);
    element.setAttribute('data-sidebartype', 'mini-sidebar');
    element.classList.add('mini-sidebar');
    if (settings[0].sidebarpos === 'fixed') {
      document.getElementById('sidebar-position').setAttribute('checked', '');
    }
    if (settings[0].headerpos === 'fixed') {
      document.getElementById('header-position').setAttribute('checked', '');
    }
    if (settings[0].theme === 'dark') {
      document.getElementById('theme-view').setAttribute('checked', '');
    }
    if (settings[0].boxed === 'boxed') {
      document.getElementById('boxed-layout').setAttribute('checked', '');
    }
    if (settings[0].dir === 'rtl') {
      document.getElementById('rtl').setAttribute('checked', '');
    }
  };

  const darkTheme = (a) => {
    if (a.target.checked) {
      let darktheme = JSON.parse(JSON.stringify(settings));
      darktheme[0].theme = 'dark';
      setSettings(darktheme);
    } else {
      let lighttheme = JSON.parse(JSON.stringify(settings));
      lighttheme[0].theme = 'light';
      setSettings(lighttheme);
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*Theme Setting && Changes Default(FULL) LAYOUT to BOXED LAYOUT                   */
  /*--------------------------------------------------------------------------------*/
  const boxedTheme = (b) => {
    if (b.target.checked) {
      let boxtheme = JSON.parse(JSON.stringify(settings));
      boxtheme[0].boxed = 'boxed';
      setSettings(boxtheme);
    } else {
      let fulltheme = JSON.parse(JSON.stringify(settings));
      fulltheme[0].boxed = 'full';
      setSettings(fulltheme);
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*Theme Setting && Changes Default(ltr) DIRECTION to rtl DIRECTION                   */
  /*--------------------------------------------------------------------------------*/
  const rtl = (h) => {
    if (h.target.checked) {
      let rtl = JSON.parse(JSON.stringify(settings));
      rtl[0].dir = 'rtl';
      setSettings(rtl);
    } else {
      let ltr = JSON.parse(JSON.stringify(settings));
      ltr[0].dir = 'ltr';
      setSettings(ltr);
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*Theme Setting && Changes Default(FIXED) POSITION of HEADER to ABSOLUTE POSITION */
  /*--------------------------------------------------------------------------------*/
  const headerPosition = (c) => {
    if (c.target.checked) {
      let fixedpos = JSON.parse(JSON.stringify(settings));
      fixedpos[0].headerpos = 'fixed';
      setSettings(fixedpos);
    } else {
      let absolutepos = JSON.parse(JSON.stringify(settings));
      absolutepos[0].headerpos = 'absolute';
      setSettings(absolutepos);
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*Theme Setting && Changes Default(FIXED) POSITION of SIDEBAR to ABSOLUTE POSITION*/
  /*--------------------------------------------------------------------------------*/
  const sidebarPosition = (d) => {
    if (d.target.checked) {
      let sidebarfixedpos = JSON.parse(JSON.stringify(settings));
      sidebarfixedpos[0].sidebarpos = 'fixed';
      setSettings(sidebarfixedpos);
    } else {
      let sidebarabsolutepos = JSON.parse(JSON.stringify(settings));
      sidebarabsolutepos[0].sidebarpos = 'absolute';
      setSettings(sidebarabsolutepos);
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*Theme Setting && Changes NAVBAR BACKGROUND-COLOR from given options             */
  /*--------------------------------------------------------------------------------*/
  const navbarbgChange = (e) => {
    let navskin = e.currentTarget.dataset.navbarbg;
    let newsettings = JSON.parse(JSON.stringify(settings));
    newsettings[0].navbarbg = navskin;
    setSettings(newsettings);
  };
  /*--------------------------------------------------------------------------------*/
  /*Theme Setting && Changes SIDEBAR-MENU BACKGROUND-COLOR from given options       */
  /*--------------------------------------------------------------------------------*/
  const sidebarbgChange = (f) => {
    let sidebarskin = f.currentTarget.dataset.sidebarbg;
    let newsettings = JSON.parse(JSON.stringify(settings));
    newsettings[0].sidebarbg = sidebarskin;
    setSettings(newsettings);
  };
  /*--------------------------------------------------------------------------------*/
  /*Theme Setting && Changes LOGO BACKGROUND-COLOR from given options               */
  /*--------------------------------------------------------------------------------*/
  const logobgChange = (g) => {
    let logoskin = g.currentTarget.dataset.logobg;
    let newsettings = JSON.parse(JSON.stringify(settings));
    newsettings[0].logobg = logoskin;
    setSettings(newsettings);
  };

  return (
    <div
      id="main-wrapper"
      dir={settings[0].dir}
      data-theme={settings[0].theme}
      data-layout={settings[0].layout}
      data-sidebartype={settings[0].sidebartype}
      data-sidebar-position={settings[0].sidebarpos}
      data-header-position={settings[0].headerpos}
      data-boxed-layout={settings[0].boxed}
    >
      {/*--------------------------------------------------------------------------------*/}
      {/* Header                                                                         */}
      {/*--------------------------------------------------------------------------------*/}
      <Header data={{ settings: settings }} {...props} socket={socket} />
      {/*--------------------------------------------------------------------------------*/}
      {/* Sidebar                                                                        */}
      {/*--------------------------------------------------------------------------------*/}
      <Sidebar data={{ settings: settings }} {...props} routes={ThemeRoutes} />
      {/*--------------------------------------------------------------------------------*/}
      {/* Page Main-Content                                                              */}
      {/*--------------------------------------------------------------------------------*/}
      <div className="page-wrapper d-block">
        <div className="page-content container-fluid">
          <Switch>
            {ThemeRoutes.map((prop) => {
              if (
                roles?.length &&
                prop.roles?.length &&
                !includesRole(prop.roles, roles)
              ) {
                return <Redirect to="/auth/login" />;
              }
              if (prop.collapse) {
                return prop.children.map((prop2) => {
                  if (prop2.collapse) {
                    return prop2.subchild.map((prop3) => {
                      return (
                        <Route
                          path={prop3.path}
                          render={(routeProps) => {
                            return (
                              <ComponentProvider features={prop3.features}>
                                <prop3.component {...routeProps} />
                              </ComponentProvider>
                            );
                          }}
                          key={location.pathname}
                        />
                      );
                    });
                  }
                  return (
                    <Route
                      path={prop2.path}
                      render={(routeProps) => {
                        return (
                          <ComponentProvider features={prop2.features}>
                            <prop2.component {...routeProps} />
                          </ComponentProvider>
                        );
                      }}
                      key={location.pathname}
                    />
                  );
                });
              } else if (prop.redirect) {
                return (
                  <Redirect
                    from={prop.path}
                    to={prop.pathTo}
                    key={location.pathname}
                  />
                );
              } else {
                return (
                  <Route
                    path={prop.path}
                    key={location.pathname}
                    render={(routeProps) => {
                      return (
                        <ComponentProvider features={prop.features}>
                          <prop.component {...routeProps} />
                        </ComponentProvider>
                      );
                    }}
                  />
                );
              }
            })}
          </Switch>
        </div>
        <Footer />
      </div>
      {/*--------------------------------------------------------------------------------*/}
      {/* Customizer from which you can set all the Layout Settings                      */}
      {/*--------------------------------------------------------------------------------*/}
      <Customizer
        darkTheme={darkTheme}
        boxedTheme={boxedTheme}
        rtl={rtl}
        headerPosition={headerPosition}
        sidebarPosition={sidebarPosition}
        navbarbgChange={navbarbgChange}
        sidebarbgChange={sidebarbgChange}
        logobgChange={logobgChange}
      />
    </div>
  );
};

export default connect(
  (state) => ({
    user: state?.user?.user
  }),
  (dispatch) => ({
    setNotificationList: (id) => {
      dispatch(setNotificationList(id));
    }
  })
)(FullLayout);
