import React, { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Calendar from '../../../components/Calendar';
import LayoutTemplate from '../../../components/LayoutTemplate';
import CalendarHeaderOptions from './CalendarHeaderOptions';
import { getUsers } from '../../../redux/actions/user';

import './styles.scss';

const MyCalendar = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  return (
    <div className="calendar-page">
      <LayoutTemplate
        title="Calendar"
        headerOptions={<CalendarHeaderOptions />}
        content={
          <div className="calendar-block">
            <Calendar />
          </div>
        }
      />
    </div>
  );
};

export default memo(MyCalendar);
