import React, { memo } from 'react';
import { Button } from 'reactstrap';

import logo from "../../assets/images/kraal-logo.svg";

import './styles.scss';

const Error403 = () => {
  return (
    <div className="error-403">
      <div className="error-code"><img src={logo} alt="Kraal logo" height="185" width="260" /></div>
      <div className="error-title">Sorry, access denied</div>
      <div className="error-description">You don't have permission to view this page.</div>
      <Button
        color="primary"
        className={'error-btn'}
        onClick={() => {
          window.location.href = '/profile?tab=5';
        }}
      >
        Update subscription plan
      </Button>
    </div>
  );
};

export default memo(Error403);
