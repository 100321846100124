import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import LayoutTemplate from '../../../components/LayoutTemplate';
import { getUsers } from '../../../redux/actions/user';
import TaskHeaderOptions from './TaskHeaderOptions';
import Tasks from '../../../components/Tasks';

const MyTasks = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  return (
    <div>
      <LayoutTemplate
        title="Tasks"
        headerOptions={<TaskHeaderOptions />}
        content={
          <div className="tasks-block">
            <Tasks />
          </div>
        }
      />
    </div>
  );
};

export default MyTasks;
