import React from "react";
import ReactTable from "react-table";

import axios from "../../../axios";

class LivestockCount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      geofences: [],
      farmId: "",
    };
  }
  async getFences() {
    let response = await axios.get("geofences");
    if (response.status === 200) {
      this.setState((state) => ({
        ...state,
        geofences: response.data,
      }));
    }
  }

  componentDidMount() {
    this.getFences();
  }
  render() {
    //this.getFences();
    const columns = [
      {
        Header: "Geofence Name",
        accessor: "name",
        headerClassName: "wordwrap",
      },
      {
        Header: "Assigned Livestock",
        accessor: "active_livestock_count",
        headerClassName: "wordwrap",
      },
      {
        Header: "Tagged Assigned Livestock",
        accessor: "actual_livestock",
        headerClassName: "wordwrap",
      },
      {
        Header: "tagged Assigned Livestock",
        accessor: "actual_livestock",
        headerClassName: "wordwrap",
      },
      {
        Header: "Expected Livestock Count",
        accessor: "expected_livestock",
        headerClassName: "wordwrap",
      },
    ];
    return (
      <div>
        {this.props.farmId}
        <ReactTable
          showPagination={this.state.geofences > 0}
          minRows={0}
          data={this.state.geofences}
          columns={columns}
          resizable={false}
          defaultPageSize={10}
        />
      </div>
    );
  }
}

export default LivestockCount;
