'use strict';

import React from 'react';

class LoadingBar extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  }
}

export default LoadingBar;
