import { TABLE_COLUMNS } from '../configs/subscriptions';

export const getTableColumns = (columns, planType, featureName) => {
  if (TABLE_COLUMNS[featureName]?.[planType]?.length) {
    return columns?.filter((column) =>
      TABLE_COLUMNS[featureName]?.[planType]?.includes(column.id)
    );
  }
  return columns;
};

export const isLivestockFieldAvailable = (fieldId, planType, featureName) => {
  if (TABLE_COLUMNS[featureName]?.[planType]?.length) {
    return TABLE_COLUMNS[featureName]?.[planType].includes(fieldId);
  }
  return true;
};

export const isFieldAvailable = (fieldId, planType, featureName) => {
  if (TABLE_COLUMNS[featureName]?.[planType]?.length) {
    return TABLE_COLUMNS[featureName]?.[planType].includes(fieldId);
  }
  return true;
};
