import React, { memo } from 'react';

import WidgetTemplate from '../WidgetTemplate';

const ActiveLivestockWidget = ({ props }) => {
  const onClick = () => {
    props.history.push({
      pathname: 'animals',
      filter: { farms: props.farm ? [props.farm] : [] },
    });
  };

  const icon = <div className="hc-icon"></div>;

  return (
    <WidgetTemplate
      icon={icon}
      title={'Active Livestock'}
      onClick={onClick}
      value={props.activeLivestock}
    />
  );
};

export default memo(ActiveLivestockWidget);
