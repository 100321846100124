import React from 'react';
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Form,
  Row,
  Col,
  Label,
  Button
} from 'reactstrap';
import axios from '../../axios';

import validators from './validators';

import img2 from '../../assets/images/diagri-background.png';
import img101 from '../../assets/images/kraal_darklogo.png';

const sidebarBackground = {
  backgroundImage: 'url(' + img2 + ')',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center'
};

class Reset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      password_confirm: '',
      error: null,
      hide_password: true,
      validated: false,
      validate_error: 'Validating your token.',
      done: false
    };
    this.handleClick = this.handleClick.bind(this);
    this.validators = validators;
    this.onInputChange = this.onInputChange.bind(this);
    this.doLogin = this.doLogin.bind(this);
    this.showErrors = this.showErrors.bind(this);
    this.formValidators = this.formValidators.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
  }
  async componentDidMount() {
    try {
      let response = await axios.post('/password/reset/verify/token', {
        token: this.props.match.params.token
      });
      if (response.status === 200) {
        this.setState({
          ...this.state,
          validated: true
        });
      }
    } catch (error) {
      console.error('verify reset token', error.response.data.message);
      this.setState({
        ...this.state,
        validate_error: error.response.data.message
      });
    }
  }
  togglePassword(type = 'normal') {
    if (type === 'normal') {
      this.setState({ hide_password: !this.state.hide_password });
    } else if (type === 'confirm') {
      this.setState({
        hide_password_confirm: !this.state.hide_password_confirm
      });
    }
    this.forceUpdate();
  }
  onInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
    this.formValidators([event.target.name], event.target.value);
  }
  formValidators(fieldName, value) {
    if (fieldName !== 'email') {
      this.validators[fieldName].valid = true;
      this.validators[fieldName].state = value;
    }
    this.validators[fieldName].errors = [];
    this.validators[fieldName].state = value;
    this.validators[fieldName].valid = true;
    this.validators[fieldName].rules.forEach((rule) => {
      if (rule.test instanceof RegExp) {
        if (!rule.test.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      } else if (typeof rule.test === 'function') {
        if (!rule.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      }
    });
  }
  validForm() {
    let status = true;
    Object.keys(this.validators).forEach((field) => {
      if (field === 'email') {
        if (!this.validators[field].valid) {
          status = false;
        }
      }
    });
    return status;
  }
  showErrors(fieldName) {
    const validator = this.validators[fieldName];
    const result = '';
    if (validator && !validator.valid) {
      const errors = validator.errors.map((info, index) => {
        return (
          <span className="error" key={index}>
            * {info}
            <br />
          </span>
        );
      });
      return <div className="error mb-2">{errors}</div>;
    }
    return result;
  }
  async doLogin(event) {
    event.preventDefault();
    const { email, password } = this.state;
    //TODO: request to backend
    try {
      let response = await axios.post('/password/reset', {
        token: this.props.match.params.token,
        password: password
      });
      if (response.status === 200) {
        this.setState({
          ...this.state,
          done: true
        });
      }
    } catch (error) {
      let response = error.response;
      // if (response.data.field===null) {
      // 	response.data["field"] = "password";
      // }
      // this.validators[response.data.field].valid = false;
      // this.validators[response.data.field].errors = [];
      // this.validators[response.data.field].errors.push(response.data.message);
      // this.forceUpdate();
    }
  }
  handleClick() {
    var elem = document.getElementById('loginform');
    elem.style.transition = 'all 2s ease-in-out';
    elem.style.display = 'none';
    document.getElementById('recoverform').style.display = 'block';
  }
  render() {
    return (
      <div
        className="auth-wrapper  align-items-center d-flex"
        style={sidebarBackground}
      >
        {/*--------------------------------------------------------------------------------*/}
        {/*Login2 Cards*/}
        {/*--------------------------------------------------------------------------------*/}
        <div className="container">
          <div>
            <Row className="no-gutters justify-content-center">
              <Col md="6" lg="5" className="bg-dark text-white">
                <div className="p-5">
                  <h5 className="font-login-small">
                    We’ll get you back on the Kraal platform shortly.
                  </h5>
                  <div className="logo-place login-form-logo">
                    <img src={img101}></img>
                  </div>
                </div>
              </Col>
              <Col md="6" lg="5" className="bg-white">
                <div className="p-5">
                  <h3 className="font-medium mb-3">Reset Password</h3>
                  {this.state.validated && !this.state.done && (
                    <Form className="mt-3" id="loginform" action="/dashbaord">
                      <Label for="password" className="mt-3">
                        New Password
                      </Label>
                      <InputGroup className="mb-3" size="lg">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type={this.state.hide_password ? 'password' : 'text'}
                          id="password"
                          name="password"
                          value={this.state.password}
                          onChange={this.onInputChange}
                          placeholder="Password"
                        />
                        <InputGroupAddon
                          addonType="append"
                          onClick={() => {
                            this.togglePassword('normal');
                          }}
                        >
                          <InputGroupText>
                            <i
                              className={`fas ${
                                this.state.hide_password
                                  ? 'fa-eye'
                                  : 'fa-eye-slash'
                              }`}
                            ></i>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      {this.showErrors('password')}
                      <Label for="password" className="mt-3">
                        New Password Confirmation
                      </Label>
                      <InputGroup className="mb-3" size="lg">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type={this.state.hide_password ? 'password' : 'text'}
                          id="password"
                          name="password_confirm"
                          value={this.state.password_confirm}
                          onChange={this.onInputChange}
                          placeholder="Password"
                        />
                        <InputGroupAddon
                          addonType="append"
                          onClick={() => {
                            this.togglePassword('normal');
                          }}
                        >
                          <InputGroupText>
                            <i
                              className={`fas ${
                                this.state.hide_password
                                  ? 'fa-eye'
                                  : 'fa-eye-slash'
                              }`}
                            ></i>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      {this.showErrors('password_confirm')}

                      <Row className="mb-3">
                        <Col xs="12">
                          <Button
                            color="primary"
                            onClick={this.doLogin}
                            className={`${this.validForm() ? '' : 'disabled'}`}
                            size="lg"
                            type="submit"
                            block
                          >
                            Reset Password
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                  {!this.state.validated && (
                    <div>
                      <p className="text-red">
                        {this.state.validate_error || ''}
                      </p>
                      <a href="/auth/recover-pwd">
                        <b>Get new token</b>
                      </a>
                    </div>
                  )}
                  {this.state.done && (
                    <div>
                      <p>
                        Your password has been reset. You can now login with
                        your new password
                      </p>
                      <a href="/auth/login">
                        <b>Go to Login</b>
                      </a>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default Reset;
