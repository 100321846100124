export const getQueryNameByRelationType = (relationType) => {
  switch (relationType) {
    case 'geofence': {
      return 'geofences';
    }
    case 'farm': {
      return 'farms';
    }
    case 'site': {
      return 'sites';
    }
    case 'group': {
      return 'labels';
    }
    case 'tag': {
      return 'tags';
    }
    case 'gateway': {
      return 'gateways';
    }
    case 'livestock': {
      return 'animals';
    }
    default: {
      return relationType;
    }
  }
};
