import React from 'react';


class Footer extends React.Component {
  render() {
    return (
      <footer className="footer text-center">
       All rights reserved by Kraal Ltd.
      </footer>
    );
  }
}
export default Footer;
