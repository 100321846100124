import React from 'react';
import { Row, Col } from 'reactstrap';

import axios from '../../axios';

import img2 from '../../assets/images/diagri-background.png';
import img101 from '../../assets/images/kraal_darklogo.png';

const sidebarBackground = {
  backgroundImage: 'url(' + img2 + ')',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center'
};

class Login2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: props.match.params.token,
      verified: false
    };
  }
  async verifyToken() {
    try {
      let response = await axios.post('verify/verify', {
        token: this.state.token
      });
      if (response.status == 200) {
        this.setState({
          verified: true
        });
        this.props.history.push('/profile');
      }
      this.forceUpdate();
    } catch (error) {}
  }
  componentDidMount() {
    this.verifyToken();
  }
  render() {
    return (
      <div
        className="auth-wrapper  align-items-center d-flex"
        style={sidebarBackground}
      >
        {/*--------------------------------------------------------------------------------*/}
        {/*Login2 Cards*/}
        {/*--------------------------------------------------------------------------------*/}
        <div className="container">
          <div>
            <Row className="no-gutters justify-content-center reset-height">
              <Col md="6" lg="5" className="bg-dark text-white">
                <div className="p-5">
                  <h4 className="font-login">Verify email.</h4>

                  <div className="logo-place login-form-logo">
                    <img src={img101}></img>
                  </div>
                </div>
              </Col>
              <Col md="6" lg="5" className="bg-white">
                {this.state.verified && (
                  <div className="p-5">
                    <p>Your account has been verfified.</p>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default Login2;
