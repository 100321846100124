import React, { memo } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import moment from 'moment';

const ButtonRow = ({ onSelect }) => {
  const subscriptionState = useSelector((state) => state.subscription);
  const planStatusMapping = {
    active: (
      <>
        Your current plan which will end on
        <strong>
          {moment(subscriptionState?.myPlan?.end_date).format(
            ' DD/MM/YYYY HH:mm'
          )}
        </strong>
      </>
    ),
    pending: (
      <>
        Your current plan which is <strong>pending</strong>
      </>
    ),
    inactive: (
      <>
        Your current plan which is <strong>inactive</strong>
      </>
    )
  };
  return (
    <Row>
      <Col sm="4" md="4" lg="3"></Col>

      {subscriptionState.plans.map((plan, index) => {
        const isSelected = plan.type === subscriptionState?.myPlan?.type;
        return (
          <Col
            className={`btn-col align-center plan plan-footer ${
              isSelected && 'selected-btn-col'
            }`}
            key={plan.title + index}
          >
            <Button
              style={{
                backgroundColor: plan.color
              }}
              onClick={() => onSelect(plan, subscriptionState?.myPlan?.id)}
            >
              {subscriptionState?.myPlan?.id ? 'Update' : 'Select'}
            </Button>
            {isSelected ? (
              <div
                style={{
                  paddingTop: '10px',
                  paddingBottom: '10px'
                }}
              >
                {planStatusMapping[subscriptionState?.myPlan?.status]}
              </div>
            ) : null}
          </Col>
        );
      })}
    </Row>
  );
};

export default memo(ButtonRow);
