import React, { memo, useState, useEffect } from 'react';
import Select from 'react-select';
import { FcCheckmark } from 'react-icons/fc';
import { Button, Modal, ModalBody, ModalFooter, Row, Col } from 'reactstrap';

import { PLANS_PERIOD, LIMIT_OPTIONS } from '../../../constants/subscription';

const SubmitSubscriptionModal = ({
  isUpdate,
  isOpen,
  onCancel,
  onSubmit,
  plan,
  title,
  isEditDisabled,
  cancelText
}) => {
  const [period, setPeriod] = useState({ value: 1, label: 1 });
  const [limits, setLimits] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const handlePeriodChange = (e) => {
    setPeriod(e);
  };

  const onSubmitClick = () => {
    onSubmit(period.value, limits, isUpdate);
  };

  useEffect(() => {
    const initialLimits = {};
    plan?.feature_options?.forEach((item) => {
      if (item.limited) {
        initialLimits[item.type] = item.count;
      }
    });

    setLimits(initialLimits);
    setPeriod({ value: plan.period || 1, label: plan.period || 1 });
  }, [plan]);

  useEffect(() => {
    const totalPrice = plan?.feature_options?.reduce((prev, current) => {
      let limitMultiplier = 1;
      if (current.limited) {
        limitMultiplier = limits[current.type] / current.price_limit;
      }
      const price = current.price * period.value * limitMultiplier;
      return prev + price;
    }, 0);

    setTotalPrice(totalPrice);
  }, [period.value, limits]);

  return (
    <Modal size="lg" isOpen={isOpen}>
      <ModalBody>
        <br />
        <h5 className="text-center">
          <strong>
            {title || 'Select subscription period and feature limits'}
          </strong>
        </h5>
        <br />
        <br />
        <Row className="mb-3" style={{ alignItems: 'center' }}>
          <Col lg="6">Plan:</Col>
          <Col style={{ textAlign: 'center' }}>{plan.title}</Col>
        </Row>
        <Row className="mb-3" style={{ alignItems: 'center' }}>
          <Col lg="6">Period (month):</Col>
          <Col>
            <Select
              isDisabled={isEditDisabled}
              value={period}
              onChange={handlePeriodChange}
              options={PLANS_PERIOD.map((v) => {
                return { label: v, value: v };
              })}
            />
          </Col>
        </Row>
        <Row
          style={{
            width: '90%',
            height: '1px',
            background: '#e9ecef',
            margin: '14px auto'
          }}
        />
        {plan?.feature_options?.map((feature) => {
          return (
            <Row
              key={feature.title}
              style={{ padding: '6px 0', height: '48px', alignItems: 'center' }}
            >
              <Col lg="6">{feature.title}</Col>
              {feature.limited ? (
                <Col>
                  <Select
                    isDisabled={isEditDisabled}
                    value={{
                      label: limits[feature.type],
                      value: limits[feature.type]
                    }}
                    onChange={(e) => {
                      setLimits({
                        ...limits,
                        [feature.type]: e.value
                      });
                    }}
                    options={LIMIT_OPTIONS[feature.type]?.map((v) => {
                      return { label: v, value: v };
                    })}
                  />
                </Col>
              ) : (
                <Col style={{ textAlign: 'center' }}>
                  <FcCheckmark />
                </Col>
              )}
            </Row>
          );
        })}
        <Row
          style={{
            width: '90%',
            height: '1px',
            background: '#e9ecef',
            margin: '14px auto'
          }}
        />
        <Row
          style={{
            paddingTop: '20px',
            alignItems: 'center'
          }}
        >
          <Col lg="6">Total price:</Col>
          <Col style={{ textAlign: 'center' }}>${totalPrice}</Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        {onCancel && (
          <Button color="secondary" onClick={onCancel}>
            {cancelText || 'Cancel'}
          </Button>
        )}
        {onSubmit && (
          <Button color="primary" onClick={onSubmitClick}>
            Select
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default memo(SubmitSubscriptionModal);
