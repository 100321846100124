const validator = {
  email: {
    rules: [
      {
        test: /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i,
        message: 'Please Enter Valid Email'
      }
    ],
    errors: [],
    valid: false,
    state: ''
  },
  password: {
    rules: [
      {
        test: (value) => {
          return value?.length > 6;
        },
        message: 'Password must be at least 6 characters long.'
      },
      {
        test: (value) => {
          return /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()\-_=+\\|[\]{};:/?.><]).*$/.test(
            value
          );
        },
        message:
          'Please enter stronger password. Use at least 1 uppercase, 1 lowercase, 1 numeric and 1 special character.'
      }
    ],
    errors: [],
    valid: false,
    state: ''
  },
  password_confirm: {
    rules: [
      {
        test: (value, password) => {
          let passwd = password ?? this?.a?.password?.state;
          return passwd?.trim() === value?.trim();
        },
        message: 'Passwords do not match. Please try again.'
      }
    ],
    errors: [],
    valid: false,
    state: ''
  }
};

export default validator;
