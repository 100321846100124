import React, {
  useEffect,
  useState,
  useLayoutEffect,
  useCallback,
  useRef
} from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import { Provider, useDispatch } from 'react-redux';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import { io } from 'socket.io-client';

import store from './redux/store';
import indexRoutes from './routes/index.jsx';
import { setBaseURL } from './redux/actions/user';
import { SocketContext } from './context/socket';
import { getMyPlan } from './redux/actions/subscription';

import './assets/scss/style.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-phone-input-2/lib/style.css';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [socket, setSocket] = useState(null);
  const logoutTimer = useRef();

  const logout = (socket) => {
    window.localStorage.removeItem('token');
    window.location = '/';
    if (socket) {
      socket.disconnect();
    }
  };
  const onMouseMove = useCallback(() => {
    if (logoutTimer.current) {
      clearInterval(logoutTimer.current);
      logoutTimer.current = setInterval(() => {
        logout();
      }, 960000);
    }
  }, [logoutTimer]);

  useEffect(() => {
    logoutTimer.current = setInterval(() => {
      logout();
    }, 960000);
    return () => {
      clearInterval(logoutTimer.current);
    };
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('mousemove', onMouseMove);
    return () => {
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    // eslint-disable-next-line no-undef
    const [protocol, url] = process.env.REACT_APP_BASE_URL.split('://');
    const wsProtocol = protocol === 'http' ? 'ws' : 'wss';
    if (token) {
      setSocket(
        io(`${wsProtocol}://${url}`, {
          transports: ['websocket'],
          auth: {
            token
          }
        })
      );
    }
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      dispatch(getMyPlan());
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        const token = localStorage.getItem('token');

        if (!token && props.location.pathname) {
          let baseURL = props.location.pathname;
          if (props.location.search) baseURL += '?' + props.location.search;
          if (props.location.hash) baseURL += '#' + props.location.hash;
          dispatch(setBaseURL(baseURL));
        }

        return token ? (
          <SocketContext.Provider
            value={{
              socket
            }}
          >
            <Component {...props} />
          </SocketContext.Provider>
        ) : (
          <Redirect to="/auth/login" />
        );
      }}
    />
  );
};

const App = () => (
  <div>
    <div id="cover-spin"></div>
    <ToastContainer />
    <BrowserRouter>
      <Provider store={store}>
        <Switch>
          {indexRoutes.map((prop, key) => {
            if (prop.protected) {
              return (
                <PrivateRoute
                  {...prop}
                  path={prop.path}
                  key={key}
                  name={prop.name}
                  component={prop.component}
                />
              );
            } else {
              return (
                <Route path={prop.path} key={key} component={prop.component} />
              );
            }
          })}
        </Switch>
      </Provider>
    </BrowserRouter>
  </div>
);

ReactDOM.render(<App />, document.getElementById('root'));
