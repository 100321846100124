export const RELATION_TYPES = [
  'geofence',
  'livestock',
  'farm',
  'site',
  'group'
  // 'tag',
  // 'gateway',
];
export const RECURRING_TYPES = ['day', 'week', 'month', 'year'];

export const EVENT_MODAL_MODE = {
  CREATE: 'create',
  UPDATE: 'update',
  VIEW: 'view',
  GUEST: 'guest'
};
