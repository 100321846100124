import React, { useEffect, useState } from 'react';
import { Col, FormGroup } from 'reactstrap';
import axios from '../../../axios';
import Select from 'react-select';

const SiteFilters = (props) => {
  const [sites, setSites] = useState([]);
  const fetchData = async () => {
    let { data } = await axios.get('sites');
    setSites(
      data.map((x) => {
        return { value: x.id, label: x.name };
      })
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="filter-block labels">
      <p>Sites filter</p>
      <FormGroup row>
        <Col sm="12" lg="12">
          <Select
            name="type"
            value={props.selected}
            onChange={(value) => props.onChange(value, 'sites')}
            options={sites}
            isMulti={true}
            isSearchable={true}
          ></Select>
        </Col>
      </FormGroup>
    </div>
  );
};

export default SiteFilters;
