import React from 'react';
import {
  Row,
  Col,
  Card,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Input,
  Table
} from 'reactstrap';
import Select from 'react-select';
import zoom from '../../assets/images/zoom.png';

import Map from '../../components/geo_map';
import axios from '../../axios';
import { CompactPicker } from 'react-color';

class GeofenceNew extends React.Component {
  //Tabs
  constructor(props) {
    super(props);

    this.state = {
      activeTab: '1',
      editing: true,
      isFullScreen: false,
      selected_geofences: [],
      color: '#3388ff',
      geofence: {
        farm_id: null,
        name: '',
        size: '',
        color: '',
        animals_count: '',
        description: '',
        circumference: '',
        days_grazed: '',
        address: '',
        geo_json: {},
        farms_org: [],
        dropdownOpen: false
      },
      farms: [],
      selected_farm: ''
    };

    this.onChange = this.onChange.bind(this);
    this.multiselectChange = this.multiselectChange.bind(this);
    this.newGeofence = this.newGeofence.bind(this);
    this.onGeoJSONChange = this.onGeoJSONChange.bind(this);
    this.saveFence = this.saveFence.bind(this);
    this.toggleButton = this.toggleButton.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
  }
  toggleFullscreen() {
    this.setState((state) => ({
      ...state,
      isFullScreen: !this.state.isFullScreen
    }));
  }
  toggleButton() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  onChange = (value, field) => {
    this.setState((state) => ({
      ...state,
      geofence: {
        ...state.geofence,
        [field]: value
      }
    }));
    this.forceUpdate();
  };
  onGeoJSONChange(value) {
    this.setState((state) => ({
      ...state,
      geofence: {
        ...state.geofence,
        geo_json: value
      }
    }));
  }
  newGeofence(value) {
    this.setState((state) => ({
      ...state,
      geofence: {
        ...state.geofence,
        geo_json: value
      }
    }));
  }
  multiselectChange = (sle) => {
    let features = [];
    let farm = this.state.farms_org.filter((f) => {
      return f.id == sle.value;
    });
    features = farm[0].geofences;
    this.setState({
      ...this.state,
      selected_farm: sle,
      geofence: {
        ...this.state.geofence,
        farm_id: sle.value,
        address: sle.meta.address,
        features: features
      },
      editing: true
    });
    this.forceUpdate();
  };
  async componentDidMount() {
    //TODO: Get tag
    let response = await axios.get('farms?with_details=true');
    let farms = response.data.map((f) => {
      return {
        geofences: f.geofences,
        value: f.id,
        label: f.name,
        meta: {
          address: f.address.full_address,
          master_geofences: f.master_geofence
        }
      };
    });
    let farm = farms[0];
    this.setState({
      ...this.state,
      farms: farms,
      farms_org: response.data,
      selected_farm: farm,
      editing: true,
      geofence: {
        ...this.state.geofence,
        farm_id: farm.value,
        address: farm.meta.address,
        features: farm.geofences
      }
    });
  }
  async saveFence() {
    await axios.post('geofences', {
      ...this.state.geofence,
      color: this.state.color
    });
    this.props.history.push('/geofences');
  }

  render() {
    const zoomImg = {
      backgroundImage: 'url(' + zoom + ')'
    };
    return (
      <div>
        <Row>
          <Col xs="6" md="6" lg="6">
            <h4 className="">New Geofence</h4>
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="/geofences">List of geofences</a>
              </BreadcrumbItem>
              <BreadcrumbItem active>New Geofence</BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col xs="6" md="6" lg="6">
            <div className="">
              {' '}
              {/* flex-reverse */}
              <Button
                color="primary"
                className="float-right"
                onClick={() => this.saveFence()}
              >
                Save
              </Button>
              <Button
                className="float-right outline"
                onClick={() => {
                  this.props.history.push('/geofences');
                }}
              >
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="12" lg="12">
            <Card>
              <Row className="">
                <Col
                  xs="12"
                  md="12"
                  lg="12"
                  style={{ zIndex: 9 }}
                  className="global-table"
                >
                  <Table className="no-border mt-2 mb-2">
                    <tbody>
                      <tr>
                        <td className="v-align-middle">
                          <b>Farm</b>
                        </td>
                        <td>
                          <Select
                            id="geo_farm"
                            name="farm"
                            value={this.state.selected_farm}
                            onChange={this.multiselectChange}
                            options={this.state.farms}
                            defaultValue={
                              this.state.selected_farm.value
                                ? this.state.selected_farm
                                : null
                            }
                            isMulti={false}
                          ></Select>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row>
                <Col
                  xs="12"
                  md="12"
                  lg="12"
                  className={
                    this.state.isFullScreen
                      ? 'rg-full-screen icon-position'
                      : ''
                  }
                >
                  <Map
                    geofence={this.state.geofence}
                    newGeofence={this.newGeofence}
                    editing={this.state.editing}
                    onEdit={this.onGeoJSONChange}
                    color={this.state.color}
                    type="geofence"
                    new={true}
                    isFullScreen={this.state.isFullScreen}
                  ></Map>
                  <div
                    className="full-screen-icon text-center"
                    onClick={this.toggleFullscreen}
                    style={zoomImg}
                  ></div>
                </Col>
              </Row>
            </Card>
            <Card>
              <Row>
                <Col xs="12" md="12" lg="12" className="global-table">
                  {this.state.geofence.farm_id && (
                    <Table>
                      <tbody>
                        <tr>
                          <td colSpan="2">
                            <h4>Geofence Details</h4>
                          </td>
                        </tr>
                        <tr>
                          <td className="v-align-middle">
                            <b>Geofence Name</b>
                          </td>
                          <td>
                            <Input
                              name="name"
                              defaultValue={this.state.geofence.name}
                              onChange={(e) => {
                                this.onChange(e.target.value, e.target.name);
                              }}
                            ></Input>
                          </td>
                        </tr>
                        <tr>
                          <td className="v-align-middle">
                            <b>Geofence Color</b>
                          </td>
                          <td>
                            <CompactPicker
                              name="colour"
                              color={this.state.color}
                              onChange={(e) => {
                                this.setState({
                                  ...this.state,
                                  color: e.hex
                                });
                              }}
                            ></CompactPicker>
                          </td>
                        </tr>
                        <tr>
                          <td className="v-align-middle">
                            <b>Farm ID</b>
                          </td>
                          <td>{this.state.geofence.farm_id}</td>
                        </tr>
                        <tr>
                          <td className="v-align-middle">
                            <b>Size (ha)</b>
                          </td>
                          <td>{this.state.geofence.size}</td>
                        </tr>
                        <tr>
                          <td className="v-align-middle">
                            <b>Perimeter (km)</b>
                          </td>
                          <td>{this.state.geofence.circumference}</td>
                        </tr>
                        <tr>
                          <td className="v-align-middle">
                            <b>Description</b>
                          </td>
                          <td>
                            <Input
                              type="textarea"
                              name="description"
                              defaultValue={this.state.geofence.description}
                              onChange={(e) => {
                                this.onChange(e.target.value, e.target.name);
                              }}
                            ></Input>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  )}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row className={this.state.isFullScreen ? 'rg-full-screen-btn' : ''}>
          <Col lg="12">
            <Button
              color="primary"
              className="float-right"
              onClick={() => this.saveFence()}
            >
              Save
            </Button>
            <Button
              className="float-right outline"
              onClick={() => {
                this.props.history.push('/geofences');
              }}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default GeofenceNew;
