import React, { Component } from 'react';
import ReactTable from 'react-table';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import moment from 'moment';
import './sig-table-style.css';
import SelectionPopover from '../Popovers/SelectionPopover';
import { errorToastHandler } from '../action_notifier';
import messages from '../../constants/messages';

class SigTable extends Component {
  constructor(props) {
    super(props);
    this.prevState = []; //store the previous state
    this.onClickAllRecords = this.onClickAllRecords.bind(this);
    this.onClickOnThisPage = this.onClickOnThisPage.bind(this);
    this.changeEditApplyRecords = this.changeEditApplyRecords.bind(this);
  }
  onClickOnThisPage() {
    const newCheckboxValue = !this.state.allChecked;

    let checkedCopy = {};
    if (newCheckboxValue) {
      this.state.tableData.map((val) => {
        const id = val?._original?.id ?? val.id;
        checkedCopy[id] = 1;
      });
    }
    this.setState((state) => ({
      ...state,
      editApply: checkedCopy
    }));
    this.setState({
      allChecked: newCheckboxValue
    });
    this.props.onselect(checkedCopy);
    this.onToggleSelectionPopover();
  }

  onClickAllRecords() {
    const newCheckboxValue = !this.state.allChecked;
    let checkedCopy = {};
    if (newCheckboxValue) {
      this.state.field.map((val) => {
        checkedCopy[val.id] = 1;
      });
    }
    this.setState((state) => ({
      ...state,
      editApply: checkedCopy
    }));
    this.setState({
      allChecked: newCheckboxValue
    });
    this.props.onselect(checkedCopy);
    this.onToggleSelectionPopover();
  }
  onToggleSelectionPopover(isOpen) {
    const open =
      isOpen !== undefined
        ? isOpen
        : !this.state.isOpenSelectionPopover && !this.state.allChecked;

    this.setState({
      isOpenSelectionPopover: open
    });
  }
  state = {
    field: [], //row
    tableData: [],
    isOpenSelectionPopover: false,
    columns: [], //column
    editApply: {}, //selected field data
    allChecked: false, //select all the field
    newRowsKeys: [], //have the keys of new rows added
    isNewRowAdd: false,
    newRowPos: [],
    editRowsKeys: [], //have the keys of edit rows
    editRowState: [], //store the state of initial row before changes
    sortableFixed: false, // to make sortable fixed while add
    stopSave: false,
    posOfEditRows: [] //store the position of the edited rows
  };

  componentDidUpdate(prevProps) {
    // rendering data
    if (this.props.data !== prevProps.data) {
      this.setState({
        field: this.props.data.data,
        tableData: this.props.data?.data?.slice(0, 25),
        columns: this.columnFormater()
      });
    }

    //rendering new rows data
    if (this.props.newRows !== prevProps.newRows) {
      this.prevState = JSON.parse(JSON.stringify(this.state.field)); //preserving prev state
      let newRows = [...this.state.field];
      let newRowKeys = [];
      this.props.newRows.forEach((val, indx) => {
        newRowKeys.push(val.key);
        this.state.newRowPos.push(indx);
      });
      newRows.unshift(...this.props.newRows);
      this.setState({
        field: newRows,
        newRowsKeys: newRowKeys
      });
    }
  }

  changeEditApplyRecords = (newRecords) => {
    this.setState({
      editApply: newRecords
    });
  };

  //fun extract the additional propeties of columns
  //add editable properties to the columns
  columnFormater = () => {
    let columns = [...(this.props?.data?.column ?? [])];
    for (let i of columns) {
      if (i.additional) {
        i['Cell'] = this.renderEditable;
      }
    }
    let newCol = {
      Header: () => (
        <div className="position-relative form-check">
          <SelectionPopover
            isOpen={this.state.isOpenSelectionPopover}
            onClickOnThisPage={this.onClickOnThisPage}
            onClickAllRecords={this.onClickAllRecords}
            setOpen={(isOpen) =>
              this.setState({
                isOpenSelectionPopover:
                  isOpen !== undefined
                    ? isOpen
                    : !this.state.isOpenSelectionPopover
              })
            }
            content={
              <input
                type="checkbox"
                checked={this.state.allChecked}
                onChange={() => {
                  this.onToggleSelectionPopover();
                  if (this.state.allChecked) {
                    this.setState({
                      allChecked: !this.state.allChecked
                    });
                    this.props.onselect([]);
                    this.setState((state) => ({
                      ...state,
                      editApply: {}
                    }));
                  }
                }}
              />
            }
          />
        </div>
      ),
      maxWidth: 80,
      filterable: false,
      sortable: false,
      Cell: this.renderCheckBox
    };
    columns.unshift(newCol);
    return columns;
  };

  renderCheckBox = (cellinfo) => {
    return (
      <div className="position-relative form-check">
        <input
          key={cellinfo.original.key}
          type="checkbox"
          checked={this.state.editApply[cellinfo.original.key] !== undefined}
          onChange={() => {
            const editApply = { ...this.state.editApply };
            if (editApply[cellinfo.original.key] !== undefined) {
              delete editApply[cellinfo.original.key];
            } else {
              editApply[cellinfo.original.key] = cellinfo.index;
            }
            this.setState({ editApply });
            this.props.onselect(editApply);
          }}
        />
      </div>
    );
  };

  globalCancelHandler = () => {
    const row = [...this.state.field];
    if (this.state.isNewRowAdd) {
      this.setState({
        field: this.prevState,
        editRowsKeys: [],
        posOfEditRows: [],
        isNewRowAdd: false
      });
    } else {
      this.state.posOfEditRows.map((val) => {
        row[val] = this.prevState[val];
      });
      this.setState({
        field: row,
        editRowsKeys: [],
        posOfEditRows: []
      });
    }
  };

  validateData = () => {
    let stopSave = false;
    this.state.field.map((row) => {
      if (row.isEditable !== undefined && row.isEditable) {
        this.state.columns.map((col) => {
          if (
            col.accessor !== undefined &&
            col.additional.isRequired &&
            (col.additional.isAddable || col.additional.isEditable)
          ) {
            let hasValue = false;
            if (
              ['options', 'multi_select'].indexOf(col.additional.dataType) > -1
            ) {
              hasValue = row[col.accessor]?.filter(
                (x) => x.isSelected === true
              );
              hasValue = hasValue?.length ? true : false;
            } else if (['number'].indexOf(col.additional.dataType) > -1) {
              hasValue = row[col.accessor] !== '' ? true : false;
            } else {
              hasValue = row[col.accessor] ? true : false;
            }
            if (!hasValue) {
              stopSave = true;
            }
          }
        });
      }
    });

    // this.setState({ stopSave: stopSave })
    return stopSave;
  };

  clearTable = () => {
    let rows = [...this.state.field];
    rows.map((x) => (x.isEditable = false));
    this.setState({
      field: rows,
      newRowsKeys: [],
      editRowsKeys: [],
      posOfEditRows: [],
      sortableFixed: false,
      isNewRowAdd: false
    });
  };

  saveHandler = () => {
    let stopSave = this.validateData();
    if (!stopSave) {
      let row = [...this.state.field];
      let stack = [];
      for (let i of row) {
        if (i.isEditable) {
          stack.push(i);
        }
      }
      this.props.onsave(stack);
    } else {
      errorToastHandler(messages.FILL_REQUIRED_FIELDS);
    }
  };

  addHandler = () => {
    this.props.onselect(this.state.editApply);
    this.setStatePostAdd();
  };

  setStatePostAdd = () => {
    this.setState({
      editApply: {},
      allChecked: false,
      sortableFixed: true,
      isNewRowAdd: true,
      posOfEditRows: [],
      editRowsKeys: []
    });
  };

  editHandler = () => {
    let editAppliedOnRow = this.state.editApply;
    let row = [...this.state.field];
    this.prevState = JSON.parse(JSON.stringify(this.state.field)); //preserving prev state
    let stackOfRows = [];
    let rowsPos = [];

    const recordsWithMeasureId = Object.keys(editAppliedOnRow).filter((id) =>
      this.state.field.find((record) => record.id === Number(id))
    );

    const editApplyRecords = recordsWithMeasureId.reduce((a, v) => {
      return { ...a, [Number(v)]: editAppliedOnRow[Number(v)] };
    }, {});

    for (let i of row) {
      if (editApplyRecords[i.key] || editApplyRecords[i.key] === 0) {
        i.isEditable = true;
        stackOfRows.push(i.key);
        rowsPos.push(row.indexOf(i));
      }
    }
    this.setState({
      field: row,
      editApply: {},
      editRowsKeys: stackOfRows,
      posOfEditRows: rowsPos,
      allChecked: false
    });
  };

  deleteHandler = () => {
    //delete row
    let deleteAppliedOnRow = this.state.editApply;
    let row = [...this.state.field];
    let isDel = window.confirm('Are you sure to archive these record(s)?');
    if (isDel) {
      this.props.ondelete(deleteAppliedOnRow);

      let filtered = row?.filter((value) => {
        if (deleteAppliedOnRow[value.key] === undefined) {
          return row;
        }
      });
      this.setState({
        field: filtered,
        editApply: {},
        allChecked: false
      });
    }
  };

  filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    if (row[id] !== undefined) {
      if (typeof row[id] === 'object') {
        let stack = [];
        row[id]
          ?.filter((x) => x.isSelected === true)
          .map((x) => {
            stack.push(x.label);
          });
        return String(stack.join('||').toLowerCase()).includes(
          filter.value.toLowerCase()
        );
        // return String(stack.join('||').toLowerCase()).startsWith(filter.value.toLowerCase());
      } else if (typeof row[id] === 'number') {
        return String(row[id]).includes(filter.value);
      } else {
        return String(row[id].toLowerCase()).includes(
          filter.value.toLowerCase()
        );
        // return String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase());
      }
    } else {
      return false;
    }
  };

  renderEditable = (cellInfo) => {
    let cell_val = null,
      isEditable = false;
    if (cellInfo.original.isEditable) {
      if (isNaN(cellInfo.original.id) && cellInfo.column.additional.isAddable) {
        isEditable = true;
      } else if (
        !isNaN(cellInfo.original.id) &&
        cellInfo.column.additional.isEditable
      ) {
        isEditable = true;
      }
    }
    if (isEditable) {
      if (cellInfo.column.additional.dataType === 'date') {
        cell_val = (
          <DatePicker
            className="form-control"
            dateFormat="dd/MM/yyyy"
            placeholder={cellInfo.column.additional.placeholder || ''}
            selected={
              cellInfo.original[cellInfo.column.id] !== ''
                ? new Date(
                    moment(cellInfo.original[cellInfo.column.id]).format()
                  )
                : undefined
            }
            onChange={(e) => {
              const field = [...this.state.field];
              if (e) {
                field[cellInfo.index][cellInfo.column.id] = moment(
                  e.toDateString()
                ).format('YYYY-MM-DD');
              } else {
                field[cellInfo.index][cellInfo.column.id] = '';
              }
              if (typeof cellInfo.column.additional.onChange !== 'undefined') {
                cellInfo.column.additional.onChange(field[cellInfo.index]);
              }
              this.setState({ field });
            }}
          />
        );
      }

      if (cellInfo.column.additional.dataType === 'options') {
        const temp = [...this.state.field];
        cell_val = (
          <div>
            <Select
              value={
                temp[cellInfo.index][cellInfo.column.id]?.filter(
                  (x) => x.isSelected === true
                )[0]
              }
              onChange={(optionsList) => {
                temp[cellInfo.index][cellInfo.column.id].map((x) => {
                  x.isSelected = x.value === optionsList.value;
                });
                if (
                  typeof cellInfo.column.additional.onChange !== 'undefined'
                ) {
                  cellInfo.column.additional.onChange(temp[cellInfo.index]);
                }
                this.setState({ field: temp });
              }}
              options={temp[cellInfo.index][cellInfo.column.id]}
            />
          </div>
        );
      }

      if (cellInfo.column.additional.dataType === 'bool') {
        cell_val = (
          <input
            type="checkbox"
            defaultChecked={cellInfo.original[cellInfo.column.id]}
            onChange={(e) => {
              const field = [...this.state.field];
              field[cellInfo.index][cellInfo.column.id] = e.target.checked;
              this.setState({ field });
            }}
          />
        );
      }

      if (cellInfo.column.additional.dataType === 'text_field') {
        cell_val = (
          <input
            type="text"
            className="form-control"
            placeholder={cellInfo.column.additional.placeholder || ''}
            value={this.state.field[cellInfo.index][cellInfo.column.id]}
            onChange={(e) => {
              const field = [...this.state.field];
              field[cellInfo.index][cellInfo.column.id] = e.target.value;
              this.setState({ field });
            }}
          />
        );
      }

      if (cellInfo.column.additional.dataType === 'number') {
        cell_val = (
          <input
            type="number"
            className="form-control"
            placeholder={cellInfo.column.additional.placeholder || ''}
            value={this.state.field[cellInfo.index][cellInfo.column.id]}
            onChange={(e) => {
              const field = [...this.state.field];
              field[cellInfo.index][cellInfo.column.id] = e.target.value;
              this.setState({ field });
            }}
          />
        );
      }

      if (cellInfo.column.additional.dataType === 'multi_select') {
        const temp = [...this.state.field];
        cell_val = (
          <div>
            <Select
              value={temp[cellInfo.index][cellInfo.column.id]?.filter(
                (x) => x.isSelected === true
              )}
              onChange={(optionsList) => {
                let selectedVal = optionsList.map((x) => x.value);
                temp[cellInfo.index][cellInfo.column.id].map((x) => {
                  x.isSelected = selectedVal.indexOf(x.value) > -1;
                });
                if (
                  typeof cellInfo.column.additional.onChange !== 'undefined'
                ) {
                  cellInfo.column.additional.onChange(temp[cellInfo.index]);
                }
                this.setState({ field: temp });
              }}
              options={temp[cellInfo.index][cellInfo.column.id]}
              isMulti={true}
            />
          </div>
        );
      }
    } else {
      let renderVal = null;

      if (
        this.state.field.length &&
        this.state.field?.[cellInfo.index] &&
        this.state.field[cellInfo.index][cellInfo.column.id]
      ) {
        if (cellInfo.column.additional.dataType === 'options') {
          renderVal = this.state.field[cellInfo.index][
            cellInfo.column.id
          ]?.filter((x) => x.isSelected === true);
          if (renderVal.length) {
            renderVal = renderVal[0].label;
          }
        } else if (cellInfo.column.additional.dataType === 'multi_select') {
          let arr = [];
          this.state.field[cellInfo.index][cellInfo.column.id]
            ?.filter((x) => x.isSelected === true)
            .map((x) => {
              arr.push(x.label);
            });
          renderVal = arr.join(', ');
        } else {
          //date render
          if (cellInfo.column.additional.dataType === 'date') {
            //dateFormat
            if (cellInfo.column.additional.dateFormat !== undefined) {
              if (this.state.field[cellInfo.index][cellInfo.column.id] !== '') {
                renderVal = moment(
                  this.state.field[cellInfo.index][cellInfo.column.id]
                ).format(cellInfo.column.additional.dateFormat);
              } else {
                renderVal = '';
              }
            } else {
              renderVal = moment(
                this.state.field[cellInfo.index][cellInfo.column.id]
              ).format('DD-MM-YYYY');
            }
          } else {
            if (typeof cellInfo.column.additional.Cell !== 'undefined') {
              renderVal = cellInfo.column.additional.Cell(
                this.state.field[cellInfo.index]
              );
            } else {
              renderVal =
                this.state.field[cellInfo.index][cellInfo.column.id].toString();
            }
          }
        }
      }
      cell_val = <div>{renderVal}</div>;
    }
    return cell_val;
  };

  render() {
    return (
      <div>
        <ReactTable
          data={this.state.field}
          columns={this.state.columns}
          defaultPageSize={25}
          resizable={false}
          filterable={true}
          showPagination={this.state.field?.length > 0}
          minRows={10}
          sortable={!this.state.sortableFixed}
          resolveData={(data) => data.map((row) => row)}
          defaultFilterMethod={(filter, row) =>
            this.filterCaseInsensitive(filter, row)
          }
          onFetchData={(props) => {
            const data = props.data.length
              ? props.sortedData.slice(0, props.pageSize)
              : this.state.field;
            this.setState({ tableData: data });
          }}
        />
      </div>
    );
  }
}

export default SigTable;
