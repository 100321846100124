import messages from '../constants/messages';
import moment from 'moment';

const requiredTaskFields = [
  { value: 'title', text: 'Title' },
  { value: 'status', text: 'Status' },
  { value: 'priority', text: 'Priority' },
  { value: 'dueDate', text: 'Due date' }
];
export const validateTask = (task) => {
  for (const requiredField of requiredTaskFields) {
    if (!task[requiredField.value]) {
      return messages.FIELD_REQUIRED(requiredField.text);
    }
  }
  if (task.relation_type && !task.relation_id) {
    return messages.TASK_PROVIDE_RELATION;
  }
  const currentTimestamp = +new Date();
  const dueTimestamp = +new Date(task.dueDate);
  const remindTimestamp = task.remindDate ? +new Date(task.remindDate) : null;
  if (remindTimestamp && remindTimestamp < currentTimestamp) {
    return messages.TASK_REMIND_DATE_IN_PAST;
  }
  if (remindTimestamp && remindTimestamp > dueTimestamp) {
    return messages.TASK_REMIND_DATE_AFTER_DUE_DATE;
  }
  if (task.recurringType && !task.recurringEndDate) {
    return messages.TASK_PROVIDE_RECURRING_DATE;
  }

  return null;
};

export const getTaskStatus = (status) => {
  switch (status) {
    case null: {
      return 'Pending';
    }
    case 'accepted': {
      return 'Accepted';
    }
    case 'declined': {
      return 'Declined';
    }
    default: {
      return status;
    }
  }
};

export const getTaskNullState = () => {
  return {
    title: null,
    description: null,
    dueDate: moment().add(1, 'h').format('D/M/YYYY HH:00'),
    remindDate: null,
    status: null,
    priority: null,
    relationId: null,
    relationType: null,
    relationName: null,
    recurringEndDate: null,
    recurringType: null,
    userIds: [],
    userEmails: []
  };
};
