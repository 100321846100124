import { REDUCERS } from '../../constants/reducers';
import { AdminService } from '../../services';

export const setAdminState = (value) => ({
  type: REDUCERS.ADMIN.SET_ADMIN_STATE,
  value
});

export const getRoles = () => {
  return async (dispatch) => {
    try {
      const { data } = await AdminService.getRoles();
      const payload = {
        roles: data
      };
      dispatch(setAdminState(payload));
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const updateUsersProfile = (payload) => {
  return async () => {
    try {
      const { data } = await AdminService.updateUsers(payload);
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};
