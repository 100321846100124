import { REDUCERS } from '../../constants/reducers';

const initialState = {
  tags: [],
  tag: {}
};

const tag = (state = initialState, action) => {
  switch (action.type) {
    case REDUCERS.TAG.SET_TAGS: {
      return Object.assign({}, state, {
        tags: [...action.value]
      });
    }
    case REDUCERS.TAG.SET_TAG: {
      return Object.assign({}, state, {
        tag: { ...action.value }
      });
    }
    case REDUCERS.RESET:
      return initialState;
    default:
      return state;
  }
};

export { tag };
