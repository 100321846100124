import React from 'react';
import { Col, FormGroup, Label, Input } from 'reactstrap';
import moment from 'moment';

let time_options = {
  '1day': moment.utc().subtract(1, 'day').format(),
  '1week': moment.utc().subtract(1, 'week').format(),
  '2week': moment.utc().subtract(2, 'week').format(),
  '4week': moment.utc().subtract(4, 'week').format()
};

function TimeFilters(props) {
  return (
    <div className="filter-block time">
      <p>Time Filters</p>
      <FormGroup row>
        <Col sm="2">
          <Input
            value={time_options['1day']}
            checked={props.selected === time_options['1day']}
            onChange={(e) => props.onChange(e.target.value, e.target.name)}
            name="time"
            type="radio"
          ></Input>
        </Col>
        <Label sm="10">1 day</Label>
      </FormGroup>
      <FormGroup row>
        <Col sm="2">
          <Input
            value={time_options['1week']}
            checked={props.selected === time_options['1week']}
            onChange={(e) => props.onChange(e.target.value, e.target.name)}
            name="time"
            type="radio"
          ></Input>
        </Col>
        <Label sm="10">1 week</Label>
      </FormGroup>
      <FormGroup row>
        <Col sm="2">
          <Input
            value={time_options['2week']}
            checked={props.selected === time_options['2week']}
            onChange={(e) => props.onChange(e.target.value, e.target.name)}
            name="time"
            type="radio"
          ></Input>
        </Col>
        <Label sm="10">2 weeks</Label>
      </FormGroup>
      <FormGroup row>
        <Col sm="2">
          <Input
            value={time_options['4week']}
            checked={props.selected === time_options['4week']}
            onChange={(e) => props.onChange(e.target.value, e.target.name)}
            name="time"
            type="radio"
          ></Input>
        </Col>
        <Label sm="10">4 weeks</Label>
      </FormGroup>
    </div>
  );
}

export default TimeFilters;
