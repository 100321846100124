import { REDUCERS } from '../../constants/reducers';
import { SubscriptionService } from '../../services';

export const setPlans = (value) => ({
  type: REDUCERS.SUBSCRIPTION.SET_PLANS,
  value
});

export const setSubscriptionState = (value) => ({
  type: REDUCERS.SUBSCRIPTION.SET_SUBSCRIPTION_STATE,
  value
});

export const setMappedPlans = (value) => ({
  type: REDUCERS.SUBSCRIPTION.SET_MAPPED_PLANS,
  value
});

export const setFeatureOptions = (value) => ({
  type: REDUCERS.SUBSCRIPTION.SET_FEATURE_OPTIONS,
  value
});

export const setMappedFeatureOptions = (value) => ({
  type: REDUCERS.SUBSCRIPTION.SET_MAPPED_FEATURE_OPTIONS,
  value
});

export const getPlans = () => {
  return async (dispatch) => {
    try {
      const { data } = await SubscriptionService.getPlans();

      const mappedPlans = data.reduce((acc, curr) => {
        const mappedFeatures = curr.feature_options.reduce((acc, curr) => {
          return { ...acc, [curr.title]: curr };
        }, []);

        return {
          ...acc,
          [curr.title]: { ...curr, feature_options: mappedFeatures }
        };
      }, {});

      dispatch(setPlans(data));
      dispatch(setMappedPlans(mappedPlans));
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const getFeatureOptions = () => {
  return async (dispatch) => {
    try {
      const { data } = await SubscriptionService.getFeatureOptions();
      const mappedFeatureOptions = data.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.type]: { ...curr }
        };
      }, {});
      dispatch(setFeatureOptions(data));
      dispatch(setMappedFeatureOptions(mappedFeatureOptions));
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const addUserSubscription = (payload) => {
  return async () => {
    try {
      const { data } = await SubscriptionService.addUserSubscription(payload);
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const getMyPlan = () => {
  return async (dispatch) => {
    try {
      const { data } = await SubscriptionService.getMyPlan();
      const myMappedFeatureOptions = data?.plan?.feature_options?.reduce(
        (acc, curr) => {
          return {
            ...acc,
            [curr.type]: { ...curr }
          };
        },
        {}
      );
      const featureGroups = data?.plan?.feature_options?.map((i) => {
        return i.group;
      });
      const uniqueFeatureGroups = [...new Set(featureGroups)];

      const payload = {
        myPlan: {
          ...data?.plan,
          period: data.period,
          user_plan_id: data.id,
          status: data.status,
          start_date: data.start_date,
          end_date: data.end_date,
          total_price: data.total_price
        },
        myFeatureGroups: uniqueFeatureGroups,
        myMappedFeatureOptions: myMappedFeatureOptions,
        loading: false
      };

      dispatch(setSubscriptionState(payload));
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const updateUserSubscriptionStatus = (payload) => {
  return async () => {
    try {
      const { data } = await SubscriptionService.updateUserSubscriptionStatus(
        payload
      );
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};
