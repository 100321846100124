import React from "react";
import {
	Tooltip,
} from 'reactstrap';

class StatusIndicator extends React.Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
		  className: "statusIndicator " + props.status,
		  status: props.status,
			tooltipOpen: false,
			id: this.makeid(15)

		};
	  }
	  toggle() {
		this.setState({
		  tooltipOpen: !this.state.tooltipOpen
		});
		}
		makeid(length) {
			var text = "";
			var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

			for (var i = 0; i < length; i++)
				text += possible.charAt(Math.floor(Math.random() * possible.length));

			return text;
			}
	render() {
		return (
			<div id={this.state.id} className={this.state.className}>
				<Tooltip placement="top" isOpen={this.state.tooltipOpen} target={this.state.id} toggle={this.toggle}>
					<span className="capitalise">{this.state.status}</span>
				</Tooltip>
			</div>

		);
	}
}

export default StatusIndicator;
