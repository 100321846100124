import { REDUCERS } from '../../constants/reducers';
import { getQueryNameByRelationType } from '../../helpers/relation';
import { formatDate } from '../../helpers/date';
import { TaskService } from '../../services';
import axios from '../../axios';

export const setCurrentTask = (value) => ({
  type: REDUCERS.TASK.SET_CURRENT_TASK,
  value
});

export const setTaskState = (value) => ({
  type: REDUCERS.TASK.SET_TASK_STATE,
  value
});

export const setTaskQueryParams = (value) => ({
  type: REDUCERS.TASK.SET_TASK_QUERY_PARAMS,
  value
});

export const setTaskList = (value) => ({
  type: REDUCERS.TASK.SET_TASK_LIST,
  value
});

export const getAllTasks = (params) => {
  return async (dispatch) => {
    try {
      const { data } = await TaskService.getAllTasks(params);
      dispatch(setTaskList(data));
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const getTaskById = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await TaskService.getTaskById(id);
      const task = {
        ...data,
        remindDate: formatDate(data.remind_at),
        dueDate: formatDate(data.due_date),
        userIds: data.users?.map((user) => user.id),
        userEmails: data.users?.map((user) => user.email)
      };
      dispatch(setCurrentTask(task));
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const createNewTask = (payload) => {
  return async () => {
    try {
      const { data } = await TaskService.createNewTask(payload);
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const updateTask = (id, payload) => {
  return async () => {
    try {
      const { data } = await TaskService.updateTask(id, payload);
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const deleteTask = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await TaskService.deleteTask(id);
      dispatch(setCurrentTask({}));
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const sendRelationQuery = (relationType) => {
  return async () => {
    try {
      const queryName = getQueryNameByRelationType(relationType);
      let response = await axios.get(queryName);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const acceptTask = (id) => {
  return async () => {
    try {
      const { data } = await TaskService.acceptTask(id);
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const declineTask = (id) => {
  return async () => {
    try {
      const { data } = await TaskService.declineTask(id);
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};
