import React from 'react';

import WidgetTemplate from '../WidgetTemplate';

const CalvesWeanedWidget = ({ props }) => {
  const onClick = () => {
    // props.history.push({
    //   pathname: 'animals',
    //   filter: { farms: props.farm ? [props.farm] : [] },
    // });
  };

  const icon = <div className="hc-icon"></div>;

  return (
    <WidgetTemplate
      icon={icon}
      title={'Calves Weaned'}
      onClick={onClick}
      value={'  '}
    />
  );
};

export default CalvesWeanedWidget;
