import React, { memo } from 'react';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { FcCheckmark, FcCancel } from 'react-icons/fc';
import { capitalize } from '../../../helpers/common';

const FeatureRow = ({ feature }) => {
  const subscriptionState = useSelector((state) => state.subscription);

  return (
    subscriptionState?.plans?.length && (
      <Row className="feature-row">
        <Col sm="4" md="4" lg="3" className="feature-title">
          {capitalize(feature?.title)}
        </Col>

        {subscriptionState?.plans?.map((plan, index) => {
          const isSelected = plan.type === subscriptionState?.myPlan?.type;

          const key = plan.title + index;

          const featureData =
            subscriptionState?.mappedPlans?.[plan.title]?.feature_options[
              feature.title
            ];

          const isPlanIncludesFeature = !!featureData;

          if (isPlanIncludesFeature) {
            if (featureData.limited)
              return (
                <Col
                  className={`feature-col align-center limited ${
                    isSelected && 'selected-col'
                  }`}
                  key={key}
                >
                  <div>Limited</div>
                </Col>
              );

            return (
              <Col
                className={`feature-col align-center ${isSelected && 'selected-col'}`}
                key={key}
              >
                <FcCheckmark />
              </Col>
            );
          }

          return (
            <Col
              className={`feature-col align-center ${isSelected && 'selected-col'}`}
              key={key}
            >
              <FcCancel />
            </Col>
          );
        })}
      </Row>
    )
  );
};

export default memo(FeatureRow);
