import React from 'react';
import {
  Row,
  Col,
  Card,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Input,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import Select from 'react-select';
import classnames from 'classnames';

import axios from '../../axios';
import Map from '../../components/map_sites';
import Alerts from '../../components/Alerts';
import zoom from '../../assets/images/zoom.png';
import { CompactPicker } from 'react-color';
import PrevNextButtons from '../../components/PrevNextButtons';
import { connect } from 'react-redux';

class Site extends React.Component {
  //Tabs
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      delete_modal: false,
      isFullScreen: false,
      query: '',
      selected_sites: [],
      site_types: [],
      selected_type: {},
      selected_geofences: [],
      color: '#3388ff',
      site: {
        identifier: '',
        name: '',
        geofences: [],
        rules: [],
        geo_json: {}
      },
      filters: {
        farms: [],
        geofences: []
      },
      selected_farm: {},
      farms_org: [],
      dropdownOpen: false,
      edit: false,
      new: this.props.match.params.id === 'new' ? true : false
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleButton = this.toggleButton.bind(this);
    this.getSites = this.getSites.bind(this);
    this.onNewFence = this.onNewFence.bind(this);
    this.getSiteTypes = this.getSiteTypes.bind(this);
    this.onSiteChangeType = this.onSiteChangeType.bind(this);
    this.onSiteChangeGeofence = this.onSiteChangeGeofence.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
  }
  toggleFullscreen() {
    this.setState((state) => ({
      ...state,
      isFullScreen: !this.state.isFullScreen
    }));
  }
  onChange = (value, field) => {
    if (field === 'query') {
      this.getTags();
    }
    this.setState((state) => ({
      ...state,
      [field]: value
    }));
  };
  toggleButton() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  onCheckboxChange = (value, field) => {
    this.setState((state) => ({
      ...state,
      [field]: !state[field]
    }));
  };
  async componentDidMount() {
    this.getSiteTypes();
    let response = await axios.get('farms?with_details=true');
    let farms = response.data.map((f) => {
      return {
        value: f.id,
        label: f.name,
        meta: {
          address: f.address.full_address,
          master_geofences: f.master_geofence,
          features: f.geofences,
          sites: f.sites
        }
      };
    });
    if (farms.length) {
      let farm = farms[0];
      this.setState(
        {
          ...this.state,
          farms: farms,
          farms_org: response.data,
          selected_farm: farm,
          site: {
            ...this.state.site,
            farm_id: farm.value,
            address: farm.meta.address,
            features: farm.meta.features
          }
        },
        () => this.getSites()
      );
    } else {
      this.setState(
        {
          ...this.state,
          farms: farms,
          farms_org: response.data
        },
        () => this.getSites()
      );
    }
  }
  async getSites(id = null) {
    if (this.state.new) {
      return;
    }
    let response = await axios.get(
      'sites/' + (id !== null ? id : this.props.match.params.id),
      {
        query: this.state.query
      }
    );
    if (response.status === 200) {
      this.setState({
        site: response.data,
        color: response.data.color,
        selected_farm: this.state.farms.filter(
          (x) => x.value === response.data.farm_id
        )[0],
        selected_geofences: response.data.geofences.map((x) => {
          return { label: x.name, value: x.id };
        }),
        selected_type: this.state.site_types.filter(
          (x) => x.value === response.data.type
        )
      });
    }
  }
  async getSiteTypes() {
    let response = await axios.get('/sites/types');
    if (response.status === 200) {
      this.setState({
        site_types: response.data
      });
    }
  }
  async saveSite() {
    if (this.state.new) {
      let response = await axios.post('sites', {
        ...this.state.site,
        color: this.state.color
      });
      this.setState({
        new: false
      });
      await this.getSites(response.data.id);
      this.props.history.push('/site/' + response.data.id);
    } else {
      let response = await axios.put('sites/' + this.props.match.params.id, {
        ...this.state.site,
        color: this.state.color
      });
      this.setState({
        edit: false
      });
      await this.getSites(response.data.id);
      setTimeout(() => {
        window.location.href = '/site/' + response.data.id;
      }, 300);
    }
  }
  async deleteSite() {
    let response = await axios.delete('sites/' + this.props.match.params.id);
    if (response.status === 200) {
      this.props.history.push('/sites');
    }
  }
  onSiteChange(value, field) {
    if (field === 'value') {
      this.setState({
        selected_type: value
      });
      value = value.value;
    }
    this.setState({
      site: {
        ...this.state.site,
        [field]: value
      }
    });
  }
  onSiteChangeType(value) {
    this.setState({
      selected_type: value,
      site: {
        ...this.state.site,
        type: value.value
      }
    });
  }
  onSiteChangeGeofence(value) {
    this.setState({
      selected_geofences: value,
      site: {
        ...this.state.site,
        geofence_ids: value.map((x) => x.value)
      }
    });
  }
  onNewFence(val) {
    this.setState({
      site: {
        ...this.state.site,
        geo_json: val
      }
    });
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  toggleModal(modal) {
    this.setState((state) => ({
      ...state,
      [modal]: !state[modal]
    }));
  }
  multiselectChange = (sle) => {
    this.setState({
      ...this.state,
      selected_farm: sle,
      site: {
        ...this.state.site,
        farm_id: sle.value,
        address: sle.meta.address,
        features: sle.meta.features
      },
      editing: true
    });
    this.forceUpdate();
  };
  render() {
    function capitalize(string) {
      return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
    }
    const zoomImg = {
      backgroundImage: 'url(' + zoom + ')'
    };
    return (
      <div>
        <Row className="">
          <Col xs="6" md="6" lg="6">
            <h4 className="">
              {this.state.edit ? 'Edit Site ' + this.state.site.name : ''}{' '}
              {this.state.new ? 'Create New Site' : ''}{' '}
              {!this.state.edit &&
                !this.state.new &&
                'Site ' + this.state.site.name}
            </h4>
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="#" onClick={() => this.props.history.push('/sites')}>
                  List of Sites
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem>
                {this.state.edit ? 'Edit Site' : ''}{' '}
                {this.state.new ? 'Create New Site' : ''}{' '}
                {!this.state.edit &&
                  !this.state.new &&
                  'Site ' + this.state.site.name}
              </BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col xs="6" md="6" lg="6">
            <PrevNextButtons
              history={this.props.history}
              nextId={this.state.site.next_id}
              prevId={this.state.site.prev_id}
              path="site"
            />
            {this.state.new && (
              <div>
                <Button
                  color="primary"
                  className="float-right"
                  onClick={() => this.saveSite()}
                >
                  Save
                </Button>
                <Button
                  className="float-right outline"
                  onClick={() => {
                    this.props.history.push('/sites');
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
            {this.state.edit && (
              <div>
                <Button
                  color="primary"
                  className="float-right"
                  onClick={() => this.saveSite()}
                >
                  Save
                </Button>
                <Button
                  className="float-right outline"
                  onClick={() =>
                    this.setState({ edit: false }, () => this.getSites())
                  }
                >
                  Cancel
                </Button>
              </div>
            )}
            {!this.state.edit && !this.state.new && (
              <ButtonDropdown
                className="float-right"
                isOpen={this.state.dropdownOpen}
                toggle={this.toggleButton}
              >
                <DropdownToggle className="remove-mr" caret>
                  Actions
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => this.setState({ edit: true })}>
                    Edit site
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => this.toggleModal('delete_modal')}
                  >
                    Archive site
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="12" lg="12">
            <Card>
              {(this.state.new || this.state.edit) && (
                <Row className="">
                  <Col lg="12" style={{ zIndex: 9 }} className="global-table">
                    <Table className="no-border mt-2 mb-2">
                      <tbody>
                        <tr>
                          <td className="v-align-middle">
                            <b>Farm</b>
                          </td>
                          <td>
                            <Select
                              name="farm"
                              onChange={this.multiselectChange}
                              value={this.state.selected_farm}
                              options={this.state.farms}
                              defaultValue={
                                this.state.selected_farm.value
                                  ? this.state.selected_farm
                                  : null
                              }
                              isMulti={false}
                            ></Select>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              )}
              <Row>
                <Col
                  xs="12"
                  md="12"
                  lg="12"
                  className={`
								${this.state.isFullScreen ? 'rg-full-screen icon-position' : ''} 
								${this.state.editing ? '' : 'rg-no-space'}
							`}
                >
                  <Map
                    new={this.state.new || this.state.edit}
                    editing={this.state.edit || this.state.new}
                    site={{
                      geo_json: this.state.site.geo_json,
                      features: this.state.site.features
                    }}
                    color={this.state.color}
                    features={this.state.site.features}
                    onEdit={this.onNewFence}
                    onCreate={this.onNewFence}
                    farm={this.state.selected_farm}
                    isFullScreen={this.state.isFullScreen}
                  ></Map>
                  <div
                    className="full-screen-icon text-center"
                    onClick={this.toggleFullscreen}
                    style={zoomImg}
                  ></div>
                </Col>
              </Row>
            </Card>
            <Card>
              <Row>
                <Col xs="12" md="12" lg="12" className="global-table">
                  {(this.state.new || this.state.edit) && (
                    <Table>
                      <tbody>
                        <tr>
                          <td colSpan="2">
                            <h4>Site {this.state.site.name} Details</h4>
                          </td>
                        </tr>
                        <tr>
                          <td className="v-align-middle">
                            <b>Site Name</b>
                          </td>
                          <td>
                            <Input
                              name="name"
                              defaultValue={this.state.site.name}
                              onChange={(e) =>
                                this.onSiteChange(e.target.value, e.target.name)
                              }
                            ></Input>
                          </td>
                        </tr>
                        <tr>
                          <td className="v-align-middle">
                            <b>Site Geofences</b>
                          </td>
                          <td>
                            <Select
                              name="type"
                              defaultValue={this.state.selected_geofences}
                              options={
                                this.state.selected_farm.meta
                                  ? this.state.selected_farm.meta.features.map(
                                      (x) => {
                                        return { label: x.name, value: x.id };
                                      }
                                    )
                                  : []
                              }
                              onChange={this.onSiteChangeGeofence}
                              isMulti={true}
                              isSearchable={true}
                            ></Select>
                          </td>
                        </tr>
                        <tr>
                          <td className="v-align-middle">
                            <b>Site Type</b>
                          </td>
                          <td>
                            <Select
                              name="type"
                              defaultValue={this.state.selected_type}
                              options={this.state.site_types}
                              onChange={this.onSiteChangeType}
                            ></Select>
                          </td>
                        </tr>
                        <tr>
                          <td className="v-align-middle">
                            <b>Site Color</b>
                          </td>
                          <td>
                            <CompactPicker
                              name="colour"
                              color={this.state.color}
                              onChange={(e) => {
                                this.setState({
                                  ...this.state,
                                  color: e.hex
                                });
                              }}
                            ></CompactPicker>
                          </td>
                        </tr>
                        <tr>
                          <td className="v-align-middle">
                            <b>Site Description</b>
                          </td>
                          <td>
                            <Input
                              name="description"
                              defaultValue={this.state.site.description}
                              onChange={(e) =>
                                this.onSiteChange(e.target.value, e.target.name)
                              }
                            ></Input>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  )}
                  
                  {!this.state.new && !this.state.edit && (
                    <Table>
                      <tbody>
                        <tr>
                          <td colSpan="2">
                            <h4>Site {this.state.site.name} Details</h4>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Site ID</b>
                          </td>
                          <td>{this.state.site.identifier}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Site Name</b>
                          </td>
                          <td>{this.state.site.name}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Site Type</b>
                          </td>
                          <td>{capitalize(this.state.site.type)}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Site Geofences</b>
                          </td>
                          <td>
                            {this.state.site.geofences
                              .map((x) => x.name)
                              .join(', ') || 'No geofences'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Site Description</b>
                          </td>
                          <td>{this.state.site.description}</td>
                        </tr>
                        {['trial', 'advanced', 'basic'].includes(
                          this.props.subscription.myPlan.type
                        ) ? null : (
                          <tr>
                            <td>
                              <b>Site Rules</b>
                            </td>
                            <td>
                              {this.state.site.rules
                                .map((x) => x.name)
                                .join(', ') || 'No rules'}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  )}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col
            lg="12"
            md="12"
            sm="12"
            className={
              this.state.isFullScreen && (this.state.edit || this.state.new)
                ? 'rg-full-screen-btn'
                : ''
            }
          >
            {this.state.edit && (
              <div>
                <Button
                  color="primary"
                  className="float-right"
                  onClick={() => this.saveSite()}
                >
                  Save
                </Button>
                <Button
                  className="float-right outline"
                  onClick={() =>
                    this.setState({ edit: false }, () => this.getSites())
                  }
                >
                  Cancel
                </Button>
              </div>
            )}
            {this.state.new && (
              <div>
                <Button
                  color="primary"
                  className="float-right"
                  onClick={() => this.saveSite()}
                >
                  Save
                </Button>
                <Button
                  className="float-right outline"
                  onClick={() => {
                    this.props.history.push('/sites');
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
          </Col>
        </Row>

        {this.props.match.params.id !== 'new' && (
          <Card>
            <Row className="pad-10 pb-0">
              <Col sm="6" className="mb-0">
                <h5>History</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <Nav tabs className="fancy-tabs">
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === '1'
                      })}
                      onClick={() => {
                        this.toggle('1');
                      }}
                    >
                      Notifications
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md="12" lg="12">
                <TabContent className="no-bg" activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <Alerts
                      type="full"
                      query={{ site_ids: [this.props.match.params.id] }}
                    ></Alerts>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Card>
        )}

        <Modal
          isOpen={this.state.delete_modal}
          className={this.props.className}
        >
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>Archive Site</b>
            </h5>
            <br />
            <br />
            <br />
            Are you sure you want to archive this site? This action cannot be
            undone.
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => this.toggleModal('delete_modal')}
            >
              Cancel
            </Button>{' '}
            <Button color="danger" onClick={() => this.deleteSite()}>
              Archive
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default connect((state) => state)(Site);
