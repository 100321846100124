import axios from '../../axios';

const service = {
  async clearAllNotifications() {
    return await axios({
      method: 'put',
      url: '/notifications/clearAll'
    });
  },

  async snoozeAllNotifications() {
    return await axios({
      method: 'put',
      url: '/notifications/snoozeAll'
    });
  },

  async getNotifications(params) {
    return await axios({
      method: 'get',
      url: '/notifications',
      params: { ...params }
    });
  }
};

export default service;
