import { REDUCERS } from '../../constants/reducers';

const initialState = {
  loading: true,
  plans: [],
  mappedPlans: {},
  featureOptions: [],
  mappedFeatureOptions: {},
  myMappedFeatureOptions: {},
  myFeatureGroups: [],
  myPlan: {}
};

const subscription = (state = initialState, action) => {
  switch (action.type) {
    case REDUCERS.SUBSCRIPTION.SET_SUBSCRIPTION_STATE: {
      return Object.assign({}, state, {
        ...state,
        ...action.value
      });
    }
    case REDUCERS.SUBSCRIPTION.SET_PLANS: {
      return Object.assign({}, state, {
        plans: [...action.value]
      });
    }
    case REDUCERS.SUBSCRIPTION.SET_MAPPED_PLANS: {
      return Object.assign({}, state, {
        mappedPlans: { ...action.value }
      });
    }
    case REDUCERS.SUBSCRIPTION.SET_FEATURE_OPTIONS: {
      return Object.assign({}, state, {
        featureOptions: [...action.value]
      });
    }
    case REDUCERS.SUBSCRIPTION.SET_MAPPED_FEATURE_OPTIONS: {
      return Object.assign({}, state, {
        mappedFeatureOptions: { ...action.value }
      });
    }
    case REDUCERS.RESET:
      return initialState;
    default:
      return state;
  }
};

export { subscription };
