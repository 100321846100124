import React from 'react';
import {
  Row,
  Col,
  Card,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Input,
  Table,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import classnames from 'classnames';
import ReactTable from 'react-table';

import StatusIndicator from '../../components/statusIndicator';
import axios from '../../axios';
import Alerts from '../../components/Alerts';
import PrevNextButtons from '../../components/PrevNextButtons';

class Gateway extends React.Component {
  //Tabs
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      selected_geofences: [],
      delete_modal: false,
      gateway: {},
      editData: {},
      dropdownOpen: false,
      activeTab: '1'
    };
    this.toggle = this.toggle.bind(this);
    this.onChange = this.onChange.bind(this);
    this.enableEditing = this.enableEditing.bind(this);
    this.toggleButton = this.toggleButton.bind(this);
    this.onCancelEdit = this.onCancelEdit.bind(this);
    this.saveGateway = this.saveGateway.bind(this);
  }
  async componentDidMount() {
    //TODO: Get tag
    this.getGateway();
  }
  async getGateway() {
    let gatewayId = this.props.match.params.id;
    let response = await axios.get('gateways/' + gatewayId);
    if (response.status === 200) {
      let gateway = response.data;
      this.setState((state) => ({
        ...state,
        gateway: gateway
      }));
    }
  }
  onCancelEdit = (value, field) => {
    this.setState((state) => ({
      ...state,
      editData: this.state.gateway,
      [field]: value
    }));
  };
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  enableEditing() {
    this.setState((state) => ({
      ...state,
      editing: true,
      editData: { ...this.state.gateway }
    }));
  }
  toggleButton() {
    this.setState((state) => ({
      ...state,
      dropdownOpen: !this.state.dropdownOpen
    }));
  }
  onChange = (value, field) => {
    this.setState((state) => ({
      ...state,
      editData: {
        ...state.editData,
        [field]: value
      }
    }));
  };

  async saveGateway() {
    let gatewayId = this.props.match.params.id;
    let editData = this.state.editData;
    try {
      let response = await axios.put('gateways/' + gatewayId, {
        id: editData.id,
        name: editData.name
      });
      if (response.status === 200) {
        this.getGateway();
        this.setState({
          ...this.state,
          editing: false
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    if (!this.state.gateway.id) {
      return null;
    }
    let columns = [
      {
        Header: 'Kraal tag',
        id: 'tag',
        accessor: (d) => {
          return { status: d.status, identifier: d.identifier, id: d.id };
        },
        headerClassName: 'wordwrap',
        Cell: (props) => (
          <a
            href="#"
            onClick={() => {
              this.props.history.push('/tag/' + props.value.id);
            }}
          >
            {props.value.identifier}
          </a>
        )
      }
    ];

    return (
      <div>
        <Row>
          <Col xs="6" md="6" lg="6">
            <h4 className="">
              {this.state.editing && 'Edit '}Gateway{' '}
              {this.state.gateway.name || this.state.gateway.appeui}
            </h4>
            <Breadcrumb>
              <BreadcrumbItem>
                <a
                  href="#"
                  onClick={() => this.props.history.push('/gateways')}
                >
                  List of Kraal Gateways
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {this.state.editing && 'Edit '}Gateway{' '}
                {this.state.gateway.name || this.state.gateway.appeui}
              </BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col xs="6" md="6" lg="6">
            <PrevNextButtons
              history={this.props.history}
              nextId={this.state.gateway.next_id}
              prevId={this.state.gateway.prev_id}
              path="gateway"
            />
            {this.state.editing && (
              <React.Fragment>
                <Button
                  color="primary"
                  className="float-right"
                  onClick={() => this.saveGateway()}
                >
                  Save
                </Button>
                <Button
                  className="outline float-right"
                  onClick={() => this.onCancelEdit(false, 'editing')}
                >
                  Cancel
                </Button>
              </React.Fragment>
            )}
            {!this.state.editing && (
              <ButtonDropdown
                className="float-right"
                isOpen={this.state.dropdownOpen}
                toggle={this.toggleButton}
              >
                <DropdownToggle caret className="remove-mr">
                  Actions
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => this.enableEditing()}>
                    Edit Kraal Gateway
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="12" lg="12" className="global-table">
            <Card>
              <Row>
                <Col xs="12" md="12" lg="12">
                  <div className="p-30  global-heading-tag">
                    <h5 className="details-title">
                      Gateway{' '}
                      {this.state.gateway.name || this.state.gateway.appeui}
                    </h5>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="12" lg="12">
                  <Table>
                    <tbody>
                      <tr>
                        <td className="v-align-middle">
                          <b>Gateway ID</b>
                        </td>
                        <td>{this.state.gateway.identifier || '/'}</td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Gateway Name</b>
                        </td>
                        <td>
                          {!this.state.editing && (
                            <span>{this.state.gateway.name || '/'}</span>
                          )}
                          {this.state.editing && (
                            <Input
                              name={'name'}
                              defaultValue={this.state.editData.name}
                              onChange={(e) => {
                                this.onChange(e.target.value, e.target.name);
                              }}
                            />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Gateway Connection Status</b>
                        </td>
                        <td>
                          <StatusIndicator
                            key={this.state.gateway.id}
                            status={this.state.gateway.status}
                          />{' '}
                          {this.state.gateway.status.charAt(0).toUpperCase() +
                            this.state.gateway.status.slice(1)}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Connected Kraal Tags</b>
                        </td>
                        <td>{this.state.gateway.tags.length}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        {this.state.gateway.tags && (
          <Row>
            <Col xs="12" md="12" lg="12">
              <Card>
                <Row>
                  <Col xs="12" md="12" lg="12">
                    <div className="p-30 global-heading-tag kraal-listing">
                      <h4 className="">Connected Kraal Tags</h4>
                      <p className="text-muted">
                        Kraal tags connected to {this.state.gateway.identifier}
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md="12" lg="12">
                    <ReactTable
                      showPagination={this.state.gateway.tags.length > 0}
                      minRows={0}
                      data={this.state.gateway.tags}
                      columns={columns}
                      resizable={true}
                      defaultPageSize={25}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs="12" md="12" lg="12" className="global-table">
            <Card>
              <Row className="pad-10 pb-0">
                <Col sm="12" className="mb-0">
                  <h5>History</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Nav tabs className="fancy-tabs">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === '1'
                        })}
                        onClick={() => {
                          this.toggle('1');
                        }}
                      >
                        Notifications
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="12" lg="12">
                  <TabContent
                    className="no-bg"
                    activeTab={this.state.activeTab}
                  >
                    <TabPane tabId="1">
                      <Alerts
                        type="full"
                        query={{ gateway_ids: [this.props.match.params.id] }}
                        filter={{
                          filter: (data) => {
                            return data.type === 'gateway';
                          }
                        }}
                      ></Alerts>
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Gateway;
