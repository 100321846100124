import axios from '../../axios';

const service = {
  async getRoles() {
    return await axios({
      method: 'get',
      url: '/admin/roles'
    });
  },

  async updateUsers(data) {
    return await axios({
      method: 'put',
      url: `/admin/users`,
      data: data
    });
  }
};

export default service;
