import React, { memo, useState, useEffect } from 'react';
import Select from 'react-select';
import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Label,
  Form,
  FormGroup,
  ModalHeader,
  Col,
  FormFeedback
} from 'reactstrap';

const AddTagModal = ({ isOpen, onSubmit, onCancel, className, farm_opts }) => {
  const [tagData, setTagData] = useState({
    type: null,
    deveui: null,
    diagri_id: null,
    version: null,
    firmware_version: null,
    farm: null
  });
  const [isTagValid, setTagValid] = useState(false);
  useEffect(() => {
    setTagValid(
      Object.values(tagData).filter(Boolean).length ===
        Object.values(tagData).length
    );
  }, [tagData]);
  const onTagChange = (value, field) => {
    setTagData({
      ...tagData,
      [field]: value
    });
  };
  return (
    <Modal isOpen={isOpen} className={className}>
      <ModalHeader>
        <h6>
          <b>Add Kraal Tag</b>
        </h6>
      </ModalHeader>
      <ModalBody>
        <br />
        <br />
        <Form>
          <FormGroup row>
            <Label sm="3">Type</Label>
            <Col sm="9">
              <Input
                type="text"
                placeholder="Type"
                onChange={(e) => onTagChange(e.target.value, 'type')}
                valid={tagData.type?.length}
                invalid={!tagData.type?.length && tagData.type !== null}
              />
              <FormFeedback>Required</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm="3">Tag DEVEUI</Label>
            <Col sm="9">
              <Input
                type="text"
                placeholder="DEVEUI"
                onChange={(e) => onTagChange(e.target.value, 'deveui')}
                valid={tagData.deveui?.length}
                invalid={!tagData.deveui?.length && tagData.deveui !== null}
              />
              <FormFeedback>Required</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm="3">Kraal Tag ID</Label>
            <Col sm="9">
              <Input
                type="text"
                placeholder="Kraal Tag ID"
                onChange={(e) => onTagChange(e.target.value, 'diagri_id')}
                valid={tagData.diagri_id?.length}
                invalid={
                  !tagData.diagri_id?.length && tagData.diagri_id !== null
                }
              />
              <FormFeedback>Required</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm="3">Tag Version</Label>
            <Col sm="9">
              <Input
                type="text"
                placeholder="Tag Version"
                onChange={(e) => onTagChange(e.target.value, 'version')}
                valid={tagData.version?.length}
                invalid={!tagData.version?.length && tagData.version !== null}
              />
              <FormFeedback>Required</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm="3">Tag Firmware Version</Label>
            <Col sm="9">
              <Input
                type="text"
                placeholder="Firmware Version"
                onChange={(e) =>
                  onTagChange(e.target.value, 'firmware_version')
                }
                valid={tagData.firmware_version?.length}
                invalid={
                  !tagData.firmware_version?.length &&
                  tagData.firmware_version !== null
                }
              />
              <FormFeedback>Required</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm="3">Farm</Label>
            <Col sm="9">
              <Select
                name="farm"
                value={tagData.farm}
                onChange={(e) => {
                  setTagData({
                    ...tagData,
                    farm: e
                  });
                }}
                options={farm_opts}
              />
              <FormFeedback>Required</FormFeedback>
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => onCancel()}>
          Cancel
        </Button>{' '}
        <Button
          color="primary"
          disabled={!isTagValid}
          onClick={() =>
            onSubmit({
              ...tagData,
              farm_id: tagData.farm.value
            })
          }
        >
          Add tag
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default memo(AddTagModal);
