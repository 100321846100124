import { REDUCERS } from '../../constants/reducers';
import { GatewayService } from '../../services';

export const setGateway = (value) => ({
  type: REDUCERS.GATEWAY.SET_GATEWAY,
  value
});

export const setGateways = (value) => ({
  type: REDUCERS.GATEWAY.SET_GATEWAYS,
  value
});

export const getAllGateways = () => {
  return async (dispatch) => {
    try {
      const { data } = await GatewayService.getAllGateways();
      dispatch(setGateways(data));
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const searchGateways = (payload) => {
  return async (dispatch) => {
    try {
      const { data } = await GatewayService.searchGateways(payload);
      dispatch(setGateways(data));
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const createNewGateway = (payload) => {
  return async (dispatch) => {
    try {
      const { data } = await GatewayService.createNewGateway(payload);
      dispatch(getAllGateways());
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};
