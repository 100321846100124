import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Button,
  FormFeedback,
  Label,
  Input,
  InputGroup,
  Form,
} from "reactstrap";

import { useSetState } from "../../../hooks/useSetState";
import { sendContactUs } from "../../../redux/actions/user";

import "./styles.scss";

const ContactUs = ({ history }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [state, setState] = useSetState({
    subject: null,
    message: null,
  });

  const onChangeField = (e, field) => {
    setState({
      [field]: e.target.value,
    });
  };

  const onClickSubmit = () => {
    const payload = {
      subject: state.subject,
      message: state.message,
      email: user.user.email,
    };

    dispatch(sendContactUs(payload))
      .then((res) => {
        if (res.id) {
          history.goBack();
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const onClickCancel = () => {
    history.goBack();
  };
  return (
    <div className="contact-us-page">
      <Row>
        <h4 className="">Contact us</h4>
      </Row>

      <div className="card">
        <Form className="mt-3" id="contactUs" action="/dashboard">
          <Label for="subject" className="">
            Subject
          </Label>
          <InputGroup className="mb-2">
            <Input
              type="subject"
              id="subject"
              name="subject"
              value={state.subject}
              onChange={(e) => onChangeField(e, "subject")}
              placeholder="Subject"
              valid={state.subject?.length}
              invalid={!state.subject?.length && state.subject !== null}
            />
            <FormFeedback>Required</FormFeedback>
          </InputGroup>
          <Label for="message" className="">
            Message
          </Label>
          <Input
            type="textarea"
            rows="5"
            id="message"
            placeholder="Message"
            onChange={(e) => onChangeField(e, "message")}
            valid={state.message?.length}
            invalid={!state.message?.length && state.message !== null}
          />
          <FormFeedback>Required</FormFeedback>
          {/* {this.showErrors("email")} */}
        </Form>
      </div>
      <Row>
        <Col lg="12" md="12" sm="12">
          <Button
            color="primary"
            className="float-right"
            onClick={onClickSubmit}
            disabled={!state.message?.length || !state.subject?.length}
          >
            Submit
          </Button>
          <Button className="outline float-right" onClick={onClickCancel}>
            Cancel
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default memo(ContactUs);
