import React, { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
  ButtonGroup,
  Button
} from 'reactstrap';

import EventModal from '../../../../components/Modals/EventModal';
import { EVENT_MODAL_MODE } from '../../../../constants/event';
import {
  incrementNextCountCalendar,
  incrementPrevCountCalendar,
} from '../../../../redux/actions/calendar';

const CalendarHeaderOptions = memo(() => {
  const dispatch = useDispatch();
  const [isOpenEventModal, setOpenEventModal] = useState(false);
  const [isOpenActionsDropdown, setOpenActionsDropdown] = useState(false);

  const onActionsClick = () => {
    setOpenActionsDropdown((prevState) => !prevState);
  };

  const onAddEventClick = () => {
    setOpenEventModal(true);
  };

  const onSubmit = () => {
    setOpenEventModal(false);
  };

  return (
    <div className="calendar__header-options">
      <ButtonGroup
        style={{
          marginLeft: '6px',
          float: 'right'
        }}
      >
        <Button
          color="primary"
          onClick={() => {
            dispatch(incrementPrevCountCalendar());
          }}
        >
          Prev
        </Button>
        <Button
          color="primary"
          onClick={() => {
            dispatch(incrementNextCountCalendar());
          }}
        >
          Next
        </Button>
      </ButtonGroup>
      <ButtonDropdown
        className="float-right"
        isOpen={isOpenActionsDropdown}
        toggle={onActionsClick}
      >
        <DropdownToggle caret>Actions</DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={onAddEventClick}>Add event</DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>

      <EventModal
        onSubmit={onSubmit}
        isOpen={isOpenEventModal}
        onCancel={() => setOpenEventModal(false)}
        mode={EVENT_MODAL_MODE.CREATE}
      />
    </div>
  );
});

export default CalendarHeaderOptions;
