import { REDUCERS } from '../../constants/reducers';

const initialState = {
  list: [],
  options: []
};

const farm = (state = initialState, action) => {
  switch (action.type) {
    case REDUCERS.FARM.SET_FARM_STATE: {
      return Object.assign({}, state, {
        ...state,
        ...action.value
      });
    }
    case REDUCERS.RESET:
      return initialState;
    default:
      return state;
  }
};

export { farm };
