import { REDUCERS } from '../../constants/reducers';

const initialState = {
  gateways: [],
  gateway: {}
};

const gateway = (state = initialState, action) => {
  switch (action.type) {
    case REDUCERS.GATEWAY.SET_GATEWAYS: {
      return Object.assign({}, state, {
        gateways: [...action.value]
      });
    }
    case REDUCERS.GATEWAY.SET_GATEWAY: {
      return Object.assign({}, state, {
        gateway: { ...action.value }
      });
    }
    case REDUCERS.RESET:
      return initialState;
    default:
      return state;
  }
};

export { gateway };
