import axios from 'axios';
import { networkErrorToastHandler } from '../components/action_notifier';

// eslint-disable-next-line no-undef
let baseURL = `${process.env.REACT_APP_BASE_URL}/api`;

let client = axios.create({
  baseURL: baseURL
});

export const setAuthorizationHeader = (token = null) => {
  if (token) {
    client.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete client.defaults.headers.common.Authorization;
  }
};

client.interceptors.response.use(
  (response) => {
    // const { config } = response;
    const token = response.headers['x-set-auth-token'];
    if (token) {
      localStorage.setItem('token', token);
      setAuthorizationHeader(token);
    }
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 401) {
        window.location.href = '/auth/login';
        //localStorage.removeItem("token");
      }
      if (error.response.config.method !== 'get') {
        networkErrorToastHandler(error);
      }
      return Promise.reject(error);
    }
  }
);
client.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('token');

    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

export default client;
