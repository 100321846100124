export const REDUCERS = {
  ADMIN: {
    SET_ADMIN_STATE: 'SET_ADMIN_STATE'
  },
  USER: {
    SET_USER: 'SET_USER',
    SET_USERS: 'SET_USERS',
    SET_BASE_URL: 'SET_BASE_URL',
    SET_USER_STATE: 'SET_USER_STATE'
  },
  TAG: {
    SET_TAG: 'SET_TAG',
    SET_TAGS: 'SET_TAGS'
  },
  GATEWAY: {
    SET_GATEWAY: 'SET_GATEWAY',
    SET_GATEWAYS: 'SET_GATEWAYS'
  },
  NOTIFICATION: {
    SET_NOTIFICATION_LIST: 'SET_NOTIFICATION_LIST',
    SET_NOTIFICATION_STATE: 'SET_NOTIFICATION_STATE'
  },
  EVENT: {
    SET_CURRENT_EVENT: 'SET_CURRENT_EVENT',
    SET_EVENT_LIST: 'SET_EVENT_LIST',
    SET_EVENT_QUERY_PARAMS: 'SET_EVENT_QUERY_PARAMS',
    SET_EVENT_STATE: 'SET_EVENT_STATE'
  },
  TASK: {
    SET_TASK_STATE: 'SET_TASK_STATE',
    SET_CURRENT_TASK: 'SET_CURRENT_TASK',
    SET_TASK_LIST: 'SET_TASK_LIST',
    SET_TASK_QUERY_PARAMS: 'SET_TASK_QUERY_PARAMS'
  },
  SUBSCRIPTION: {
    SET_PLANS: 'SET_PLANS',
    SET_MAPPED_PLANS: 'SET_MAPPED_PLANS',
    SET_FEATURE_OPTIONS: 'SET_FEATURE_OPTIONS',
    SET_MAPPED_FEATURE_OPTIONS: 'SET_MAPPED_FEATURE_OPTIONS',
    SET_SUBSCRIPTION_STATE: 'SET_SUBSCRIPTION_STATE'
  },
  FARM: {
    SET_FARM_STATE: 'SET_FARM_STATE'
  },
  CALENDAR: {
    SET_CALENDAR_STATE: 'SET_CALENDAR_STATE',
    INCREMENT_NEXT_COUNT_CALENDAR: 'INCREMENT_NEXT_COUNT_CALENDAR',
    INCREMENT_PREV_COUNT_CALENDAR: 'INCREMENT_PREV_COUNT_CALENDAR'
  },
  RESET: 'RESET'
};
