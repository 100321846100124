import React from 'react';
import {
  Row,
  Col,
  Card,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Input,
  Table,
  Modal,
  ModalBody,
  ModalFooter
} from 'reactstrap';

import axios from '../../axios';
import Select from 'react-select';
import _ from 'lodash';

function RuleStep(props) {
  let step = props.step;
  let def = _.get(props.rule.data, step.key);

  return (
    <div className="rule-box w-20">
      <div className="rule-step-label">
        <span>{step.name}</span>
      </div>
      <div className="rule-step-select">
        {step.type === 'dropdown' && (
          <Select
            name="type"
            onChange={(value) => props.onChange(value, step.key)}
            options={step.options}
            defaultValue={def}
            isMulti={false}
            isSearchable={step.key === 'anchor' || step.key === 'target'}
          ></Select>
        )}
        {step.type === 'number' && (
          <Input
            type="number"
            defaultValue={def}
            onChange={(e) => props.onChange(e.target.value, step.key)}
          ></Input>
        )}
      </div>
    </div>
  );
}
class RuleBuilder extends React.Component {
  //Tabs
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      delete_modal: false,
      rule: {
        type: {},
        farm: {}
      },
      farms: [],
      types: [],
      tempOps: [
        {
          label: 'Geofence',
          value: 'geofence'
        },
        {
          label: '2',
          value: 2
        },
        {
          label: '3',
          value: 3
        },
        {
          label: '4',
          value: 4
        }
      ],
      steps_html: null,
      dropdownOpen: false,
      steps: null,
      new: this.props.match.params.id === 'new',
      render: false
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleButton = this.toggleButton.bind(this);
    this.getRule = this.getRule.bind(this);
    this.onRuleChange = this.onRuleChange.bind(this);
    this.onStepChange = this.onStepChange.bind(this);
    this.onFarmChange = this.onFarmChange.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.generateDefinition = this.generateDefinition.bind(this);
  }
  toggleButton() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  async componentDidMount() {
    if (!this.state.new) {
      await this.getRule();
    } else {
      this.setState({
        render: true
      });
    }
    await this.getFarms();
    await this.getTypes();
    await this.getOptions();
  }
  async getFarms() {
    const response = await axios.get('farms');
    const farms = response.data.map((item) => {
      return {
        value: item.id,
        label: item.name
      };
    });
    this.setState({ farms });
  }
  async getTypes() {
    let response = await axios.get('/rules/types');
    this.setState({
      types: response.data.filter(
        (i) =>
          i.value !== 'gateway_disconnected' &&
          i.value !== 'tag_disconnected' &&
          i.value !== 'basic_health' &&
          i.value !== 'intermediate_health'
      )
    });
  }
  async getOptions() {
    if (this.state.rule.type.value == null) {
      return;
    }
    let response = await axios.get('/rules/options', {
      params: {
        type: this.state.rule.type.value,
        farmId: this.state.rule.farm.value
      }
    });
    this.setState((state) => ({
      ...state,
      rule: {
        ...state.rule,
        type: { value: response.data.name, label: response.data.display_name }
      }
    }));
    let steps = [];
    if (response.data.steps != null) {
      for (let step of response.data.steps) {
        steps.push(
          <RuleStep
            step={step}
            onChange={this.onStepChange}
            rule={this.state.rule}
          ></RuleStep>
        );
      }
    }
    this.setState(
      {
        steps: response.data.steps,
        steps_html: steps,
        render: true
      },
      () => this.forceUpdate()
    );
  }
  async getRule() {
    let response = await axios.get('rules/' + this.props.match.params.id, {
      query: this.state.query
    });

    this.setState(
      {
        //TODO: set to response data
        rule: {
          ...response.data,
          type: {
            value: response.data.type,
            label: response.data.type_display_name
          },
          farm: {
            value: response.data.farm.id,
            label: response.data.farm.name
          }
        }
      },
      () => this.getOptions()
    );
    this.generateDefinition();
  }
  onFarmChange(val) {
    this.setState({
      rule: {
        type: {},
        farm: val
      },
      steps: null,
      steps_html: null
    });
  }
  onRuleChange(value, filed) {
    this.setState({
      rule: {
        ...this.state.rule,
        [filed]: value
      }
    });
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  toggleModal(modal) {
    this.setState((state) => ({
      ...state,
      [modal]: !state[modal]
    }));
  }
  onTypeChange(sel) {
    this.setState(
      {
        rule: {
          ...this.state.rule,
          type: sel,
          definition: 'Please complete some steps.',
          data: {}
        },
        steps_html: null
      },
      () => this.getOptions()
    );
  }
  onStepChange(value, field) {
    let data = {
      ...this.state.rule.data
    };
    if (field.split('.').length > 1) {
      _.set(data, field, value);
    } else {
      data[field] = value;
    }
    this.setState(
      {
        rule: {
          ...this.state.rule,
          data: data
        }
      },
      () => this.generateDefinition()
    );
  }
  async saveRule() {
    if (this.state.new) {
      let response = await axios.post('rules', {
        ...this.state.rule,
        farm_id: this.state.rule.farm.value,
        type: this.state.rule.type.value
      });
      this.props.history.push('/rule/' + response.data.id);
    } else {
      let response = await axios.put('rules/' + this.props.match.params.id, {
        ...this.state.rule,
        farm_id: this.state.rule.farm.value,
        type: this.state.rule.type.value
      });
      this.props.history.push('/rule/' + response.data.id);
    }
  }
  generateDefinition() {
    let def = [];
    let data = this.state.rule.data;
    let type = this.state.rule.type.value;
    let not_defined = <span>{''}</span>;
    if (type === 'intermediate_health') {
      def.push(<span>Any animal moves less than </span>);
      if (data.variables) {
        if (data.variables.percentage) {
          def.push(
            <span>
              <span className="bolded">{data.variables.percentage}%</span>
              {' of '}
            </span>
          );
        }

        if (data.variables.calculated_value) {
          def.push(
            <span>
              <span className="bolded">
                {data.variables.calculated_value.value}
              </span>{' '}
            </span>
          );
        }
      }
      if (data.action) {
        def.push(
          <span>
            {'. If rule breached '}
            {data.action.label}{' '}
          </span>
        );
      } else {
        def.push(not_defined);
      }
      this.setState({
        rule: {
          ...this.state.rule,
          definition: def
        }
      });
      return;
    }
    if (data.selector) {
      def.push(
        <span>
          <span className="bolded">{data.selector.label}</span>
          {' livestock labeled '}
        </span>
      );
    } else {
      def.push(not_defined);
    }
    if (data.anchor) {
      def.push(
        <span>
          <span className="bolded">{data.anchor.label}</span>{' '}
        </span>
      );
    } else {
      def.push(not_defined);
    }
    if (data.condition) {
      def.push(<span>{data.condition.label} </span>);
    } else {
      def.push(not_defined);
    }
    if (
      type === 'site_proximity' ||
      type === 'livestock_proximity' ||
      type === 'basic_health'
    ) {
      if (data.variables) {
        if (data.variables.distance) {
          def.push(
            <span>
              <span className="bolded">{data.variables.distance} </span>
            </span>
          );
        } else {
          def.push(not_defined);
        }
        if (data.variables.distance_unit) {
          def.push(
            <span>
              <span className="bolded">
                {data.variables.distance_unit.label}
              </span>
              {type !== 'basic_health' && ' of '}
            </span>
          );
        } else {
          def.push(not_defined);
        }
        if (type === 'basic_health') {
          if (data.variables.timeframe) {
            def.push(
              <span>
                <span className="bolded">{data.variables.timeframe.label}</span>
              </span>
            );
          } else {
            def.push(not_defined);
          }
        }
      }
    }
    if (data.target) {
      def.push(
        <span>
          <span className="bolded">{data.target.label} </span>
        </span>
      );
    }

    if (type === 'site_frequency') {
      if (data.variables) {
        if (data.variables.frequency) {
          def.push(
            <span>
              <span className="bolded">{data.variables.frequency} </span>{' '}
              {' times '}{' '}
            </span>
          );
        } else {
          def.push(not_defined);
        }
        if (data.variables.timeframe) {
          def.push(
            <span>
              <span className="bolded">{data.variables.timeframe.label}</span>
            </span>
          );
        } else {
          def.push(not_defined);
        }
      }
    }
    if (data.action) {
      def.push(
        <span>
          {'. If rule breached '}
          {data.action.label}{' '}
        </span>
      );
    } else {
      def.push(not_defined);
    }
    this.setState({
      rule: {
        ...this.state.rule,
        definition: def
      }
    });
  }
  async deleteRule() {
    await axios.delete('rules/' + this.props.match.params.id);
    this.props.history.push('/rules');
  }
  render() {
    if (!this.state.render) {
      return null;
    }

    return (
      <div>
        <Row>
          <Col xs="6" md="6" lg="6">
            <h4 className="">Rule {this.state.rule.name}</h4>
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="#" onClick={() => this.props.history.push('/rules')}>
                  List of rules
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem>Rule {this.state.rule.name}</BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col xs="6" md="6" lg="6">
            {this.state.new && (
              <div>
                <Button
                  color="primary"
                  className="float-right"
                  onClick={() => this.saveRule()}
                >
                  Save
                </Button>
                <Button
                  className="float-right outline"
                  onClick={() => {
                    this.props.history.push('/rules');
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
            {!this.state.new && (
              <div>
                <Button
                  color="primary"
                  className="float-right"
                  onClick={() => this.saveRule()}
                >
                  Save
                </Button>
                <Button
                  className="float-right outline"
                  onClick={() =>
                    this.props.history.push(
                      '/rule/' + this.props.match.params.id
                    )
                  }
                >
                  Cancel
                </Button>
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="12" lg="12">
            <Card>
              <Row className="pad-15">
                <Col xs="12" md="12" lg="6">
                  <h3>Rule Creator</h3>
                  <p>
                    To create a new rule, follow the steps below. Select only
                    the items you need.
                  </p>
                </Col>
              </Row>
              <Row className="pad-15">
                <Col xs="12" md="12" lg="12">
                  <div className="rule-box w-20">
                    <div className="rule-step-label">
                      <span>Farm</span>
                    </div>
                    <div className="rule-step-select">
                      <Select
                        name="type"
                        onChange={this.onFarmChange}
                        options={this.state.farms}
                        value={this.state.rule.farm}
                        isMulti={false}
                      ></Select>
                    </div>
                  </div>
                </Col>
              </Row>
              {this.state?.rule?.farm?.value && (
                <Row className="pad-15">
                  <Col xs="12" md="12" lg="12">
                    <div className="rule-box w-20">
                      <div className="rule-step-label">
                        <span>Rule Type</span>
                      </div>
                      <div className="rule-step-select">
                        <Select
                          name="type"
                          onChange={this.onTypeChange}
                          options={this.state.types}
                          value={this.state.rule.type}
                          isMulti={false}
                        ></Select>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
              <Row className="pad-15">
                <Col xs="12" md="12" lg="12">
                  {this.state.steps_html}
                </Col>
              </Row>
              <Row className="pad-15">
                <Col sm="12" md="12" lg="12">
                  <h5 className="is-5">Rule Definition (Auto generated)</h5>
                  <div className="rule-definition">
                    <span className="center">{this.state.rule.definition}</span>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="12" lg="12">
            <Card>
              <Row>
                <Col xs="12" md="12" lg="12" className="global-table">
                  <Table>
                    <tbody>
                      <tr>
                        <td colSpan="2">
                          <h4>Rule details</h4>
                          <p className="mb-0">
                            Additional information about the notification.
                          </p>
                        </td>
                      </tr>
                      {!this.state.new && (
                        <tr>
                          <td className="v-align-middle">
                            <b>Rule ID</b>
                          </td>
                          <td>{this.state.rule.id}</td>
                        </tr>
                      )}
                      <tr>
                        <td className="v-align-middle">
                          <b>Rule Name</b>
                        </td>
                        <td>
                          <Input
                            type="text"
                            name="name"
                            defaultValue={this.state.rule.name}
                            onChange={(e) =>
                              this.onRuleChange(e.target.value, e.target.name)
                            }
                          ></Input>
                        </td>
                      </tr>
                      {this.state.rule.objects_count > 0 && (
                        <tr>
                          <td className="v-align-middle">
                            <b>Rule Objects</b>
                          </td>
                          <td>
                            {this.state.rule.objects_count} (Livestock:{' '}
                            {this.state.rule.animals_count}, Sites:{' '}
                            {this.state.rule.sites_count}, Geofences:{' '}
                            {this.state.rule.geofences_count})
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td className="v-align-middle">
                          <b>Rule Description</b>
                        </td>
                        <td>
                          <Input
                            type="textarea"
                            name="description"
                            defaultValue={this.state.rule.description}
                            onChange={(e) =>
                              this.onRuleChange(e.target.value, e.target.name)
                            }
                          ></Input>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col lg="12" md="12" sm="12">
            {this.state.new && (
              <div>
                <Button
                  color="primary"
                  className="float-right"
                  onClick={() => this.saveRule()}
                >
                  Save
                </Button>
                <Button
                  className="float-right outline"
                  onClick={() => {
                    this.props.history.push('/rules');
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
            {!this.state.new && (
              <div>
                <Button
                  color="primary"
                  className="float-right"
                  onClick={() => this.saveRule()}
                >
                  Save
                </Button>
                <Button
                  className="float-right outline"
                  onClick={() =>
                    this.props.history.push(
                      '/rule/' + this.props.match.params.id
                    )
                  }
                >
                  Cancel
                </Button>
              </div>
            )}
          </Col>
        </Row>
        <Modal
          isOpen={this.state.delete_modal}
          className={this.props.className}
        >
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>Archive Rule</b>
            </h5>
            <br />
            <br />
            <br />
            Are you sure you want to archive this rule? This action cannot be
            undone.
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => this.toggleModal('delete_modal')}
            >
              Cancel
            </Button>{' '}
            <Button color="danger" onClick={() => this.deleteRule()}>
              Archive
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default RuleBuilder;
