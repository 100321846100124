import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { SUBSCRIPTION_FEATURES } from '../../configs/subscriptions';

const FeatureProvider = ({ children, name }) => {
  const states = useSelector((state) => state);
  const userState = states.user;
  const subscriptionState = states.subscription;
  const myFeatureGroups = subscriptionState?.myFeatureGroups;
  
  const isFeatureAvailable = () => {
    if (userState.isAdmin) return true;
    return myFeatureGroups.some((group) => !!SUBSCRIPTION_FEATURES?.[group]?.includes(name))
  };

  return isFeatureAvailable() ? children : null;
};

export default memo(FeatureProvider);
