import React from 'react';
import {
  Row,
  Col,
  Card,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Input,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import classnames from 'classnames';

import Map from '../../components/geo_map';
import axios from '../../axios';
import Alerts from '../../components/Alerts';
import zoom from '../../assets/images/zoom.png';
import { CompactPicker } from 'react-color';
import ChooseGroupsModal from '../../components/Modals/ChooseGroupsModal';
import PrevNextButtons from '../../components/PrevNextButtons';
import { connect } from 'react-redux';
import ConfirmationModal from '../../components/Modals/ConfirmationModal';

class Farm extends React.Component {
  //Tabs
  constructor(props) {
    super(props);

    this.state = {
      chooseGroupModal: false,
      activeTab: '1',
      editing: false,
      isFullScreen: false,
      selected_geofences: [],
      map_key: 0,
      color: '#3388ff',
      geofence: {
        id: null,
        farm_id: null,
        name: 'Seveda',
        size: '11',
        animals_count: '11',
        description: 'asdasdas asdasd ',
        circumference: '11',
        days_grazed: '22',
        position: [24.984627, 31.717946],
        geo_json: {}
      },
      geofence_edit: {
        id: null,
        farm_id: 4,
        name: '',
        size: '11',
        animals_count: '11',
        description: 'asdasdas asdasd ',
        circumference: '11',
        days_grazed: '22',
        position: [24.984627, 31.717946],
        geo_json: {}
      },
      delete_modal: false,
      farm: {},
      dropdownOpen: false,
      groupOptions: [],
      savedGroups: [],
      selectedGroups: [],
      isOpenConfirmationModal: false
    };
    this.onChange = this.onChange.bind(this);
    this.onSaveGroups = this.onSaveGroups.bind(this);
    this.onSelectGroup = this.onSelectGroup.bind(this);
    this.multiselectChange = this.multiselectChange.bind(this);
    this.getFence = this.getFence.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onGeoJSONChange = this.onGeoJSONChange.bind(this);
    this.saveFence = this.saveFence.bind(this);
    this.toggleButton = this.toggleButton.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
  }
  toggleFullscreen() {
    this.setState((state) => ({
      ...state,
      isFullScreen: !this.state.isFullScreen
    }));
  }
  toggleModal(modal) {
    if (modal === 'chooseGroupModal') {
      this.setState((state) => ({
        ...state,
        selectedGroups: state.savedGroups
      }));
    }
    this.setState((state) => ({
      ...state,
      [modal]: !state[modal]
    }));
  }
  toggleButton() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  onChange = (value, field) => {
    this.setState((state) => ({
      ...state,
      geofence_edit: {
        ...state.geofence_edit,
        [field]: value
      }
    }));
    this.forceUpdate();
  };
  onGeoJSONChange(value) {
    this.setState((state) => ({
      ...state,
      geofence_edit: {
        ...state.geofence_edit,
        geo_json: value
      }
    }));
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  multiselectChange = (sle) => {
    this.setState({
      selected_geofences: sle
    });
  };
  async componentDidMount() {
    this.getFence();
  }
  async deleteFence() {
    let geofenceId = this.props.match.params.id;
    let response = await axios.delete('geofences/' + geofenceId);
    if (response.status == 200) {
      this.props.history.push('/geofences');
    }
  }
  async getLabels() {
    let response = await axios.get('labels');
    if (response.status == 200) {
      this.setState({
        groupOptions: response.data
          .filter((label) => label.farm?.id === this.state.geofence.farm_id)
          .map((x) => {
            return { value: x.id, label: x.name, color: x.colour };
          })
      });
    }
  }
  async getFence() {
    let geofenceId = this.props.match.params.id;
    let response = await axios.get('geofences/' + geofenceId);
    if (response.status == 200) {
      let geofence = response.data;
      let response2 = await axios.get('farms/' + response.data.farm_id);
      if (response2.status == 200) {
        this.setState({
          ...this.state,
          color: geofence.color,
          geofence: {
            ...geofence,
            features: response2.data.geofences,
            sites: response2.data.sites
          },
          geofence_edit: {
            ...geofence,
            features: response2.data.geofences,
            sites: response2.data.sites
          },
          farm: response2.data,
          selectedGroups: response.data?.labels?.length
            ? response.data?.labels?.map((l) => {
                return {
                  label: l?.name,
                  value: l?.id
                };
              })
            : null,
          savedGroups: response.data?.labels?.length
            ? response.data?.labels?.map((l) => {
                return {
                  label: l?.name,
                  value: l?.id
                };
              })
            : null
        });
      }
    }
    this.getLabels();
  }
  onSelectGroup = (value) => {
    this.setState((state) => ({
      selectedGroups: [...value]
    }));
  };

  async saveFence() {
    let geofenceId = this.props.match.params.id;

    // EVENT TRIGGER FOR MAP 'SAVE' ACTION //
    if (
      document.querySelector('.leaflet-draw-actions.leaflet-draw-actions-top')
    ) {
      document
        .querySelector('.leaflet-draw-actions.leaflet-draw-actions-top li a')
        .click();
    }

    // WAIT FOR 200 MILISECONDS TO GET DATA //
    await setTimeout(() => {}, 200);

    await axios.put('geofences/' + geofenceId, {
      ...this.state.geofence_edit,
      color: this.state.color
    });
    setTimeout(() => {
      window.location.reload();
    }, 300);
  }
  async onSaveGroups() {
    let geofenceId = this.props.match.params.id;
    const groupIds = this.state.selectedGroups.map((g) => {
      return g.value;
    });
    if (groupIds?.length) {
      try {
        await axios.put('geofences/updateGroupGeofence/' + geofenceId, {
          group_ids: groupIds
        });
        this.getFence();
      } catch (error) {
        console.error(error);
      }
    }
    this.toggleModal('chooseGroupModal');
  }
  render() {
    const zoomImg = {
      backgroundImage: 'url(' + zoom + ')'
    };
    function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    if (!this.state.geofence.id) {
      return null;
    }
    return (
      <div>
        <Row className="">
          <Col xs="6" md="6" lg="6">
            <h4 className="">
              {this.state.editing && 'Edit '}Geofence -{' '}
              {this.state.geofence.name}
            </h4>
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="/geofences">List of Geofences</a>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {this.state.editing && 'Edit '}Geofence{' '}
                {this.state.geofence.name}
              </BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col xs="6" md="6" lg="6">
            <PrevNextButtons
              history={this.props.history}
              nextId={this.state.geofence.next_id}
              prevId={this.state.geofence.prev_id}
              path="geofence"
            />
            {!this.state.editing && (
              <div className="flex-reverse">
                <ButtonDropdown
                  className="float-right"
                  isOpen={this.state.dropdownOpen}
                  toggle={this.toggleButton}
                >
                  <DropdownToggle className="remove-mr" caret>
                    Actions
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => {
                        this.setState({
                          ...this.state,
                          editing: true,
                          map_key: this.state.map_key + 1
                        });
                        this.forceUpdate();
                      }}
                    >
                      Edit geofence
                    </DropdownItem>
                    {this.state.geofence.is_master ? null : (
                      <DropdownItem
                        onClick={() => {
                          this.toggleModal('chooseGroupModal');
                        }}
                      >
                        Edit groups in geofence
                      </DropdownItem>
                    )}
                    <DropdownItem
                      onClick={() => {
                        this.toggleModal('delete_modal');
                      }}
                    >
                      Archive geofence
                    </DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
              </div>
            )}
            {this.state.editing && (
              <div className="">
                <Button
                  color="primary"
                  className="float-right"
                  onClick={() => this.saveFence()}
                >
                  Save
                </Button>
                <Button
                  className="float-right outline"
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      editing: false,
                      map_key: this.state.map_key + 1
                    })
                  }
                >
                  Cancel
                </Button>
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="12" lg="12">
            <Card>
              <Row>
                <Col
                  xs="12"
                  md="12"
                  lg="12"
                  className={`
								${this.state.isFullScreen ? 'rg-full-screen icon-position' : ''} 
								${this.state.editing ? '' : 'rg-no-space'}
							`}
                >
                  <Map
                    geofence={
                      this.state.editing
                        ? this.state.geofence_edit
                        : this.state.geofence
                    }
                    color={this.state.color}
                    editing={this.state.editing}
                    onEdit={this.onGeoJSONChange}
                    type="geofence"
                    isFullScreen={this.state.isFullScreen}
                  ></Map>
                  <div
                    className="full-screen-icon text-center"
                    style={zoomImg}
                    onClick={this.toggleFullscreen}
                  ></div>
                </Col>
              </Row>
            </Card>
            {this.state.editing && (
              <Card>
                <Row>
                  <Col xs="12" md="12" lg="12" className="global-table">
                    <Table responsive className="">
                      <tbody>
                        <tr>
                          <td colSpan="2">
                            <h4>Geofence {this.state.geofence.name} Details</h4>
                          </td>
                        </tr>
                        <tr>
                          <td className="v-align-middle">
                            <b>Geofence Name</b>
                          </td>
                          <td>
                            {!this.state.geofence.is_master && (
                              <Input
                                name="name"
                                defaultValue={this.state.geofence_edit.name}
                                onChange={(e) => {
                                  this.onChange(e.target.value, e.target.name);
                                }}
                              ></Input>
                            )}
                            {this.state.geofence.is_master &&
                              this.state.geofence.name}
                          </td>
                        </tr>
                        <tr>
                          <td className="v-align-middle">
                            <b>Geofence Color</b>
                          </td>
                          <td>
                            <CompactPicker
                              name="colour"
                              color={this.state.color}
                              onChange={(e) => {
                                this.setState({
                                  ...this.state,
                                  color: e.hex
                                });
                              }}
                            ></CompactPicker>
                          </td>
                        </tr>
                        <tr>
                          <td className="v-align-middle">
                            <b>Farm ID</b>
                          </td>
                          <td>{this.state.geofence.farm_id}</td>
                        </tr>
                        <tr>
                          <td className="v-align-middle">
                            <b>Size (ha)</b>
                          </td>
                          <td>{this.state.geofence.size}</td>
                        </tr>
                        <tr>
                          <td className="v-align-middle">
                            <b>Perimeter (km)</b>
                          </td>
                          <td>{this.state.geofence.circumference}</td>
                        </tr>
                        <tr>
                          <td className="v-align-middle">
                            <b>Description</b>
                          </td>
                          <td>
                            <Input
                              type="textarea"
                              name="description"
                              defaultValue={
                                this.state.geofence_edit.description
                              }
                              onChange={(e) => {
                                this.onChange(e.target.value, e.target.name);
                              }}
                            ></Input>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Card>
            )}
          </Col>
          {!this.state.editing && (
            <Col xs="12" md="12" lg="12">
              <Card>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col xs="12" md="12" lg="12" className="global-table">
                            <Table responsive className="width">
                              <tbody>
                                <tr>
                                  <td colSpan="2">
                                    <h4>
                                      Geofence {this.state.geofence.name}{' '}
                                      Details
                                    </h4>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Geofence ID</b>
                                  </td>
                                  <td>{this.state.geofence.identifier}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Geofence Name</b>
                                  </td>
                                  <td>{this.state.geofence.name}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Geofence Size (ha)</b>
                                  </td>
                                  <td>
                                    {numberWithCommas(
                                      Number(this.state.geofence.size).toFixed(
                                        2
                                      )
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Geofence Perimeter (km)</b>
                                  </td>
                                  <td>
                                    {numberWithCommas(
                                      Number(
                                        this.state.geofence.circumference
                                      ).toFixed(2)
                                    )}
                                  </td>
                                </tr>
                                {['trial', 'advanced', 'basic'].includes(
                                  this.props.subscription.myPlan.type
                                ) ? null : (
                                  <>
                                    <tr>
                                      <td>
                                        <b>Assigned Livestock</b>
                                      </td>
                                      <td>
                                        {numberWithCommas(
                                          this.state.geofence
                                            .active_livestock_count
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <b>Expected Tracked Livestock</b>
                                      </td>
                                      <td>
                                        {numberWithCommas(
                                          this.state.geofence.livestock_with_tag
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <b>Actual Tracked Livestock</b>
                                      </td>
                                      <td>
                                        {numberWithCommas(
                                          this.state.geofence.actual_livestock
                                        )}
                                      </td>
                                    </tr>
                                  </>
                                )}

                                <tr>
                                  <td>
                                    <b>Assigned Groups</b>
                                  </td>
                                  <td>
                                    {this?.state?.geofence?.labels?.map(
                                      (l, i) => {
                                        return (
                                          <span>
                                            {i !== 0 ? (
                                              <span>{', '}</span>
                                            ) : null}
                                            <a href={`/group/${l.id}`}>
                                              {l.name}
                                            </a>
                                          </span>
                                        );
                                      }
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Geofence Description</b>
                                  </td>
                                  <td>
                                    {this.state.geofence_edit.description}{' '}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </Card>
            </Col>
          )}
        </Row>
        {this.state.editing && (
          <Row
            className={
              this.state.isFullScreen ? 'rg-full-screen-btn mb-4' : 'mb-4'
            }
          >
            <Col>
              <div className="">
                <Button
                  color="primary"
                  className="float-right"
                  onClick={() => this.saveFence()}
                >
                  Save
                </Button>
                <Button
                  className="float-right outline"
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      editing: false,
                      map_key: this.state.map_key + 1
                    })
                  }
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        )}
        <Card>
          <Row className="pad-10 pb-0">
            <Col sm="12" className="mb-0">
              <h5>History</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <Nav tabs className="fancy-tabs">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === '1'
                    })}
                    onClick={() => {
                      this.toggle('1');
                    }}
                  >
                    Notifications
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="12" lg="12">
              <TabContent className="no-bg" activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  <Alerts
                    type="full"
                    query={{ geofence_ids: [this.props.match.params.id] }}
                  ></Alerts>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Card>

        <ChooseGroupsModal
          isOpen={this.state.chooseGroupModal}
          options={this.state.groupOptions}
          values={this.state.selectedGroups}
          handleGroupsChange={this.onSelectGroup}
          onCancel={() => this.toggleModal('chooseGroupModal')}
          onSave={() => this.setState({ isOpenConfirmationModal: true })}
        />

        <Modal
          isOpen={this.state.delete_modal}
          className={this.props.className}
        >
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>Archive geofence</b>
            </h5>
            <br />
            <br />
            <br />
            Are you sure you want to archive this geofence? This action cannot
            be undone.
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => this.toggleModal('delete_modal')}
            >
              Cancel
            </Button>{' '}
            <Button color="danger" onClick={() => this.deleteFence()}>
              Archive
            </Button>
          </ModalFooter>
        </Modal>

        <ConfirmationModal
          isOpen={this.state.isOpenConfirmationModal}
          description={
            'One or more groups are already assigned to a different geofence. Do you want to update the geofence for these groups?'
          }
          onCancel={() => {
            this.setState({ isOpenConfirmationModal: false });
          }}
          onSubmit={() => {
            this.onSaveGroups();
            this.setState({
              isOpenConfirmationModal: false
            });
          }}
          submitText={'Yes'}
          submitButtonType={'primary'}
        />
      </div>
    );
  }
}

export default connect((state) => state)(Farm);
