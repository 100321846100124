import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import GatewayTable from "../../../../components/Tables/GatewayTable";
import { getAllGateways } from "../../../../redux/actions/gateway";

const AdministrationGateways = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllGateways());
  }, []);

  return (
    <div className="administration-gateway-page">
      <GatewayTable {...props}/>
    </div>
  );
};

export default AdministrationGateways;
