export const PLANS_PERIOD = [1, 3, 6, 12];
export const TRIAL_PLAN = 'trial';

export const LIMIT_OPTIONS = {
  livestock: [250, 500, 750, 1000],
  farms: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  geofences: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  sites: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  rules: [20, 40, 60, 80, 100],
  sms: [25, 50, 75, 100]
};
export const PLANS_STATUS = {
  ACTIVE: 'active',
  PENDING: 'pending',
  INACTIVE: 'inactive'
};

export const PLANS_TYPE = {
  ADVANCED: 'advanced',
  TRIAL: 'trial',
  BASIC: 'basic',
  COMPLETED: 'completed'
};

export const STATUS_COLOR = {
  active: 'green',
  pending: '#87bdd8',
  inactive: 'red'
};

export const FEATURE_LIST = {
  USER_SUMMARY_CARD: 'USER_SUMMARY_CARD',
  USER_PERSONAL_DETAILS: 'USER_PERSONAL_DETAILS',
  USER_ADDRESS_DETAILS: 'USER_ADDRESS_DETAILS',
  USER_NOTIFICATION_PREFERENCES: 'USER_NOTIFICATION_PREFERENCES',
  USER_PROFILE_PICTURE: 'USER_PROFILE_PICTURE',
  CONTACT_US: 'CONTACT_US',
  ACTIVE_LIVESTOCK_WIDGET: 'ACTIVE_LIVESTOCK_WIDGET',
  ACTIVE_NOTIFICATIONS_WIDGET: 'ACTIVE_NOTIFICATIONS_WIDGET',
  ACTIVE_RULES_WIDGET: 'ACTIVE_RULES_WIDGET',
  CALVES_PRODUCED_WIDGET: 'CALVES_PRODUCED_WIDGET',
  CALVES_WEANED_WIDGET: 'CALVES_WEANED_WIDGET',
  LIVESTOCK_COUNT_WIDGET: 'LIVESTOCK_COUNT_WIDGET',
  WEATHER_WIDGET: 'WEATHER_WIDGET',
  CALENDAR_WIDGET: 'CALENDAR_WIDGET',
  TASKS_WIDGET: 'TASKS_WIDGET',
  MAP_WIDGET: 'MAP_WIDGET',
  LIVESTOCK_ANALYSIS_WIDGET: 'LIVESTOCK_ANALYSIS_WIDGET',
  FARM_FILTER: 'FARM_FILTER',
  GROUP_FILTER: 'GROUP_FILTER',
  GEOFENCE_FILTER: 'GEOFENCE_FILTER',
  SITES_FILTER: 'SITES_FILTER',
  RULES_FILTER: 'RULES_FILTER',
  TAGS_FILTER: 'TAGS_FILTER',
  LIVESTOCK_ID_FILTER: 'LIVESTOCK_ID_FILTER',
  CALENDAR: 'CALENDAR',
  TASKS: 'TASKS',
  LIVESTOCK_LIST: 'LIVESTOCK_LIST',
  NOTIFICATION_ICON: 'NOTIFICATION_ICON',
  LAST_NOTIFICATIONS_WIDGET: 'LAST_NOTIFICATIONS_WIDGET',
  RULES_WIDGET: 'RULES_WIDGET',
  MAP_TRACKING: 'MAP_TRACKING',
  BASIC_LIVESTOCK_LIST_TRACKING: 'BASIC_LIVESTOCK_LIST_TRACKING',
  LIVESTOCK_WITH_NOTIFICATIONS_FITLER: 'LIVESTOCK_WITH_NOTIFICATIONS_FITLER',
  LIST_OF_NOTIFICATIONS: 'LIST_OF_NOTIFICATIONS',
  NOTIFICATION_DETAIL: 'NOTIFICATION_DETAIL',
  LIVESTOCK_DETAIL: 'LIVESTOCK_DETAIL',
  LIVESTOCK_PICTURE: 'LIVESTOCK_PICTURE',
  NOTIFICATION_LIST: 'NOTIFICATION_LIST',
  MEASURES_TAB: 'MEASURES_TAB',
  BREEDING_TAB: 'BREEDING_TAB',
  TREATMENT_TAB: 'TREATMENT_TAB',
  NOTIFICATION_TAB: 'NOTIFICATION_TAB',
  TRACK_ANIMAL: 'TRACK_ANIMAL',
  FARMS_LIST: 'FARMS_LIST',
  FARM_DETAILS_FIELDS: 'FARM_DETAILS_FIELDS',
  GEOFENCES_LIST: 'GEOFENCES_LIST',
  GEOFENCE_DETAILS_FIELDS: 'GEOFENCE_DETAILS_FIELDS',
  SITES_LIST: 'SITES_LIST',
  SITE_DETAILS_FIELDS: 'SITE_DETAILS_FIELDS',
  GROUPS_LIST: 'GROUPS_LIST',
  GROUP_DETAILS_FIELDS: 'GROUP_DETAILS_FIELDS',
  RULES_LIST: 'RULES_LIST',
  RULE_DETAILS_FIELDS: 'RULE_DETAILS_FIELDS',
  KRAAL_TAGS_LIST: 'KRAAL_TAGS_LIST'
};
