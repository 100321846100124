import { FEATURE_LIST } from '../constants/subscription';

export const SUBSCRIPTION_FEATURES = {
  basic: [
    FEATURE_LIST.ACTIVE_LIVESTOCK_WIDGET,
    FEATURE_LIST.CALVES_PRODUCED_WIDGET,
    FEATURE_LIST.CALVES_WEANED_WIDGET,
    FEATURE_LIST.LIVESTOCK_COUNT_WIDGET,
    FEATURE_LIST.WEATHER_WIDGET,
    FEATURE_LIST.CALENDAR_WIDGET,
    FEATURE_LIST.TASKS_WIDGET,
    FEATURE_LIST.MAP_WIDGET,
    FEATURE_LIST.LIVESTOCK_ANALYSIS_WIDGET,
    FEATURE_LIST.FARM_FILTER
  ],
  tracking: [
    FEATURE_LIST.NOTIFICATION_ICON,
    FEATURE_LIST.ACTIVE_LIVESTOCK_WIDGET,
    FEATURE_LIST.ACTIVE_NOTIFICATIONS_WIDGET,
    FEATURE_LIST.ACTIVE_RULES_WIDGET,
    FEATURE_LIST.LIVESTOCK_COUNT_WIDGET,
    FEATURE_LIST.LAST_NOTIFICATIONS_WIDGET,
    FEATURE_LIST.RULES_WIDGET,
    FEATURE_LIST.MAP_TRACKING,
    FEATURE_LIST.TRACK_ANIMAL,
    FEATURE_LIST.RULES_FILTER,
    FEATURE_LIST.RULES_WIDGET,
    FEATURE_LIST.LAST_NOTIFICATIONS_WIDGET,
    FEATURE_LIST.NOTIFICATION_ICON
  ],
  measures: [FEATURE_LIST.MEASURES_TAB],
  treatment: [FEATURE_LIST.TREATMENT_TAB],
  breeding: [FEATURE_LIST.BREEDING_TAB],
  farm: []
};

export const EXPENDED_DEFAULT_LIVESTOCK_LIST = [
  'select',
  'identifier',
  'eartag_management_id',
  'sex',
  'colour',
  'breedName',
  'brand',
  'farm',
  'assignedGeofence',
  'livestockType',
  'age',
  'weight',
  'breedingStatus',
  'calfLivestockId',
  'calfMgmtId',
  'calfAge',
  'officialTagId',
  'earMark',
  'specie',
  'specieName',
  'dob',
  'polledHorned',
  'description',
  'sireId',
  'damId',
  'numbersCalves',
  'purchaseDate',
  'purchasedFrom',
  'purchasedWhere',
  'purchaseNotes',
  'dateSold',
  'soldTo',
  'lossDate',
  'lossReason',
  'comments'
];

export const TABLE_COLUMNS = {
  [FEATURE_LIST.LIVESTOCK_COUNT_WIDGET]: {
    basic: ['geofenceName', 'assignedLivestock', 'unassignedAnimals'],
    trial: ['geofenceName', 'assignedLivestock', 'unassignedAnimals'],
    advanced: ['geofenceName', 'assignedLivestock', 'unassignedAnimals']
  },

  [FEATURE_LIST.LIVESTOCK_LIST]: {
    basic: [...EXPENDED_DEFAULT_LIVESTOCK_LIST],
    trial: [...EXPENDED_DEFAULT_LIVESTOCK_LIST],
    advanced: [...EXPENDED_DEFAULT_LIVESTOCK_LIST]
  },

  [FEATURE_LIST.FARMS_LIST]: {
    basic: [
      'select',
      'farmId',
      'farmName',
      'farmAddress',
      'farmSize',
      'farmPerimeter',
      'farmHerd',
      'assignedLivestock'
    ],
    trial: [
      'select',
      'farmId',
      'farmName',
      'farmAddress',
      'farmSize',
      'farmPerimeter',
      'farmHerd',
      'assignedLivestock'
    ],
    advanced: [
      'select',
      'farmId',
      'farmName',
      'farmAddress',
      'farmSize',
      'farmPerimeter',
      'farmHerd',
      'assignedLivestock'
    ]
  },
  [FEATURE_LIST.FARM_DETAILS_FIELDS]: {
    basic: [
      'identifier',
      'name',
      'address',
      'house_number',
      'city',
      'postal_code',
      'country',
      'size',
      'circumference',
      'herd_numbers',
      'animals_count',
      'description'
    ],
    trial: [
      'identifier',
      'name',
      'address',
      'house_number',
      'city',
      'postal_code',
      'country',
      'size',
      'circumference',
      'herd_numbers',
      'animals_count',
      'description'
    ],
    advanced: [
      'identifier',
      'name',
      'address',
      'house_number',
      'city',
      'postal_code',
      'country',
      'size',
      'circumference',
      'herd_numbers',
      'animals_count',
      'description'
    ]
  },
  [FEATURE_LIST.GEOFENCES_LIST]: {
    trial: [
      'select',
      'index',
      'id',
      'geofenceName',
      'geofenceSize',
      'geofencePerimeter',
      'assignedLivestock',
      'assignedGroups'
    ],
    basic: [
      'select',
      'index',
      'id',
      'geofenceName',
      'geofenceSize',
      'geofencePerimeter',
      'assignedLivestock',
      'assignedGroups'
    ],
    advanced: [
      'select',
      'index',
      'id',
      'geofenceName',
      'geofenceSize',
      'geofencePerimeter',
      'assignedLivestock',
      'assignedGroups'
    ]
  },
  [FEATURE_LIST.SITES_LIST]: {
    trial: [
      'select',
      'id',
      'siteName',
      'sitetype',
      'siteGeofence',
      'siteDescription'
    ],
    basic: [
      'select',
      'id',
      'siteName',
      'sitetype',
      'siteGeofence',
      'siteDescription'
    ],
    advanced: [
      'select',
      'id',
      'siteName',
      'sitetype',
      'siteGeofence',
      'siteDescription'
    ]
  }
};

export const FEATURE_FILTERS = {
  [FEATURE_LIST.LIVESTOCK_COUNT_WIDGET]: {
    basic: ['geofenceName', 'assignedLivestock'],
    trial: ['geofenceName', 'assignedLivestock'],
    advanced: ['geofenceName', 'assignedLivestock']
  }
};
