import React from 'react';
import {
  Row,
  Col,
  Card,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalBody,
  ModalFooter,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment';
import get from 'lodash.get';

import axios from '../../axios';
import AnimalCard from '../../components/animals/animalCard';
import Alerts from '../../components/Alerts';
import Measures from '../../components/Measures';
import Breeding from '../../components/Breeding';
import Treatments from '../../components/Treatments';

import coming_soon from '../../assets/images/coming-soon.png';
import graph_coming_soon from '../../assets/images/graph-coming-soon.png';
import PrevNextButtons from '../../components/PrevNextButtons';
import Calendar from '../../components/Calendar';
import EventModal from '../../components/Modals/EventModal';
import { errorToastHandler } from '../../components/action_notifier';
import { EVENT_MODAL_MODE } from '../../constants/event';
import Tasks from '../../components/Tasks';
import TaskModal from '../../components/Modals/TaskModal';
import { TASK_MODAL_MODE } from '../../constants/task';
import { FEATURE_LIST } from '../../constants/subscription';
import FeatureProvider from '../../components/FeatureProvider';
import { connect } from 'react-redux';
import { getUsers } from '../../redux/actions/user';
import messages from '../../constants/messages';
import { NotificationService } from '../../services';
import {
  getNotifications,
  setNotificationList,
  setNotificationState
} from '../../redux/actions/notification';
import {
  getConfirmationModalDescription,
  isGeofenceMatchedWithPhysicalGroup
} from '../../helpers/livestock';
import ConfirmationModal from '../../components/Modals/ConfirmationModal';
import { ANIMAL_ERRORS } from '../../constants/livestock';

class Livestock extends React.Component {
  //Tabs
  constructor(props) {
    super(props);

    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      isOpenConfirmationModal: false,
      isConfirmed: false,
      confirmationModalDescription: '',
      delete_modal: false,
      delete_notif_modal: false,
      link_modal: false,
      edit_modal: false,
      animal: {},
      animal_edit: {},
      specie_opts: [
        { value: 'Bovine', label: 'Bovine' },
        { value: 'Ovine', label: 'Ovine' },
        { value: 'Caprine', label: 'Caprine' }
      ], // TODO: load list of species from api
      // TODO: load list of breeds from api
      sex_opts: [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' }
      ],
      breed_opts: [],
      farm_opts: [],
      farms: [],
      selected_sex: [],
      selected_farm: [],
      selected_specie: [],
      selected_breed: [],
      selected_geofences: [],
      dropdownOpen: false,
      activeTab: '1',
      selected_labels: [],
      labels_opts: [],
      type_opts: [],
      selected_type: {},
      geofence_opts: [],
      tabDropdownOpen: false,
      isTabInAction: false,
      tab_action: '',
      tabType: '',
      isOpenEventModal: false,
      isOpenTaskModal: false
    };
    this.onChange = this.onChange.bind(this);
    this.toggleButton = this.toggleButton.bind(this);
    this.getUsers = this.props.getUsers.bind(this);
    this.toggle = this.toggle.bind(this);
    this.tabToggleButton = this.tabToggleButton.bind(this);
    this.getLabels = this.getLabels.bind(this);
    this.handleFenceChange = this.handleFenceChange.bind(this);
    this.onLivestockChangeDate = this.onLivestockChangeDate.bind(this);
    this.addNewTabRows = this.addNewTabRows.bind(this);
    this.clearTabOperation = this.clearTabOperation.bind(this);
    this.getAnimal = this.getAnimal.bind(this);
    this.onAddEventClick = this.onAddEventClick.bind(this);
    this.onAddTaskClick = this.onAddTaskClick.bind(this);
    this.clearAllNotifications = this.clearAllNotifications.bind(this);
    this.snoozeAllNotifications = this.snoozeAllNotifications.bind(this);
    this.clearNotifications = this.clearNotifications.bind(this);
    this.snoozeNotifications = this.snoozeNotifications.bind(this);
    this.updateAnimalReq = this.updateAnimalReq.bind(this);
    this.editAnimal = this.editAnimal.bind(this);
    
    this.child = React.createRef();
    this.measureTab = React.createRef();
    this.breedingTab = React.createRef();
    this.treatmentTab = React.createRef();
  }

  onAddEventClick() {
    this.setState({ isOpenEventModal: true });
  }

  onAddTaskClick() {
    this.setState({ isOpenTaskModal: true });
  }

  async getLabels() {
    let response = await axios.get('labels');
    if (response.status === 200) {
      this.setState(
        {
          label_opts: response.data.map((x) => {
            return { value: x.id, label: x.name, color: x.colour };
          })
        },
        (e) => this.getAnimal()
      );
    }
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      if (this.state.isTabInAction) {
        this.tab_cancelAction();
      }
      this.setState({
        activeTab: tab
      });
    }

    // Only for Measures(3), Breeding(4), Treatments(5) tabs //
    let tabType = '';
    tab = parseInt(tab);
    if ([3, 4, 5].indexOf(tab) > -1) {
      tabType =
        document.getElementsByClassName('fancy-tabs')[0].children[tab - 1]
          .innerText;
    }
    this.setState({
      tabType: tabType
    });
  }
  onChange = (value, field) => {
    this.setState((state) => ({
      ...state,
      [field]: value
    }));
  };
  componentDidMount() {
    this.getBreeds();
    this.getTypes();
    this.getFarms();
    this.getUsers();
  }
  capitalize(string) {
    if (string === null) {
      return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  async getAnimal() {
    let animalId = this.props.match.params.id;
    let response = await axios.get('animals/' + animalId);
    let breed_s = {};

    response.data.breed = get(response.data, 'breed.display_name', null);
    response.data.specie = get(response.data, 'specie.name', null);
    if (response.data.breed != null) {
      breed_s = {
        value: response.data.breed.code,
        label: response.data.breed.display_name
      };
    }
    let specie_s = this.state.specie_opts.filter(
      (s) => s.value === response.data.specie
    )[0];
    let sex_s = this.state.sex_opts.filter(
      (s) => s.value === response.data.sex
    )[0];
    let farm_s = this.state.farm_opts.filter(
      (s) => s.value === response.data.farm_id
    )[0];
    let leabels_s = response.data.labels.map((x) => {
      return { label: x.name, value: x.id, color: x.colour };
    });
    let fences_s = response.data.geofences.map((x) => {
      return { label: x.name, value: x.id };
    });

    if (response.status === 200) {
      this.setState((state) => ({
        isConfirmed: false,
        animal: {
          ...response.data,
          dob_at_holder: response.data.dob_at
        },
        animal_edit: {
          ...response.data,
          dob_at_holder: response.data.dob_at
        },
        selected_sex: sex_s,
        selected_farm: farm_s,
        selected_specie: specie_s,
        selected_labels: leabels_s,
        selected_breed: breed_s,
        selected_geofences: fences_s,
        selected_type: {
          value: response.data.stock_type,
          label: this.capitalize(response.data.stock_type)
        }
      }));

      setTimeout(() => {
        if (
          this.props.match.params.type &&
          this.props.match.params.type === 'new' &&
          document.querySelector('.dropdown-menu .edit-livestock-btn')
        ) {
          document.querySelector('.dropdown-menu .edit-livestock-btn').click();
        }
      }, 1000);
    }
  }
  async editAnimal(isConfirmed = false) {
    ////////// RG - NEW LOGIC TO SAVE DATA //////////
    let livestockData = this.child.current.getLivestockData();
    if (livestockData.loss_date) {
      if (livestockData?.tag?.id) {
        errorToastHandler(messages.ANIMAL_UNLINK_TAG_REQUIRED);
        return false;
      }
    }

    if (livestockData.breed && livestockData.breed.id) {
      livestockData.breed = livestockData.breed.display_name;
    }
    livestockData.geofence_ids = livestockData.geofences.map((x) => {
      return x.id;
    });
    livestockData.label_ids = livestockData.labels.map((x) => {
      return x.id;
    });
    livestockData.group_ids = livestockData.label_ids
      ? livestockData.label_ids
      : [];
    livestockData.sire_id = livestockData.sire_id
      ? Number(livestockData.sire_id)
      : null;
    livestockData.dam_id = livestockData.dam_id
      ? Number(livestockData.dam_id)
      : null;
    livestockData.photo = null;
    if (livestockData.new_photo) {
      livestockData.photo = livestockData.new_photo;
    }
    if (
      !livestockData.eartag_management_id ||
      livestockData.eartag_management_id === '' ||
      !livestockData.farm_id ||
      livestockData.farm_id === '' ||
      !livestockData.specie ||
      livestockData.specie === '' ||
      !livestockData.sex ||
      livestockData.sex === ''
    ) {
      errorToastHandler(messages.FILL_MANDATORY_FIELDS);
      return false;
    } else {
      if (!isConfirmed) {
        const isGeofenceMatched =
          isGeofenceMatchedWithPhysicalGroup(livestockData);
        const description = getConfirmationModalDescription(isGeofenceMatched);
        if (description) {
          this.setState({
            confirmationModalDescription: description,
            isOpenConfirmationModal: true
          });
        } else this.updateAnimalReq(livestockData);
      } else this.updateAnimalReq(livestockData);
    }
  }
  async updateAnimalReq(livestockData) {
    let animalId = this.props.match.params.id;
    let response = await axios.put('animals/' + animalId, livestockData);
    if (response.status === 200) {
      if (this.props.match.params.type === 'new') {
        window.location.href = '/animal/' + animalId;
      } else {
        this.getAnimal();
        this.toggleModal('edit_modal');
      }
    }
  }
  toggleModal(modal) {
    // RG - OVERWRITE THE 'animal_edit' VARIABLE //
    this.setState((state) => ({
      ...state,
      animal_edit: JSON.parse(JSON.stringify(this.state.animal)),
      [modal]: !state[modal]
    }));
  }
  async deleteAnimal() {
    if (this.state.animal?.tag?.id) {
      errorToastHandler(messages.ANIMAL_UNLINK_TAG_REQUIRED);
    } else {
      let animalId = this.props.match.params.id;
      let response = await axios.delete('animals/' + animalId);
      if (response.status === 200) {
        this.props.history.push('/animals');
      }
    }
  }
  async getFarms() {
    let response = await axios.get('farms?with_details=true', {
      query: this.state.query || null
    });
    if (response.status === 200) {
      let mapped_farms = [];
      for (let farm of response.data) {
        mapped_farms.push({
          value: farm.id,
          label: farm.name,
          meta: {
            features: farm.geofences
          }
        });
      }
      this.setState(
        {
          farms: response.data,
          farm_opts: mapped_farms
        },
        (e) => this.getLabels()
      );
    }
  }
  async addLivestock() {
    try {
      let farm_id = 0;
      if (this.state.farms && this.state.farms.length) {
        farm_id = this.state.farms[0].id;
      }
      if (farm_id === 0) {
        errorToastHandler(messages.ANIMAL_FARM_REQUIRED);
      } else {
        let create_livestock = {
          eartag_management_id:
            'mgmt~tmp~' + String(Math.random()).replace('.', ''),
          sex: 'female',
          specie: 'Bovine',
          farm_id: farm_id
        };
        let response = await axios.post('animals', create_livestock);
        if (response.status === 200 && response.data.id) {
          window.location.href = '/animal/' + response.data.id + '/new';
        }
      }
    } catch (error) {}
  }
  onEditAnimalChange = (value, field) => {
    let nt = this.state.animal_edit;
    if (field === 'dob_at') {
      value = moment(value).format();
    }
    nt[field] = value;
    this.setState((state) => ({
      ...state,
      animal_edit: nt
    }));
  };
  async onEditAnimalFileChange(e) {
    let photo = await this.getBase64(e.target.files[0]);
    this.setState({
      animal_edit: {
        ...this.state.animal_edit,
        photo: photo
      }
    });
  }
  _arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
  getBase64(file) {
    return new Promise((resolve, reject) => {
      let document = '';
      let reader = new FileReader();
      document = reader.readAsArrayBuffer(file);
      reader.onload = function () {
        document = reader.result;
        let photo = `data:${file.type}; base64, ${this._arrayBufferToBase64(
          reader.result
        )}`;
        resolve(photo);
      }.bind(this);
      reader.onerror = function (error) {};
      return document;
    });
  }
  async getBreeds() {
    let response = await axios.get('breeds');
    if (response.status === 200) {
      this.setState({
        breed_opts: response.data.map((x) => {
          return { value: x.code, label: x.display_name };
        })
      });
    }
  }
  async getFences() {
    let response = await axios.get('geofences');
    if (response.status === 200) {
      this.setState({
        geofence_opts: response.data.map((x) => {
          return { value: x.id, label: x.name };
        })
      });
    }
  }
  async getTypes() {
    let response = await axios.get('/animals/stock-types');
    if (response.status === 200) {
      this.setState({
        type_opts: response.data
      });
    }
  }
  handleSpecieChange = (selectedOption) => {
    this.setState({
      selected_specie: selectedOption,
      new_animal: { ...this.state.new_animal, specie: selectedOption.value },
      animal_edit: { ...this.state.animal_edit, specie: selectedOption.value }
    });
  };
  handleSexChange = (selectedOption) => {
    this.setState({
      selected_sex: selectedOption,
      new_animal: { ...this.state.new_animal, sex: selectedOption.value },
      animal_edit: { ...this.state.animal_edit, sex: selectedOption.value }
    });
  };
  handleBreedChange = (selectedOption) => {
    this.setState({
      selected_breed: selectedOption,
      new_animal: { ...this.state.new_animal, breed: selectedOption.value }
    });
  };
  handleFarmChange = (selectedOption) => {
    this.setState({
      selected_farm: selectedOption,
      selected_geofences: [],
      new_animal: { ...this.state.new_animal, farm_id: selectedOption.value },
      animal_edit: { ...this.state.animal_edit, farm_id: selectedOption.value }
    });
  };
  handleLabelChange = (selectedOption) => {
    this.setState({
      selected_labels: selectedOption,
      new_animal: {
        ...this.state.new_animal,
        label_ids: selectedOption.map((x) => x.value)
      },
      animal_edit: {
        ...this.state.animal_edit,
        label_ids: selectedOption.map((x) => x.value)
      }
    });
  };
  handleFenceChange = (selectedOption) => {
    this.setState({
      selected_geofences: selectedOption,
      new_animal: {
        ...this.state.new_animal,
        geofence_ids: selectedOption.value
      },
      animal_edit: {
        ...this.state.animal_edit,
        geofence_ids: selectedOption.value
      }
    });
  };
  handleTypeChange = (selectedOption) => {
    this.setState({
      selected_type: selectedOption,
      new_animal: {
        ...this.state.new_animal,
        stock_type: selectedOption.value
      },
      animal_edit: {
        ...this.state.animal_edit,
        stock_type: selectedOption.value
      }
    });
  };
  onLivestockChangeDate(val, vl) {
    this.setState((state) => ({
      animal: {
        ...state.animal,
        dob_at: moment(val, 'MMMM d, yyyy h:mm aa').format(),
        dob_at_holder: new Date(val)
      },
      animal_edit: {
        ...state.animal_edit,
        dob_at: moment(val, 'MMMM d, yyyy h:mm aa').format(),
        dob_at_holder: new Date(val)
      }
    }));
  }
  toggleButton() {
    this.setState((state) => ({
      dropdownOpen: !state.dropdownOpen
    }));
  }
  tabToggleButton() {
    this.setState((state) => ({
      tabDropdownOpen: !state.tabDropdownOpen
    }));
  }
  getDate(date) {
    return moment(date).format('YYYY-MM-DD');
  }
  tab_addAction() {
    let tabRef;
    const activeTab = Number(this.state.activeTab);
    if (activeTab === 3) {
      tabRef = this.measureTab;
    } else if (activeTab === 4) {
      tabRef = this.breedingTab;
    } else if (activeTab === 5) {
      tabRef = this.treatmentTab;
    }
    if (tabRef) {
      if (
        Object.keys(tabRef.current.sigTblRef.current.state.editApply).length <=
        1
      ) {
        tabRef.current.addAction();
        this.setState({
          isTabInAction: !this.state.isTabInAction,
          tab_action: 'add'
        });
      } else if (
        Object.keys(tabRef.current.sigTblRef.current.state.editApply).length > 1
      ) {
        tabRef.current.toggleModal('bulk_modal');
      }
    }
  }
  tab_editAction() {
    let tabRef;
    const activeTab = Number(this.state.activeTab);
    if (activeTab === 3) {
      tabRef = this.measureTab;
    } else if (activeTab === 4) {
      tabRef = this.breedingTab;
    } else if (activeTab === 5) {
      tabRef = this.treatmentTab;
    }
    if (tabRef) {
      if (
        Object.keys(tabRef.current.sigTblRef.current.state.editApply).length
      ) {
        tabRef.current.editAction();
        this.setState({
          isTabInAction: !this.state.isTabInAction,
          tab_action: 'edit'
        });
      } else {
        errorToastHandler(messages.SELECTED_ITEM_REQUIRED);
      }
    }
  }
  tab_deleteAction() {
    let tabRef;
    const activeTab = Number(this.state.activeTab);

    if (activeTab === 3) {
      tabRef = this.measureTab;
    } else if (activeTab === 4) {
      tabRef = this.breedingTab;
    } else if (activeTab === 5) {
      tabRef = this.treatmentTab;
    }
    if (tabRef) {
      tabRef.current.deleteAction();
    }
  }
  tab_saveAction() {
    let tabRef;
    const activeTab = Number(this.state.activeTab);

    if (activeTab === 3) {
      tabRef = this.measureTab;
    } else if (activeTab === 4) {
      tabRef = this.breedingTab;
    } else if (activeTab === 5) {
      tabRef = this.treatmentTab;
    }
    if (tabRef) {
      tabRef.current.saveAction();
    }
  }
  tab_cancelAction() {
    let tabRef;
    const activeTab = Number(this.state.activeTab);

    if (activeTab === 3) {
      tabRef = this.measureTab;
    } else if (activeTab === 4) {
      tabRef = this.breedingTab;
    } else if (activeTab === 5) {
      tabRef = this.treatmentTab;
    }
    if (tabRef) {
      tabRef.current.cancelAction();
      this.clearTabOperation();
    }
  }
  addNewTabRows() {
    this.setState({
      isTabInAction: !this.state.isTabInAction,
      tab_action: 'add'
    });
  }
  clearTabOperation() {
    this.setState({
      isTabInAction: !this.state.isTabInAction,
      tab_action: this.state.tab_action
    });
  }

  async clearAllNotifications() {
    const response = await NotificationService.clearAllNotifications();
    if (response.status == 200) {
      this.props.setNotificationList([]);
      this.props.getNotifications();
    }
  }

  async snoozeAllNotifications() {
    const response = await NotificationService.snoozeAllNotifications();
    if (response.status == 200) {
      this.props.getNotifications();
    }
  }

  async clearNotifications() {
    const selectedNotifs = this.props.notification.selectedAlerts;
    if (selectedNotifs.length) {
      const response = await axios.put('notifications/clear', {
        ids: selectedNotifs
      });
      if (response.status == 200) {
        const updatedNotificationList = this.props.notification.list.filter(
          (notif) => !selectedNotifs.includes(notif.id)
        );
        this.props.setNotificationList(updatedNotificationList);
        this.props.setNotificationState({ selectedAlerts: [] });
        this.props.getNotifications();
      }
    }
  }
  async snoozeNotifications() {
    if (this.props.notification.selectedAlerts.length) {
      const response = await axios.put('notifications/snooze', {
        ids: this.props.notification.selectedAlerts,
        force: true,
        seconds: 86400
      });
      if (response.status == 200) {
        this.props.setNotificationState({ selectedAlerts: [] });
        this.props.getNotifications();
      }
    }
  }

  render() {
    if (this.state.animal.id === null) {
      return null;
    }
    return (
      <div>
        <Row className="">
          <Col xs="6" md="6" lg="6">
            <h4 className="">
              Livestock{' '}
              {this.state.animal.identifier ||
                this.state.animal.eartag_official_id}
            </h4>
            <Breadcrumb>
              <BreadcrumbItem>
                <a
                  href="javascript:;"
                  onClick={(e) => this.props.history.push('/animals')}
                >
                  {' '}
                  List of Livestock
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                Livestock{' '}
                {this.state.animal.identifier ||
                  this.state.animal.eartag_official_id}
              </BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col xs="6" md="6" lg="6">
            <PrevNextButtons
              history={this.props.history}
              nextId={this.state.animal.next_id}
              prevId={this.state.animal.prev_id}
              path="animal"
            />
            {!this.state.edit_modal && (
              <ButtonDropdown
                className="float-right m-l-10"
                isOpen={this.state.dropdownOpen}
                toggle={this.toggleButton}
              >
                <DropdownToggle className="remove-mr" caret>
                  Actions
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    className="float-right"
                    onClick={(e) => this.props.history.push('/animal_new')}
                  >
                    Add livestock
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => this.toggleModal('edit_modal')}
                    className="edit-livestock-btn"
                  >
                    Edit livestock
                  </DropdownItem>
                  {this.state.animal?.deleted_at ||
                  this.state.animal?.loss_date ? null : (
                    <DropdownItem
                      onClick={() => this.toggleModal('delete_modal')}
                    >
                      Archive livestock
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </ButtonDropdown>
            )}

            {this.state.edit_modal && (
              <div>
                <Button
                  color="primary"
                  className="float-right"
                  onClick={(e) => this.editAnimal()}
                >
                  Save
                </Button>
                <Button
                  className="outline float-right"
                  onClick={() => this.toggleModal('edit_modal')}
                >
                  Cancel
                </Button>
              </div>
            )}

            {this.state.animal.tag && (
              <FeatureProvider name={FEATURE_LIST.TRACK_ANIMAL}>
                <Button
                  className="float-right"
                  color="primary"
                  onClick={(e) =>
                    this.props.history.push('/tracking/' + this.state.animal.id)
                  }
                >
                  <i className="fas fa-pen-square"></i> Track Livestock
                </Button>
              </FeatureProvider>
            )}
          </Col>
        </Row>

        <AnimalCard
          animal={this.state.animal_edit}
          history={this.props.history}
          onChange={this.onChange}
          toggleModal={this.toggleModal}
          editMode={this.state.edit_modal}
          ref={this.child}
          actionType={this.props.match.params.type}
        ></AnimalCard>

        <Card>
          <Row className="pad-10 pb-0">
            <Col sm="6" className="mb-0">
              <h5>Livestock History</h5>
            </Col>
            <Col sm="6">
              {this.state.tabType !== '' && !this.state.isTabInAction && (
                <ButtonDropdown
                  className="float-right m-l-10 tab-action-btn"
                  isOpen={this.state.tabDropdownOpen}
                  toggle={this.tabToggleButton}
                >
                  <DropdownToggle caret className="btn-sm">
                    Actions
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={(e) => this.tab_addAction()}>
                      Add {this.state.tabType?.toLowerCase()}
                    </DropdownItem>
                    <DropdownItem onClick={(e) => this.tab_editAction()}>
                      Edit {this.state.tabType?.toLowerCase()}
                    </DropdownItem>
                    <DropdownItem onClick={(e) => this.tab_deleteAction()}>
                      Archive {this.state.tabType?.toLowerCase()}
                    </DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
              )}
              {this.state.activeTab === '1' && !this.state.isTabInAction && (
                <ButtonDropdown
                  className="float-right m-l-10 tab-action-btn"
                  isOpen={this.state.tabDropdownOpen}
                  toggle={this.tabToggleButton}
                >
                  <DropdownToggle caret className="btn-sm">
                    Actions
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={this.onAddEventClick}>
                      Add event
                    </DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
              )}
              {this.state.activeTab === '2' && !this.state.isTabInAction && (
                <ButtonDropdown
                  className="float-right m-l-10 tab-action-btn"
                  isOpen={this.state.tabDropdownOpen}
                  toggle={this.tabToggleButton}
                >
                  <DropdownToggle caret className="btn-sm">
                    Actions
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={this.onAddTaskClick}>
                      Add task
                    </DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
              )}
              {this.state.activeTab === '7' && !this.state.isTabInAction && (
                <ButtonDropdown
                  className="float-right m-l-10 tab-action-btn"
                  isOpen={this.state.tabDropdownOpen}
                  toggle={this.tabToggleButton}
                >
                  <DropdownToggle caret>Actions</DropdownToggle>
                  <DropdownMenu>
                    {this.props.notification.selectedAlerts.length ? (
                      <DropdownItem
                        color="primary"
                        className="float-right"
                        onClick={() => this.clearNotifications()}
                      >
                        Clear notifications
                      </DropdownItem>
                    ) : null}
                    {this.props.notification.selectedAlerts.length ? (
                      <DropdownItem onClick={() => this.snoozeNotifications()}>
                        Snooze notifications
                      </DropdownItem>
                    ) : null}

                    <DropdownItem onClick={() => this.clearAllNotifications()}>
                      Clear all new notifications
                    </DropdownItem>
                    <DropdownItem onClick={() => this.snoozeAllNotifications()}>
                      Snooze all new notifications
                    </DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
              )}

              {this.state.tabType !== '' && this.state.isTabInAction && (
                <div>
                  <Button
                    color="primary"
                    className="float-right"
                    onClick={(e) => this.tab_saveAction()}
                  >
                    Save
                  </Button>
                  <Button
                    className="outline float-right"
                    onClick={(e) => this.tab_cancelAction()}
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Nav tabs className="fancy-tabs">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === '1'
                    })}
                    onClick={() => {
                      this.toggle('1');
                    }}
                  >
                    Calendar
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === '2'
                    })}
                    onClick={() => {
                      this.toggle('2');
                    }}
                  >
                    Tasks
                  </NavLink>
                </NavItem>
                <FeatureProvider name={FEATURE_LIST.MEASURES_TAB}>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === '3'
                      })}
                      onClick={() => {
                        this.toggle('3');
                      }}
                    >
                      Measures
                    </NavLink>
                  </NavItem>
                </FeatureProvider>

                <FeatureProvider name={FEATURE_LIST.BREEDING_TAB}>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === '4'
                      })}
                      onClick={() => {
                        this.toggle('4');
                      }}
                    >
                      Breeding
                    </NavLink>
                  </NavItem>
                </FeatureProvider>
                <FeatureProvider name={FEATURE_LIST.TREATMENT_TAB}>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === '5'
                      })}
                      onClick={() => {
                        this.toggle('5');
                      }}
                    >
                      Treatments
                    </NavLink>
                  </NavItem>
                </FeatureProvider>

                {/* <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === '6'
                    })}
                    onClick={() => {
                      this.toggle('6');
                    }}
                  >
                    Behaviour
                  </NavLink>
                </NavItem> */}
                <FeatureProvider name={FEATURE_LIST.NOTIFICATION_LIST}>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === '7'
                      })}
                      onClick={() => {
                        this.toggle('7');
                      }}
                    >
                      Notifications
                    </NavLink>
                  </NavItem>
                </FeatureProvider>

                {/* <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === '7'
                    })}
                    onClick={() => {
                      this.toggle('7');
                    }}
                  >
                    KPI's
                  </NavLink>
                </NavItem> */}
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="12" lg="12">
              <TabContent className="no-bg" activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  <Calendar
                    step={60}
                    queryParameters={{
                      relationId: this.props.match.params.id,
                      relationType: 'livestock',
                      withDetails: true
                    }}
                  />
                </TabPane>
                <TabPane tabId="2">
                  <Tasks
                    queryParameters={{
                      relationId: this.props.match.params.id,
                      relationType: 'livestock',
                      withDetails: true
                    }}
                  />
                </TabPane>
                <FeatureProvider name={FEATURE_LIST.MEASURES_TAB}>
                  <TabPane tabId="3">
                    <Measures
                      ref={this.measureTab}
                      type="single"
                      animal_id={this.props.match.params.id}
                      tab_action={this.state.tab_action}
                      addNewTabRows={this.addNewTabRows}
                      clearTabOperation={this.clearTabOperation}
                      onSave={this.getAnimal}
                    />
                  </TabPane>
                </FeatureProvider>

                <FeatureProvider name={FEATURE_LIST.BREEDING_TAB}>
                  <TabPane tabId="4">
                    <Breeding
                      ref={this.breedingTab}
                      type="single"
                      animal_id={this.props.match.params.id}
                      tab_action={this.state.tab_action}
                      addNewTabRows={this.addNewTabRows}
                      clearTabOperation={this.clearTabOperation}
                      onSave={this.getAnimal}
                    />
                  </TabPane>
                </FeatureProvider>

                <FeatureProvider name={FEATURE_LIST.TREATMENT_TAB}>
                  <TabPane tabId="5">
                    <Treatments
                      ref={this.treatmentTab}
                      type="single"
                      animal_id={this.props.match.params.id}
                      tab_action={this.state.tab_action}
                      addNewTabRows={this.addNewTabRows}
                      clearTabOperation={this.clearTabOperation}
                      onSave={this.getAnimal}
                    />
                  </TabPane>
                </FeatureProvider>

                <TabPane tabId="6">
                  <div className="text-center">
                    <img src={graph_coming_soon} className="img-fluid ieFix" />
                  </div>
                </TabPane>

                <FeatureProvider name={FEATURE_LIST.NOTIFICATION_LIST}>
                  <TabPane tabId="7">
                    <Alerts
                      type="full"
                      columnType={2}
                      query={{ animal_ids: [this.props.match.params.id] }}
                    ></Alerts>
                  </TabPane>
                </FeatureProvider>

                <TabPane tabId="8">
                  <div className="text-center">
                    <br />
                    <img src={coming_soon} className="img-fluid ieFix" />
                    <br />
                    <br />
                    <br />
                  </div>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Card>

        <Modal
          isOpen={this.state.delete_modal}
          className={this.props.className}
        >
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>Archive animal</b>
            </h5>
            <br />
            <br />
            <br />
            Are you sure you want to archive this animal? This action cannot be
            undone.
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => this.toggleModal('delete_modal')}
            >
              Cancel
            </Button>{' '}
            <Button color="danger" onClick={() => this.deleteAnimal()}>
              Archive
            </Button>
          </ModalFooter>
        </Modal>
        <EventModal
          onSubmit={() => {
            this.setState({ isOpenEventModal: false });
          }}
          isOpen={this.state.isOpenEventModal}
          onCancel={() => {
            this.setState({ isOpenEventModal: false });
          }}
          mode={EVENT_MODAL_MODE.CREATE}
          config={{}}
        />
        <TaskModal
          onSubmit={() => {
            this.setState({ isOpenTaskModal: false });
          }}
          isOpen={this.state.isOpenTaskModal}
          onCancel={() => {
            this.setState({ isOpenTaskModal: false });
          }}
          mode={TASK_MODAL_MODE.CREATE}
        />
        <ConfirmationModal
          isOpen={this.state.isOpenConfirmationModal}
          description={this.state.confirmationModalDescription}
          onCancel={() => {
            this.setState({ isOpenConfirmationModal: false });
          }}
          onSubmit={() => {
            this.editAnimal(true);
            this.setState({
              isConfirmed: true,
              isOpenConfirmationModal: false
            });
          }}
          submitText={'Yes'}
          submitButtonType={'primary'}
        />
      </div>
    );
  }
}

export default connect(
  (state) => state,
  (dispatch) => ({
    getUsers: () => {
      return dispatch(getUsers());
    },
    getNotifications: () => {
      return dispatch(getNotifications());
    },
    setNotificationList: (data) => {
      dispatch(setNotificationList(data));
    },
    setNotificationState: (payload) => {
      dispatch(setNotificationState(payload));
    }
  })
)(Livestock);
