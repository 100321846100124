export const LIVESTOCK_MAJOR_MEASURE_TYPES_FILTER = [
  { text: '', value: '' },
  {
    text: 'Weight',
    value: 'Weight'
  },
  {
    text: 'Size',
    value: 'Size'
  },
  {
    text: 'Financial',
    value: 'Financial'
  },
  {
    text: 'Age',
    value: 'Age'
  },
  {
    text: 'EBV - Bull',
    value: 'EBV - Bull'
  },
  {
    text: 'EBV - Cows',
    value: 'EBV - Cows'
  }
];

export const LIVESTOCK_MINOR_MEASURE_TYPES_FILTER = [
  { text: '', value: '' },
  {
    text: 'Calving (Calf)',
    value: 'Calving (Calf)'
  },
  {
    text: 'Weaning (Calf)',
    value: 'Weaning (Calf)'
  },
  {
    text: 'Yearling and 18-month weight',
    value: 'Yearling and 18-month weight'
  },
  {
    text: 'Mature',
    value: 'Mature'
  },
  {
    text: 'Post Calving (Cow)',
    value: 'Post Calving (Cow)'
  },
  {
    text: 'Post Weaning (Cow)',
    value: 'Post Weaning (Cow)'
  },
  {
    text: 'Purchase',
    value: 'Purchase'
  },
  {
    text: 'Sale',
    value: 'sale'
  },
  {
    text: 'Slaughter',
    value: 'Slaughter'
  },
  {
    text: 'Regulatory',
    value: 'Regulatory'
  },
  {
    text: 'Management',
    value: 'Management'
  },
  {
    text: 'Withers Height',
    value: 'Withers Height'
  },
  {
    text: 'Hip Height',
    value: 'Hip Height'
  },
  {
    text: 'Length',
    value: 'Length'
  },
  {
    text: 'Girth',
    value: 'Girth'
  },
  {
    text: 'Head',
    value: 'Head'
  },
  {
    text: 'Neck',
    value: 'Neck'
  },
  {
    text: 'Purchase Price (live)',
    value: 'Purchase Price (live)'
  },
  {
    text: 'Sale Price (live)',
    value: 'Sale Price (live)'
  },
  {
    text: 'Sale Price (Carcass/Slaughter)',
    value: 'Sale Price (Carcass/Slaughter)'
  },
  {
    text: 'Tax',
    value: 'Tax'
  },
  {
    text: 'Veld Conversion Rate',
    value: 'Veld Conversion Rate'
  },
  {
    text: 'Pelvic Score',
    value: 'Pelvic Score'
  },
  {
    text: 'Growth During Test Period',
    value: 'Growth During Test Period'
  },
  {
    text: 'Growth During Summer Period',
    value: 'Growth During Summer Period'
  },
  {
    text: 'Temperament Score',
    value: 'Temperament Score'
  },
  {
    text: 'Weaner',
    value: 'Weaner'
  },
  {
    text: 'Muscling Score',
    value: 'Muscling Score'
  },
  {
    text: 'Scrotal',
    value: 'Scrotal'
  },
  {
    text: 'Docility',
    value: 'Docility'
  },
  {
    text: 'Hair',
    value: 'Hair'
  },
  {
    text: 'Economic Index',
    value: 'Economic Index'
  },
  {
    text: 'Feetlot Index',
    value: 'Feetlot Index'
  },
  {
    text: 'Calving Ease (Direct)',
    value: 'Calving Ease (Direct)'
  },
  {
    text: 'Birth Weight',
    value: 'Birth Weight'
  },
  {
    text: '200 Day Growth',
    value: '200 Day Growth'
  },
  {
    text: '400 Day Growth',
    value: '400 Day Growth'
  },
  {
    text: '600 Day Growth',
    value: '600 Day Growth'
  },
  {
    text: 'Milk',
    value: 'Milk'
  },
  {
    text: 'SC',
    value: 'SC'
  },
  {
    text: 'DC',
    value: 'DC'
  },
  {
    text: 'Eye Muscle Area',
    value: 'Eye Muscle Area'
  },
  {
    text: 'Intramuscular Fat',
    value: 'Intramuscular Fat'
  },
  {
    text: 'Retail Beef Yield',
    value: 'Retail Beef Yield'
  },
  {
    text: 'Calving intervals',
    value: 'Calving intervals'
  },
  {
    text: 'Calving Ease',
    value: 'Calving Ease'
  }
];

export const ANIMAL_ERRORS = {
  ASSIGNED_TO_DIFFERENT_GEOGENCE:
    'One or more livestock is in a group that is assigned to a different geofence. Do you want to assign this livestock to a different geofence? If you select Yes, this livestock will be removed from the group and assigned to the new geofence.',
  NOT_ASSIGNED_TO_GEOGENCE:
    'One or more livestock is in a group that is not assigned to a geofence. Do you want to assign this livestock to a new and different geofence? If you select Yes, this livestock will be removed from this group and assigned to the new geofence.',
  UPDATE_GEOFENCE_TO_GROUP:
    'One or more of the selected livestock is already assigned to a different geofence. Do you want to update the geofence for this livestock to be the same as the geofence for the group?'
};
