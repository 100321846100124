import React, { useEffect, useState } from 'react';
import { Col, FormGroup } from 'reactstrap';
import axios from '../../../axios';
import Select from 'react-select';

const SiteTypeFilters = (props) => {
  const [siteTypes, setSiteTypes] = useState([]);
  const fetchData = async () => {
    let { data } = await axios.get('sites/types');
    setSiteTypes(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="filter-block labels">
      <p>Site type filter</p>
      <FormGroup row>
        <Col sm="12" lg="12">
          <Select
            name="type"
            value={props.selected}
            onChange={(value) => props.onChange(value, 'site_types')}
            options={siteTypes}
            isMulti={true}
            isSearchable={true}
          ></Select>
        </Col>
      </FormGroup>
    </div>
  );
};

export default SiteTypeFilters;
