import axios from '../../axios';

const service = {
  async updateAnimals(payload) {
    return await axios({
      method: 'put',
      url: '/animals',
      data: payload
    });
  }
};

export default service;
