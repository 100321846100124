'use strict';

import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Col,
  Input
} from 'reactstrap';
import axios from '../axios';
import moment from 'moment';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import SigTable from './sig-table-component/sig-table';
import { errorToastHandler } from './action_notifier';
import messages from '../constants/messages';

class Treatments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      props: this.props,
      selectedIds: [],
      bulk_modal: false,
      data: [],
      newRows: [],
      tableData: {},
      treatmentTypes: [],
      treatmentUOM: [],
      treatmentForIds: [],
      common_data: {
        treatment_type_id: [],
        treatment_date: null,
        symptoms: '',
        is_positive: '0',
        treatment: '',
        medication: '',
        dosage_uom: [],
        dosage_amount: '0',
        vet: '',
        comments: '',
        cost: '0',
        repeat_date: '',
        review_date: '',
        result: ''
      },
      filters: {
        farms: [],
        geofences: [],
        labels: []
      },
      query: ''
    };
    this.getTreatmentTypes = this.getTreatmentTypes.bind(this);
    this.getTreatmentUOM = this.getTreatmentUOM.bind(this);
    this.getData = this.getData.bind(this);
    this.onDeleteHandler = this.onDeleteHandler.bind(this);
    this.onSelectHandler = this.onSelectHandler.bind(this);
    this.onSaveHandler = this.onSaveHandler.bind(this);
    this.perpairTableData = this.perpairTableData.bind(this);
    this.addAction = this.addAction.bind(this);
    this.editAction = this.editAction.bind(this);
    this.deleteAction = this.deleteAction.bind(this);
    this.onEditChange = this.onEditChange.bind(this);
    this.resetCommonTreatment = this.resetCommonTreatment.bind(this);
    this.createBulkRows = this.createBulkRows.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.clearCache = this.clearCache.bind(this);
    this.validateCommonData = this.validateCommonData.bind(this);

    this.sigTblRef = React.createRef();
  }
  async componentDidMount() {
    this.getTreatmentTypes();

    // ITS VALUE WILL COME FROM LIVESTOCK LISTING PAGE //
    let treatmentForIds = localStorage.getItem('treatmentForIds') || '[]';
    treatmentForIds = JSON.parse(treatmentForIds);
    this.setState({
      treatmentForIds: treatmentForIds
    });
    if (treatmentForIds.length) {
      this.toggleModal('bulk_modal');
    }
    localStorage.removeItem('treatmentForIds');
  }
  async getTreatmentTypes() {
    let response = await axios.get('treatment_types');
    if (response.status == 200) {
      this.setState(
        {
          treatmentTypes: response.data.map((x) => {
            return { label: x.treatment_type_name, value: x.id };
          })
        },
        (e) => this.getTreatmentUOM()
      );
    }
  }
  async getTreatmentUOM() {
    let response = await axios.get('treatment_uom');
    if (response.status == 200) {
      this.setState(
        {
          treatmentUOM: response.data.map((x) => {
            return { label: x.uom_description, value: x.uom_code };
          })
        },
        (e) => this.getData()
      );
    }
  }
  async getData() {
    let response;
    if (this.props.animal_id) {
      response = await axios.get('treatment/index', {
        params: {
          livestock_ids: this.props.animal_id,
          treatment_ids: this.props.animal_id
        }
      });
    } else {
      response = await axios.get('treatment/index', {
        params: {
          query: this.state.query,
          farm_ids: this.state.filters?.farms?.map((x) => x.value),
          label_ids: this.state.filters?.labels?.map((x) => x.value),
          geofence_ids: this.state.filters?.geofences?.map((x) => x.value)
        }
      });
    }
    if (response.status == 200) {
      this.setState(
        {
          data: response.data.map((x) => {
            x['key'] = x.id;
            x['livestock_sex'] = this.capitalize(x.livestock_sex);
            x['repeat_date'] = x.repeat_date || '';
            x['review_date'] = x.review_date || '';
            return x;
          })
        },
        (e) => this.perpairTableData()
      );
    }
  }
  capitalize(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
  }
  perpairTableData() {
    // PERPAIR COLOUMNS //
    const columns = [
      {
        Header: 'Treatment ID',
        accessor: 'treatment_identifier',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: false,
          isEditable: false,
          dataType: 'text_field',
          isRequired: false
        },
        sortMethod: (a, b) => {
          if (!a) return -1;
          return a?.localeCompare(b);
        },
        minWidth: 120
      },
      {
        Header: 'Treatment Type *',
        accessor: 'treatment_type_id',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'options',
          isRequired: true
        },
        sortMethod: (a, b) => {
          let aText = a.filter((x) => x.isSelected == true);
          aText = aText.length ? aText[0]['label'] : '';

          let bText = b.filter((x) => x.isSelected == true);
          bText = bText.length ? bText[0]['label'] : '';

          return aText.localeCompare(bText);
        },
        minWidth: 150,
        filterMethod: (filter, row) => {
          if (filter.value === null) {
            return true;
          } else {
            return row._original.treatment_type_name === filter.value;
          }
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) => {
              onChange(event.target.value);
            }}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}
          >
            <option value={null}></option>
            {this.state?.common_data?.treatment_type_id.map((type) => {
              return <option value={type.label}>{type.label}</option>;
            })}
          </select>
        )
      },
      {
        Header: 'Treatment Date *',
        accessor: 'treatment_date',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'date',
          dateFormat: 'DD/MM/YYYY',
          isRequired: true,
          onChange: (data) => {
            let showAlert = false;
            if (
              data['treatment_date'] !== '' &&
              data['repeat_date'] !== '' &&
              moment(data['treatment_date']).valueOf() >
                moment(data['repeat_date']).valueOf()
            ) {
              data['repeat_date'] = '';
              showAlert = true;
            }
            if (
              data['treatment_date'] !== '' &&
              data['review_date'] !== '' &&
              moment(data['treatment_date']).valueOf() >
                moment(data['review_date']).valueOf()
            ) {
              data['review_date'] = '';
              showAlert = true;
            }
            if (showAlert) {
              errorToastHandler(
                messages.TREATMENT_REVIEW_REPEAT_DATE_AFTER_TREATMENT_DATE
              );
            }
            if (data['treatment_date'] === '') {
              data['repeat_date'] = '';
              data['review_date'] = '';
            }
          }
        },
        sortMethod: (a, b) => {
          if (!a) return -1;
          return a?.localeCompare(b);
        },
        minWidth: 160
      },
      {
        Header: 'Symptoms',
        accessor: 'symptoms',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'text_field',
          isRequired: false
        },
        sortMethod: (a, b) => {
          if (!a) return -1;
          return a?.localeCompare(b);
        },
        minWidth: 120
      },
      {
        Header: 'Is Positive',
        accessor: 'is_positive',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'bool',
          isRequired: false,
          Cell: (data) => {
            return data['is_positive'] == 1 ? 'Yes' : 'No';
          }
        },
        sortMethod: (a, b) => {
          if (!a) return -1;
          return a - b;
        },
        minWidth: 120
      },
      {
        Header: 'Treatment *',
        accessor: 'treatment',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'text_field',
          isRequired: true
        },
        sortMethod: (a, b) => {
          if (!a) return -1;
          return a?.localeCompare(b);
        },
        minWidth: 140
      },
      {
        Header: 'Medication',
        accessor: 'medication',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'text_field',
          isRequired: false
        },
        sortMethod: (a, b) => {
          if (!a) return -1;
          return a?.localeCompare(b);
        },
        minWidth: 120
      },
      {
        Header: 'Dosage UoM',
        accessor: 'dosage_uom',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'options',
          isRequired: false
        },
        sortMethod: (a, b) => {
          let aText = a.filter((x) => x.isSelected == true);
          aText = aText.length ? aText[0]['label'] : '';

          let bText = b.filter((x) => x.isSelected == true);
          bText = bText.length ? bText[0]['label'] : '';

          return aText.localeCompare(bText);
        },
        minWidth: 140
      },
      {
        Header: 'Dosage Amount',
        accessor: 'dosage_amount',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'number',
          placeholder: '0',
          isRequired: false
        },
        sortMethod: (a, b) => {
          if (!a) return -1;
          return a - b;
        },
        minWidth: 150
      },
      {
        Header: 'Vet',
        accessor: 'vet',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'text_field',
          isRequired: false
        },
        sortMethod: (a, b) => {
          if (!a) return -1;
          return a?.localeCompare(b);
        },
        minWidth: 140
      },
      {
        Header: 'Comments',
        accessor: 'comments',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'text_field',
          isRequired: false
        },
        sortMethod: (a, b) => {
          if (!a) return -1;
          return a?.localeCompare(b);
        },
        minWidth: 180
      },
      {
        Header: 'Cost',
        accessor: 'cost',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'number',
          placeholder: '0',
          isRequired: false
        },
        sortMethod: (a, b) => {
          if (!a) return -1;
          return a - b;
        },
        minWidth: 140
      },
      {
        Header: 'Repeat Date',
        accessor: 'repeat_date',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'date',
          dateFormat: 'DD/MM/YYYY',
          isRequired: false,
          onChange: (data) => {
            if (
              (data['treatment_date'] === '' && data['repeat_date'] !== '') ||
              moment(data['treatment_date']).valueOf() >
                moment(data['repeat_date']).valueOf()
            ) {
              data['repeat_date'] = '';
              errorToastHandler(
                messages.TREATMENT_REPEAT_DATE_AFTER_TREATMENT_DATE
              );
            }
          }
        },
        sortMethod: (a, b) => {
          if (!a) return -1;
          return a?.localeCompare(b);
        },
        minWidth: 140
      },
      {
        Header: 'Review Date',
        accessor: 'review_date',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'date',
          dateFormat: 'DD/MM/YYYY',
          isRequired: false,
          onChange: (data) => {
            if (
              (data['treatment_date'] === '' && data['review_date'] !== '') ||
              moment(data['treatment_date']).valueOf() >
                moment(data['review_date']).valueOf()
            ) {
              data['review_date'] = '';
              errorToastHandler(
                messages.TREATMENT_REVIEW_DATE_AFTER_TREATMENT_DATE
              );
            }
          }
        },
        sortMethod: (a, b) => {
          if (!a) return -1;
          return a?.localeCompare(b);
        },
        minWidth: 140
      },
      {
        Header: 'Result',
        accessor: 'result',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'text_field',
          isRequired: false
        },
        sortMethod: (a, b) => {
          if (!a) return -1;
          return a?.localeCompare(b);
        },
        minWidth: 180
      }
    ];

    // LIVESTOCK COLUMNS //
    const livestockColumns = [
      {
        id: 'identifier',
        Header: 'Livestock ID',
        accessor: 'identifier',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: false,
          isEditable: false,
          dataType: 'text_field',
          isRequired: false,
          Cell: (data) => {
            return <a href={'/animal/' + data['id']}>{data['identifier']}</a>;
          }
        },
        sortMethod: (a, b) => {
          if (!a) return -1;
          return a.localeCompare(b);
        },
        minWidth: 120
      },
      {
        Header: 'Mgmt Tag ID',
        accessor: 'eartag_management_id',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: false,
          isEditable: false,
          dataType: 'text_field',
          isRequired: false
        },
        sortMethod: (a, b) => {
          if (!a) return -1;
          return a.localeCompare(b);
        },
        minWidth: 130
      },
      {
        Header: 'Sex',
        accessor: 'sex',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: false,
          isEditable: false,
          dataType: 'text_field',
          isRequired: false
        },
        sortMethod: (a, b) => {
          if (!a) return -1;
          return a.localeCompare(b);
        }
      },
      {
        Header: 'Colour',
        accessor: 'colour',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: false,
          isEditable: false,
          dataType: 'text_field',
          isRequired: false
        },
        sortMethod: (a, b) => {
          if (!a) return -1;
          return a.localeCompare(b);
        },
        minWidth: 110
      },
      {
        Header: 'Breed',
        accessor: 'breed',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: false,
          isEditable: false,
          dataType: 'text_field',
          isRequired: false
        },
        sortMethod: (a, b) => {
          if (!a) return -1;
          return a?.localeCompare(b);
        },
        minWidth: 140
      }
    ];
    // ADD INTO LIST VIEW //
    if (typeof this.props.animal_id === undefined || !this.props.animal_id) {
      livestockColumns.reverse();
      livestockColumns.map((x) => columns.unshift(x));
    }

    // PERPAIR ROW DATA //
    // CREATING ARRAY OF TREATMENT TYPE, SET VALUE OF UOM //
    let rowData = this.state.data.map((row) => {
      let treatmentTypes = JSON.parse(
        JSON.stringify(this.state.treatmentTypes)
      );
      row.treatment_type_id = treatmentTypes.map((x) => {
        x.isSelected = x.value === row.treatment_type_id;
        return x;
      });

      let treatmentUOM = JSON.parse(JSON.stringify(this.state.treatmentUOM));
      row.dosage_uom = treatmentUOM.map((x) => {
        x.isSelected = x.value === row.dosage_uom;
        return x;
      });

      return row;
    });

    this.setState(
      (state) => ({
        ...state,
        tableData: {
          column: columns,
          data: rowData
        }
      }),
      (e) => this.resetCommonTreatment()
    );
  }
  addAction() {
    this.addRows();
  }
  addRows() {
    // GET DYNAMIC COLOUMNS FROM TABLE VIEW //
    let newrowKeys = {};
    this.state.tableData.column.map((obj) => {
      if (['options', 'multi_select'].indexOf(obj.additional.dataType) > -1) {
        newrowKeys[obj.accessor] = [];
      } else if (['bool'].indexOf(obj.additional.dataType) > -1) {
        newrowKeys[obj.accessor] = false;
      } else {
        newrowKeys[obj.accessor] = '';
      }
    });

    // ADDING NEW ROW ON TOP //
    if (Object.keys(newrowKeys).length) {
      // ASSIGN DEFAULT VALUES //
      newrowKeys['isEditable'] = true;
      newrowKeys['treatment_type_id'] = JSON.parse(
        JSON.stringify(this.state.common_data['treatment_type_id'])
      );
      newrowKeys['dosage_uom'] = JSON.parse(
        JSON.stringify(this.state.common_data['dosage_uom'])
      );
      newrowKeys['treatment_date'] = this.state.common_data['treatment_date'];
      newrowKeys['symptoms'] = this.state.common_data['symptoms'];
      newrowKeys['is_positive'] = this.state.common_data['is_positive'];
      newrowKeys['treatment'] = this.state.common_data['treatment'];
      newrowKeys['medication'] = this.state.common_data['medication'];
      newrowKeys['dosage_amount'] = this.state.common_data['dosage_amount'];
      newrowKeys['vet'] = this.state.common_data['vet'];
      newrowKeys['comments'] = this.state.common_data['comments'];
      newrowKeys['cost'] = this.state.common_data['cost'];
      newrowKeys['repeat_date'] = this.state.common_data['repeat_date'];
      newrowKeys['review_date'] = this.state.common_data['review_date'];
      newrowKeys['result'] = this.state.common_data['result'];

      if (this.state.common_data['treatment_date']) {
        newrowKeys['treatment_date'] = moment(
          this.state.common_data['treatment_date']
        ).format('YYYY-MM-DD');
      } else {
        newrowKeys['treatment_date'] = moment().format('YYYY-MM-DD');
      }

      // CREATE n NUMBER OF ROWS, BASED ON SELECTION //
      let newRows = [],
        counter = 1;
      if (Object.keys(this.sigTblRef.current.state.editApply).length) {
        counter = Object.keys(this.sigTblRef.current.state.editApply).length;
      }

      // CHECK IF CREATE NEW ROWS REQUEST COME FROM LIVESTOCK LISTING PAGE //
      if (this.state.treatmentForIds.length) {
        counter = this.state.treatmentForIds.length;
      }

      while (counter--) {
        let newrow = JSON.parse(JSON.stringify(newrowKeys));
        newrow['id'] = Math.random().toString(36).substr(2, 9);
        newrow['key'] = newrow['id'];
        // CHECK IF CREATE NEW ROWS REQUEST COME FROM LIVESTOCK LISTING PAGE //
        if (this.state.treatmentForIds.length) {
          let row = this.state.treatmentForIds[counter];
          newrow['livestock_id'] = row['livestock_id'];
          newrow['treatment_id'] = row['treatment_id'];
          newrow['livestock_breed'] = row['breed'];
          newrow['livestock_colour'] = row['colour'];
          newrow['livestock_identifier'] = row['identifier'];
          newrow['livestock_management_id'] = row['management_id'];
          newrow['livestock_sex'] = this.capitalize(row['sex']);
          newrow['identifier'] = row['livestock_identifier'];
          newrow['breed'] = row['breed'];
          newrow['colour'] = row['colour'];
          newrow['eartag_management_id'] = row['management_id'];
          newrow['sex'] = this.capitalize(row['sex']);
        }
        // DEFAULT BEHAVIOUR //
        else {
          newrow['livestock_id'] = this.props.animal_id
            ? parseInt(this.props.animal_id)
            : 0;
        }
        newRows.push(newrow);
      }

      // GET & ASSIGN LIVESTOCK ID FOR NEW ROWS //
      let tmpEditApply = JSON.parse(
        JSON.stringify(this.sigTblRef.current.state.editApply)
      );
      if (Object.keys(tmpEditApply).length) {
        Object.keys(tmpEditApply).map((id, indx) => {
          let row = this.sigTblRef.current.state.field.filter(
            (x) => x.id === parseInt(id)
          );
          if (row.length) {
            newRows[indx]['livestock_id'] = row[0]['livestock_id'];
            newRows[indx]['treatment_id'] = row[0]['treatment_id'];
            newRows[indx]['livestock_breed'] = row[0]['breed'];
            newRows[indx]['livestock_colour'] = row[0]['colour'];
            newRows[indx]['livestock_identifier'] = row[0]['identifier'];
            newRows[indx]['livestock_management_id'] =
              row[0]['eartag_management_id'];
            newRows[indx]['livestock_sex'] = row[0]['sex'];
            newRows[indx]['breed'] = row[0]['breed'];
            newRows[indx]['colour'] = row[0]['colour'];
            newRows[indx]['eartag_management_id'] =
              row[0]['eartag_management_id'];
            newRows[indx]['identifier'] = row[0]['identifier'];
            newRows[indx]['sex'] = this.capitalize(row[0]['sex']);
          }
        });
      }

      this.setState(
        (state) => ({
          ...state,
          newRows: newRows
        }),
        (e) => this.sigTblRef.current.setStatePostAdd()
      );

      // SHOW FIRST PAGE AFTER ADDING NEW ROWS //
      setTimeout(() => {
        while (
          !document.getElementsByClassName('-previous')[0].children[0].disabled
        ) {
          document.getElementsByClassName('-previous')[0].children[0].click();
        }
      }, 200);
    }
  }
  editAction() {
    if (Object.keys(this.sigTblRef.current.state.editApply).length) {
      this.sigTblRef.current.editHandler();
    } else {
      errorToastHandler(messages.SELECTED_ITEM_REQUIRED);
    }
  }
  deleteAction() {
    if (Object.keys(this.sigTblRef.current.state.editApply).length) {
      this.sigTblRef.current.deleteHandler();
    } else {
      errorToastHandler(messages.SELECTED_ITEM_REQUIRED);
    }
  }
  saveAction() {
    this.resetCommonTreatment();
    this.sigTblRef.current.saveHandler();
  }
  cancelAction() {
    this.clearCache();
    this.resetCommonTreatment();
    this.sigTblRef.current.globalCancelHandler();
  }
  clearCache() {
    this.setState((state) => ({
      ...state,
      treatmentForIds: []
    }));
  }
  resetCommonTreatment() {
    this.setState((state) => ({
      ...state,
      common_data: {
        treatment_type_id: JSON.parse(
          JSON.stringify(this.state.treatmentTypes)
        ),
        treatment_date: null,
        symptoms: '',
        is_positive: '0',
        treatment: '',
        medication: '',
        dosage_uom: JSON.parse(JSON.stringify(this.state.treatmentUOM)),
        dosage_amount: '0',
        vet: '',
        comments: '',
        cost: '0',
        repeat_date: '',
        review_date: '',
        result: ''
      }
    }));
  }
  // NOT IN USE; BUT REQUIRED FOR CODE FLOW //
  onSelectHandler = (rows) => {
    this.setState((state) => ({
      ...state,
      selectedIds: [...Object.keys(rows)]
    }));
  };
  onSaveHandler = (rows) => {
    // return row which is saved
    let postData = rows.map((row) => {
      let type_id = row.treatment_type_id.filter((x) => x.isSelected == true);
      type_id = type_id.length ? type_id[0]['value'] : null;

      let dosage_uom = row.dosage_uom.filter((x) => x.isSelected == true);
      dosage_uom = dosage_uom.length ? dosage_uom[0]['value'] : null;

      let obj = {
        id: isNaN(row.treatment_id) ? null : row.treatment_id,
        livestock_id: parseInt(row.livestock_id),
        treatment_type_id: parseInt(type_id),
        treatment_date: row.treatment_date
          ? moment(row.treatment_date).format('YYYY-MM-DD')
          : '',
        symptoms: row.symptoms,
        is_positive: row.is_positive ? 1 : 0,
        treatment: row.treatment,
        medication: row.medication,
        dosage_amount: parseFloat(row.dosage_amount),
        dosage_uom: dosage_uom,
        vet: row.vet,
        comments: row.comments,
        cost: parseFloat(row.cost),
        repeat_date: row.repeat_date
          ? moment(row.repeat_date).format('YYYY-MM-DD')
          : '',
        review_date: row.review_date
          ? moment(row.review_date).format('YYYY-MM-DD')
          : '',
        result: row.result
      };
      return obj;
    });
    if (postData.length) {
      this.saveOperation(postData);
    }
  };
  onDeleteHandler = (rows) => {
    // return keys of the row which is deleted
    let deletedIds = Object.keys(rows).map((x) => parseInt(x));

    if (deletedIds.length) {
      this.deleteOperation(deletedIds);
    }
  };
  async saveOperation(postData) {
    try {
      if (this.props.tab_action === 'edit') {
        let response = await axios.put('treatment/bulk', {
          treatmentData: JSON.stringify(postData)
        });
        if (response.status == 200) {
          this.props.clearTabOperation();
          this.sigTblRef.current.clearTable();
          this.getData();
          this.clearCache();
          if (typeof this.props.onSave !== 'undefined') {
            this.props.onSave();
          }
        }
      } else {
        let response = await axios.post('treatment/store/bulk', {
          treatmentData: JSON.stringify(postData)
        });
        if (response.status == 200) {
          this.props.clearTabOperation();
          this.sigTblRef.current.clearTable();
          this.getData();
          this.clearCache();
          if (typeof this.props.onSave !== 'undefined') {
            this.props.onSave();
          }
        }
      }
    } catch (error) {}
  }
  async deleteOperation(deletedIds) {
    let response = await axios.delete('treatment/destroy_bulk', {
      data: {
        ids: deletedIds
      }
    });
    if (response.status == 200) {
      this.getData();
      this.clearCache();
    }
  }
  toggleModal(modal) {
    this.resetCommonTreatment();

    this.setState((state) => ({
      ...state,
      [modal]: !state[modal]
    }));
  }
  closePopup(modal) {
    this.toggleModal(modal);
    this.clearCache();
  }
  onEditChange = (value, field) => {
    if (field == 'is_positive') {
      value = value ? 1 : 0;
    }
    this.setState((state) => ({
      common_data: {
        ...state.common_data,
        [field]: value
      }
    }));
  };
  validateCommonData() {
    let isValid = true,
      showAlert = false;

    if (
      this.state.common_data['treatment_date'] !== '' &&
      this.state.common_data['repeat_date'] !== '' &&
      moment(this.state.common_data['treatment_date']).valueOf() >
        moment(this.state.common_data['repeat_date']).valueOf()
    ) {
      showAlert = true;
    }
    if (
      this.state.common_data['treatment_date'] !== '' &&
      this.state.common_data['review_date'] !== '' &&
      moment(this.state.common_data['treatment_date']).valueOf() >
        moment(this.state.common_data['review_date']).valueOf()
    ) {
      showAlert = true;
    }

    if (
      this.state.common_data['repeat_date'] !== '' &&
      (this.state.common_data['treatment_date'] === '' ||
        this.state.common_data['treatment_date'] === null ||
        moment(this.state.common_data['treatment_date']).valueOf() >
          moment(this.state.common_data['repeat_date']).valueOf())
    ) {
      showAlert = true;
    }

    if (
      this.state.common_data['review_date'] !== '' &&
      (this.state.common_data['treatment_date'] === '' ||
        this.state.common_data['treatment_date'] === null ||
        moment(this.state.common_data['treatment_date']).valueOf() >
          moment(this.state.common_data['review_date']).valueOf())
    ) {
      showAlert = true;
    }

    if (showAlert) {
      isValid = false;
      errorToastHandler(
        messages.TREATMENT_REVIEW_REPEAT_DATE_AFTER_TREATMENT_DATE
      );
    }

    return isValid;
  }
  createBulkRows() {
    if (this.validateCommonData()) {
      this.props.addNewTabRows();
      this.setState((state) => ({
        ...state,
        bulk_modal: false
      }));
      this.addAction();
    }
  }
  filterData(filters) {
    this.setState(
      {
        filters: filters
      },
      (e) => this.getData()
    );
  }
  handleKeyPress = (charCode) => {
    if (charCode === 13) {
      this.getData();
    }
  };
  keywordSearch(value, field) {
    this.setState((state) => ({
      ...state,
      [field]: value
    }));
  }
  render() {
    return (
      <div className="allow-overflow">
        <SigTable
          data={this.state.tableData} // data for the table
          newRows={this.state.newRows} //add new rows here
          ondelete={this.onDeleteHandler} //callback
          onselect={this.onSelectHandler} // callback
          onsave={this.onSaveHandler} // callback
          ref={this.sigTblRef}
        />
        <Modal isOpen={this.state.bulk_modal}>
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>Create New Treatments</b>
            </h5>
            <br />
            <Form>
              <FormGroup row>
                <Label sm="4">Treatment Type</Label>
                <Col sm="8">
                  <Select
                    value={
                      this.state.common_data.treatment_type_id.filter(
                        (x) => x.isSelected === true
                      )[0]
                    }
                    onChange={(selection) => {
                      this.state.common_data.treatment_type_id.map((x) => {
                        x.isSelected = x.value === selection.value;
                        return x;
                      });
                      this.setState({ common_data: this.state.common_data });
                    }}
                    options={this.state.common_data.treatment_type_id}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4">Treatment Date</Label>
                <Col sm="8">
                  <DatePicker
                    selected={this.state.common_data.treatment_date}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    onChange={(e) => this.onEditChange(e, 'treatment_date')}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4">Symptoms</Label>
                <Col sm="8">
                  <Input
                    type="text"
                    defaultValue={this.state.common_data.symptoms}
                    onChange={(e) =>
                      this.onEditChange(e.target.value, 'symptoms')
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4">Is Positive</Label>
                <Col sm="8">
                  <Input
                    type="checkbox"
                    onChange={(e) =>
                      this.onEditChange(e.target.checked, 'is_positive')
                    }
                    className="form-control"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4">Treatment</Label>
                <Col sm="8">
                  <Input
                    type="text"
                    defaultValue={this.state.common_data.treatment}
                    onChange={(e) =>
                      this.onEditChange(e.target.value, 'treatment')
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4">Medication</Label>
                <Col sm="8">
                  <Input
                    type="text"
                    defaultValue={this.state.common_data.medication}
                    onChange={(e) =>
                      this.onEditChange(e.target.value, 'medication')
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4">Dosage UoM</Label>
                <Col sm="8">
                  <Select
                    value={
                      this.state.common_data.dosage_uom.filter(
                        (x) => x.isSelected === true
                      )[0]
                    }
                    onChange={(selection) => {
                      this.state.common_data.dosage_uom.map((x) => {
                        x.isSelected = x.value === selection.value;
                        return x;
                      });
                      this.setState({ common_data: this.state.common_data });
                    }}
                    options={this.state.common_data.dosage_uom}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4">Dosage Amount</Label>
                <Col sm="8">
                  <Input
                    type="number"
                    defaultValue={this.state.common_data.dosage_amount}
                    onChange={(e) =>
                      this.onEditChange(e.target.value, 'dosage_amount')
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4">Vet</Label>
                <Col sm="8">
                  <Input
                    type="text"
                    defaultValue={this.state.common_data.vet}
                    onChange={(e) => this.onEditChange(e.target.value, 'vet')}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4">Comments</Label>
                <Col sm="8">
                  <Input
                    type="text"
                    defaultValue={this.state.common_data.comments}
                    onChange={(e) =>
                      this.onEditChange(e.target.value, 'comments')
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4">Cost</Label>
                <Col sm="8">
                  <Input
                    type="number"
                    placeholder="0"
                    defaultValue={this.state.common_data.cost}
                    onChange={(e) => this.onEditChange(e.target.value, 'cost')}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4">Repeat Date</Label>
                <Col sm="8">
                  <DatePicker
                    selected={this.state.common_data.repeat_date}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    onChange={(e) => this.onEditChange(e, 'repeat_date')}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4">Review Date</Label>
                <Col sm="8">
                  <DatePicker
                    selected={this.state.common_data.review_date}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    onChange={(e) => this.onEditChange(e, 'review_date')}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4">Result</Label>
                <Col sm="8">
                  <Input
                    type="text"
                    defaultValue={this.state.common_data.result}
                    onChange={(e) =>
                      this.onEditChange(e.target.value, 'result')
                    }
                  />
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => this.closePopup('bulk_modal')}
            >
              Cancel
            </Button>{' '}
            <Button color="danger" onClick={() => this.createBulkRows()}>
              Create Treatments
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Treatments;
