import General from '../views/dashboards/general.jsx';
//Sample Pages Dropdown

import Profile from '../views/pages/profile.jsx';
import Tags from '../views/pages/tags.jsx';
import Animals from '../views/pages/animals.jsx';
import AnimalNew from '../views/pages/animal_new.jsx';
import Geofences from '../views/pages/geofences.jsx';
import Farms from '../views/pages/farms.jsx';
import Tracking from '../views/pages/tracking.jsx';
import Gateways from '../views/pages/gateways.jsx';
import Gateway from '../views/pages/gateway.jsx';
import Labels from '../views/pages/labels.jsx';
import Label from '../views/pages/label.jsx';
import LabelNew from '../views/pages/label_new';
import Rules from '../views/pages/rules.jsx';
import Rule from '../views/pages/rule.jsx';
import RuleBuilder from '../views/pages/rules_builder.jsx';
import Notifications from '../views/pages/notifications.jsx';
import Notification from '../views/pages/notification.jsx';
import Sites from '../views/pages/sites.jsx';
import Site from '../views/pages/site.jsx';
import Tag from '../views/pages/tag.jsx';
import Farm from '../views/pages/farm.jsx';
import FarmNew from '../views/pages/farm_new.jsx';
import Animal from '../views/pages/animal.jsx';
import Measure from '../views/pages/measures.jsx';
import Breeding from '../views/pages/breeding.jsx';

import Geofence from '../views/pages/geofence.jsx';
import GeofenceNew from '../views/pages/geofence_new.jsx';
import Treatment from '../views/pages/treatment.jsx';
import { USER_ROLE } from '../constants/user.js';
import AdministrationUsers from '../views/pages/Administration/Users/index.jsx';
import AdministrationTags from '../views/pages/Administration/Tags/index.jsx';
import AdministrationGateways from '../views/pages/Administration/Gateways/index.jsx';
import NewUser from '../views/pages/NewUser/index.jsx';
import NewGateway from '../views/pages/NewGateway/index.jsx';
import EditTag from '../views/pages/EditTag/index.jsx';
import EditGateway from '../views/pages/EditGateway/index.jsx';
import ContactUs from '../views/pages/ContactUs/index.jsx';
import MyCalendar from '../views/pages/MyCalendar/index.jsx';
import MyTasks from '../views/pages/MyTasks/index.jsx';

const ThemeRoutes = [
  {
    path: '/farm/:id',
    name: 'Farm',
    icon: 'mdi mdi-view-dashboard',
    features: ['farms'],
    component: Farm,
    protected: true,
    display: false
  },
  {
    path: '/site/:id',
    name: 'Site',
    icon: 'mdi mdi-view-dashboard',
    features: ['sites'],
    component: Site,
    display: false,
    protected: true
  },
  {
    path: '/farm_new',
    name: 'Farm',
    icon: 'mdi mdi-view-dashboard',
    features: ['farms'],
    component: FarmNew,
    protected: true,
    display: false
  },
  {
    path: '/geofence_new',
    name: 'GeofenceNew',
    icon: 'mdi mdi-view-dashboard',
    features: ['geofences'],
    component: GeofenceNew,
    protected: true,
    display: false
  },
  {
    path: '/notification/:id',
    name: 'Notification',
    icon: 'mdi mdi-view-dashboard',
    features: ['notifications'],
    component: Notification,
    display: false,
    protected: true
  },
  {
    path: '/group/new',
    name: 'Group',
    icon: 'mdi mdi-view-dashboard',
    features: ['groups'],
    component: LabelNew,
    display: false,
    protected: true
  },
  {
    path: '/group/:id',
    name: 'Group',
    icon: 'mdi mdi-view-dashboard',
    features: ['groups'],
    component: Label,
    display: false,
    protected: true
  },
  {
    path: '/rule/:id',
    name: 'Rule',
    icon: 'mdi mdi-view-dashboard',
    features: ['rules'],
    component: Rule,
    display: false,
    protected: true
  },
  {
    path: '/gateway/:id',
    name: 'Gateway',
    icon: 'mdi mdi-view-dashboard',
    features: ['gateways'],
    component: Gateway,
    display: false,
    protected: true
  },
  {
    path: '/tag/:id',
    name: 'Tag',
    icon: 'mdi mdi-view-dashboard',
    features: ['tags'],
    component: Tag,
    protected: true,
    display: false
  },
  {
    path: '/geofence/:id',
    name: 'Geofence',
    icon: 'mdi mdi-view-dashboard',
    features: ['geofences'],
    component: Geofence,
    protected: true,
    display: false
  },
  {
    path: '/animal_new',
    name: 'LivestockNew',
    icon: 'mdi mdi-view-dashboard',
    features: ['livestock'],
    component: AnimalNew,
    protected: true,
    display: false
  },
  {
    path: '/animal/:id/:type',
    name: 'Livestock',
    icon: 'mdi mdi-view-dashboard',
    features: ['livestock'],
    component: Animal,
    protected: true,
    display: false
  },
  {
    path: '/animal/:id',
    name: 'Livestock',
    icon: 'mdi mdi-view-dashboard',
    features: ['livestock'],
    component: Animal,
    protected: true,
    display: false
  },

  /* DISPLAY MENU ITEMS */
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'stf stf-dashboard',
    features: ['weather', 'calendar', 'tasks'],
    component: General,
    protected: true
  },
  {
    path: '/tracking/:id',
    name: 'Track Livestock',
    icon: 'stf stf-track',
    component: Tracking,
    features: ['track_livestock'],
    protected: true,
    display: false
  },
  {
    path: '/tracking',
    name: 'Track Livestock',
    icon: 'stf stf-track',
    features: ['track_livestock'],
    component: Tracking,
    protected: true,
    display: true
  },
  {
    path: '/calendar',
    name: 'Calendar',
    features: ['calendar'],
    icon: 'icon-calender stf',
    protected: true,
    component: MyCalendar
  },
  {
    path: '/tasks',
    name: 'Tasks',
    features: ['tasks'],
    icon: 'icon-list stf',
    protected: true,
    component: MyTasks
  },
  {
    path: '/notifications',
    name: 'Notifications',
    icon: 'stf stf-notifications',
    features: ['notifications'],
    component: Notifications,
    display: true,
    protected: true
  },
  {
    collapse: true,
    path: '/animals',
    name: 'Livestock',
    icon: 'stf stf-livestock',
    features: ['livestock'],
    component: Animals,
    protected: true,
    display: true,
    children: [
      {
        path: '/animals',
        name: 'Livestock',
        mini: 'B',
        icon: 'mdi mdi-adjust',
        features: ['livestock'],
        protected: true,
        component: Animals
      },
      {
        path: '/measures',
        name: 'Measures',
        mini: 'B',
        icon: 'mdi mdi-adjust',
        features: ['measures'],
        protected: true,
        component: Measure
      },
      {
        path: '/breeding',
        name: 'Breeding',
        mini: 'B',
        icon: 'mdi mdi-adjust',
        features: ['breeding'],
        protected: true,
        component: Breeding
      },
      {
        path: '/teatments',
        name: 'Treatments',
        features: ['treatment'],
        icon: 'mdi mdi-adjust',
        protected: true,
        component: Treatment
      }
    ]
  },
  {
    path: '/farms',
    name: 'Farms',
    icon: 'stf stf-farms',
    features: ['farms'],
    component: Farms,
    protected: true
  },
  {
    path: '/geofences',
    name: 'Geofences',
    icon: 'stf stf-geofence',
    features: ['geofences'],
    component: Geofences,
    protected: true,
    display: true
  },
  {
    path: '/sites',
    name: 'Sites',
    icon: 'stf stf-sites',
    features: ['sites'],
    component: Sites,
    display: true,
    protected: true
  },
  {
    path: '/groups',
    name: 'Groups',
    icon: 'stf stf-labels',
    features: ['groups'],
    component: Labels,
    display: true,
    protected: true
  },
  {
    path: '/rules/builder/:id',
    name: 'RuleBuilder',
    features: ['rules'],
    icon: 'stf stf-rules',
    component: RuleBuilder,
    display: false,
    protected: true
  },
  {
    path: '/rules',
    name: 'Rules',
    features: ['rules'],
    icon: 'stf stf-rules',
    component: Rules,
    display: true,
    protected: true
  },

  {
    path: '/tags',
    name: 'Kraal Tags',
    features: ['tags'],
    icon: 'stf stf-tags',
    component: Tags,
    protected: true
  },
  {
    path: '/gateways',
    name: 'Kraal Gateways',
    icon: 'stf stf-gateway',
    features: ['gateways'],
    component: Gateways,
    display: true,
    protected: true
  },
  // Do not move rule builder as it must be the first to match

  // DO NOT DISPLAY

  {
    path: '/profile',
    name: 'Profile',
    icon: 'mdi mdi-view-dashboard',
    features: ['default'],
    component: Profile,
    protected: true,
    display: false
  },
  {
    path: '/contact-us',
    name: 'Contact us',
    icon: 'mdi mdi-view-dashboard',
    features: ['default'],
    component: ContactUs,
    protected: true,
    display: false
  },
  {
    path: '/administration/tag/:id',
    name: 'Administration Tag',
    icon: 'mdi mdi-view-dashboard',
    features: ['default'],
    component: EditTag,
    roles: [USER_ROLE.ADMIN],
    protected: true,
    display: false
  },
  {
    path: '/administration/gateway/:id',
    name: 'Administration Gateway',
    icon: 'mdi mdi-view-dashboard',
    features: ['default'],
    component: EditGateway,
    roles: [USER_ROLE.ADMIN],
    protected: true,
    display: false
  },
  {
    collapse: true,
    path: '/administration',
    name: 'Administration',
    icon: 'icon-wrench stf',
    features: ['default'],
    component: AdministrationUsers,
    protected: true,
    display: true,
    roles: [USER_ROLE.ADMIN],
    children: [
      {
        path: '/administration/users',
        name: 'Users',
        icon: 'mdi mdi-adjust',
        features: ['default'],
        protected: true,
        component: AdministrationUsers,
        roles: [USER_ROLE.ADMIN]
      },
      {
        path: '/administration/tags',
        name: 'Tags',
        icon: 'mdi mdi-adjust',
        protected: true,
        features: ['default'],
        component: AdministrationTags,
        roles: [USER_ROLE.ADMIN]
      },
      {
        path: '/administration/gateways',
        name: 'Gateways',
        icon: 'mdi mdi-adjust',
        features: ['default'],
        protected: true,
        roles: [USER_ROLE.ADMIN],
        component: AdministrationGateways
      }
    ]
  },
  {
    path: '/new-user',
    name: 'NewUser',
    icon: 'mdi mdi-view-dashboard',
    features: ['default'],
    component: NewUser,
    protected: true,
    display: false
  },
  {
    path: '/new-gateway',
    name: 'NewGateway',
    icon: 'mdi mdi-view-dashboard',
    component: NewGateway,
    features: ['default'],
    roles: [USER_ROLE.ADMIN],
    protected: true,
    display: false
  },
  {
    path: '/',
    pathTo: '/dashboard',
    name: 'Dashboard',
    features: ['default'],
    redirect: true
  }
];
export default ThemeRoutes;
