import React, { useEffect } from 'react';
import moment from 'moment';
import {
  Row,
  Col,
  Card,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  FormGroup,
  Nav,
  Input,
  Table,
  NavItem,
  NavLink,
  Modal,
  ModalBody,
  ModalFooter,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  TabContent,
  TabPane
} from 'reactstrap';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { useSetState } from '../../hooks/useSetState';
import axios from '../../axios';
import Select from 'react-select';
import ReactTable from 'react-table';
import { CompactPicker } from 'react-color';
import { getColorName } from '../../helpers/common';
import SelectionPopover from '../../components/Popovers/SelectionPopover';
import ChooseGeofencesModal from '../../components/Modals/ChooseGeofencesModal';
import PrevNextButtons from '../../components/PrevNextButtons';
import { getFarms } from '../../redux/actions/farm';
import { ANIMAL_ERRORS } from '../../constants/livestock';
import ConfirmationModal from '../../components/Modals/ConfirmationModal';

const selectedTypeMapping = {
  virtual: {
    value: 'virtual',
    label: 'Virtual'
  },
  physical: {
    value: 'physical',
    label: 'Physical'
  },
  collection: {
    value: 'collection',
    label: 'Collection'
  }
};

const LabelPage = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useSetState({
    animals: true,
    labels: true
  });
  const [state, setState] = useSetState({
    activeTab: '1',
    chooseGeofencesModal: false,
    selectedGeofence: {},
    geofenceOptions: [],
    delete_modal: false,
    add_modal: false,
    selectAll: false,
    query: '',
    query_rules: '',
    selectedLivestock: [],
    isOpenSelectionPopover: {
      1: false,
      2: false
    },
    tableData: [],
    selected_labels: [],
    selected_livestock: [],
    selected_rules: [],
    livestock_opts: [],
    rules_opts: [],
    colourName: '',
    linked_livestock: [],
    includedLivestock: [],
    excludedLivestock: [],
    tableIncludedData: [],
    tableExcludedData: [],
    selectedIncluded: [],
    selectedExcluded: [],

    label: {
      name: '',
      id: null,
      color: '',
      animals: [],
      rules: []
    },
    new_label: {
      name: '',
      color: '',
      rules: [],
      livestock: []
    },
    loading: {
      animals: true,
      labels: true
    },
    edit: false,
    includedActionsOpen: false,
    excludedActionsOpen: false,
    dropdownOpen: false,
    isOpenConfirmationModal: false
  });
  const farmState = useSelector((state) => state.farm);

  const onClickOnThisPage = () => {
    const newCheckboxValue = !state.selectAll;
    const selected =
      state.activeTab === '1' ? 'tableIncludedData' : 'tableExcludedData';
    let checkedCopy = [];
    if (newCheckboxValue) {
      state[selected].map((val) => {
        const id = val?._original?.id ?? val.id;
        checkedCopy.push(id);
      });
    }
    setState(() => ({
      selectedLivestock: checkedCopy,
      linked_livestock: checkedCopy
    }));
    setState({
      selectAll: newCheckboxValue
    });
    onToggleSelectionPopover();
  };

  const onClickAllRecords = () => {
    const newCheckboxValue = !state.selectAll;
    let checkedCopy = [];
    const selected =
      state.activeTab === '1' ? 'includedLivestock' : 'excludedLivestock';
    if (newCheckboxValue) {
      state[selected].map((val) => {
        checkedCopy.push(val.id);
      });
    }
    setState(() => ({
      selectedLivestock: checkedCopy,
      linked_livestock: checkedCopy
    }));
    setState({
      selectAll: newCheckboxValue
    });
    onToggleSelectionPopover();
  };
  const onToggleSelectionPopover = (isOpen) => {
    const open =
      isOpen !== undefined
        ? isOpen
        : !state.isOpenSelectionPopover[state.activeTab] && !state.selectAll;
    setState({
      isOpenSelectionPopover: { [state.activeTab]: open }
    });
  };

  useEffect(() => {
    getRules();
    getLabel();
    getLabels();
    getFences();
    getFarmsOptions();
  }, []);

  useEffect(() => {
    if (!loading.animals && !loading.labels) {
      let includedLivestock = [];
      let excludedLivestock = [];

      state.livestock_opts.forEach((item) => {
        if (state.linked_livestock.includes(item.id)) {
          includedLivestock.push(item);
        } else {
          const physicalGroup = item.labels.find(
            (group) => group.main_type === 'physical'
          );
          if (!physicalGroup) {
            excludedLivestock.push(item);
          }
        }
      });

      setState({
        includedLivestock: includedLivestock,
        tableIncludedData: includedLivestock.slice(0, 25),
        excludedLivestock: excludedLivestock,
        tableExcludedData: excludedLivestock.slice(0, 25)
      });
    }
  }, [loading]);

  const toggleButton = () => {
    setState({
      dropdownOpen: !state.dropdownOpen
    });
  };

  const getFarmsOptions = () => {
    dispatch(getFarms());
  };

  const getLabels = async () => {
    const response = await axios.get('labels', {
      params: {
        farm_ids: []
      }
    });

    const groups = response.data
      .filter((item) => {
        return item.main_type !== 'collection';
      })
      .map((item) => {
        return {
          value: item.id,
          label: item.name
        };
      });

    setState({
      groups
    });
  };

  const getFences = async () => {
    try {
      let response = await axios.get('geofences', {
        params: { only_custom: true }
      });
      setState({
        geofenceOptions: response?.data?.map((x) => {
          return { value: x.id, label: x.name };
        })
      });
    } catch (error) {
      console.error(error);
    }
  };
  const getLabel = () => {
    return new Promise(async (resolve) => {
      let response = await axios.get('labels/' + props.match.params.id, {
        query: state.query
      });

      const label = {
        ...response.data,
        collection: (response.data.collection || []).map((item) => {
          return {
            label: item.name,
            value: item.id
          };
        })
      };

      if (response.status === 200) {
        setState({
          label,
          linked_livestock: response.data.animals.map((x) => x.id),
          selectedGeofence: response.data?.geofences?.[0]
            ? {
                label: response.data?.geofences?.[0]?.name,
                value: response.data?.geofences?.[0]?.id
              }
            : null
          //colourName: col.data.colors[0].name,
        });
        setLoading({
          labels: false
        });
        getLivestock(response.data);
        return resolve();
      }
    });
  };

  const toggle = (tab) => {
    if (state.activeTab !== tab) {
      setState({
        activeTab: tab
      });
    }
  };
  const toggleModal = (modal) => {
    setState((state) => ({
      ...state,
      [modal]: !state[modal]
    }));
  };
  const getRules = async () => {
    let response = await axios.get('rules?query=' + state.query_rules);
    setState({
      rules_opts: response.data
    });
  };
  const getLivestock = async (data) => {
    const groupType = data.type;
    const mainType = data.main_type;
    const farmId = data.farm_id;
    const localQuery = groupType === 'loss_date' ? '&with_archived=true' : '';
    let params = {
      farm_ids: [farmId].filter(Boolean)
    };
    if (mainType === 'collection') {
      params = {
        label_ids: data.collection.map((item) => {
          return item.id;
        })
      };
    }
    let response = await axios.get(
      'animals?query=' + state.query + localQuery,
      {
        params
      }
    );
    setState({
      livestock_opts: response.data
    });
    setLoading({
      animals: false
    });
  };

  const saveLabel = async () => {
    const payload = {
      ...state.label,
      collectionIds: (state.label.collection || []).map((item) => item.value)
    };
    await axios.put(`/labels/${props.match.params.id}`, payload);
    setState({
      edit: false
    });
    getLabel();
  };

  const onSelectLivestock = (id) => {
    let livestocks = state.selectedLivestock;
    if (livestocks.includes(id)) {
      var index = livestocks.indexOf(id);
      if (index > -1) {
        livestocks.splice(index, 1);
      }
    } else {
      livestocks.push(id);
    }

    setState({
      selectedLivestock: livestocks
    });
  };

  const removeFromTheGroup = async () => {
    try {
      await axios.delete(`labels/${props.match.params.id}/animals`, {
        data: { animal_ids: [...state.selectedLivestock] }
      });
      getLabel();
    } catch (error) {
      console.error(error);
    }
  };

  const addToTheGroup = async () => {
    try {
      await axios.post(`labels/${props.match.params.id}/animals`, {
        animal_ids: [...state.selectedLivestock]
      });
      getLabel();
    } catch (error) {
      console.error(error);
    }
  };

  const hasAnimalDifferentGeofence = () => {
    const groupGeofenceId = state.label?.geofences?.[0]?.id;
    let isAnimalWithDifferentGeofenceId = false;
    for (const id of state.selectedLivestock) {
      const animal = state.excludedLivestock.find((i) => i.id === id);
      const sameGeofence = animal?.geofences?.find(
        (i) => i.id === groupGeofenceId
      );
      if (!sameGeofence) {
        isAnimalWithDifferentGeofenceId = true;
      }
    }
    if (isAnimalWithDifferentGeofenceId) {
      setState({
        isOpenConfirmationModal: true
      });
    } else {
      addToTheGroup();
    }
  };

  const deleteLabel = async () => {
    let response = await axios.delete('labels/' + props.match.params.id);
    if (response.status === 200) {
      props.history.push('/groups');
    }
  };

  const onLabelChange = (value, field) => {
    setState({
      label: {
        ...state.label,
        [field]: value
      }
    });
  };
  const onSelectGeofence = (value) => {
    setState({
      selectedGeofence: value
    });
  };
  const getAssignedAnimalsList = () => {
    const animals = state.label.animals;
    const assignedAnimals = [];
    for (const animal of animals) {
      if (animal?.labels?.every((l) => l.main_type !== 'physical')) {
        assignedAnimals.push(animal);
      }
    }
    return assignedAnimals;
  };
  const onSaveGeofence = async () => {
    let groupId = props.match.params.id;
    const geofenceId = state.selectedGeofence?.value;
    if (geofenceId) {
      try {
        await axios.put('labels/updateGeofenceInGroup/' + groupId, {
          geofence_id: geofenceId
        });
        getLivestock();
      } catch (error) {
        console.error(error);
      }
    }
    toggleModal('chooseGeofencesModal');
  };
  const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  function filterCaseInsensitive(filter, row) {
    const id = filter.pivotId || filter.id;
    if (row[id]) {
      if (row[id] && typeof row[id] === 'object') {
        if (typeof row[id]?.['identifier'] === 'number') {
          return row[id]?.['identifier'] !== undefined
            ? String(row[id]?.['identifier']).includes(filter.value)
            : true;
        } else {
          return row[id] !== undefined
            ? String(row[id]?.['identifier']?.toLowerCase()).includes(
                filter.value?.toLowerCase()
              )
            : true;
        }
      } else if (typeof row[id] === 'number') {
        return row[id] !== undefined
          ? String(row[id]).includes(filter.value)
          : true;
      } else {
        return row[id] !== undefined
          ? String(row[id]?.toLowerCase()).includes(filter.value.toLowerCase())
          : true;
      }
    }
  }
  const toggleDropdown = (menu) => {
    setState({
      [menu]: !state?.[menu]
    });
  };

  const onChangeTab = (tabNumber) => {
    toggle(tabNumber);
    setState({
      selectedLivestock: [],
      selectAll: false
    });
  };
  const isCustomGroup = (label) => {
    return label.type === 'custom';
  };
  const isVirtualGroup = (label) => {
    return label.type === 'virtual';
  };

  const columns1 = [
    {
      Header: () => (
        <div className="position-relative form-check">
          <SelectionPopover
            isOpen={state.isOpenSelectionPopover['1']}
            onClickOnThisPage={() => onClickOnThisPage()}
            onClickAllRecords={() => onClickAllRecords()}
            setOpen={(isOpen) =>
              setState({
                isOpenSelectionPopover: {
                  [state.activeTab]:
                    isOpen !== undefined
                      ? isOpen
                      : !state.isOpenSelectionPopover[state.activeTab]
                }
              })
            }
            content={
              <input
                type="checkbox"
                defaultChecked={state.selectAll}
                onChange={() => {
                  onToggleSelectionPopover();
                  if (state.selectAll) {
                    setState({
                      selectAll: !state.selectAll
                    });
                    setState((state) => ({
                      ...state,
                      linked_livestock: [],
                      selectedLivestock: []
                    }));
                  }
                }}
              />
            }
          />
        </div>
      ),
      id: 'select',
      accessor: (d) => {
        return { id: d.id };
      },
      Cell: (props) => (
        <FormGroup check>
          <Input
            type="checkbox"
            value={props.value.id}
            defaultChecked={state.selectedLivestock.includes(props.value.id)}
            onChange={(e) => onSelectLivestock(parseInt(e.target.value))}
            key={props.value.id}
          />{' '}
        </FormGroup>
      ),
      headerClassName: 'wordwrap',
      sortable: false,
      filterable: false,
      maxWidth: 85
    },
    {
      Header: 'Livestock ID',
      id: 'glivestckid',
      accessor: (d) => {
        return { id: d.id, identifier: d.identifier };
      },
      headerClassName: 'wordwrap',
      sortMethod: (a, b) => {
        return a.identifier.localeCompare(b.identifier);
      },
      Cell: (prop) => (
        <a
          href="#"
          onClick={() => props.history.push('/animal/' + prop.value.id)}
        >
          {prop.value.identifier}
        </a>
      )
    },
    {
      Header: 'Mgmt Tag ID',
      id: 'eartag_management_id',
      accessor: (d) => {
        return d.eartag_management_id.indexOf('mgmt~tmp~') > -1
          ? ''
          : d.eartag_management_id;
      },
      headerClassName: 'wordwrap',
      sortMethod: (a, b) => {
        return a.localeCompare(b);
      },

      minWidth: 130
    },
    {
      Header: 'Sex',
      accessor: 'sex',
      headerClassName: 'wordwrap',
      Cell: (props) => {
        return capitalize(props.value);
      }
    },
    {
      Header: 'Colour',
      accessor: 'colour',
      headerClassName: 'wordwrap'
    },
    {
      Header: 'Breed',
      accessor: 'breed.display_name',
      headerClassName: 'wordwrap',
      minWidth: 120
    },
    {
      Header: 'Kraal Tag ID',
      id: 'tag.diagri_id',
      accessor: (d) => {
        return { id: d.id, identifier: d.tag?.diagri_id };
      },
      headerClassName: 'wordwrap',
      sortMethod: (a, b) => {
        return a?.identifier?.localeCompare(b?.identifier);
      },
      Cell: (prop) => (
        <a href="#" onClick={() => props.history.push('/tag/' + prop.value.id)}>
          {prop.value.identifier}
        </a>
      ),
      minWidth: 120
    },
    {
      Header: 'Brand',
      accessor: 'brand',
      headerClassName: 'wordwrap',
      minWidth: 120
    },
    {
      Header: 'Assigned Geofence',
      id: 'Assignedg',
      accessor: (d) => {
        return {
          identifier:
            d.geofences.length > 0
              ? d.geofences.filter((a) => a.is_master != true).length > 0
                ? d.geofences
                    .filter((a) => a.is_master != true)
                    .map((x) => {
                      return x.name;
                    })
                    .join(',')
                : ''
              : '',
          name:
            d.geofences.length > 0
              ? d.geofences.filter((a) => a.is_master != true).length > 0
                ? d.geofences
                    .filter((a) => a.is_master != true)
                    .map((x, index) => {
                      return (
                        <a
                          key={index}
                          href="#"
                          onClick={() =>
                            this.props.history.push('geofence/' + x.id)
                          }
                        >
                          {x.name}
                        </a>
                      );
                    })
                    .reduce((prev, curr) => [prev, ', ', curr])
                : ''
              : ''
        };
      },
      headerClassName: 'wordwrap',
      Cell: (props) => {
        return props.value.name;
      },
      sortMethod: (a, b) => {
        return a.identifier.localeCompare(b.identifier);
      },
      minWidth: 150
    },
    {
      Header: 'Livestock Type',
      id: 'LivestockType',
      accessor: (d) => {
        return d.stock_type ? capitalize(d.stock_type) : '';
      },
      headerClassName: 'wordwrap',
      minWidth: 150
    },
    {
      Header: 'Age',
      id: 'age',
      accessor: (d) => {
        let value = '';
        if (
          d.dob_at &&
          d.dob_at != '' &&
          d.dob_at != '0000-00-00 00:00:00' &&
          !d.dob_at.includes('d.dob_at')
        ) {
          let b = moment(d.dob_at);
          let a = moment();
          let years = a.diff(b, 'year');
          b.add(years, 'years');
          let months = a.diff(b, 'months');
          b.add(months, 'months');
          let days = a.diff(b, 'days');
          value = years + 'y ' + months + 'm ' + days + 'd';
          return value;
        } else {
          return '';
        }
        //return <div>{value} </div>
      },
      headerClassName: 'wordwrap',
      sortMethod: (a, b) => {
        return a.localeCompare(b);
      },
      minWidth: 150
      //filterable:false
    },
    {
      Header: 'Breeding Status',
      accessor: 'breeding_status',
      headerClassName: 'wordwrap',
      minWidth: 160
    }
  ];

  const columns2 = [
    {
      Header: () => (
        <div className="position-relative form-check">
          <SelectionPopover
            isOpen={state.isOpenSelectionPopover['2']}
            onClickOnThisPage={() => onClickOnThisPage()}
            onClickAllRecords={() => onClickAllRecords()}
            setOpen={(isOpen) =>
              setState({
                isOpenSelectionPopover: {
                  [state.activeTab]:
                    isOpen !== undefined
                      ? isOpen
                      : !state.isOpenSelectionPopover[state.activeTab]
                }
              })
            }
            content={
              <input
                type="checkbox"
                defaultChecked={state.selectAll}
                onChange={() => {
                  onToggleSelectionPopover();
                  if (state.selectAll) {
                    setState({
                      selectAll: !state.selectAll
                    });
                    setState((state) => ({
                      ...state,
                      linked_livestock: [],
                      selectedLivestock: []
                    }));
                  }
                }}
              />
            }
          />
        </div>
      ),
      id: 'select',
      accessor: (d) => {
        return { id: d.id };
      },
      Cell: (props) => (
        <FormGroup check>
          <Input
            type="checkbox"
            value={props.value.id}
            defaultChecked={state.selectedLivestock.includes(props.value.id)}
            onChange={(e) => onSelectLivestock(parseInt(e.target.value))}
            key={props.value.id}
          />{' '}
        </FormGroup>
      ),
      headerClassName: 'wordwrap',
      sortable: false,
      filterable: false,
      maxWidth: 85
    },
    {
      Header: 'Livestock ID',
      id: 'glivestckid',
      accessor: (d) => {
        return { id: d.id, identifier: d.identifier };
      },
      headerClassName: 'wordwrap',
      sortMethod: (a, b) => {
        return a.identifier.localeCompare(b.identifier);
      },
      Cell: (prop) => (
        <a
          href="#"
          onClick={() => props.history.push('/animal/' + prop.value.id)}
        >
          {prop.value.identifier}
        </a>
      )
    },
    {
      Header: 'Mgmt Tag ID',
      id: 'eartag_management_id',
      accessor: (d) => {
        return d.eartag_management_id.indexOf('mgmt~tmp~') > -1
          ? ''
          : d.eartag_management_id;
      },
      headerClassName: 'wordwrap',
      sortMethod: (a, b) => {
        return a.localeCompare(b);
      },

      minWidth: 130
    },
    {
      Header: 'Sex',
      accessor: 'sex',
      headerClassName: 'wordwrap',
      Cell: (props) => {
        return capitalize(props.value);
      }
    },
    {
      Header: 'Colour',
      accessor: 'colour',
      headerClassName: 'wordwrap'
    },
    {
      Header: 'Breed',
      accessor: 'breed.display_name',
      headerClassName: 'wordwrap',
      minWidth: 120
    },
    {
      Header: 'Kraal Tag ID',
      id: 'tag.diagri_id',
      accessor: (d) => {
        return { id: d.id, identifier: d.tag?.diagri_id };
      },
      headerClassName: 'wordwrap',
      sortMethod: (a, b) => {
        return a?.identifier?.localeCompare(b?.identifier);
      },
      Cell: (prop) => (
        <a href="#" onClick={() => props.history.push('/tag/' + prop.value.id)}>
          {prop.value.identifier}
        </a>
      ),
      minWidth: 120
    },
    {
      Header: 'Brand',
      accessor: 'brand',
      headerClassName: 'wordwrap',
      minWidth: 120
    },
    {
      Header: 'Assigned Geofence',
      id: 'Assignedg',
      accessor: (d) => {
        return {
          identifier:
            d.geofences.length > 0
              ? d.geofences.filter((a) => a.is_master != true).length > 0
                ? d.geofences
                    .filter((a) => a.is_master != true)
                    .map((x) => {
                      return x.name;
                    })
                    .join(',')
                : ''
              : '',
          name:
            d.geofences.length > 0
              ? d.geofences.filter((a) => a.is_master != true).length > 0
                ? d.geofences
                    .filter((a) => a.is_master != true)
                    .map((x, index) => {
                      return (
                        <a
                          key={index}
                          href="#"
                          onClick={() =>
                            this.props.history.push('geofence/' + x.id)
                          }
                        >
                          {x.name}
                        </a>
                      );
                    })
                    .reduce((prev, curr) => [prev, ', ', curr])
                : ''
              : ''
        };
      },
      headerClassName: 'wordwrap',
      Cell: (props) => {
        return props.value.name;
      },
      sortMethod: (a, b) => {
        return a.identifier.localeCompare(b.identifier);
      },
      minWidth: 150
    },
    {
      Header: 'Livestock Type',
      id: 'LivestockType',
      accessor: (d) => {
        return d.stock_type ? capitalize(d.stock_type) : '';
      },
      headerClassName: 'wordwrap',
      minWidth: 150
    },
    {
      Header: 'Age',
      id: 'age',
      accessor: (d) => {
        let value = '';
        if (
          d.dob_at &&
          d.dob_at != '' &&
          d.dob_at != '0000-00-00 00:00:00' &&
          !d.dob_at.includes('d.dob_at')
        ) {
          let b = moment(d.dob_at);
          let a = moment();
          let years = a.diff(b, 'year');
          b.add(years, 'years');
          let months = a.diff(b, 'months');
          b.add(months, 'months');
          let days = a.diff(b, 'days');
          value = years + 'y ' + months + 'm ' + days + 'd';
          return value;
        } else {
          return '';
        }
        //return <div>{value} </div>
      },
      headerClassName: 'wordwrap',
      sortMethod: (a, b) => {
        return a.localeCompare(b);
      },
      minWidth: 150
      //filterable:false
    },
    {
      Header: 'Breeding Status',
      accessor: 'breeding_status',
      headerClassName: 'wordwrap',
      minWidth: 160
    }
  ];
  return (
    <div>
      <Row className="">
        <Col xs="6" md="6" lg="6">
          <h4 className="">Group {state.label.name}</h4>
          <Breadcrumb>
            <BreadcrumbItem>
              <a href="#" onClick={() => props.history.push('/groups')}>
                List of Groups
              </a>
            </BreadcrumbItem>
            <BreadcrumbItem>Group {state.label.name}</BreadcrumbItem>
          </Breadcrumb>
        </Col>
        <Col xs="6" md="6" lg="6">
          <PrevNextButtons
            history={props.history}
            nextId={state.label.next_id}
            prevId={state.label.prev_id}
            path="group"
          />
          {!state.edit && (
            <ButtonDropdown
              className="float-right"
              isOpen={state.dropdownOpen}
              toggle={toggleButton}
            >
              <DropdownToggle className="remove-mr" caret>
                Actions
              </DropdownToggle>
              <DropdownMenu>
                {isCustomGroup(state.label) && (
                  <DropdownItem
                    onClick={() => props.history.push('/group/new')}
                  >
                    Add group
                  </DropdownItem>
                )}
                <DropdownItem onClick={() => setState({ edit: true })}>
                  Edit group
                </DropdownItem>
                {isCustomGroup(state.label) && (
                  <DropdownItem onClick={() => toggleModal('delete_modal')}>
                    Archive group
                  </DropdownItem>
                )}
                {isCustomGroup(state.label) && !isVirtualGroup(state.label) ? (
                  <DropdownItem
                    onClick={() => {
                      toggleModal('chooseGeofencesModal');
                    }}
                  >
                    Edit group geofence
                  </DropdownItem>
                ) : null}
              </DropdownMenu>
            </ButtonDropdown>
          )}
          {state.edit && (
            <div>
              <Button
                color="primary"
                className="float-right"
                onClick={() => saveLabel()}
              >
                Save
              </Button>
              <Button
                className="float-right outline"
                onClick={() => setState({ edit: false })}
              >
                Cancel
              </Button>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="12" lg="12" className="global-table">
          <Card>
            <Row className="pad-15 global-heading-tag">
              <Col xs="12" md="12" lg="6">
                <h5 className="details-title">
                  Group {state.label.name} details
                </h5>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="12" lg="12">
                {!state.edit && (
                  <Table>
                    <tbody>
                      <tr>
                        <td>
                          <b>Group ID</b>
                        </td>
                        <td>{state.label.identifier}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Group Name</b>
                        </td>
                        <td>{state.label.name}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Group Type</b>
                        </td>
                        <td style={{ textTransform: 'capitalize' }}>
                          {state.label.main_type}
                        </td>
                      </tr>
                      {state.label.main_type === 'collection' && (
                        <tr>
                          <td>
                            <b>Collection</b>
                          </td>
                          <td style={{ textTransform: 'capitalize' }}>
                            {state.label.collection
                              .map(({ label }) => {
                                return label;
                              })
                              .join(', ')}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td>
                          <b>Group Color</b>
                        </td>
                        <td>
                          <div
                            className="label-tag no-margin"
                            style={{ background: state.label.colour }}
                          >
                            {getColorName(state.label.colour_name) ||
                              getColorName(state.label.colour)}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Farm</b>
                        </td>
                        <td>
                          <a
                            href="#"
                            onClick={() =>
                              props.history.push(
                                '/farm/' + state.label.farm?.id
                              )
                            }
                          >
                            {state.label.farm?.name}
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>No. of Livestock in Group</b>
                        </td>
                        <td>{state.label.animals_count}</td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Assigned Livestock</b>
                        </td>
                        <td>
                          {getAssignedAnimalsList().map((animal, index) => {
                            return (
                              <a
                                href="#"
                                onClick={() =>
                                  props.history.push('/animal/' + animal.id)
                                }
                              >
                                {index !== 0 && <span>, </span>}
                                {animal.identifier}{' '}
                              </a>
                            );
                          })}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                )}
                {state.edit && (
                  <Table>
                    <tbody>
                      <tr>
                        <td className="v-align-middle">
                          <b>Group Name</b>
                        </td>
                        <td>
                          {state.label.type === 'custom' ? (
                            <Input
                              name="name"
                              defaultValue={state.label.name}
                              onChange={(e) =>
                                onLabelChange(e.target.value, e.target.name)
                              }
                            ></Input>
                          ) : (
                            state.label.name
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Group Type</b>
                        </td>
                        <td>
                          <Select
                            value={selectedTypeMapping[state.label.main_type]}
                            onChange={(e) => {
                              setState({
                                label: {
                                  ...state.label,
                                  main_type: e.value,
                                  collection: []
                                }
                              });
                            }}
                            options={[
                              {
                                value: 'virtual',
                                label: 'Virtual'
                              },
                              {
                                value: 'physical',
                                label: 'Physical'
                              },
                              {
                                value: 'collection',
                                label: 'Collection'
                              }
                            ]}
                          />
                        </td>
                      </tr>
                      {state.label.main_type === 'collection' && (
                        <tr>
                          <td className="v-align-middle">
                            <b>Groups</b>
                          </td>
                          <td>
                            <Select
                              value={state.label.collection}
                              onChange={(group) => {
                                onLabelChange(group, 'collection');
                              }}
                              options={state.groups}
                              isMulti={true}
                              isSearchable={true}
                            />
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td className="v-align-middle">
                          <b>Group Color</b>
                        </td>
                        <td>
                          <CompactPicker
                            name="colour"
                            color={state.label.colour}
                            onChange={(e) => onLabelChange(e.hex, 'colour')}
                          ></CompactPicker>
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Farm</b>
                        </td>
                        <td>
                          <Select
                            value={farmState?.options?.find(
                              (opt) => opt.value === state.label.farm_id
                            )}
                            onChange={(e) => onLabelChange(e.value, 'farm_id')}
                            options={farmState?.options}
                            isSearchable={true}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                )}
              </Col>
            </Row>
          </Card>
          <Row className="mb-4">
            <Col lg="12" md="12" sm="12">
              {state.edit && (
                <div>
                  <Button
                    color="primary"
                    className="float-right"
                    onClick={() => saveLabel()}
                  >
                    Save
                  </Button>
                  <Button
                    className="float-right outline"
                    onClick={() => setState({ edit: false })}
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="12" lg="12">
          <Card>
            <Row>
              <Col>
                <Nav tabs className="fancy-tabs">
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: state.activeTab === '1'
                      })}
                      onClick={() => onChangeTab('1')}
                    >
                      Included Livestock
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: state.activeTab === '2'
                      })}
                      onClick={() => onChangeTab('2')}
                    >
                      Excluded Livestock
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md="12" lg="12">
                <TabContent className="no-bg" activeTab={state.activeTab}>
                  <TabPane tabId="1">
                    <Row className="pad-10">
                      <Col sm="6" className="mb-0">
                        <h5>Livestock</h5>
                      </Col>
                      {(state.selectedLivestock?.length || state.selectAll) &&
                      state.label.main_type !== 'collection' &&
                      isCustomGroup(state.label) ? (
                        <Col xs="12" md="12" lg="6">
                          <ButtonDropdown
                            className="float-right"
                            isOpen={state.includedActionsOpen}
                            toggle={() => toggleDropdown('includedActionsOpen')}
                          >
                            <DropdownToggle className="remove-mr" caret>
                              Actions
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem onClick={removeFromTheGroup}>
                                Remove from group
                              </DropdownItem>
                            </DropdownMenu>
                          </ButtonDropdown>
                        </Col>
                      ) : null}
                    </Row>
                    <Row>
                      <Col xs="12" md="12" lg="12">
                        <ReactTable
                          minRows={0}
                          data={state.includedLivestock}
                          columns={columns1}
                          resizable={true}
                          defaultPageSize={25}
                          showPagination={state.livestock_opts.length > 0}
                          filterable={true}
                          defaultFilterMethod={filterCaseInsensitive}
                          onFetchData={(props) => {
                            const data = props.data.length
                              ? props.sortedData.slice(0, props.pageSize)
                              : state.includedLivestock;
                            setState({ tableIncludedData: data });
                          }}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row className="pad-10">
                      <Col sm="6" className="mb-0">
                        <h5>Livestock</h5>
                      </Col>
                      {state.selectedLivestock?.length &&
                      state.label.main_type !== 'collection' ? (
                        <Col xs="12" md="12" lg="6">
                          <ButtonDropdown
                            className="float-right"
                            isOpen={state.excludedActionsOpen}
                            toggle={() => toggleDropdown('excludedActionsOpen')}
                          >
                            <DropdownToggle className="remove-mr" caret>
                              Actions
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={hasAnimalDifferentGeofence}
                              >
                                Add to the group
                              </DropdownItem>
                            </DropdownMenu>
                          </ButtonDropdown>
                        </Col>
                      ) : null}
                    </Row>
                    <Row>
                      <Col xs="12" md="12" lg="12">
                        <ReactTable
                          minRows={0}
                          data={state.excludedLivestock}
                          columns={columns2}
                          resizable={true}
                          defaultPageSize={25}
                          showPagination={state.livestock_opts.length > 0}
                          filterable={true}
                          defaultFilterMethod={filterCaseInsensitive}
                          onFetchData={(props) => {
                            const data = props.data.length
                              ? props.sortedData.slice(0, props.pageSize)
                              : state.excludedLivestock;
                            setState({ tableExcludedData: data });
                          }}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Modal isOpen={state.delete_modal} className={props.className}>
        <ModalBody>
          <br />
          <h5 className="text-center">
            <b>Archive group</b>
          </h5>
          <br />
          <br />
          <br />
          Are you sure you want to archive this group? This action cannot be
          undone.
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => toggleModal('delete_modal')}>
            Cancel
          </Button>{' '}
          <Button color="danger" onClick={() => deleteLabel()}>
            Archive
          </Button>
        </ModalFooter>
      </Modal>
      <ChooseGeofencesModal
        isOpen={state.chooseGeofencesModal}
        options={state.geofenceOptions}
        value={state.selectedGeofence}
        handleGeofenceChange={onSelectGeofence}
        onCancel={() => toggleModal('chooseGeofencesModal')}
        onSave={onSaveGeofence}
      />
      <ConfirmationModal
        isOpen={state.isOpenConfirmationModal}
        description={ANIMAL_ERRORS.UPDATE_GEOFENCE_TO_GROUP}
        onCancel={() => {
          setState({ isOpenConfirmationModal: false });
        }}
        onSubmit={() => {
          addToTheGroup();
          setState({
            isOpenConfirmationModal: false
          });
        }}
        submitText={'Yes'}
        submitButtonType={'primary'}
      />
    </div>
  );
};

export default LabelPage;
