import React from 'react';
import { Button } from 'reactstrap';

import TimeFilters from './partials/time';
import LabelFilters from './partials/labels';
import GeofenceFilters from './partials/geofences';
import FarmFilters from './partials/farms';
import SiteFilters from './partials/sites';
import AnimalFilters from './partials/animals';
import RuleFilters from './partials/rules';
import TagFilters from './partials/tags';
import SiteTypeFilters from './partials/site-types.jsx';

class Filters extends React.Component {
  //Tabs
  constructor(props) {
    super(props);
    this.state = {
      filter_open: props.open,
      filter_types: props.filter_types,
      filters: {
        time: '',
        labels: props.farms || [],
        geofences: props.farms || [],
        farms: props.farms || [],
        tags: [],
        sites: [],
        animals: [],
        rules: [],
        site_types: []
      }
    };
    this.onChange = this.onChange.bind(this);
    this.toggleFilters = this.toggleFilters.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.open !== this.state.filter_open) {
      this.setState({ filter_open: nextProps.open });
    }

    if (nextProps?.farms?.length !== this.state.filters.farms.length) {
      this.setState({
        filters: {
          farms: nextProps.farms || []
        }
      });
    }
  }

  onChange = (value, field) => {
    this.setState((state) => ({
      ...state,
      filters: {
        ...this.state.filters,
        [field]: value
      }
    }));
  };
  toggleFilters() {
    this.setState({
      filter_open: !this.state.filter_open
    });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }
  resetFilters() {
    this.setState(
      {
        filters: {
          time: '',
          labels: [],
          geofences: [],
          farms: [],
          tags: [],
          sites: [],
          animals: [],
          rules: [],
          site_types: []
        }
      },
      () => this.updateFilters()
    );
  }
  updateFilters() {
    this.props.updateFilters({
      ...this.state.filters,
      ...this.state.filter_open
    });

    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  render() {
    return (
      <aside
        className={this.state.filter_open ? 'active' : ''}
        id="track-filters"
      >
        <div className="filters-header">
          <div className="close" onClick={() => this.toggleFilters()}>
            <i className="mdi mdi-close"></i>
          </div>
          <h5>All filters</h5>
        </div>
        <div className="filters-body">
          {this.state.filter_types.includes('farm') && (
            <FarmFilters
              onChange={this.onChange}
              isMulti={this.props.isMulti}
              selected={this.state.filters.farms}
            >
              {' '}
            </FarmFilters>
          )}
          {this.state.filter_types.includes('geofence') && (
            <GeofenceFilters
              onChange={this.onChange}
              selected={this.state.filters.geofences}
            >
              {' '}
            </GeofenceFilters>
          )}
          {this.state.filter_types.includes('site') && (
            <SiteFilters
              onChange={this.onChange}
              selected={this.state.filters.sites}
            >
              {' '}
            </SiteFilters>
          )}
          {this.state.filter_types.includes('site-type') && (
            <SiteTypeFilters
              onChange={this.onChange}
              selected={this.state.filters.site_types}
            >
              {' '}
            </SiteTypeFilters>
          )}
          {this.state.filter_types.includes('label') && (
            <LabelFilters
              onChange={this.onChange}
              selected={this.state.filters.labels}
            >
              {' '}
            </LabelFilters>
          )}
          {this.state.filter_types.includes('rule') && (
            <RuleFilters
              onChange={this.onChange}
              selected={this.state.filters.rules}
            >
              {' '}
            </RuleFilters>
          )}
          {this.state.filter_types.includes('tag') && (
            <TagFilters
              onChange={this.onChange}
              selected={this.state.filters.tags}
            >
              {' '}
            </TagFilters>
          )}
          {this.state.filter_types.includes('animal') && (
            <AnimalFilters
              onChange={this.onChange}
              selected={this.state.filters.animals}
            >
              {' '}
            </AnimalFilters>
          )}
          {this.state.filter_types.includes('time') && (
            <TimeFilters
              selected={this.state.filters.time}
              onChange={this.onChange}
            ></TimeFilters>
          )}
        </div>
        <div className="filters-footer">
          <Button
            color="primary"
            className={this.props.hideReset ? 'btn-block' : ''}
            onClick={() => this.updateFilters()}
          >
            Apply Filters
          </Button>
          {!this.props.hideReset && (
            <Button
              className="outline float-right"
              onClick={() => this.resetFilters()}
            >
              Reset
            </Button>
          )}
        </div>
      </aside>
    );
  }
}

export default Filters;
