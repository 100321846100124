import axios from '../../axios';

const service = {
  async getFarms() {
    return await axios({
      method: 'get',
      url: '/farms'
    });
  }
};

export default service;
