import { toast } from 'react-toastify';
import messages from '../constants/messages';

const option = {
  closeButton: true,
  autoClose: 3000,
  draggablePercent: 80,
  newestOnTop: true,
  pauseOnHover: false,
  position: toast.POSITION.TOP_RIGHT,
  hideProgressBar: true,
  // type: toast.TYPE.ERROR,
  className: 'black-background',
  bodyClassName: 'grow-font-size',
  progressClassName: 'fancy-progress-bar'
};

export function networkErrorToastHandler(error) {
  option.autoClose = 6000;
  if (error.response && error.response.data && error.response.data.message) {
    toast.error(error.response.data.message, option);
  } else {
    toast.error(messages.DEFAULT_ERROR, option);
  }
}

export function errorToastHandler(error) {
  option.autoClose = 6000;
  toast.error(error, option);
}

export function successToastHandler(message) {
  toast.success(message, option);
}
