import React from 'react';
import {
  Map,
  FeatureGroup,
  GeoJSON,
  LayersControl,
  Popup
} from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import L from 'leaflet';
import { GoogleLayer } from 'react-leaflet-google';
import 'react-leaflet-fullscreen/dist/styles.css';
import _ from 'lodash';
import Feature from './SiteFeature';
import { Link } from 'react-router-dom';

const { BaseLayer } = LayersControl;
const key = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const satellite = 'SATELLITE';

class MapSimple extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      farm: props.farm,
      position: [-29.132312, 26.057016],
      editing: props.editing,
      draw: {},
      new: this.props.new,
      bounds: [
        [-31.0, 13.0],
        [-31.0, 33.0]
      ],
      features: props.site.features,
      color: this.props.color,
      leaflet_id: null,
      geofences: [],
      enableDraw: true,
      set: false,
      site: props.site
    };
    this.handleGeoJSON = this.handleGeoJSON.bind(this);
    this.created = this.created.bind(this);
    this.edited = this.edited.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }
  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }
  async componentDidMount() {
    this.handleGeoJSON(this.props.farm, this.props.site);
  }
  centerMap(farm) {
    const group = new L.geoJson(farm);
    let bounds = group.getBounds();
    const corner2 = L.latLng(
      parseFloat(bounds._southWest?.lat),
      parseFloat(bounds._southWest?.lng)
    );
    const corner1 = L.latLng(
      parseFloat(bounds._northEast?.lat),
      parseFloat(bounds._northEast?.lng)
    );
    bounds = L.latLngBounds(corner1, corner2);
    this.setState({
      bounds: bounds
    });
  }
  handleGeoJSON(farm, site) {
    let geo = {
      type: 'FeatureCollection',
      features: []
    };
    let master = {
      type: 'FeatureCollection',
      features: []
    };
    let sites = {
      type: 'FeatureCollection',
      features: []
    };
    if (!farm.meta || farm.meta.features.length == 0) {
      return;
    }
    for (let gf of farm.meta.features) {
      if (gf.is_master) {
        master.features.push({ ...gf.geo_json, color: gf.color });
      } else {
        geo.features.push({ ...gf.geo_json, color: gf.color });
      }
    }
    for (let st of farm.meta.sites) {
      if (site?.geo_json?.properties?.identifier !== st.identifier) {
        sites.features.push({ ...st.geo_json, color: st.color });
      }
    }

    this.setState({
      features_geo: geo,
      features_master: master,
      features_sites: sites,
      farm,
      site
    });

    const center = site?.geo_json?.type ? site?.geo_json : master;
    this.centerMap(center);
  }
  async componentWillReceiveProps(nextProps) {
    if (nextProps.isFullScreen !== this.props.isFullScreen) {
      this.refs.map.leafletElement._onResize();
    }
    if (nextProps.new !== this.state.new) {
      this.setState({
        new: nextProps.new
      });
    }
    if (!_.isEqual(nextProps.farm, this.state.farm)) {
      this.handleGeoJSON(nextProps.farm, nextProps.site);
    }
    if (!_.isEqual(nextProps.site, this.state.site)) {
      this.handleGeoJSON(nextProps.farm, nextProps.site);
    }
    if (nextProps.color !== this.state.color) {
      const map = this.refs.map;
      map.leafletElement.eachLayer((layer) => {
        if (layer._leaflet_id === this.state.leaflet_id) {
          layer.setStyle({
            color: nextProps.color
          });
        }
      });
      // eslint-disable-next-line react/no-string-refs
      this.setState({
        color: nextProps.color
      });
    }
  }
  created(e) {
    this.setState({
      ...this.state,
      enableDraw: false
    });
    const layers = L.layerGroup().addLayer(e.layer);
    this.setState({
      leaflet_id: e.layer._leaflet_id
    });

    layers.eachLayer((a) => {
      if (this.props.onCreate) {
        const geoJson = a.toGeoJSON();
        if (a._mRadius) {
          geoJson.properties.radius = a._mRadius;
        } else if (a._radius) {
          geoJson.properties.radius = a._radius;
        }
        this.props.onCreate(geoJson);
      }
    });
  }
  edited(e) {
    e.layers.eachLayer((a) => {
      if (this.props.onEdit) {
        const geoJson = a.toGeoJSON();
        if (a._mRadius) {
          geoJson.properties.radius = a._mRadius;
        } else if (a._radius) {
          geoJson.properties.radius = a._radius;
        }
        this.props.onEdit(geoJson);
      }
    });
  }
  onDelete() {
    this.setState({
      ...this.state,
      enableDraw: true
    });
  }
  render() {
    return (
      <Map
        ref="map"
        scrollWheelZoom={false}
        className={`mapsites farms mapobject ${
          this.props.editing ? 'editing' : ''
        }`}
        bounds={this.state.bounds}
        zoom={10}
      >
        <FeatureGroup
          key={JSON.stringify(this.state.site.new)}
          ref="featuregroup"
          onEachFeature={this.onFeature}
        >
          <EditControl
            ref="drawControl"
            position="topright"
            onEdited={this.edited}
            onCreated={this.created}
            onDeleted={this.onDelete}
            draw={{
              polygon: false,
              polyline: false,
              point: false,
              marker: false,
              circle: this.props.editing &&
                this.state.enableDraw && {
                  shapeOptions: {
                    color: this.state.color,
                    fillOpacity: '0.5',
                    opacity: 0.5
                  }
                },
              circlemarker: this.props.editing &&
                this.state.enableDraw && {
                  shapeOptions: {
                    color: this.state.color,
                    fillOpacity: '0.5',
                    opacity: 0.5
                  }
                },
              rectangle: this.props.editing &&
                this.state.enableDraw && {
                  shapeOptions: {
                    color: this.state.color,
                    fillOpacity: '0.5',
                    opacity: 0.5
                  }
                }
            }}
            edit={{
              edit: true,
              delete: true
            }}
          />
          {this.state.site.geo_json.geometry && !this.state.new && (
            <Feature
              new={this.state.new}
              color={this.state.color}
              type={this.state.site.geo_json.geometry.type}
              geometry={this.state.site.geo_json.geometry.coordinates}
              properties={this.state.site.geo_json.properties}
              key={JSON.stringify(this.state.site)}
            />
          )}
        </FeatureGroup>
        <LayersControl collapsed={!L.Browser.mobile} position="bottomleft">
          <BaseLayer checked name="Google Maps Satellite">
            <GoogleLayer googlekey={key} maptype={satellite} />
          </BaseLayer>
          <BaseLayer name="Google Maps Hybrid">
            <GoogleLayer googlekey={key} maptype={'HYBRID'} />
          </BaseLayer>
          <BaseLayer name="Google Maps Terrain">
            <GoogleLayer googlekey={key} maptype={'TERRAIN'} />
          </BaseLayer>
          <BaseLayer name="Google Maps Roadmap">
            <GoogleLayer googlekey={key} maptype={'ROADMAP'} />
          </BaseLayer>
        </LayersControl>

        {this.state?.features_master?.features?.map((item) => {
          return (
            <GeoJSON
              key={item?.properties?.id}
              data={item}
              style={{
                color: item.color,
                weight: 3,
                fillOpacity: 0.2,
                opacity: 0.8
              }}
            >
              <Popup>
                <b>{item.properties.name}</b>
                <br />
                <br />
                {item.properties.description}
                <br />
                <br />
                <Link to={`/geofence/${item.properties.id}`}>
                  <span>More details</span>
                </Link>
              </Popup>
            </GeoJSON>
          );
        })}
        {this.state?.features_geo?.features?.map((item) => {
          return (
            <GeoJSON
              key={item?.properties?.id}
              data={item}
              style={{
                color: item.color,
                weight: 2,
                fillOpacity: 0.2,
                opacity: 0.8
              }}
            >
              <Popup>
                <b>{item.properties.name}</b>
                <br />
                <br />
                {item.properties.description}
                <br />
                <br />
                <Link to={`/geofence/${item.properties.id}`}>
                  <span>More details</span>
                </Link>
              </Popup>
            </GeoJSON>
          );
        })}
        {this.state?.features_sites?.features
          ?.filter((item) => item.geometry?.type)
          .map((item) => {
            return (
              <Feature
                color={item.color}
                type={item?.geometry?.type}
                geometry={item?.geometry?.coordinates}
                properties={item.properties}
                key={item?.properties?.id}
              />
            );
          })}
      </Map>
    );
  }
}

export default MapSimple;
