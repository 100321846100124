import messages from '../constants/messages';
import moment from 'moment';

export const getTimeInterval = (view) => {
  let interval = null;
  switch (view) {
    case 'work_week':
      interval = 'week';
      break;
    default: {
      interval = view;
    }
  }
  return interval;
};

export const validateEvent = (event) => {
  if (!event.title) {
    return messages.EVENT_TITLE_REQUIRED;
  }
  if (!event.startDate) {
    return messages.EVENT_START_DATE_REQUIRE;
  }
  if (!event.endDate) {
    return messages.EVENT_END_DATE_REQUIRED;
  }
  if (event.relation_type && !event.relation_id) {
    return messages.EVENT_PROVIDE_RELATION;
  }
  const currentTimestamp = +new Date();
  const startTimestamp = +new Date(event.startDate);
  const endTimestamp = +new Date(event.endDate);
  const remindTimestamp = event.remindDate ? +new Date(event.remindDate) : null;

  if (startTimestamp >= endTimestamp) {
    return messages.EVENT_START_DATE_AFTER_END_DATE;
  }
  if (remindTimestamp && remindTimestamp < currentTimestamp) {
    return messages.EVENT_REMIND_DATE_IN_PAST;
  }
  if (remindTimestamp && remindTimestamp > startTimestamp) {
    return messages.EVENT_REMIND_DATE_AFTER_START_DATE;
  }
  if (event.recurringType && !event.recurringEndDate) {
    return messages.EVENT_PROVIDE_RECURRING_DATE;
  }

  return null;
};

export const getEventStatus = (status) => {
  switch (status) {
    case null: {
      return 'Pending';
    }
    case 'accepted': {
      return 'Accepted';
    }
    case 'declined': {
      return 'Declined';
    }
    default: {
      return status;
    }
  }
};

export const getEventNullState = () => {
  return {
    title: null,
    description: null,
    startDate: moment().add(1, 'h').format('D/M/YYYY HH:00'),
    endDate: moment().add(2, 'h').format('D/M/YYYY HH:00'),
    remindDate: null,
    relationId: null,
    relationType: null,
    relationName: null,
    recurringEndDate: null,
    recurringType: null,
    userIds: [],
    userEmails: []
  };
};
