import axios from '../../axios';

const service = {
  async getAllTags() {
    return await axios({
      method: 'get',
      url: '/tags/all'
    });
  },

  async searchTags(params) {
    return await axios({
      method: 'get',
      url: '/tags/search',
      params: params
    });
  },

  async addTag(payload) {
    return await axios({
      method: 'post',
      url: '/tags/create',
      data: payload
    });
  },

  async updateMany(payload) {
    return await axios({
      method: 'put',
      url: '/admin/tags',
      data: payload
    });
  }
};

export default service;
