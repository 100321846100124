import React, { memo } from 'react';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';

import { capitalize } from '../../../helpers/common';
import { TRIAL_PLAN } from '../../../constants/subscription';

const PlanRow = () => {
  const subscriptionState = useSelector((state) => state.subscription);
  return (
    <Row>
      <Col sm="4" md="4" lg="3"></Col>
      {subscriptionState.plans.map((plan, index) => {
        const isSelected = plan.type === subscriptionState?.myPlan?.type;

        return (
          <Col
            className={`align-center plan plan-header ${isSelected && "selected-plan-col"}`}
            style={{
              backgroundColor: plan.color,
            }}
            key={plan.title + index}
          >
            <div className="title">{capitalize(plan.title)}</div>
            <div className="price">
              {plan.type === TRIAL_PLAN ? (
                'Free'
              ) : (
                <>
                  <div>${plan.total_price}</div>
                  <div className="minor-text">per month</div>
                </>
              )}
            </div>
          </Col>
        );
      })}
    </Row>
  );
};

export default memo(PlanRow);
