import Login2 from '../views/authentication/login2.jsx';
import Register2 from '../views/authentication/register2.jsx';
import Lockscreen from '../views/authentication/lockscreen.jsx';
import Recoverpwd from '../views/authentication/recover-pwd.jsx';
import Maintanance from '../views/authentication/maintanance.jsx';
import Verify from '../views/authentication/verify.jsx';
import Reset from '../views/authentication/reset.jsx';
import SetPassword from '../views/pages/SetPassword';

const authRoutes = [
  {
    path: '/auth/login',
    name: 'Login to Diagri',
    icon: 'mdi mdi-account-key',
    component: Login2
  },
  {
    path: '/auth/register',
    name: 'Register',
    icon: 'mdi mdi-account-plus',
    component: Register2
  },
  {
    path: '/authentication/lockscreen',
    name: 'Lockscreen',
    icon: 'mdi mdi-account-off',
    component: Lockscreen
  },
  {
    path: '/auth/recover-pwd',
    name: 'Recover Password',
    icon: 'mdi mdi-account-convert',
    component: Recoverpwd
  },
  {
    path: '/authentication/maintanance',
    name: 'Maintanance',
    icon: 'mdi mdi-pencil-box-outline',
    component: Maintanance
  },
  {
    path: '/account/verify/:token',
    name: 'verify',
    icon: 'mdi mdi-pencil-box-outline',
    component: Verify
  },
  {
    path: '/account/password-reset/:token',
    name: 'reset',
    icon: 'mdi mdi-pencil-box-outline',
    component: Reset
  },
  {
    path: '/account/password-set/:token',
    name: 'set',
    icon: 'mdi mdi-pencil-box-outline',
    component: SetPassword
  }
];
export default authRoutes;
