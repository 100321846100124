import axios from '../../axios';

const service = {
  async getAllGateways() {
    return await axios({
      method: 'get',
      url: '/gateways/all'
    });
  },

  async createNewGateway(payload) {
    return await axios({
      method: 'post',
      url: '/gateways',
      data: payload
    });
  },

  async searchGateways(params) {
    return await axios({
      method: 'get',
      url: '/gateways/search',
      params: params
    });
  }
};

export default service;
