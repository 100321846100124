import React from 'react';
import {
  Row,
  Col,
  Card,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Input,
  Table,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import moment from 'moment';

import axios from '../../../axios';

class EditGateway extends React.Component {
  //Tabs
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      selected_geofences: [],
      delete_modal: false,
      gateway: {},
      editData: {},
      dropdownOpen: false,
      activeTab: '1'
    };
    this.toggle = this.toggle.bind(this);
    this.onChange = this.onChange.bind(this);
    this.enableEditing = this.enableEditing.bind(this);
    this.toggleButton = this.toggleButton.bind(this);
    this.onCancelEdit = this.onCancelEdit.bind(this);
    this.saveGateway = this.saveGateway.bind(this);
  }
  async componentDidMount() {
    //TODO: Get gateway
    this.getGateway();
  }
  toggleModal(modal) {
    this.setState((state) => ({
      ...state,
      [modal]: !state[modal]
    }));
  }
  async getGateway() {
    let id = this.props.match.params.id;
    let response = await axios.get('gateways/admin/' + id);
    if (response.status === 200) {
      let gateway = response.data;
      this.setState((state) => ({
        ...state,
        gateway: gateway
      }));
    }
  }
  onCancelEdit = (value, field) => {
    this.setState((state) => ({
      ...state,
      editData: this.state.gateway,
      [field]: value
    }));
  };
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  enableEditing() {
    this.setState((state) => ({
      ...state,
      editing: true,
      editData: { ...this.state.gateway }
    }));
  }
  toggleButton() {
    this.setState((state) => ({
      ...state,
      dropdownOpen: !this.state.dropdownOpen
    }));
  }
  onChange = (value, field) => {
    this.setState((state) => ({
      ...state,
      editData: {
        ...state.editData,
        [field]: value
      }
    }));
  };
  setDeleteGateways() {
    this.toggleModal('delete_modal');
  }

  async deleteGateways() {
    let response = await axios.delete('gateways/archive', {
      data: {
        ids: [this.state.gateway.id]
      }
    });

    if (response.status == 200) {
      this.getGateway();
      this.toggleModal('delete_modal');
    }
  }
  async saveGateway() {
    let id = this.props.match.params.id;
    let editData = this.state.editData;

    try {
      let response = await axios.put('gateways/' + id, {
        ...editData
      });
      if (response.status === 200) {
        this.getGateway();
        this.setState({
          ...this.state,
          editing: false
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    if (!this.state.gateway.id) {
      return null;
    }
    return (
      <div>
        <Row>
          <Col xs="6" md="6" lg="6">
            <h4 className="">
              {this.state.editing && 'Edit '}Gateway{' '}
              {this.state.gateway.name || this.state.gateway.appeui}
            </h4>
            <Breadcrumb>
              <BreadcrumbItem>
                <a
                  href="#"
                  onClick={() =>
                    this.props.history.push('/administration/gateways')
                  }
                >
                  List of Kraal Gateways
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {this.state.editing && 'Edit '}Gateway{' '}
                {this.state.gateway.name || this.state.gateway.appeui}
              </BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col xs="6" md="6" lg="6">
            {this.state.editing && (
              <React.Fragment>
                <Button
                  color="primary"
                  className="float-right"
                  onClick={() => this.saveGateway()}
                >
                  Save
                </Button>
                <Button
                  className="outline float-right"
                  onClick={() => this.onCancelEdit(false, 'editing')}
                >
                  Cancel
                </Button>
              </React.Fragment>
            )}
            {!this.state.editing && !this.state.gateway.deleted_at && (
              <ButtonDropdown
                className="float-right"
                isOpen={this.state.dropdownOpen}
                toggle={this.toggleButton}
              >
                <DropdownToggle caret className="remove-mr">
                  Actions
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => this.enableEditing()}>
                    Edit Kraal Gateway
                  </DropdownItem>
                  <DropdownItem onClick={() => this.setDeleteGateways()}>
                    Archive Kraal tag
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="12" lg="12" className="global-table">
            <Card>
              <Row>
                <Col xs="12" md="12" lg="12">
                  <div className="p-30  global-heading-gateway">
                    <h5 className="details-title">
                      Gateway{' '}
                      {this.state.gateway.name || this.state.gateway.appeui}
                    </h5>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="12" lg="12">
                  <Table>
                    <tbody>
                      <tr>
                        <td className="v-align-middle">
                          <b>Gateway ID</b>
                        </td>
                        <td>{this.state.gateway.id || '/'}</td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Farm Id</b>
                        </td>
                        <td>
                          <span>{this.state.gateway.farm_id || '/'}</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Identifier</b>
                        </td>
                        <td>
                          {this.state.editing ? (
                            <Input
                              name={'identifier'}
                              defaultValue={this.state.editData.identifier}
                              onChange={(e) => {
                                this.onChange(e.target.value, e.target.name);
                              }}
                            />
                          ) : (
                            <span>{this.state.gateway.identifier || '/'}</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Gateway Name</b>
                        </td>
                        <td>
                          {this.state.editing ? (
                            <Input
                              name={'name'}
                              defaultValue={this.state.editData.name}
                              onChange={(e) => {
                                this.onChange(e.target.value, e.target.name);
                              }}
                            />
                          ) : (
                            <span>{this.state.gateway.name || '/'}</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Appeui</b>
                        </td>
                        <td>
                          {this.state.editing ? (
                            <Input
                              name={'appeui'}
                              defaultValue={this.state.editData.appeui}
                              onChange={(e) => {
                                this.onChange(e.target.value, e.target.name);
                              }}
                            />
                          ) : (
                            <span>{this.state.gateway.appeui || '/'}</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Gweui</b>
                        </td>
                        <td>
                          {this.state.editing ? (
                            <Input
                              name={'gweui'}
                              defaultValue={this.state.editData.gweui}
                              onChange={(e) => {
                                this.onChange(e.target.value, e.target.name);
                              }}
                            />
                          ) : (
                            <span>{this.state.gateway.gweui || '/'}</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>ARN</b>
                        </td>
                        <td>
                          {this.state.editing ? (
                            <Input
                              name={'arn'}
                              defaultValue={this.state.editData.arn}
                              onChange={(e) => {
                                this.onChange(e.target.value, e.target.name);
                              }}
                            />
                          ) : (
                            <span>{this.state.gateway.arn || '/'}</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Version</b>
                        </td>
                        <td>
                          {this.state.editing ? (
                            <Input
                              name={'version'}
                              defaultValue={this.state.editData.version}
                              onChange={(e) => {
                                this.onChange(e.target.value, e.target.name);
                              }}
                            />
                          ) : (
                            <span>{this.state.gateway.version || '/'}</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Carrier Type</b>
                        </td>
                        <td>
                          {this.state.editing ? (
                            <Input
                              name={'carrier_type'}
                              defaultValue={this.state.editData.carrier_type}
                              onChange={(e) => {
                                this.onChange(e.target.value, e.target.name);
                              }}
                            />
                          ) : (
                            <span>
                              {this.state.gateway.carrier_type || '/'}
                            </span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Connection Speed</b>
                        </td>
                        <td>
                          <span>
                            {this.state.gateway.connection_speed || '/'}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Lora Signal Strength</b>
                        </td>
                        <td>
                          <span>
                            {this.state.gateway.lora_signal_strength || '/'}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Lat</b>
                        </td>
                        <td>{this.state.gateway?.lat || '/'}</td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Lng</b>
                        </td>
                        <td>{this.state.gateway.lng || '/'}</td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Created at</b>
                        </td>
                        <td>
                          {(this.state.gateway.created_at &&
                            moment(this.state.gateway.created_at).format(
                              'DD/MM/YYYY HH:mm:ss'
                            )) ||
                            '/'}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Updated at</b>
                        </td>
                        <td>
                          {(this.state.gateway.updated_at &&
                            moment(this.state.gateway.updated_at).format(
                              'DD/MM/YYYY HH:mm:ss'
                            )) ||
                            '/'}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Deleted at</b>
                        </td>
                        <td>
                          {(this.state.gateway.deleted_at &&
                            moment(this.state.gateway.deleted_at).format(
                              'DD/MM/YYYY HH:mm:ss'
                            )) ||
                            '/'}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal isOpen={this.state.delete_modal} className={'delete-modal'}>
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>Archive gateways</b>
            </h5>
            <br />
            <br />
            <br />
            {this.state.gateway.connected_tags_count
              ? 'This gateway has active tag connections. Archiving this gateway will disconnect all tags connected to this gateway. Are you sure you want to archive this gateway?'
              : 'Are you sure you want to archive selected gateways? This action cannot be undone.'}
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => this.toggleModal('delete_modal')}
            >
              Cancel
            </Button>{' '}
            <Button color="danger" onClick={() => this.deleteGateways()}>
              Archive
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default EditGateway;
