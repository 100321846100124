import React from 'react';
import {
  Row,
  Col,
  Card,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

import axios from '../../axios';
import ReactTable from 'react-table';
import Filters from '../../components/filters/index.jsx';
import SelectionPopover from '../../components/Popovers/SelectionPopover';
import { connect } from 'react-redux';
import { getTableColumns } from '../../helpers/subscription';
import { FEATURE_LIST } from '../../constants/subscription';
class Sites extends React.Component {
  //Tabs
  constructor(props) {
    super(props);

    this.state = {
      activeTab: '1',
      delete_modal: false,
      dropdownOpen: false,
      query: '',
      isOpenSelectionPopover: false,
      tableData: [],
      x: [],
      sites: [],
      selected_sites: [],
      filters: {
        farms: [],
        geofences: [],
        site_types: []
      },
      filters_open: false,
      selectAll: false
    };

    this.toggle = this.toggle.bind(this);
    this.toggleButton = this.toggleButton.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.getSites = this.getSites.bind(this);
    this.onSiteSelect = this.onSiteSelect.bind(this);
    this.filtersUpdated = this.filtersUpdated.bind(this);
    this.filtersClose = this.filtersClose.bind(this);
    this.onClickAllRecords = this.onClickAllRecords.bind(this);
    this.onClickOnThisPage = this.onClickOnThisPage.bind(this);
  }
  onClickOnThisPage() {
    const newCheckboxValue = !this.state.selectAll;

    let checkedCopy = [];
    if (newCheckboxValue) {
      this.state.tableData.map((val) => {
        const id = val?._original?.id ?? val.id;
        checkedCopy.push(id);
      });
    }
    this.setState((state) => ({
      ...state,
      selected_sites: checkedCopy
    }));
    this.setState({
      selectAll: newCheckboxValue
    });
    this.onToggleSelectionPopover();
  }

  onClickAllRecords() {
    const newCheckboxValue = !this.state.selectAll;
    let checkedCopy = [];
    if (newCheckboxValue) {
      this.state.sites.map((val) => {
        checkedCopy.push(val.id);
      });
    }
    this.setState((state) => ({
      ...state,
      selected_sites: checkedCopy
    }));
    this.setState({
      selectAll: newCheckboxValue
    });
    this.onToggleSelectionPopover();
  }
  onToggleSelectionPopover(isOpen) {
    const open =
      isOpen !== undefined
        ? isOpen
        : !this.state.isOpenSelectionPopover && !this.state.selectAll;

    this.setState({
      isOpenSelectionPopover: open
    });
  }
  handleCSVChange = (event) => {
    this.setState({
      uploading: true
    });
    let reader = new FileReader();
    reader.readAsText(event.target.files[0]);

    reader.onload = function () {
      this.setState({
        csvfile: reader.result,
        uploading: false
      });
    }.bind(this);
    reader.onerror = function (error) {
      console.error('Error: ', error);
    };
  };
  onChange = (value, field) => {
    this.setState((state) => ({
      ...state,
      [field]: value
    }));
  };
  handleKeyPress = (charCode) => {
    if (charCode === 13) {
      this.getSites();
    }
  };
  toggleButton() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  onCheckboxChange = (value, field) => {
    this.setState((state) => ({
      ...state,
      [field]: !state[field]
    }));
  };

  componentDidMount() {
    this.getSites();
  }
  async getSites() {
    let response = await axios.get('sites', {
      params: {
        query: this.state.query,
        farm_ids: this.state.filters.farms.map((x) => x.value),
        type: this.state.filters.site_types.map((x) => x.value),
        geofence_ids: this.state.filters.geofences.map((x) => x.value)
      }
    });
    if (response.status === 200) {
      this.setState({
        sites: response.data,
        tableData: response.data.slice(0, 25)
      });
    }
  }
  async deleteSites() {
    let response = await axios.delete('sites/bulk', {
      data: { ids: this.state.selected_sites }
    });
    if (response.status === 200) {
      this.getSites();
      this.setState({
        delete_modal: false,
        selected_sites: []
      });
    }
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  toggleModal(modal) {
    this.setState((state) => ({
      ...state,
      [modal]: !state[modal]
    }));
  }
  onSiteSelect(site) {
    let sites = this.state.selected_sites;
    if (sites.includes(site)) {
      var index = sites.indexOf(site);
      if (index > -1) {
        sites.splice(index, 1);
      }
    } else {
      sites.push(site);
    }

    if (this.state.sites.length === this.state.selected_sites.length) {
      this.setState({
        selectAll: true
      });
    } else {
      this.setState({
        selectAll: false
      });
    }
    this.setState({
      selected_sites: sites
    });
  }
  filtersUpdated(filters) {
    this.setState(
      {
        filters: filters
      },
      () => this.getSites()
    );
  }
  filtersClose() {
    this.setState({ filters_open: false });
  }
  render() {
    function capitalize(string) {
      return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
    }
    const columns = [
      {
        Header: (cellinfo) => (
          <div className="position-relative form-check">
            <SelectionPopover
              isOpen={this.state.isOpenSelectionPopover}
              onClickOnThisPage={this.onClickOnThisPage}
              onClickAllRecords={this.onClickAllRecords}
              setOpen={(isOpen) =>
                this.setState({
                  isOpenSelectionPopover:
                    isOpen !== undefined
                      ? isOpen
                      : !this.state.isOpenSelectionPopover
                })
              }
              content={
                <input
                  type="checkbox"
                  defaultChecked={this.state.selectAll}
                  onChange={() => {
                    this.onToggleSelectionPopover();
                    if (this.state.selectAll) {
                      this.setState({
                        selectAll: !this.state.selectAll
                      });
                      this.setState((state) => ({
                        ...state,
                        selected_sites: []
                      }));
                    }
                  }}
                />
              }
            />
          </div>
        ),
        id: 'select',
        accessor: (d) => {
          return { id: d.id, tag: d.tag };
        },
        Cell: (props) => (
          <FormGroup check>
            <Input
              type="checkbox"
              defaultChecked={this.state.selected_sites.includes(
                props.value.id
              )}
              value={props.value.id}
              onChange={(e) =>
                this.onSiteSelect(
                  parseInt(e.target.value),
                  props.value.tag ? 'linked' : 'unlinked'
                )
              }
              key={props.value.id}
            />{' '}
          </FormGroup>
        ),
        headerClassName: 'wordwrap',
        sortable: false,
        filterable: false,
        maxWidth: 85
      },
      {
        Header: 'Site ID',
        id: 'id',
        accessor: (d) => {
          return { id: d.id, identifier: d.identifier };
        },
        headerClassName: 'wordwrap',
        Cell: (props) => (
          <a
            href="#"
            onClick={() => this.props.history.push('site/' + props.value.id)}
          >
            {props.value.identifier}
          </a>
        ),
        maxWidth: 120
      },
      {
        Header: 'Site Name',
        accessor: 'name',
        id: 'siteName',
        headerClassName: 'wordwrap'
      },
      {
        Header: 'Site Type',
        id: 'sitetype',
        accessor: (d) => {
          return capitalize(d.type);
        },
        headerClassName: 'wordwrap'
      },
      {
        Header: 'Site Geofence',
        accessor: 'location',
        id: 'siteGeofence',
        headerClassName: 'wordwrap'
      },
      {
        Header: 'Site Description',
        accessor: 'description',
        id: 'siteDescription',
        headerClassName: 'wordwrap'
      },
      {
        Header: 'Site Rules',
        id: 'siterule',
        accessor: (d) => {
          return d.rules.length > 0
            ? d.rules.map((x) => x.name).join(', ')
            : '/';
        },
        headerClassName: 'wordwrap'
      }
    ];

    function filterCaseInsensitive(filter, row) {
      const id = filter.pivotId || filter.id;
      if (row[id] && typeof row[id] === 'object') {
        return row[id] !== undefined
          ? String(row[id]['identifier']?.toLowerCase())?.includes(
              filter?.value?.toLowerCase()
            )
          : true;
      } else if (typeof row[id] === 'number') {
        return row[id] !== undefined
          ? String(row[id])?.includes(filter?.value)
          : true;
      } else {
        return row[id] !== undefined
          ? String(row[id]?.toLowerCase())?.includes(filter?.value?.toLowerCase())
          : true;
      }
    }
    return (
      <div>
        <Filters
          open={this.state.filters_open}
          updateFilters={this.filtersUpdated}
          onClose={this.filtersClose}
          filter_types={['farm', 'site-type', 'geofence']}
        ></Filters>

        <Row>
          <Col xs="12" md="12" lg="12">
            <Card>
              <Row className="pad-10">
                <Col xs="12" md="12" lg="6">
                  <Row>
                    <Col xs="12" md="12" lg="6" className="listing-heading">
                      <h4 className="">Sites</h4>
                      <Breadcrumb>
                        <BreadcrumbItem>List of Sites</BreadcrumbItem>
                      </Breadcrumb>
                    </Col>

                    <Col xs="12" md="12" lg="6"></Col>
                  </Row>
                </Col>
                <Col
                  xs="12"
                  md="12"
                  lg="6"
                  className="d-flex justify-content-end align-items-center h-100"
                >
                  <InputGroup className="head-search mr-2" size="sm">
                    <Input
                      type="search"
                      id="search"
                      name="search"
                      value={this.state.query}
                      onChange={(e) => this.onChange(e.target.value, 'query')}
                      placeholder="Search Sites"
                      onKeyPress={(e) => this.handleKeyPress(e.charCode)}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText
                        onClick={() => this.handleKeyPress(13)}
                        className="rg-pointer"
                      >
                        <i className="fas fa-search"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <ButtonDropdown
                    className="float-right"
                    isOpen={this.state.dropdownOpen}
                    toggle={this.toggleButton}
                  >
                    <DropdownToggle caret>Actions</DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => this.props.history.push('/site/new')}
                      >
                        Add site
                      </DropdownItem>
                      {this.state.selected_sites.length > 0 && (
                        <DropdownItem
                          onClick={() => this.toggleModal('delete_modal')}
                        >
                          {' '}
                          Archive site
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </ButtonDropdown>
                  <Button
                    color="primary"
                    className="float-right mr-2"
                    onClick={() =>
                      this.setState({
                        ...this.state,
                        filters_open: !this.state.filters_open
                      })
                    }
                  >
                    <i className="fa fa-filter"></i>Filters
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="12" lg="12">
                  <ReactTable
                    showPagination={this.state.sites.length > 0}
                    minRows={0}
                    data={this.state.sites}
                    columns={getTableColumns(
                      columns,
                      this.props.subscription.myPlan?.type,
                      FEATURE_LIST.SITES_LIST
                    )}
                    resizable={true}
                    defaultPageSize={25}
                    filterable={true}
                    defaultFilterMethod={filterCaseInsensitive}
                    onFetchData={(props) => {
                      const data = props.data.length
                        ? props.sortedData.slice(0, props.pageSize)
                        : this.state.sites;
                      this.setState({ tableData: data });
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.delete_modal}
          className={this.props.className}
        >
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>Archive sites</b>
            </h5>
            <br />
            <br />
            <br />
            Are you sure you want to archive selected sites? This action cannot
            be undone.
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => this.toggleModal('delete_modal')}
            >
              Cancel
            </Button>{' '}
            <Button color="danger" onClick={() => this.deleteSites()}>
              Archive
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default connect((state) => state)(Sites);
