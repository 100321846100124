export const TASK_STATUSES = ['open', 'in progress', 'done'];

export const TASK_PRIORITIES = ['low', 'medium', 'high'];

export const TASK_MODAL_MODE = {
  CREATE: 'create',
  UPDATE: 'update',
  VIEW: 'view',
  GUEST: 'guest'
};
