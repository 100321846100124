import React, { memo } from "react";
import Select from "react-select";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";

const ChooseGroupsModal = ({
  isOpen,
  onCancel,
  handleGroupsChange,
  onSave,
  values = [],
  options = []
}) => {
  return (
    <Modal size="lg" isOpen={isOpen}>
      <ModalBody>
        <br />
        <h5 className="text-center">
          <strong>Groups</strong>
        </h5>
        <br />
        <br />
        <FormGroup>
          <Select
            value={values}
            onChange={handleGroupsChange}
            options={options}
            isMulti={true}
            isSearchable={true}
            />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>{" "}
        <Button color="primary" onClick={onSave}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default memo(ChooseGroupsModal);
