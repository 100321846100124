export const getSearchParams = (link) => {
  const searchParams = {};
  const search = link ?? window.location.search;
  const searchParamsObj = new URLSearchParams(search);
  searchParamsObj.forEach((value, key) => {
    searchParams[key] = decodeURIComponent(value);
  });
  return searchParams;
};

export const getParamsString = (link) => {
  return link?.length ? link.split('?')[1] : '';
};
