import axios from '../../axios';

const service = {
  async getPlans() {
    return await axios({
      method: 'get',
      url: '/subscription/plans'
    });
  },

  async getFeatureOptions() {
    return await axios({
      method: 'get',
      url: '/subscription/feature-options'
    });
  },

  async addUserSubscription(data) {
    return await axios({
      method: 'post',
      url: '/subscription/user',
      data: data
    });
  },

  async updateUserSubscriptionStatus(data) {
    return await axios({
      method: 'put',
      url: '/subscription/user/status',
      data: data
    });
  },

  async getMyPlan() {
    return await axios({
      method: 'get',
      url: '/subscription/user/my'
    });
  }
};

export default service;
