import React from 'react';
import {
  Row,
  Col,
  Card,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

import axios from '../../axios';
import Select from 'react-select';
import ReactTable from 'react-table';
import { CompactPicker } from 'react-color';
import Filters from '../../components/filters/index.jsx';
import SelectionPopover from '../../components/Popovers/SelectionPopover';
import { filterCaseInsensitive } from '../../helpers/filter';
import { capitalize } from '../../helpers/common';
class Labels extends React.Component {
  //Tabs
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      delete_modal: false,
      add_modal: false,
      query: '',
      isOpenSelectionPopover: false,
      tableData: [],
      selected_labels: [],
      selected_livestock: [],
      selected_rules: [],
      livestock_opts: [],
      rules_opts: [],
      dropdownOpen: false,
      filters_open: false,
      selectAll: false,
      labels: [],
      filters: {
        farms: [],
        geofences: [],
        labels: [],
        animals: [],
        rules: []
      },
      new_label: {
        name: '',
        colour: '',
        rules: [],
        animal_ids: []
      }
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.getLabels = this.getLabels.bind(this);
    this.getLivestock = this.getLivestock.bind(this);
    this.toggleButton = this.toggleButton.bind(this);
    this.deleteLabels = this.deleteLabels.bind(this);
    this.filtersUpdated = this.filtersUpdated.bind(this);
    this.filtersClose = this.filtersClose.bind(this);
    this.onClickAllRecords = this.onClickAllRecords.bind(this);
    this.onClickOnThisPage = this.onClickOnThisPage.bind(this);
  }
  onClickOnThisPage() {
    const newCheckboxValue = !this.state.selectAll;

    let checkedCopy = [];
    if (newCheckboxValue) {
      this.state.tableData.map((val) => {
        const id = val?._original?.id ?? val.id;
        checkedCopy.push(id);
      });
    }
    this.setState((state) => ({
      ...state,
      selected_labels: checkedCopy
    }));
    this.setState({
      selectAll: newCheckboxValue
    });
    this.onToggleSelectionPopover();
  }

  onClickAllRecords() {
    const newCheckboxValue = !this.state.selectAll;
    let checkedCopy = [];
    if (newCheckboxValue) {
      this.state.labels.map((val) => {
        checkedCopy.push(val.id);
      });
    }
    this.setState((state) => ({
      ...state,
      selected_labels: checkedCopy
    }));
    this.setState({
      selectAll: newCheckboxValue
    });
    this.onToggleSelectionPopover();
  }
  onToggleSelectionPopover(isOpen) {
    const open =
      isOpen !== undefined
        ? isOpen
        : !this.state.isOpenSelectionPopover && !this.state.selectAll;

    this.setState({
      isOpenSelectionPopover: open
    });
  }
  onChange = (value, field) => {
    this.setState((state) => ({
      ...state,
      [field]: value
    }));
  };
  handleKeyPress = (charCode) => {
    if (charCode === 13) {
      this.getLabels();
    }
  };
  toggleButton() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  onCheckboxChange = (value, field) => {
    this.setState((state) => ({
      ...state,
      [field]: !state[field]
    }));
  };

  componentDidMount() {
    this.getLivestock();
    this.getLabels();
  }
  async getLabels() {
    let response = await axios.get('labels', {
      params: {
        query: this.state.query,
        farm_ids: this.state.filters.farms?.map((x) => x.value),
        label_ids: this.state.filters.labels?.map((x) => x.value),
        animal_ids: this.state.filters.animals?.map((x) => x.value),
        geofence_ids: this.state.filters.geofences?.map((x) => x.value),
        rule_ids: this.state.filters.rules?.map((x) => x.value)
      }
    });
    if (response.status == 200) {
      this.setState({
        labels: response.data,
        selectAll: false,
        tableData: response.data.slice(0, 25),
        selected_labels: []
      });
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  toggleModal(modal) {
    this.setState((state) => ({
      ...state,
      [modal]: !state[modal]
    }));
  }
  async getLivestock() {
    let response = await axios.get('animals');
    let mapped_livestock = [];
    for (let live of response.data) {
      if (live.tag == null) {
        continue;
      }
      mapped_livestock.push({
        value: live.id,
        label: live.eartag_official_id
      });
    }
    this.setState({
      livestock_opts: mapped_livestock
    });
  }
  onLabelSelect(label) {
    let labels = this.state.selected_labels;
    if (labels.includes(label)) {
      var index = labels.indexOf(label);
      if (index > -1) {
        labels.splice(index, 1);
      }
    } else {
      labels.push(label);
    }

    if (this.state.labels.length == this.state.selected_labels.length) {
      this.setState({
        selectAll: true
      });
    } else {
      this.setState({
        selectAll: false
      });
    }

    this.setState({
      selected_labels: labels
    });
  }
  async addLabel() {
    let response = await axios.post('/labels', this.state.new_label);
    if (response.status == 200) {
      this.getLabels();
      this.toggleModal('add_modal');
    }
  }
  async deleteLabels() {
    let response = await axios.delete('labels/bulk', {
      data: { ids: this.state.selected_labels }
    });
    if (response.status == 200) {
      this.getLabels();
      this.toggleModal('delete_modal');
    }
  }
  onNewLabelChangeLivestock = (selectedOption) => {
    this.setState({
      selected_livestock: selectedOption,
      new_label: {
        ...this.state.new_label,
        animal_ids: selectedOption.map((x) => x.value)
      }
    });
  };
  onNewLabelChangeRules = (selectedOption) => {
    this.setState({
      selected_rules: selectedOption,
      new_label: {
        ...this.state.new_label,
        rules: selectedOption.map((x) => x.value)
      }
    });
  };
  onNewLabelChange(value, field) {
    this.setState({
      new_label: {
        ...this.state.new_label,
        [field]: value
      }
    });
  }

  filtersUpdated(filters) {
    this.setState(
      {
        filters: filters
      },
      () => this.getLabels()
    );
  }
  filtersClose() {
    this.setState({ filters_open: false });
  }
  render() {
    const columns = [
      {
        Header: () => (
          <div className="position-relative form-check">
            <SelectionPopover
              isOpen={this.state.isOpenSelectionPopover}
              onClickOnThisPage={this.onClickOnThisPage}
              onClickAllRecords={this.onClickAllRecords}
              setOpen={(isOpen) =>
                this.setState({
                  isOpenSelectionPopover:
                    isOpen !== undefined
                      ? isOpen
                      : !this.state.isOpenSelectionPopover
                })
              }
              content={
                <input
                  type="checkbox"
                  defaultChecked={this.state.selectAll}
                  onChange={() => {
                    this.onToggleSelectionPopover();
                    if (this.state.selectAll) {
                      this.setState({
                        selectAll: !this.state.selectAll
                      });
                      this.setState((state) => ({
                        ...state,
                        selected_labels: []
                      }));
                    }
                  }}
                />
              }
            />
          </div>
        ),
        id: 'select',
        accessor: (d) => {
          return { id: d.id, tag: d.tag };
        },
        Cell: (props) => (
          <FormGroup check>
            <Input
              type="checkbox"
              defaultChecked={this.state.selected_labels.includes(
                props.value.id
              )}
              value={props.value.id}
              onChange={(e) => this.onLabelSelect(parseInt(e.target.value))}
              key={props.value.id}
            />{' '}
          </FormGroup>
        ),
        headerClassName: 'wordwrap',
        sortable: false,
        filterable: false,
        maxWidth: 85
      },
      {
        Header: 'Group ID',
        id: 'id',
        accessor: (d) => {
          return {
            color: d.colour,
            id: d.id,
            name: d.name,
            identifier: d.identifier
          };
        },
        headerClassName: 'wordwrap',
        Cell: (props) => (
          <a
            href="#"
            onClick={() => this.props.history.push('group/' + props.value.id)}
          >
            {props.value.identifier}
          </a>
        ),
        maxWidth: 120,
        sortMethod: (a, b) => {
          return a.identifier.localeCompare(b.identifier);
        }
      },
      {
        Header: 'Group Name',
        accessor: (d) => {
          return {
            color: d.colour,
            id: d.id,
            name: d.name,
            identifier: d.name
          };
        },
        id: 'name',
        headerClassName: 'wordwrap',
        Cell: (props) => (
          <a
            href="#"
            onClick={() => this.props.history.push('group/' + props.value.id)}
          >
            <div
              className="label-tag"
              style={{ background: props.value.color, color: 'white' }}
            >
              {props.value.name}
            </div>
          </a>
        ),
        sortMethod: (a, b) => {
          return a.name.localeCompare(b.name);
        },
        minWidth: 200
      },
      {
        Header: 'Group Type',
        accessor: 'main_type',
        id: 'main_type',
        headerClassName: 'wordwrap',
        Cell: (props) => {
          return capitalize(props.value);
        },
        minWidth: 200
      },
      {
        Header: 'Farm',
        accessor: (d) => {
          return {
            id: d?.farm?.id,
            name: d?.farm?.name,
            identifier: d?.farm?.identifier
          };
        },
        id: 'farm',
        headerClassName: 'wordwrap',
        Cell: (props) => (
          <a
            href="#"
            onClick={() => this.props.history.push('farm/' + props.value.id)}
          >
            {props.value.name}
          </a>
        ),
        sortMethod: (a, b) => {
          return a?.name?.localeCompare(b?.name);
        },
        minWidth: 200,
        filterMethod: (filter, row) => {
          const payload = {
            farm: row?.farm?.name
          };
          return filterCaseInsensitive(filter, payload);
        }
      },
      {
        Header: '# of Livestock in Group',
        id: 'livestock',
        accessor: 'animals_count',
        headerClassName: 'wordwrap'
      }
    ];

    return (
      <div>
        <Filters
          open={this.state.filters_open}
          updateFilters={this.filtersUpdated}
          onClose={this.filtersClose}
          filter_types={['farm', 'animal', 'geofence']}
        ></Filters>

        <Row>
          <Col xs="12" md="12" lg="12">
            <Card>
              <Row className="pad-10">
                <Col xs="12" md="12" lg="6">
                  <Row>
                    <Col xs="12" md="12" lg="6" className="listing-heading">
                      <h4 className="">Groups</h4>
                      <Breadcrumb>
                        <BreadcrumbItem>List of Groups</BreadcrumbItem>
                      </Breadcrumb>
                    </Col>

                    <Col xs="12" md="12" lg="6"></Col>
                  </Row>
                </Col>
                <Col
                  xs="12"
                  md="12"
                  lg="6"
                  className="d-flex justify-content-end align-items-center h-100"
                >
                  <InputGroup className="head-search mr-2" size="sm">
                    <Input
                      type="search"
                      id="search"
                      name="search"
                      value={this.state.query}
                      onChange={(e) => this.onChange(e.target.value, 'query')}
                      placeholder="Search Groups"
                      onKeyPress={(e) => this.handleKeyPress(e.charCode)}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText
                        onClick={() => this.handleKeyPress(13)}
                        className="rg-pointer"
                      >
                        <i className="fas fa-search"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <ButtonDropdown
                    className="float-right"
                    isOpen={this.state.dropdownOpen}
                    toggle={this.toggleButton}
                  >
                    <DropdownToggle caret>Actions</DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => this.props.history.push('group/new')}
                      >
                        Add new group
                      </DropdownItem>
                      {this.state.selected_labels.length > 0 && (
                        <DropdownItem
                          onClick={() => this.toggleModal('delete_modal')}
                        >
                          Archive group
                        </DropdownItem>
                      )}
                      {this.state.selected_labels.length > 0 && (
                        <DropdownItem
                          onClick={() =>
                            this.props.history.push({
                              pathname: 'group/new',
                              collection: this.state.selected_labels
                                .filter((id) => {
                                  const label = this.state.labels.find(
                                    (item) => item.id === id
                                  );

                                  return label.main_type !== 'collection';
                                })
                                .map((id) => {
                                  const label = this.state.labels.find(
                                    (item) => item.id === id
                                  );
                                  return {
                                    label: label.name,
                                    value: label.id
                                  };
                                }),
                              mainType: 'collection'
                            })
                          }
                        >
                          View compound groups
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </ButtonDropdown>
                  <Button
                    color="primary"
                    className="float-right mr-2"
                    onClick={() =>
                      this.setState({
                        ...this.state,
                        filters_open: !this.state.filters_open
                      })
                    }
                  >
                    <i className="fa fa-filter"></i>Filters
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="12" lg="12">
                  <ReactTable
                    showPagination={this.state.labels.length > 0}
                    minRows={0}
                    data={this.state.labels}
                    columns={columns}
                    resizable={true}
                    defaultPageSize={25}
                    filterable={true}
                    defaultFilterMethod={filterCaseInsensitive}
                    onFetchData={(props) => {
                      const data = props.data.length
                        ? props.sortedData.slice(0, props.pageSize)
                        : this.state.labels;
                      this.setState({ tableData: data });
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.delete_modal}
          className={this.props.className}
        >
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>Archive groups</b>
            </h5>
            <br />
            <br />
            <br />
            Are you sure you want to archive selected groups? This action cannot
            be undone.
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => this.toggleModal('delete_modal')}
            >
              Cancel
            </Button>{' '}
            <Button color="danger" onClick={() => this.deleteLabels()}>
              Archive
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.add_modal} className={this.props.className}>
          <ModalHeader>
            <b>Add group</b>
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <Label sm="4" for="animal_photo">
                  Group Name
                </Label>
                <Col sm="8">
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    onChange={(e) =>
                      this.onNewLabelChange(e.target.value, e.target.name)
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4" for="animal_photo">
                  Group Color
                </Label>
                <Col sm="8">
                  <CompactPicker
                    name="colour"
                    color={this.state.new_label.colour}
                    onChange={(e) => this.onNewLabelChange(e.hex, 'colour')}
                  ></CompactPicker>
                  {/* <Input type="color" name="colour" id="color" onChange={e=>(this.onNewLabelChange(e.target.value, e.target.name))} /> */}
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4" for="animal_photo">
                  Group Livestock
                </Label>
                <Col sm="8">
                  <Select
                    value={this.state.selected_livestock}
                    onChange={this.onNewLabelChangeLivestock}
                    options={this.state.livestock_opts}
                    isMulti={true}
                  />
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => this.toggleModal('add_modal')}
            >
              Cancel
            </Button>{' '}
            <Button color="primary" onClick={() => this.addLabel()}>
              Add
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Labels;
