import { REDUCERS } from '../../constants/reducers';

const initialState = {
  events: [],
  queryParams: {},
  currentEvent: {
    title: '',
    description: '',
    startDate: '',
    endDate: '',
    remindDate: '',
    relationId: '',
    relationType: '',
    recurringEndDate: '',
    recurringType: '',
    userIds: [],
    userEmails: []
  }
};

const event = (state = initialState, action) => {
  switch (action.type) {
    case REDUCERS.EVENT.SET_EVENT_STATE: {
      return Object.assign({}, state, {
        ...state,
        ...action.value
      });
    }
    case REDUCERS.EVENT.SET_EVENT_QUERY_PARAMS: {
      return Object.assign({}, state, {
        queryParams: { ...action.value }
      });
    }
    case REDUCERS.EVENT.SET_EVENT_LIST: {
      return Object.assign({}, state, {
        events: [...action.value]
      });
    }
    case REDUCERS.EVENT.SET_CURRENT_EVENT: {
      return Object.assign({}, state, {
        currentEvent: { ...state.currentEvent, ...action.value }
      });
    }
    case REDUCERS.RESET:
      return initialState;
    default:
      return state;
  }
};

export { event };
