import React from "react";
import {
	Tooltip,
} from 'reactstrap';

class RuleSnoozeIcon extends React.Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
		  tooltipOpen: false,
		  id: this.makeid(12)
		};
	  }
	  toggle() {
		this.setState({
		  tooltipOpen: !this.state.tooltipOpen
		});
	  }
	makeid(length) {
		var text = "";
		var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

		for (var i = 0; i < length; i++)
		  text += possible.charAt(Math.floor(Math.random() * possible.length));

		return text;
	  }
	render() {
		return (
			<i id={this.state.id} className="fa fa-clock rule-snoozed">
				<Tooltip placement="top" isOpen={this.state.tooltipOpen} target={this.state.id} toggle={this.toggle}>
					Snoozed
				</Tooltip>
			</i>

		);
	}
}

export default RuleSnoozeIcon;
