import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import UserTable from '../../../../components/Tables/UserTable';
import { getRoles } from '../../../../redux/actions/admin';
import { getPlans } from '../../../../redux/actions/subscription';
import { getAllUsers, setUserState } from '../../../../redux/actions/user';

const AdministrationUsers = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUsers()).then(() => {
      dispatch(setUserState({ loading: false }));
    });
    dispatch(getPlans());
    dispatch(getRoles());
  }, []);

  return (
    <div className="administration-user-page">
      <UserTable {...props} />
    </div>
  );
};

export default AdministrationUsers;
