import React from 'react';
import moment from 'moment';
import { FormGroup, Input } from 'reactstrap';
import SelectionPopover from '../../../components/Popovers/SelectionPopover';

export const getColumns = (props) => {
  const columns = [
    {
      id: 'select',
      accessor: (item) => {
        return { id: item.id };
      },
      headerClassName: 'wordwrap',
      sortable: false,
      Header: () => (
        <div className="position-relative form-check">
          <SelectionPopover
            isOpen={props.state.isOpenSelectionPopover}
            onClickOnThisPage={props.onClickOnThisPage}
            onClickAllRecords={props.onClickAllRecords}
            setOpen={(isOpen) =>
              props.setState({
                isOpenSelectionPopover:
                  isOpen !== undefined
                    ? isOpen
                    : !props.state.isOpenSelectionPopover
              })
            }
            content={
              <input
                type="checkbox"
                defaultChecked={props.selection.selectAll}
                onChange={() => {
                  props.onChangeSelectAllCheckbox();
                }}
              />
            }
          />
        </div>
      ),
      Cell: (values) => {
        return (
          <FormGroup check>
            <Input
              type="checkbox"
              defaultChecked={props.selection.selectedGateways.includes(
                values.value.id
              )}
              value={values.value.id}
              onChange={(e) => props.onSelect(parseInt(e.target.value))}
              key={values.value.id}
            />
          </FormGroup>
        );
      },
      filterable: false,
      maxWidth: 85
    },
    {
      Header: 'Gateway ID',
      id: 'id',
      accessor: (d) => {
        return { id: d.id, appeui: d.identifier, identifier: d.identifier };
      },
      headerClassName: 'wordwrap',
      Cell: (value) => {
        return (
          <a
            href="#"
            onClick={() =>
              props.history.push('/administration/gateway/' + value.value.id)
            }
          >
            {value.value.appeui}
          </a>
        );
      },
      minWidth: 125
    },
    {
      Header: 'Farm Id',
      accessor: (d) => {
        return { id: d.id, name: d.name, identifier: d.name ? d.name : '' };
      },
      headerClassName: 'wordwrap',
      id: 'farmId',
      Cell: (props) => (
        <a
          href="#"
          onClick={() => this.props.history.push('farm/' + props.value.id)}
        >
          {props.value.name}
        </a>
      )
    },
    {
      Header: 'Appeui',
      headerClassName: 'wordwrap',
      accessor: 'appeui',
      minWidth: 180
    },
    {
      Header: 'Gweui',
      headerClassName: 'wordwrap',
      accessor: 'gweui',
      minWidth: 180
    },
    {
      Header: 'Gateway identifier',
      accessor: 'identifier',
      headerClassName: 'wordwrap',
      minWidth: 125
    },
    {
      Header: 'Serial Number',
      accessor: 'serial_number',
      headerClassName: 'wordwrap'
    },
    {
      Header: 'Gateway Name',
      accessor: 'name',
      headerClassName: 'wordwrap'
    },
    {
      Header: 'ARN',
      accessor: 'arn',
      headerClassName: 'wordwrap'
    },
    {
      Header: 'Version',
      accessor: 'version',
      headerClassName: 'wordwrap'
    },
    {
      Header: 'Carrier Type',
      accessor: 'carrier_type',
      headerClassName: 'wordwrap'
    },
    {
      Header: 'Connection Speed',
      accessor: 'connection_speed',
      headerClassName: 'wordwrap'
    },
    {
      Header: 'Lora Signal Strength',
      accessor: 'lora_signal_strength',
      headerClassName: 'wordwrap'
    },
    {
      Header: 'Lat',
      accessor: 'lat',
      headerClassName: 'wordwrap'
    },
    {
      Header: 'Lng',
      accessor: 'lng',
      headerClassName: 'wordwrap'
    },
    {
      Header: 'Creation Date',
      id: 'created_at',
      accessor: (item) => {
        return moment(item.created_at).format('DD/MM/YYYY HH:mm:ss');
      },
      headerClassName: 'wordwrap',
      sortable: true,
      sortMethod: (a, b) => {
        return moment(b).format('x') - moment(a).format('x');
      },
      maxWidth: 180
    },
    {
      Header: 'Update Date',
      id: 'updated_at',
      accessor: (item) => {
        return moment(item.updated_at).format('DD/MM/YYYY HH:mm:ss');
      },
      headerClassName: 'wordwrap',
      sortable: true,
      sortMethod: (a, b) => {
        return moment(b).format('x') - moment(a).format('x');
      },
      maxWidth: 180
    },
    {
      Header: 'Delete Date',
      id: 'deleted_at',
      accessor: (item) => {
        return item.deleted_at
          ? moment(item.deleted_at).format('DD/MM/YYYY HH:mm:ss')
          : null;
      },
      headerClassName: 'wordwrap',
      Cell: (props) => <span className="number">{props.value}</span>,
      sortable: true,
      sortMethod: (a, b) => {
        return moment(b).format('x') - moment(a).format('x');
      }
    }
  ];

  return columns;
};
