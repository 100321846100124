import React, { useEffect, useState } from 'react';
import { Col, FormGroup } from 'reactstrap';
import axios from '../../../axios';
import Select from 'react-select';

const TagFilters = (props) => {
  const [tags, setTags] = useState([]);
  const fetchData = async () => {
    let { data } = await axios.get('tags', {
      params: {
        only_linked: true
      }
    });
    setTags(
      data.map((x) => {
        return { value: x.id, label: x.diagri_id };
      })
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="filter-block labels">
      <p>Tags filter</p>
      <FormGroup row>
        <Col sm="12" lg="12">
          <Select
            name="type"
            value={props.selected}
            onChange={(value) => props.onChange(value, 'tags')}
            options={tags}
            isMulti={true}
            isSearchable={true}
          ></Select>
        </Col>
      </FormGroup>
    </div>
  );
};

export default TagFilters;
