import React, { memo } from 'react';

import WidgetTemplate from '../WidgetTemplate';
import img7 from '../../../assets/images/numbered-list.png';

const RulesWidget = ({ props }) => {
  const onClick = () => {
    props.history.push({
      pathname: 'rules',
      filter: { farms: props.farm ? [props.farm] : [] },
    });
  };

  const icon = (
    <div className="hc-icon">
      <img src={img7} alt="hc-icon"></img>
    </div>
  );

  return (
    <WidgetTemplate
      icon={icon}
      title={'Active Rules'}
      onClick={onClick}
      value={props.ruleCount}
    />
  );
};

export default memo(RulesWidget);
