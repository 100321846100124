import React from 'react';
import {
  Map,
  Polygon,
  FeatureGroup,
  GeoJSON,
  LayersControl,
  Popup
} from 'react-leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { EditControl } from 'react-leaflet-draw';
import L from 'leaflet';
import { GoogleLayer } from 'react-leaflet-google';
import 'react-leaflet-fullscreen/dist/styles.css';
import SiteFeature from './SiteFeature';
import { Link } from 'react-router-dom';

const { BaseLayer } = LayersControl;
const provider = new OpenStreetMapProvider();

const key = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const satellite = 'SATELLITE';

function Feature(props) {
  let type = props.type;
  let LINE;
  if (type === 'Polygon') {
    LINE = (
      <Polygon
        positions={props.geometry}
        color={props.color}
        opacity="0.5"
        fillOpacity="0.5"
      ></Polygon>
    );
  }
  return LINE;
}
class MapFence extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      geofence: JSON.parse(JSON.stringify(props.geofence)),
      position: [-29.132312, 26.057016],
      geo_json: {},
      leaflet_id: null,
      color: this.props.color,
      features_sites: {
        features: []
      },
      bounds: [
        [-31.0, 13.0],
        [-31.0, 33.0]
      ],
      editing: props.editing,
      enableDraw: props.editing,
      new: props.new || false,
      features: {
        features: []
      }
    };

    this.created = this.created.bind(this);
    this.edited = this.edited.bind(this);
    this.tick = this.tick.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
  }
  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }
  componentDidMount() {
    this.tick(this.props);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.tick(nextProps);

    if (nextProps.isFullScreen !== this.props.isFullScreen) {
      // eslint-disable-next-line react/no-string-refs
      this.refs.map.leafletElement._onResize();
    }

    if (nextProps.color !== this.state.color) {
      const map = this.refs.map;
      map.leafletElement.eachLayer((layer) => {
        if (layer._leaflet_id === this.state.leaflet_id) {
          layer.setStyle({
            color: nextProps.color
          });
        }
      });
      // eslint-disable-next-line react/no-string-refs
      this.setState({
        color: nextProps.color
      });
    }
  }

  async tick(props) {
    if (props.geofence.farm_id == null) {
      return;
    }
    let position = this.state.position;
    const coordsReverted = [];
    let coords = [];
    if (props.geofence.position) {
      position = props.geofence.position;
    } else if (props.geofence.address) {
      let address = await provider.search({ query: props.geofence.address });
      if (address.length > 0) {
        position = [address[0].y, address[0].x];
      }
    }
    let gf = null;
    if (!this.state.new) {
      gf = JSON.parse(JSON.stringify(props.geofence));
      coords = JSON.parse(JSON.stringify(gf.geo_json.geometry.coordinates[0]));
      for (let c in coords) {
        let a = L.GeoJSON.coordsToLatLng(coords[c]);
        coordsReverted[c] = [a?.lat, a?.lng];
      }
      gf.geo_json.geometry.coordinates[0] = coordsReverted;
    }
    let features = {
      type: 'FeatureGroup',
      features: []
    };
    let geo = {
      type: 'FeatureCollection',
      features: []
    };
    let sts = {
      type: 'Feature',
      features: []
    };
    let fences = [];
    if (this.props.geofence.features) {
      fences = JSON.parse(JSON.stringify(this.props.geofence.features));
    }

    let sites = [];
    if (this.props.geofence.sites) {
      sites = JSON.parse(JSON.stringify(this.props.geofence.sites));
    }

    for (let gf of fences) {
      if (gf.is_master) {
        geo.features.push({ ...gf.geo_json, color: gf.color });
      } else {
        features.features.push({ ...gf.geo_json, color: gf.color });
      }
    }
    for (let st of sites) {
      sts.features.push({ ...st.geo_json, color: st.color });
    }
    const geofence = gf != null ? gf : props.geofence;
    this.setState({
      ...this.state,
      position,
      geofence,
      features_master: geo,
      features: features,
      features_sites: sts
    });

    let center = JSON.parse(JSON.stringify(geo));
    if (gf?.geo_json) {
      center = JSON.parse(JSON.stringify(gf?.geo_json));
      center.geometry.coordinates[0] = coords;
    }

    this.centerMap(center);
  }

  created(e) {
    this.setState({
      ...this.state,
      enableDraw: false
    });

    let layers = L.layerGroup().addLayer(e.layer);
    this.setState({
      leaflet_id: e.layer._leaflet_id
    });

    layers.eachLayer((a) => {
      if (this.props.type === 'geofence') {
        this.props.newGeofence(a.toGeoJSON());
      }
    });
  }
  edited(e) {
    e.layers.eachLayer((a) => {
      if (this.props.type === 'geofence') {
        if (this.props.onEdit) {
          this.props.onEdit(a.toGeoJSON());
        }
      }
    });
  }
  onDelete() {
    this.setState({
      ...this.state,
      enableDraw: true
    });
  }
  // ROMMY - RESETING VERTEX TO FIX THE MAP SWITCH VIEW //
  resetVertex(e) {
    if (e.layers && this.props.type === 'geofence') {
      let geo_json = JSON.parse(JSON.stringify(this.props.geofence.geo_json));
      geo_json.geometry.coordinates[0] = [];
      e.layers.eachLayer((a) => {
        if (this.props.onEdit && a._index != undefined) {
          let b = a.toGeoJSON();
          geo_json.geometry.coordinates[0][a._index] = b.geometry.coordinates;
        }
      });
      if (geo_json.geometry.coordinates[0].length) {
        geo_json.geometry.coordinates[0].push(
          geo_json.geometry.coordinates[0][0]
        );
      }
      this.props.onEdit(geo_json);
    }
  }
  async centerMap(geofence) {
    if (geofence?.features?.length === 0) {
      let address = await provider.search({
        query: this.props.geofence.address
      });
      if (address.length > 0) {
        let position = [address[0].y, address[0].x];
        this.setState({
          position: position
        });
      }
      return;
    }
    const group = new L.geoJson(geofence);
    let bounds = group.getBounds();
    const corner2 = L.latLng(
      parseFloat(bounds._southWest?.lat),
      parseFloat(bounds._southWest?.lng)
    );
    const corner1 = L.latLng(
      parseFloat(bounds._northEast?.lat),
      parseFloat(bounds._northEast?.lng)
    );
    bounds = L.latLngBounds(corner1, corner2);
    this.setState({
      bounds: bounds
    });
  }
  render() {
    return (
      <Map
        ref="map"
        scrollWheelZoom={false}
        className={`farms mapobject ${this.props.editing ? 'editing' : ''}`}
        // center={this.state.position}
        bounds={this.state.bounds || null}
        zoom={10}
      >
        <FeatureGroup ref="featuregroup">
          <EditControl
            ref="drawControl"
            position="topright"
            onEdited={this.edited}
            onCreated={this.created}
            onDeleted={this.onDelete}
            onEditVertex={(e) => {
              this.resetVertex(e);
            }}
            draw={{
              polygon: this.props.editing &&
                this.state.enableDraw && {
                  shapeOptions: {
                    color: this.state.color,
                    fillOpacity: '0.5',
                    opacity: 0.5
                  }
                },
              polyline: false,
              point: false,
              marker: false,
              circle: false,
              circlemarker: false,
              rectangle: false
            }}
            edit={{
              edit: true,
              delete: true
            }}
          />

          {this.state.geofence.geo_json.type != null && !this.state.new && (
            <Feature
              key={JSON.stringify(this.state.geofence.geo_json)}
              type={this.state.geofence.geo_json.geometry.type}
              geometry={this.state.geofence.geo_json.geometry.coordinates}
              color={this.state.color}
            ></Feature>
          )}
        </FeatureGroup>
        <FeatureGroup
          edit={{
            edit: false,
            delete: false
          }}
        >
          {this.state.features_sites.features.length &&
            !this.state.new &&
            this.state.features_sites.features
              .filter((i) => {
                return i.geometry != null && i.geometry.type != null;
              })
              .map((i) => {
                return (
                  <SiteFeature
                    key={JSON.stringify(i)}
                    color={i.color}
                    type={i.geometry.type}
                    geometry={i.geometry.coordinates}
                    properties={i.properties}
                  ></SiteFeature>
                );
              })}
        </FeatureGroup>
        <LayersControl position="bottomleft">
          <BaseLayer checked name="Google Maps Satellite">
            <GoogleLayer googlekey={key} maptype={satellite} />
          </BaseLayer>
          <BaseLayer name="Google Maps Hybrid">
            <GoogleLayer googlekey={key} maptype={'HYBRID'} />
          </BaseLayer>
          <BaseLayer name="Google Maps Terrain">
            <GoogleLayer googlekey={key} maptype={'TERRAIN'} />
          </BaseLayer>
          <BaseLayer name="Google Maps Roadmap">
            <GoogleLayer googlekey={key} maptype={'ROADMAP'} />
          </BaseLayer>
        </LayersControl>
        {this.state?.features_master?.features?.map((item) => {
          return (
            <GeoJSON
              key={item?.properties?.id}
              data={item}
              style={{
                color: item.color,
                weight: 3,
                fillOpacity: 0.2,
                opacity: 0.8
              }}
            >
              <Popup>
                <b>{item.properties.name}</b>
                <br />
                <br />
                {item.properties.description}
                <br />
                <br />
                <Link to={`/geofence/${item.properties.id}`}>
                  <span>More details</span>
                </Link>
              </Popup>
            </GeoJSON>
          );
        })}
        {this.state?.features?.features?.map((item) => {
          return (
            <GeoJSON
              key={item?.properties?.id}
              data={item}
              style={{
                color: item.color,
                weight: 2,
                fillOpacity: 0.2,
                opacity: 0.8
              }}
            >
              <Popup>
                <b>{item.properties.name}</b>
                <br />
                <br />
                {item.properties.description}
                <br />
                <br />
                <Link to={`/geofence/${item.properties.id}`}>
                  <span>More details</span>
                </Link>
              </Popup>
            </GeoJSON>
          );
        })}
      </Map>
    );
  }
}

export default MapFence;
