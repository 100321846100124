import img7 from '../../../assets/images/numbered-list.png';
import React from 'react';
import { useSelector } from 'react-redux';

import { Card, CardBody, CardTitle, Row, Col } from 'reactstrap';
import ActiveLivestockWidget from '../../Widgets/ActiveLivestockWidget';
import CalvesProducedWidget from '../../Widgets/CalvesProducedWidget';
import NotificationsWidget from '../../Widgets/NotificationsWidget';
import RulesWidget from '../../Widgets/RulesWidget';
import CalvesWeanedWidget from '../../Widgets/CalvesWeanedWidget';
import FeatureProvider from '../../FeatureProvider';
import { FEATURE_LIST } from '../../../constants/subscription';

const TopSection = (props) => {
  return (
    <Row className="highlated-cols">
      <Col sm={12} md={4} className="hc-1 hc-col">
        <FeatureProvider name={FEATURE_LIST.ACTIVE_LIVESTOCK_WIDGET}>
          <ActiveLivestockWidget props={props} />
        </FeatureProvider>
      </Col>

      <Col sm={12} md={4} className="hc-2 hc-col">
        <FeatureProvider name={FEATURE_LIST.CALVES_PRODUCED_WIDGET}>
          <CalvesProducedWidget props={props} />
        </FeatureProvider>
        <FeatureProvider name={FEATURE_LIST.ACTIVE_NOTIFICATIONS_WIDGET}>
          <NotificationsWidget props={props} />
        </FeatureProvider>
      </Col>

      <Col sm={12} md={4} className="hc-3 hc-col">
        <FeatureProvider name={FEATURE_LIST.CALVES_WEANED_WIDGET}>
          <CalvesWeanedWidget props={props} />
        </FeatureProvider>
        <FeatureProvider name={FEATURE_LIST.ACTIVE_RULES_WIDGET}>
          <RulesWidget props={props} />
        </FeatureProvider>
      </Col>
    </Row>
  );
};

export default TopSection;
