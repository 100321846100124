import axios from '../../axios';

const service = {
  async getAllEvents(params) {
    return await axios({
      method: 'get',
      url: '/events',
      params: params
    });
  },

  async getEventById(id) {
    return await axios({
      method: 'get',
      url: `/events/${id}`
    });
  },

  async createNewEvent(payload) {
    return await axios({
      method: 'post',
      url: '/events',
      data: payload
    });
  },

  async deleteEvent(id) {
    return await axios({
      method: 'delete',
      url: `/events/${id}`
    });
  },

  async updateEvent(id, payload) {
    return await axios({
      method: 'put',
      url: `/events/${id}`,
      data: payload
    });
  },

  async acceptEvent(id) {
    return await axios({
      method: 'post',
      url: `/events/${id}/accept`
    });
  },

  async declineEvent(id) {
    return await axios({
      method: 'post',
      url: `/events/${id}/decline`
    });
  }
};

export default service;
