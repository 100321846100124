import React from 'react';
import {
  Row,
  Col,
  Card,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import axios from '../../axios';
import ReactTable from 'react-table';
import SelectionPopover from '../../components/Popovers/SelectionPopover';
import { getTableColumns } from '../../helpers/subscription';
import { connect } from 'react-redux';
import { FEATURE_LIST } from '../../constants/subscription';

class Farms extends React.Component {
  //Tabs
  constructor(props) {
    super(props);

    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      activeTab: '1',
      farms: [],
      isOpenSelectionPopover: false,
      tableData: [],
      query: '',
      selectAll: false,
      dropdownOpen: false,
      selected_rows: [],
      delete_modal: false
    };
    this.toggleButton = this.toggleButton.bind(this);
    this.onClickAllRecords = this.onClickAllRecords.bind(this);
    this.onClickOnThisPage = this.onClickOnThisPage.bind(this);
  }
  onClickOnThisPage() {
    const newCheckboxValue = !this.state.selectAll;

    let checkedCopy = [];
    if (newCheckboxValue) {
      this.state.tableData.map((val) => {
        const id = val?._original?.id ?? val.id;
        checkedCopy.push(id);
      });
    }
    this.setState((state) => ({
      ...state,
      selected_rows: checkedCopy
    }));
    this.setState({
      selectAll: newCheckboxValue
    });
    this.onToggleSelectionPopover();
  }

  onClickAllRecords() {
    const newCheckboxValue = !this.state.selectAll;
    let checkedCopy = [];
    if (newCheckboxValue) {
      this.state.farms.map((val) => {
        checkedCopy.push(val.id);
      });
    }
    this.setState((state) => ({
      ...state,
      selected_rows: checkedCopy
    }));
    this.setState({
      selectAll: newCheckboxValue
    });
    this.onToggleSelectionPopover();
  }
  onToggleSelectionPopover(isOpen) {
    const open =
      isOpen !== undefined
        ? isOpen
        : !this.state.isOpenSelectionPopover && !this.state.selectAll;

    this.setState({
      isOpenSelectionPopover: open
    });
  }

  toggleButton() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  onChange = (value, field) => {
    this.setState((state) => ({
      ...state,
      [field]: value
    }));
  };
  handleKeyPress = (charCode) => {
    if (charCode === 13) {
      this.getFarms();
    }
  };
  onCheckboxChange = (value, field) => {
    this.setState((state) => ({
      ...state,
      [field]: !state[field]
    }));
  };
  onTagChange(value, field) {
    let nt = this.state.edit_tag;
    nt[field] = value;
    this.setState((state) => ({
      ...state,
      edit_tag: nt
    }));
  }
  onRowSelect(rowId) {
    let rowIds = this.state.selected_rows;
    if (rowIds.includes(rowId)) {
      var index = rowIds.indexOf(rowId);
      if (index > -1) {
        rowIds.splice(index, 1);
      }
    } else {
      rowIds.push(rowId);
    }
    if (this.state.farms.length == this.state.selected_rows.length) {
      this.setState({
        selectAll: true
      });
    } else {
      this.setState({
        selectAll: false
      });
    }

    this.setState({
      selected_rows: rowIds
    });
  }
  async deleteRows() {
    let response = await axios.delete('farms/bulk', {
      data: { ids: this.state.selected_rows }
    });
    if (response.status == 200) {
      this.getFarms();
      this.setState({
        delete_modal: false,
        selected_rows: []
      });
    }
  }
  async componentDidMount() {
    this.getFarms();
  }
  async getFarms() {
    let response = await axios.get('farms?query=' + this.state.query);
    if (response.status === 200) {
      this.setState((state) => ({
        ...state,
        farms: response.data,
        tableData: response.data.slice(0, 25)
      }));
    }
  }
  toggleModal(modal) {
    this.setState((state) => ({
      ...state,
      [modal]: !state[modal]
    }));
  }

  //
  render() {
    function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    const columns = [
      {
        Header: (cellinfo) => (
          <div className="position-relative form-check">
            <SelectionPopover
              isOpen={this.state.isOpenSelectionPopover}
              onClickOnThisPage={this.onClickOnThisPage}
              onClickAllRecords={this.onClickAllRecords}
              setOpen={(isOpen) =>
                this.setState({
                  isOpenSelectionPopover:
                    isOpen !== undefined
                      ? isOpen
                      : !this.state.isOpenSelectionPopover
                })
              }
              content={
                <input
                  type="checkbox"
                  defaultChecked={this.state.selectAll}
                  onChange={() => {
                    this.onToggleSelectionPopover();
                    if (this.state.selectAll) {
                      this.setState({
                        selectAll: !this.state.selectAll
                      });
                      this.setState((state) => ({
                        ...state,
                        selected_rows: []
                      }));
                    }
                  }}
                />
              }
            />
          </div>
        ),
        id: 'select',
        accessor: (d) => {
          return { id: d.id, tag: d.tag };
        },
        Cell: (props) => (
          <FormGroup check>
            <Input
              type="checkbox"
              defaultChecked={this.state.selected_rows.includes(props.value.id)}
              value={props.value.id}
              onChange={(e) =>
                this.onRowSelect(
                  parseInt(e.target.value),
                  props.value.tag ? 'linked' : 'unlinked'
                )
              }
              key={props.value.id}
            />{' '}
          </FormGroup>
        ),
        headerClassName: 'wordwrap',
        sortable: false,
        filterable: false,
        maxWidth: 85
      },

      {
        Header: 'Farm ID',
        id: 'farmId',
        accessor: (d) => {
          return { id: d.id, identifier: d.identifier };
        },
        headerClassName: 'wordwrap',
        Cell: (props) => (
          <a
            href="#"
            onClick={() => this.props.history.push('farm/' + props.value.id)}
          >
            {props.value.identifier}
          </a>
        )
      },
      {
        Header: 'Farm Name',
        id: 'farmName',
        accessor: 'name',
        headerClassName: 'wordwrap'
      },
      {
        Header: 'Farm address',
        id: 'farmAddress',
        accessor: 'address.full_address',
        headerClassName: 'wordwrap',
        minWidth: 150
      },
      {
        Header: 'Farm Size (ha)',
        id: 'farmSize',
        accessor: 'size',
        Cell: (props) =>
          props.value != null
            ? numberWithCommas(Number(props.value).toFixed(2))
            : '/',
        headerClassName: 'wordwrap'
      },
      {
        Header: 'Farm Perimeter (km)',
        id: 'farmPerimeter',
        accessor: 'circumference',
        Cell: (props) =>
          props.value != null
            ? numberWithCommas(Number(props.value).toFixed(2))
            : '/',
        headerClassName: 'wordwrap',
        minWidth: 120
      },
      {
        Header: 'Farm Herd #',
        id: 'farmHerd',
        accessor: 'herd_numbers',
        Cell: (props) =>
          props.value != null
            ? numberWithCommas(Number(props.value).toFixed(2))
            : '/',
        headerClassName: 'wordwrap'
      },
      {
        Header: 'Assigned Livestock',
        id: 'assignedLivestock',
        accessor: 'assigned_livestock',
        Cell: (props) =>{
          return  props.value != null
          ? numberWithCommas(Number(props.value).toFixed(2))
          : '/'
        },
        headerClassName: 'wordwrap',
        minWidth: 120
      },
      {
        Header: 'Expected Tracked Livestock',
        id: 'expectedLivestockCount',
        accessor: 'expected_livestock',
        Cell: (props) =>
          props.value != null
            ? numberWithCommas(Number(props.value).toFixed(2))
            : '/',
        headerClassName: 'wordwrap',
        minWidth: 120
      },
      {
        Header: 'Actual Tracked Livestock',
        id: 'activeLivestockCount',
        accessor: 'actual_livestock',
        Cell: (props) =>
          props.value != null
            ? numberWithCommas(Number(props.value).toFixed(2))
            : '/',
        headerClassName: 'wordwrap',
        minWidth: 120
      },

      // {
      //   Header: 'Current Livestock Count',
      //   id: 'currentLivestockCount',
      //   accessor: 'actual_livestock',
      //   Cell: (props) =>
      //     props.value != null
      //       ? numberWithCommas(Number(props.value).toFixed(2))
      //       : '/',
      //   headerClassName: 'wordwrap',
      //   minWidth: 120
      // }
    ];

    function filterCaseInsensitive(filter, row) {
      const id = filter.pivotId || filter.id;

      if (row[id] && typeof row[id] == 'object') {
        if (typeof row[id]['identifier'] == 'number') {
          return row[id]['identifier'] !== undefined
            ? String(row[id]['identifier'])?.includes(filter?.value)
            : true;
        } else {
          return row[id] !== undefined
            ? String(row[id]['identifier']?.toLowerCase())?.includes(
                filter?.value?.toLowerCase()
              )
            : true;
        }
      } else if (typeof row[id] == 'number') {
        return row[id] !== undefined
          ? String(row[id])?.includes(filter?.value)
          : true;
      } else {
        return row[id] !== undefined
          ? String(row[id]?.toLowerCase())?.includes(
              filter?.value?.toLowerCase()
            )
          : true;
      }
    }
    return (
      <div>
        <Row>
          <Col xs="12" md="12" lg="12">
            <Card>
              <Row className="pad-10">
                <Col xs="12" md="12" lg="6">
                  <Row>
                    <Col xs="12" md="12" lg="6" className="listing-heading">
                      <h4 className="">Farms</h4>
                      <Breadcrumb>
                        <BreadcrumbItem>List of Farms</BreadcrumbItem>
                      </Breadcrumb>
                    </Col>
                    <Col xs="12" md="12" lg="6"></Col>
                  </Row>
                </Col>
                <Col
                  xs="12"
                  md="12"
                  lg="6"
                  className="d-flex justify-content-end align-items-center h-100"
                >
                  <InputGroup className="head-search mr-2" size="sm">
                    <Input
                      type="search"
                      id="search"
                      name="query"
                      defaultValue={this.state.query}
                      onChange={(e) =>
                        this.onChange(e.target.value, e.target.name)
                      }
                      placeholder="Search Farms"
                      onKeyPress={(e) => this.handleKeyPress(e.charCode)}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText
                        onClick={() => this.handleKeyPress(13)}
                        className="rg-pointer"
                      >
                        <i className="fas fa-search"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <ButtonDropdown
                    className="float-right"
                    isOpen={this.state.dropdownOpen}
                    toggle={this.toggleButton}
                  >
                    <DropdownToggle caret>Actions</DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => {
                          this.props.history.push('/farm_new');
                        }}
                      >
                        Add farm
                      </DropdownItem>
                      {this.state.selected_rows.length > 0 && (
                        <DropdownItem
                          onClick={() => this.toggleModal('delete_modal')}
                        >
                          Archive farm
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </ButtonDropdown>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="12" lg="12">
                  <ReactTable
                    showPagination={this.state.farms.length > 0}
                    minRows={0}
                    data={this.state.farms}
                    columns={getTableColumns(
                      columns,
                      this.props.subscription.myPlan?.type,
                      FEATURE_LIST.FARMS_LIST
                    )}
                    resizable={true}
                    defaultPageSize={25}
                    filterable={true}
                    defaultFilterMethod={filterCaseInsensitive}
                    onFetchData={(props) => {
                      const data = props.data.length
                        ? props.sortedData.slice(0, props.pageSize)
                        : this.state.notiffarmsications;
                      this.setState({ tableData: data });
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.delete_modal}
          className={this.props.className}
        >
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>Archive farms?</b>
            </h5>
            <br />
            <br />
            <br />
            Are you sure you want to archive selected farms? This farms rules,
            geofences and Sites will also be archived. This action cannot be
            undone.
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => this.toggleModal('delete_modal')}
            >
              Cancel
            </Button>{' '}
            <Button color="danger" onClick={() => this.deleteRows()}>
              Archive
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default connect((state) => state)(Farms);
