import React from 'react';

import TagTable from '../../../../components/Tables/TagTable';

import '../styles.scss';

const AdministrationTags = (props) => {
  return (
    <div className="administration-tag-page">
      <TagTable {...props} />
    </div>
  );
};

export default AdministrationTags;
