import React from "react";

import { Row, Col, Card, CardBody } from "reactstrap";
import ReactTable from "react-table";
import Chart from "react-c3-component";
import Select from "react-select";
import "c3/c3.css";

class OrderStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animalType: "age_group",

      colorPattern: [
        "#40c4ff",
        "#2961ff",
        "#ff821c",
        "#FF5733",
        "#3B1C15",
        "#124908",
        "#493D08",
        "#490843",
      ],
    };

    this.handleTypeChange = this.handleTypeChange.bind(this);
  }

  async handleTypeChange(selectedOption) {
    this.setState({
      animalType: selectedOption["value"],
    });
  }

  capitalize(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
  }
  render() {
    const pad = {
      padding: "0 15px",
    };
    var header = { sex: "Sex", age_group: "Age", stock_type: "Type" };
    var setHeader = header[this.state.animalType];
    const barColumns = [
      {
        Header: <React.Fragment>{setHeader}</React.Fragment>,
        accessor: "type",
        Cell: (props) => {
          return this.capitalize(props.value);
        },
        headerClassName: "wordwrap",
      },
      {
        Header: "Total",
        accessor: "count",
        headerClassName: "wordwrap",
      },
    ];
    // var barData	= [{
    // 	'sex':'male',
    // 	'total':'45'
    // },{
    // 	'sex':'Female',
    // 	'total':'15'
    // }]
    return (
      /*--------------------------------------------------------------------------------*/
      /* Used In Dashboard-4 [General]                                                  */
      /*--------------------------------------------------------------------------------*/
      <React.Fragment>
        <Card className="order-widget">
          <CardBody>
            <Row>
              <Col sm="12" md="6">
                <div id="visitor" className="mt-3">
                  <Chart
                    style={{ height: "190px", width: "100%" }}
                    config={{
                      data: {
                        columns:
                          this.props.livestocKData[this.state.animalType],

                        type: "donut",
                        tooltip: {
                          show: true,
                        },
                      },
                      donut: {
                        label: {
                          show: false,
                        },
                        title: "100%",
                        width: 20,
                      },
                      legend: {
                        hide: true,
                      },
                      color: {
                        pattern: this.state.colorPattern,
                      },
                    }}
                  />
                </div>
              </Col>
              <Col sm="12" md="6" className="gender-cols" style={pad}>
                <Row className="mt-3 gender-graph fixed-height">
                  {this.props.livestockTableData[this.state.animalType].map(
                    function (element, index) {
                      return (
                        <Col
                          xs="4"
                          className="border-right gender-label"
                          key={index}
                        >
                          <i
                            className="fa fa-circle"
                            style={{
                              color: this.state.colorPattern[index],
                              paddingRight: "5px",
                            }}
                          ></i>
                          <span>
                            {element.type.charAt(0).toUpperCase() +
                              element.type.slice(1)}
                          </span>
                        </Col>
                      );
                    },
                    this
                  )}
                </Row>
                <div className="livestockselect">
                  <Select
                    name="filterType"
                    defaultValue={{ label: "Age", value: "age_group" }}
                    options={[
                      { value: "sex", label: "Sex" },
                      { value: "stock_type", label: "Type" },
                      { value: "age_group", label: "Age" },
                    ]}
                    onChange={this.handleTypeChange}
                    isMulti={false}
                  ></Select>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <ReactTable
          showPagination={false}
          minRows={0}
          data={this.props.livestockTableData[this.state.animalType]}
          columns={barColumns}
          resizable={true}
        />
      </React.Fragment>
    );
  }
}

export default OrderStatus;
