import React, { memo, useState } from 'react';
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
} from 'reactstrap';

import TaskModal from '../../../../components/Modals/TaskModal';
import { TASK_MODAL_MODE } from '../../../../constants/task';

const TaskHeaderOptions = memo(() => {
  const [isOpenTaskModal, setOpenTaskModal] = useState(false);
  const [isOpenActionsDropdown, setOpenActionsDropdown] = useState(false);

  const onActionsClick = () => {
    setOpenActionsDropdown((prevState) => !prevState);
  };

  const onAddTaskClick = () => {
    setOpenTaskModal(true);
  };

  const onSubmit = () => {
    setOpenTaskModal(false);
  };

  return (
    <div className="calendar__header-options">
      <ButtonDropdown
        className="float-right"
        isOpen={isOpenActionsDropdown}
        toggle={onActionsClick}
      >
        <DropdownToggle caret>Actions</DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={onAddTaskClick}>Add task</DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
      <TaskModal
        onSubmit={onSubmit}
        isOpen={isOpenTaskModal}
        onCancel={() => setOpenTaskModal(false)}
        mode={TASK_MODAL_MODE.CREATE}
      />
    </div>
  );
});

export default TaskHeaderOptions;
