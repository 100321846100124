import { REDUCERS } from '../../constants/reducers';

const initialState = {
  tasks: [],
  queryParams: {},
  selectedTasks: [],
  currentTask: {
    title: '',
    description: '',
    startDate: '',
    endDate: '',
    remindDate: '',
    relationId: '',
    relationType: '',
    recurringEndDate: '',
    recurringType: '',
    userIds: [],
    userEmails: []
  }
};

const task = (state = initialState, action) => {
  switch (action.type) {
    case REDUCERS.TASK.SET_TASK_STATE: {
      return Object.assign({}, state, {
        ...state,
        ...action.value
      });
    }
    case REDUCERS.TASK.SET_TASK_QUERY_PARAMS: {
      return Object.assign({}, state, {
        queryParams: { ...action.value }
      });
    }
    case REDUCERS.TASK.SET_TASK_LIST: {
      return Object.assign({}, state, {
        tasks: [...action.value]
      });
    }
    case REDUCERS.TASK.SET_CURRENT_TASK: {
      return Object.assign({}, state, {
        currentTask: { ...state.currentTask, ...action.value }
      });
    }
    case REDUCERS.RESET:
      return initialState;
    default:
      return state;
  }
};

export { task };
