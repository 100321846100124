export const filterCaseInsensitive = (filter, row) => {
  const id = filter.pivotId || filter.id;
  if (row[id]) {
    if (typeof row[id] == 'object') {
      if (typeof row[id]['identifier'] == 'number') {
        return row[id]['identifier'] !== undefined
          ? String(row[id]['identifier'])?.includes(filter.value)
          : true;
      } else {
        return row[id] !== undefined
          ? String(row[id]['identifier']?.toLowerCase())?.includes(
              filter?.value?.toLowerCase()
            )
          : true;
      }
    } else if (typeof row[id] == 'number') {
      return row[id] !== undefined
        ? String(row[id])?.includes(filter?.value)
        : true;
    } else {
      return row[id] !== undefined
        ? String(row[id]?.toLowerCase())?.includes(filter?.value?.toLowerCase())
        : true;
    }
  }
};

export const defaultSortMethod = (a, b) => {
  if (!a) return -1;
  return a?.localeCompare(b);
};
